<template>
  <div class="campaign-list-table box-icon-a-disabled">
    <!-- <div class="title-table-remark">
      {{ title_table_description }}
    </div> -->
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: tableHeight, x: 1300 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.application_page.btn.ok,
        filterReset: lbls.application_page.btn.reset,
      }"
      :custom-row="customRowTable"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-name" slot-scope="text, record">
        <div class="d-flex align-items-center">
          <div class="ml-2">
            {{
              record.application
                ? record.application.name
                  ? record.application.name
                  : "-"
                : "-"
            }}
          </div>
        </div>
      </template>
      <template slot="template-description" slot-scope="text, record">
        <div style="white-space: pre-line">
          {{ record.application.description }}
        </div>
      </template>
      <template slot="template-status" slot-scope="text">
        <a-tag v-if="text" :color="'green'" class="tag-status">
          <a-badge
            :status="'success'"
            :text="lbls.application_page.tbl_colum_active"
          />
        </a-tag>
        <a-tag v-else :color="'red'" class="tag-status">
          <a-badge
            :status="'error'"
            :text="lbls.application_page.tbl_colum_inactive"
          />
        </a-tag>
      </template>
      <template slot="template-package" slot-scope="text, record">
        <div>
          {{
            record.package
              ? record.package.name
                ? record.package.name
                : "-"
              : "-"
          }}
        </div>
      </template>
      <template slot="template-edit" slot-scope="record">
        <div class="action-list">
          <a-tooltip v-if="!isPermissionEdit">
            <template slot="title">
              {{ lbls.btn.view }}
            </template>
            <a @click="handleEdit(record)">
              <a-icon type="eye" />
            </a>
          </a-tooltip>
          <a-tooltip v-if="isPermissionEdit">
            <template slot="title">
              {{ lbls.application_page.btn.edit }}
            </template>
            <a @click="handleEdit(record)">
              <a-icon type="edit" />
            </a>
          </a-tooltip>
          <a-tooltip  v-if="isPermissionEdit" placement="topRight">
            <template slot="title">
              {{ lbls.application_page.btn.change_package }}
            </template>
            <a @click="handleChangePackage(record)">
              <a-icon type="retweet" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
    tableHeight: {
      type: Number,
    },
  },
  data() {
    return {
      permission_key: "application",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      appsFilters: [],
      isFilters: false,
      // title_table_description: "",
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "135px",
          keys: "tbl_colum_status",
          dataIndex: "application.is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.application.is_active === value;
          },
        },
        {
          id: "BzbsId",
          title: "Buzzebees Application ID",
          keys: "tbl_colum_application_id",
          dataIndex: "application.buzzebees_application_id",
          width: "220px",
        },
        {
          id: "Name",
          title: "Name",
          keys: "tbl_colum_name",
          dataIndex: "application.name",
          width: "350px",
          sorter: (a, b) =>
            a.application.name.localeCompare(b.application.name),
          scopedSlots: { customRender: "template-name" },
        },
        {
          id: "Description",
          title: "Description",
          width: "350px",
          keys: "tbl_colum_description",
          dataIndex: "application.description",
          scopedSlots: { customRender: "template-description" },
        },
        {
          id: "Package",
          title: "Package",
          keys: "tbl_colum_package",
          dataIndex: "package.name",
          scopedSlots: {
            customRender: "template-package",
          },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return !record.package;
            }
            if (record.package) {
              return record.package.id == value;
            }
          },
        },
        {
          title: "",
          width: "80px",
          align: "center",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionView: true,
      isPermissionEdit: true,
    };
  },
  watch: {
    datatable() {
      this.setDataFilters();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
      // this.title_table_description = this.lbls.application_page
      //   .title_table_description
      //   ? this.lbls.application_page.title_table_description
      //   : "*Double-click a table row to edit an item.";
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    // this.title_table_description = this.lbls.application_page
    //   .title_table_description
    //   ? this.lbls.application_page.title_table_description
    //   : "*Double-click a table row to edit an item.";
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.setDataFilters();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        this.isPermissionView = permission_page.includes("application-view");
        this.isPermissionEdit = permission_page.includes("application-edit");
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.application_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.application_page[filters.text];
          });
        }
      });
    },
    setDataFilters() {
      this.appsFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.datatable, (element) => {
        if (element.package) {
          let item = element.package;
          this.appsFilters.push({
            text: item.name,
            value: item.id,
          });
        }
      });
      this.appsFilters = [
        ...new Map(this.appsFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumns = this.columns.find((o) => o.id == "Package");
      if (findcolumns) {
        findcolumns.filters = this.appsFilters;
      }
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    handleChangePackage(record) {
      this.$emit("handlechangepackage", record);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["application.is_active"]
        ? filters["application.is_active"]
        : [];
      let isFilterspackage = filters["package.name"]
        ? filters["package.name"]
        : [];
      isFiltersStatus = isFiltersStatus.length > 0;
      isFilterspackage = isFilterspackage.length > 0;
      isFilters = isFiltersStatus || isFilterspackage;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    customRowTable(record, index) {
      return {
        attrs: {
          id: index,
        },
        on: {
          dblclick: () => {
            this.handleEdit(record);
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
