<template>
  <div>
    <a-modal
      id="modaldetail"
      v-model="conditionmodal.visible"
      centered
      class="modal-with-content modal-with-details modal-with-content-custom-change"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <span
          >{{ conditionmodal.title }} :
          <span class="name-content">
            {{ conditionmodal?.application_name }}
          </span>
        </span>
      </template>
      <div class="d-flex align-center mb-2">
        <div class="line-title">{{ lbls.package_page.current_package }}</div>
        <div class="line-title-hr ml-2"></div>
      </div>
      <div class="box-list-item-apps current-color">
        <div class="d-flex align-items-start">
          <div class="mx-2 my-2">
            <div class="item-app-custom">
              <div class="mx-1">
                <div>{{ dataCurrent.display_name }}</div>
                <div class="text-secondary-custom gray mt-1">
                  {{ dataCurrent.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-center mb-2 mt-4">
        <div class="line-title">{{ lbls.application_page.select.name }}</div>
        <div class="line-title-hr ml-2"></div>
      </div>
      <div class="box-list-item-apps">
        <div class="box-item-apps-body">
          <div
            v-for="(item, i) in dataItems"
            :key="item.id"
            class="mr-2 pt-2 px-2"
          >
            <a-badge v-if="item.id == selectId" class="w-100">
              <div slot="count" class="badge-ribbon ribbon-placement-end">
                <div class="px-2">Select</div>
                <div class="ribbon-corner"></div>
              </div>
              <div
                class="d-flex align-items-start item-app"
                :class="{ active: item.id == selectId }"
                @click="handleSelect(item)"
              >
                <div class="mx-2 title-app-name">
                  <div class="item-app-custom">
                    <div class="mx-1">
                      <div>{{ i + 1 }}. {{ item.display_name }}</div>
                      <div class="text-secondary-custom mt-1">
                        {{ item.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-badge>
            <div
              v-else
              class="d-flex align-items-start item-app"
              :class="{ active: item.id == selectId }"
              @click="handleSelect(item)"
            >
              <div class="mx-2 title-app-name">
                <div class="item-app-custom">
                  <div class="mx-1">
                    <div>{{ i + 1 }}. {{ item.display_name }}</div>
                    <div class="text-secondary-custom mt-1">
                      {{ item.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button size="large" @click="handleClose">
          {{ lbls.package_page.btn.close }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{ lbls.user_page.btn.save }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import _ from "lodash";
import CommonMixin from "@/mixin/CommonMixin";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";

export default {
  name: "ManageChangPackage",
  components: {},
  mixins: [CommonMixin, ErrorApiMixin],
  props: {
    conditionmodal: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      disabledok: true,
      dataItems: [],
      dataCurrent: {},
      selectId: "",
    };
  },
  watch: {
    dataapp() {
      this.dataItems = _.cloneDeep(this.data.items);
      this.dataCurrent = _.cloneDeep(this.data.current_package);
      this.$forceUpdate();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataItems = _.cloneDeep(this.data.items);
      this.dataCurrent = _.cloneDeep(this.data.current_package);
    },
    handleClose() {
      this.selectId = "";
      this.conditionmodal.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSelect(item) {
      console.log("handleSelect : ", item);
      this.selectId = item.id;
      this.disabledok = false;
    },
    handleSave() {
      this.$emit("actionModal", this.selectId);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";

.modal-with-content-custom-change {
  .ant-modal-body {
    max-height: inherit !important;
  }
  .align-center {
    align-items: center;
  }
  .line-title {
    white-space: nowrap;
    font-weight: 500;
    font-size: small;
  }
  .line-title-hr {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
  }
  .box-list-item-apps {
    border: 2px solid var(--primary-gold-bzb-primary-bzb);
    font-weight: 500;
    background: #fff;
    .box-item-apps-body {
      height: calc(100vh - 450px);
      overflow: auto;
      .anticon {
        vertical-align: 5px;
      }
      .badge-ribbon {
        background-color: #52c41a;
        color: white;
        min-width: 20px;
        font-weight: normal;
        text-align: center;
        box-shadow: 0 0 0 1px white;
        box-sizing: border-box;
        margin: 0;
        font-size: 14px;
        line-height: 22px;
        list-style: none;
        top: 6px;
        white-space: nowrap;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
        right: 19px;

        .ribbon-corner {
          position: absolute;
          top: 100%;
          width: 8px;
          height: 8px;
          color: currentcolor;
          border: 4px solid;
          transform: scaleY(0.75);
          transform-origin: top;
          filter: brightness(75%);
        }

        &.ribbon-placement-end {
          .ribbon-corner {
            color: #52c41a;
            inset-inline-end: 0;
            border-inline-end-color: transparent;
            border-block-end-color: transparent;
          }
        }
      }
    }
    .box-item-apps-footer {
      border-top: 1px solid #dcdddf;
      padding-top: 8px;
      margin-top: 16px;
      min-height: 30px;
      font-weight: normal;
    }
    .title-app-name {
      line-height: 1;
      cursor: pointer;
    }
    .item-app {
      cursor: pointer;
      border: 1px solid var(--primary-gold-bzb-gold-8);
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      &.active {
        border: 1px solid var(--primary-gold-bzb-primary-bzb);
        background: var(--primary-gold-bzb-gold-9);
      }
    }
    .item-app:hover {
      border: 1px solid var(--primary-gold-bzb-primary-bzb);
      background: var(--primary-gold-bzb-gold-9);
    }
    .item-app-custom {
      display: flex;
      .app-img {
        border: 1px solid var(--grey-grey-8);
        border-radius: 4px;
        width: 50px;
        img {
          border-radius: 4px;
        }
      }
    }
    &.current-color {
      background-color: bisque;
    }
  }
  .body-danger {
    border: 2px solid var(--red-red-6);
  }
  .show-border-bottom {
    border-bottom: 1px solid #dcdddf;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
  .text-secondary-custom {
    line-height: 1.2;
    &.gray {
      color: gray;
    }
  }
}
</style>
