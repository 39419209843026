import { render, staticRenderFns } from "./ManageRoleSetRole-test.vue?vue&type=template&id=2cf189ce"
import script from "./ManageRoleSetRole-test.vue?vue&type=script&lang=js"
export * from "./ManageRoleSetRole-test.vue?vue&type=script&lang=js"
import style0 from "./ManageRoleSetRole-test.vue?vue&type=style&index=0&id=2cf189ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports