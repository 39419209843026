<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :is-action-flex=true
        :databreadcrumbs="breadcrumbs"
        :title="lbls.package_page.title_page"
        :totallist="totalList"
      >
      <template #actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.package_page.input_search"
            class="input-search"
            size="large"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
        </template>
      </Breadcrumbs>
      <!-- <div class="row">
        <PageHeader :buypackage="false" @onsearch="onSearch($event)" />
      </div> -->
      <div
        v-if="isBuyPackage"
        id="box-details"
        class="manage-page-detail buypackage"
      >
        <a-skeleton v-if="isShowSkeleton" class="mt-3" />
        <div v-if="data.length > 0 && !isShowSkeleton" class="page-wrapper">
          <carousel :per-page="itemPerPage">
            <slide v-for="item in data" :key="item.id">
              <ItemPackage
                :dataitempackage="item"
                @handlecreate="handleCreate($event)"
              />
            </slide>
          </carousel>
        </div>
        <div
          v-if="data.length == 0 && !isShowSkeleton"
          class="w-100 text-center bg-white p-5"
        >
          <a-empty :image="simpleImage" />
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import Cache from "@/helper/Cache.js";
import { Empty } from "ant-design-vue";
import { Carousel, Slide } from "vue-carousel";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
//import PageHeader from "@/module/Package/components/Layout/PageHeader.vue";
import ItemPackage from "@/module/Package/components/Layout/ItemPackage.vue";
import managePackageAPI from "@/core/Page/ManagePackageAPI";

export default {
  name: "BuyPackage",
  components: {
    Carousel,
    Slide,
    AppLayout,
    //PageHeader,
    ItemPackage,
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      isPermissionCreate: true,
      permission_key: "application",
      action_key: "create",
      isShowSkeleton: true,
      isBuyPackage: true,
      data: {},
      breadcrumbs: [],
      objBuyPackage: [],
      titleModalProcess: "",
      isModalProcess: false,
      isModalResponse: false,
      itemPerPage: 3,
      totalList: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
      let permission_page = this.handlePermissionPage(this.permission_key);
      this.isPermissionCreate = permission_page.includes("package-create");
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.checkLayout);
    });
    this.checkLayout();
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.callGetList();
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.package,
        name: "ManagePackage",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.package_page.buy_package,
        name: "BuyPackage",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.callGetList();
      } else {
        return;
      }
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    onBuyPackage() {
      this.isBuyPackage = true;
    },
    checkLayout() {
      let boxDetail = document.querySelector("#box-details");
      this.boxDetailWidth = boxDetail.offsetWidth;
      if (this.boxDetailWidth > 1350) this.itemPerPage = 4;
      if (this.boxDetailWidth > 1700) this.itemPerPage = 5;
      if (this.boxDetailWidth > 2080) this.itemPerPage = 6;
    },
    callGetList() {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
      };
      if (this.nameSearch) {
        params.package_name = this.nameSearch;
      }
      managePackageAPI
        .getPackageList(params)
        .then((res) => {
          this.isShowSkeleton = false;
          this.$forceUpdate();
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                this.data = res.data.result.items;
                this.totalList = res.data.result.items.length;
              }
            }
          }
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleCreate($event) {
      this.$confirm({
        title: this.lbls.package_page.modal.title_confirm,
        content: this.lbls.package_page.modal.content_buy,
        centered: true,
        autoFocusButton: null,
        okText: this.lbls.package_page.btn.ok,
        cancelText: this.lbls.package_page.btn.cancel,
        closable: true,
        onOk: () => {
          this.handleBuyPackage($event);
        },
      });
    },
    handleBuyPackage($event) {
      console.log("handleBuyPackage : ", $event.id);
      let params = {
        account_id: this.exAccount.account.id,
        package_id: $event.id,
      };
      managePackageAPI
        .postBuyPackage(params)
        .then((res) => {
          if (res.data) {
            if (res.data.is_success) {
              this.isShowSkeleton = false;
              this.handleLoading(false);
              this.modalSuccess();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(false);
          this.modalError(error);
        });
    },
    modalSuccess() {
      this.$success({
        title: this.lbls.package_page.modal.title_success,
        content: this.lbls.package_page.modal.content_buy_success,
        centered: true,
        autoFocusButton: null,
        closable: true,
        okText: this.lbls.package_page.btn.close,
        onOk: () => {
          this.$router.push({ name: "ManagePackage" });
        },
      });
    },
    modalError(error) {
      console.log("error : ", error?.response?.data);
      var message =
        error?.response?.data?.error[0]?.id == 16004
          ? this.lbls.package_page.modal.content_have_your_package
          : error?.response?.data?.error[0]?.message
          ? error?.response?.data?.error[0]?.message
          : this.lbls.package_page.modal.content_error;

      this.$error({
        title: this.lbls.package_page.modal.title_error,
        content: message,
        centered: true,
        okText: this.lbls.package_page.btn.ok,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.buypackage {
  margin: 0;
  height: calc(100vh - 280px);
  .page-wrapper {
    height: 100%;
    background: $color-white;
    border-radius: $border-radius;
    padding: 4px 8px;
    .VueCarousel {
      height: calc(100% - 10px);
    }
    .VueCarousel-wrapper {
      height: 100% !important;
    }
    .VueCarousel-inner {
      height: 100% !important;
    }
    .VueCarousel-dot,
    .VueCarousel-dot-container {
      margin-top: 0 !important;
    }
    .VueCarousel-dot--active {
      background-color: #ffb133 !important;
    }
  }
  .item {
    flex-grow: 1;
    min-width: 300px;
    height: 100%;
    border: solid 1px #ffb133;
    background-color: #fff;
    border-radius: 10px;
    cursor: grab;
    transform: scale(0.95);
    .item-header {
      height: 90px;
      line-height: 1.5;
      padding: 16px;
      border-bottom: solid 1px #ffb133;
      display: flex;
      justify-content: space-between;
      .header-name {
        color: $black-black-1;
      }
      .header-title {
        color: $green-green-3;
        font-size: 28px;
      }
    }
    .item-body {
      height: calc(100% - 200px);
      padding: 8px 16px;
      font-size: 12px;
      line-height: 2;
      border-bottom: solid 1px #ffb133;
      overflow: auto;
      .body-description {
        color: $grey-grey-4;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 16px;
        opacity: 0.8;
      }
      .body-detail {
        font-size: 14px;
      }
    }
    .item-footer {
      height: 110px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-btn-custom {
        height: 45px;
        border-radius: 6px;
        background-color: #ffa726;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .item:hover {
    transform: scale(1) !important;
    box-shadow: 0 0 10px #0003;
  }
}
</style>
