<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <Breadcrumbs
          :is-action-flex="true"
          :databreadcrumbs="breadcrumbs"
          :title="dataPageHeader.name"
          :count="dataApplications ? dataApplications.length : null"
        >
          <template v-if="!isShowSkeleton" #actions>
            <div class="action-list">
              <a-tooltip
                placement="top"
                :disabled="
                  dataPageHeader.is_default_role &&
                  dataPageHeader.name == configAdminName
                "
              >
                <template slot="title">
                  {{ lbls.role_page.btn.edit }}
                </template>
                <a @click="handleEdit()">
                  <a-icon type="edit" />
                </a>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.btn.assign_to_role }}
                </template>
                <a
                  @click="
                    goToPageAndQuery('ManageAssigntoRole', $route.query.id)
                  "
                >
                  <a-icon type="usergroup-add" />
                </a>
              </a-tooltip>
              <a-tooltip placement="topRight">
                <template slot="title">
                  {{ lbls.role_page.btn.manage }}
                </template>
                <router-link
                  :to="{
                    name: 'ManageRoleSetPerMission',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                >
                  <a-icon type="setting" />
                </router-link>
              </a-tooltip>
              <a-tooltip
                placement="topLeft"
                :disabled="dataPageHeader.is_default_role"
              >
                <template slot="title">
                  {{ lbls.role_page.btn.delete }}
                </template>
                <a @click="handleDeleteRole()">
                  <a-icon type="delete" />
                </a>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{ lbls.role_page.setrole.title_change_status }}
                </template>
                <a-switch
                  :checked="dataPageHeader.is_active"
                  :disabled="
                    dataPageHeader.is_default_role &&
                    dataPageHeader.name == configAdminName
                  "
                  size="small"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </div>
          </template>
        </Breadcrumbs>
        <div v-if="isShowSkeleton" class="background-white p-5">
          <a-skeleton />
        </div>
        <div v-show="showDataApplications">
          <ApplicationsList
            ref="applicationsList"
            :datapageheader="dataPageHeader"
            :dataapplications="dataApplications"
            :hasmoredata="hasMoreDataApp"
            @callgetlist="callGetList"
            @handleadd="handleAdd"
            @handleedit="handleEdit"
            @handledeleterole="handleDeleteRole"
            @onsearch="onSearch($event)"
            @handledeleteapp="handleDeleteApp($event)"
            @handledeleteall="handleDeleteAllApp()"
          />
        </div>
        <!-- Modal -->
        <div v-if="isModalProcess">
          <ModalProcess
            :modalprocess="modalprocess"
            :dataobjselect="dataObjSelect"
            @actionModal="actionModal($event)"
          >
          </ModalProcess>
        </div>
        <div v-if="isShowModalSelectApp">
          <ModalSelectApp
            :modalcondition="modalCondition"
            :dataapplications="dataApplicationsModal"
            :hasmoredata="hasMoreData"
            :showloading="showLoading"
            @callgetlist="checkCallGetData"
            @onsearch="onSearchModal($event)"
            @actionModal="actionModal($event)"
          >
          </ModalSelectApp>
        </div>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import { config } from "@/config/bzbsconfig.js";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import managePermissionAPI from "@/core/Page/ManagePermissionAPI";
import ModalProcess from "@/module/Role/ManageAssignRole/components/Modal/ModalProcess.vue";
import ModalSelectApp from "@/module/Role/ManageAssignRole/components/Modal/ModalSelectApp.vue";
import ApplicationsList from "@/module/Role/ManageAssigntoRole/components/Layout/ApplicationsList.vue";

export default {
  name: "ManageAssignApplicationstoRole",
  components: {
    AppLayout,
    ApplicationsList,
    ModalProcess,
    ModalSelectApp,
  },
  data() {
    return {
      breadcrumbs: [],
      imgEmpty: config.themeUrl + "/img/empty.svg",
      isShowSkeleton: true,
      dataPageHeader: {},
      pageGetlist: 0,
      dataApplications: [],
      dataApplicationsModal: [],
      objSelect: [],
      dataObjSelect: [],
      nameSearch: "",
      showDataApplications: false,
      hasMoreData: false,
      hasMoreDataApp: false,
      isEdit: false,
      isDelete: false,
      isDeleteApp: false,
      isDeleteAllApp: false,
      isShowModalSelectApp: false,
      page: 0,
      pageCopy: 0,
      objDataSave: [],
      nameSearchModal: [],
      isAddApp: false,
      isCopiesPerMission: false,
      titleModalProcess: null,
      dataEditForCheck: {},
      isModalProcess: false,
      isModalAddApps: false,
      isModalAddPermissionApps: false,
      showLoading: false,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
  },
  mounted() {},
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.setBreadcrumbs();
    this.init();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.pageGetlist = 0;
            this.dataApplications = [];
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assignapplicationstorole,
        name: "ManageAssignApplicationstoRole",
      });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetList() {
      this.pageGetlist = this.pageGetlist + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.pageGetlist,
        page_size: 100,
      };
      if (this.nameSearch) {
        params.application_name = this.nameSearch;
      }
      managePermissionAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            _.forEach(result, (item) => {
              item.application.logo_url = item.application.logo_url
                ? item.application.logo_url
                : this.logo_default;
            });
            this.dataApplications.push(...result);
            this.hasMoreDataApp = paging.has_more_data;
            this.isShowSkeleton = false;
            this.showDataApplications = true;
            this.handleLoading(false);
          } else {
            this.isShowSkeleton = false;
            this.showDataApplications = true;
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.nameSearch = text != undefined ? text : "";
        this.pageGetlist = 0;
        this.dataApplications = [];
        this.callGetList();
      } else {
        return;
      }
    },
    resetCondition() {
      this.isAddApp = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.hasMoreData = false;
      this.isShowModalSelectApp = false;
      this.page = 0;
      this.pageCopy = 0;
      this.dataApplicationsModal = [];
      this.objDataSave = [];
      this.nameSearchModal = "";
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        this.resetCondition();
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
        if (this.isDeleteAllApp) {
          this.objDataSave = value;
          this.callPostDeleteDataApp();
        }
        if (this.isAddApp) {
          this.objDataSave = value;
          this.callPostAddData();
        }
      }
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.isModalProcess = false;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
                this.handleLoading(false);
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push({ name: "ManageRoles" });
        }, 500);
      } else if (this.isAddApp) {
        this.resetCondition();
        this.init();
      } else if (this.isDeleteApp || this.isDeleteAllApp) {
        if (this.isDeleteApp) {
          _.forEach(this.objDataSave, (item) => {
            this.dataApplications = this.dataApplications.filter(
              (x) => x.application.id != item.id
            );
          });
        }
        if (this.isDeleteAllApp) {
          this.resetCondition();
          this.pageGetlist = 0;
          this.dataApplications = [];
          this.callGetList();
        }
      }
    },
    handleDeleteRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_delete,
        content: this.objSelect.name,
        okText: this.lbls.role_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isShowSkeleton = true;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleDeleteApp(item) {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDeleteApp = true;
      this.objDataSave.push(item);
      this.$confirm({
        class: "custom-width-confirm",
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_remove_apps,
        content: item.name,
        okText: this.lbls.role_page.modal.btn.remove,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteDataApp();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },

    changeSwitch(checked) {
      this.$refs.applicationsList.changeSwitch(checked);
    },

    setDataAppsForSave() {
      let data = [];
      _.forEach(this.objDataSave, (item) => {
        data.push(item.id);
      });
      return data;
    },
    callPostDeleteDataApp() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.setDataAppsForSave(),
      };
      managePermissionAPI
        .postDeleteApplications(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            this.isModalAddApps = false;
            this.isModalAddPermissionApps = false;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_remove_apps_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleDeleteAllApp() {
      this.resetCondition();
      this.handleLoading(true);
      this.isDeleteAllApp = true;
      this.callGetListForDelete();
    },
    callGetListForDelete() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.page,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      managePermissionAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.hasMoreData = paging.has_more_data;
            let data = [];
            if (result.length > 0) {
              _.forEach(result, (item) => {
                data.push({
                  id: item.application.id,
                  bzbs_id: item.application.buzzebees_application_id,
                  name: item.application.name,
                  package_dsname: item.package.display_name,
                  logo_url: item.application.logo_url,
                });
              });
            } else {
              data = _.cloneDeep(this.dataApplicationsModal);
              this.dataApplicationsModal = [];
            }
            this.dataApplicationsModal.push(...data);
            this.titleModalProcess =
              this.lbls.role_page.modal_add_app.title_remove;
            this.showModalAddApps();
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    showModalAddApps() {
      this.handleLoading(false);
      this.isShowModalSelectApp = true;
      let typeModal = this.checkTypeModal();
      this.modalCondition = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isAddApp: this.isAddApp,
        isDeleteApp: this.isDeleteApp,
        isCopiesPerMission: this.isCopiesPerMission,
        hasmoredata: this.hasMoreData,
        type: typeModal,
      };
      this.showLoading = false;
      if (this.isAddApp) {
        this.modalCondition.okText = this.lbls.role_page.btn.add_user;
      }
      if (this.isDeleteAllApp) {
        this.modalCondition.okText = this.lbls.role_page.btn.delete_user;
      }
    },
    checkTypeModal() {
      let type = null;
      if (this.isAddApp) {
        type = 0;
      }
      if (this.isDeleteAllApp) {
        type = 2;
      }
      return type;
    },
    checkCallGetData() {
      if (this.isAddApp) {
        this.callGetAvailableApp();
      }
      if (this.isDeleteAllApp) {
        this.callGetListForDelete();
      }
    },
    onSearchModal(searchData) {
      let text = searchData;
      if (text != this.nameSearchModal) {
        this.nameSearchModal = text != undefined ? text : "";
        if (this.isDeleteAllApp) {
          this.page = 0;
          this.pageGetlistDelete = 0;
          this.dataApplicationsModal = [];
          this.callGetListForDelete();
        }
        if (this.isAddApp) {
          this.page = 0;
          this.dataApplicationsModal = [];
          this.callGetAvailableApp();
        }
      } else {
        return;
      }
    },
    handleAdd() {
      this.resetCondition();
      this.handleLoading(true);
      this.isAddApp = true;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_add;
      this.callGetAvailableApp();
    },
    callGetAvailableApp() {
      this.hasMoreData = false;
      this.showLoading = true;
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.page,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      managePermissionAPI
        .getAvailableApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let items = res.data.result.items;
            let paging = res.data.result.paging;
            _.forEach(items, (item) => {
              this.dataApplicationsModal.push({
                id: item.application.id,
                bzbs_id: item.application.buzzebees_application_id,
                name: item.application.name,
                package_dsname: item.package.display_name,
                logo_url: item.application.logo_url,
              });
            });
            this.hasMoreData = paging.has_more_data;
            this.showModalAddApps();
          } else {
            this.showLoading = false;
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.setDataAppsForSave(),
      };
      var valueSaveJSON = JSON.stringify(valueSave);
      managePermissionAPI
        .postAddApplications(valueSaveJSON)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_add_apps_success;
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    closeModalProcess() {
      this.isModalProcess = false;
      this.isShowSkeleton = true;
      this.showDataApplications = false;
    },
  },
};
</script>
