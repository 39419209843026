<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      breakpoint="xl"
      width="260"
    >
      <div class="logo">
        <router-link to="manageproduct">
          <img :src="logo" />
          <h1>{{ appname }}</h1>
        </router-link>
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
      </div>
      <div class="menu-list">
        <div v-for="item in menuList" :key="item.to.name" class="menu-item">
          <router-link
            v-b-tooltip.hover.right="item.title"
            placement="righttop"
            class="menu-link"
            :to="item.to.name"
          >
            <span class="icon"><i :class="item.icon"></i></span>
            <span class="text collapsed-hide">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <div class="row mx-0">
          <div class="col-6 pl-0">
            <div class="pagename">
              <span @click="handleBack()">
                {{ pagetitle.pagename }}
              </span>
              <span v-if="pagetitle.subPageName" style="color: #125faf">
                {{ pagetitle.subPageName }}</span
              >
            </div>
          </div>
          <div class="col-6 d-flex justify-content-end pr-0">
            <div class="action-col d-flex">
              <a-dropdown class="pl-sm-4 px-4">
                <a class="ant-dropdown-link">
                  <div class="account-text d-none d-sm-block">
                    <span class="account-role"> test01 </span>
                    <span class="account-name color-grey-9e">
                      {{ msAccount }}
                    </span>
                  </div>
                  <a-avatar :src="avatar" />
                </a>
                <a-menu slot="overlay" class="account-dropdown">
                  <a-menu-item
                    v-for="item in getAccountMenuList()"
                    :key="item.to.name"
                  >
                    <a v-if="item.to.name == 'logout'" @click="logout">
                      <span class="text">{{ item.title }}</span>
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
      </a-layout-header>
      <!-- <div v-if="!$store.state.isEdit" class="page-title">
        <div class="boxsearch">
          <a-input-search
            placeholder="Search"
            style="width: 300px"
            @search="onSearch"
          />
          <a-button style="margin-left: 15px" @click="resetPage"
            >Reset</a-button
          >
        </div>
        <div class="boxbutton">
          <a-button icon="download"> Export </a-button>
          <a-button class="createbtn" @click="handleCreate()">
            + Add {{ pagetitle.name }}
          </a-button>
        </div>
      </div> -->
      <a-layout-content
        :style="{
          margin: '16px',
          minHeight: '280px',
          borderRadius: '6px',
          overflow: 'auto',
        }"
      >
        <slot></slot>
      </a-layout-content>
      <!-- <a-layout-footer v-if="$store.state.isEdit" style="text-align: center">
        <a-button-group>
          <a-button @click="handleBack()">Cancel</a-button>
          <a-button @click="handleEditData()"> Update </a-button>
        </a-button-group>
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>
<script>
import Cache from "@/helper/Cache";
import { config } from "@/config/bzbsconfig.js";

export default {
  props: {
    pagetitle: Object,
  },
  data() {
    return {
      logo: require("@/assets/images/NewLogo.png"),
      avatar: require("@/assets/images/avatar.png"),
      appname: "ActiveDirectoryManagement",
      collapsed: false,
      menuList: [
        {
          title: "Dashboard",
          icon: "icon-settings",
          to: {
            name: "dashboard",
          },
        },
        {
          title: "Manage Company",
          icon: "icon-settings",
          to: {
            name: "managecompanys",
          },
        },
        {
          title: "Manage APP",
          icon: "icon-settings",
          to: {
            name: "manageapp",
          },
        },
        {
          title: "Manage Product",
          icon: "icon-settings",
          to: {
            name: "manageproducts",
          },
        },
        {
          title: "Manage Package",
          icon: "icon-settings",
          to: {
            name: "managepackage",
          },
        },
        {
          title: "Manage Feature",
          icon: "icon-settings",
          to: {
            name: "managefeature",
          },
        },
        {
          title: "Manage Role",
          icon: "icon-settings",
          to: {
            name: "managerole",
          },
        },
        {
          title: "Manage Data Levle",
          icon: "icon-settings",
          to: {
            name: "managedatalevle",
          },
        },
        {
          title: "Manage Users",
          icon: "icon-settings",
          to: {
            name: "manageusers",
          },
        },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    getAccountMenuList() {
      return [
        {
          title: "Sign Out",
          to: {
            name: "logout",
          },
        },
      ];
    },
    init() {
      this.appname = config.appName;
      var ismsToken = Cache.get("msToken");
      this.msAccount = Cache.get("msAccount");
      if (!ismsToken) {
        this.$router.push({ name: "Login" });
      }
    },
    logout() {
      this.$router.push({ name: "logout" });
    },
    onSearch(e) {
      this.$emit("onSearch", e);
    },
    resetPage() {
      this.$emit("resetPage");
    },
    handleCreate() {
      this.$emit("handleCreate");
    },
    handleEditData() {
      this.$store.commit("manageapp", true);
    },
    handleBack() {
      this.pagetitle.subPageName = "";
      this.$store.commit("isEdit", false);
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
@mixin flagIcon() {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  height: 18px;
}
#components-layout-demo-side {
  .ant-layout-sider {
    background: $color-grey-d1;
    .logo {
      background: $color-blue-125;
      position: relative;
      padding: 0 18px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      @include transition(all 0s);
      > a {
        display: flex;
        align-items: center;
        height: 64px;
        h1 {
          display: inline-block;
          margin: 0 0 0 12px;
          @include fontSize(15);
          vertical-align: middle;
          text-overflow: ellipsis;
          overflow: hidden;
          opacity: 1;
          @include transition(all 0s);
          color: $color-white;
          @include fontWeight("regular");
        }
        img {
          display: inline-block;
          height: 32px;
          vertical-align: middle;
        }
      }
      .trigger {
        color: $color-white;
        margin-left: 5px;
      }
    }
    .menu-list {
      padding: 7px;
      .menu-item {
        .menu-link {
          .text {
            color: $color-grey-8;
          }
          @include flex(flex-start, center);
          padding: 7px;
          border-radius: 150px;
          &[disabled] {
            pointer-events: initial;
            cursor: pointer;
            color: $color-body;
          }
          &:hover {
            .icon {
              color: $color-white;
            }
            .text {
              color: $color-white;
            }
          }
          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: $color-grey-8;
            @include flex(center, center);
            font-size: 20px;
            margin-right: 4px;
          }
          &.router-link-active {
            .icon {
              color: $color-white;
            }
            .text {
              color: $color-white;
            }
          }
        }
      }
    }
    .collapsed-hide {
      opacity: 1;
      vertical-align: middle;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include transition(all 0s);
    }
    &.ant-layout-sider-collapsed {
      .collapsed-hide {
        opacity: 0;
        width: 0;
        display: inline-block;
      }
      .menu-list .menu-item .menu-link {
        justify-content: center;
        .icon {
          margin-right: 0;
        }
      }
      .logo {
        > a {
          h1 {
            display: none;
          }
        }
      }
    }
  }
  .ant-layout-header {
    .action-col {
      height: 64px;
    }
    .ant-dropdown-link {
      @include flex(null, center);
      color: #575757;
      @include breakpoint-max($desktop-xs) {
        min-height: 64px;
      }
      .account-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        @include background-style();
        display: inline-block;
      }
      .flag-icon {
        @include flagIcon();
      }
    }
    .account-text {
      padding-right: 18px;
      max-width: 150px;
      span {
        display: block;
        line-height: 1.2;
        &.account-role {
          position: relative;
          padding-right: 34px;
          > span {
            @include line-clamp(1);
          }
          &:after {
            position: absolute;
            content: "";
            right: 0;
            top: 5px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid $color-body;
          }
        }
        &.account-name {
          padding-top: 4px;
        }
      }
    }
  }
}
</style>
