<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :is-action-flex=true
        :databreadcrumbs="breadcrumbs"
        :title="lbls.crossapplication_page.title_page"
        :description="lbls.crossapplication_page.title_table_description"
      >
        <template #actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.crossapplication_page.input_search"
            class="input-search"
            size="large"
            @blur="onSearch(searchData)"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                style="margin-right: 5px; font-size: 10px"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
        </template>
      </Breadcrumbs>
      <!-- <div class="row">
        <PageHeader @onsearch="onSearch($event)" />
      </div> -->
      <div v-if="isShowSkeleton" class="background-white p-5">
        <a-skeleton />
      </div>
      <div v-else>
        <div id="box-detail" class="manage-page-detail">
          <TableList
            :datatable="data"
            :table-height="tableHeight"
            :conditiontable="conditionTable"
            @callgetlist="callGetList"
            @handlecreate="handleCreate($event)"
            @handleedit="handleEdit($event)"
            @handledelete="handleDelete($event)"
            @onsearchtable="onSearchTable($event)"
          />
          <ShowTotal
            :datashowtitle="data"
            :conditiontable="conditionTable"
            :pagesize="page_size"
            :totallist="totalList"
            @callgetlist="callGetList"
            @setpagesize="setPageSize($event)"
          />
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="listOfSubscription"
          :dataselectcross="selectCross"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAPI from "@/core/Page/ManageCrossApplicationAPI";
import Cache from "@/helper/Cache.js";
import ShowTotal from "@/components/Layout/ShowTotal";
//import PageHeader from "@/module/Cross/ManageCrossApplication/components/Layout/PageHeader.vue";
import TableList from "@/module/Cross/ManageCrossApplication/components/Table/TableList";
import ModalProcess from "@/module/Cross/ManageCrossApplication/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ShowTotal,
    //PageHeader,
    TableList,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      permission_key: "cross",
      action_key: "application-view",
      breadcrumbs: [],
      data: {},
      totalList: 0,
      nameSearch: "",
      isShowSkeleton: true,
      isModalProcess: false,
      isModalResponse: false,
      isCreate: false,
      isEdit: false,
      isDelete: false,
      titleModalProcess: "",
      idModal: "",
      titleModal: "",
      modalbtnok: "",
      contentModal: "",
      pageno: 1,
      pagesize: 10,
      selectCross: [],
      listOfSubscription: [],
      page: 0,
      page_size: 25,
      conditionTable: {},
      dataobjselect: [],
      alertData: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
        isCreate: false,
        isEdit: false,
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      tableHeight: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.calTableHeight();
      });
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossapplication,
        name: "ManageCrossAccount",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    handleCreate(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.isCreate = true;
      this.objRecord = record;
      this.titleModalProcess =
        this.lbls.crossapplication_page.drw_title_create +
        " '" +
        record.application.name +
        "' " +
        this.lbls.crossapplication_page.drw_title_to;
      this.callGetSubScriptions();
    },
    handleEdit(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.objRecord = record;
      this.titleModalProcess =
        this.lbls.crossapplication_page.drw_title_edit +
        this.lbls.crossapplication_page.drw_title_of +
        " '" +
        this.objRecord.application.name +
        "' ";
      this.callGetDetail();
    },
    handleDelete(record) {
      this.idModal = "confirm";
      this.objRecord = record;
      this.isDelete = true;
      this.$confirm({
        class: "custom-width-confirm",
        centered: true,
        autoFocusButton: null,
        title: this.lbls.applicationaccount_page.modal.content_delete,
        content: record.package.name,
        okText: this.lbls.crossapplication_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.crossapplication_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handlemodalOk() {
      if (this.idModal == "error") return;
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else {
        this.callPostAddData();
      }
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        if (this.isCreate || this.isEdit) {
          this.objDataSave = value;
          this.callPostAddData();
        }
      }
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    showModalProcess() {
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "crossapplication_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isCreate = false;
      this.isEdit = false;
      this.isDelete = false;
      this.objRecord = [];
      this.dataObjSelect = [];
      this.selectCross = [];
      this.alertdata = {};
      this.modalprocess = {};
      this.modalAlert = {};
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
      manageCrossAPI
        .getCrossList(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
                this.data.forEach((elements) => {
                  elements.id =
                    elements.owner_account.id + elements.application.id;
                });
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
              };
            }
          }
          this.$nextTick(() => {
          this.handleLoading(false);
          this.isShowSkeleton = false;
          resolve(res.data);
        });
      })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
          reject(error);
        });
      });
    },
    callGetSubScriptions() {
      let params = {
        account_id: this.exAccount.account.id,
      };
      manageCrossAPI
        .getSubScriptions(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                this.listOfSubscription = [];
                res.data.result.items.forEach((element) => {
                  let dataPackage = element.package;
                  let dataSubscription = element.subscription;
                  if (dataPackage) {
                    dataSubscription.name = dataPackage.name;
                  }
                  this.listOfSubscription.push(dataSubscription);
                });
                this.showModalProcess();
              }
            }
          }
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
        application_id: this.objRecord.application.id,
      };
      manageCrossAPI
        .getDetail(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              let dataSelectCross = res.data.result.package
                ? res.data.result.package.name
                : res.data.result.subscription
                  ? res.data.result.subscription.name
                  : "";
              this.selectCross.push(dataSelectCross);
              this.callGetSubScriptions();
              setTimeout(() => {
                this.showModalProcess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        subscription_id: this.objDataSave,
        application_id: this.objRecord.application.id,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageCrossAPI
        .postSave(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.crossapplication_page.modal.title_success;
                if (this.isCreate) {
                  this.contentModal =
                    this.lbls.crossapplication_page.modal.content_create_success;
                }
                if (this.isEdit) {
                  this.contentModal =
                    this.lbls.crossapplication_page.modal.content_update_success;
                }
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        application_id: this.objRecord.application.id,
      };
      manageCrossAPI
        .delete(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal =
                this.lbls.crossapplication_page.modal.title_success;
              this.contentModal =
                this.lbls.crossapplication_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.editable-row-operations a {
  margin-right: 8px;
}
</style>
