<template>
  <div>
    <template v-if="data.length > 0">
      <div
        v-for="(item, i) in data"
        :key="i"
        class="row m-0 content-item"
        :class="{
          'bg-unread': item.is_unread == true,
          'display-content-list':
            clicktabs == '2'
              ? !item.is_unread
              : clicktabs == '3'
              ? item.is_unread
              : null,
        }"
        @click="previewPost(item)"
      >
        <div class="col-10 p-0">
          <div class="date-time">
            <a-tag v-if="item.post_type == 'Emergency'" color="red">
              {{ item.post_type }}
            </a-tag>
            <a-tag v-else>
              {{ item.post_type }}
            </a-tag>
          </div>
          <div class="title-style">
            <a-badge
              v-if="item.is_unread"
              :count="lbls.announcement.new"
            ></a-badge>
            <h5 class="text short-text title-text m-0">
              {{ item.title }}
            </h5>
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            :class="{ 'content-unread': item.is_unread == true }"
            class="text short-text content-text"
            v-html="item.content"
          ></div>
          <div class="last-update">
            <span
              >Last Update:
              {{ formatDateAM(item.updated_date, "DD/MMM/YYYY - HH:mm") }} By
              {{ item.updated_by }}
            </span>
          </div>
        </div>
        <div class="col-2 p-0 text-right view-button">
          <a>
            {{ lbls.btn.view }}
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <h5 class="text-center my-5">
        {{ lbls.announcement.notfound }}
      </h5>
    </template>
    <ModalAnnouncement
      v-if="showModalAnnouncement"
      :is-show="showModalAnnouncement"
      :data="dataPreview"
      @closeModalAnnouncement="closeModalAnnouncement()"
      @checkBox="checkBox($event)"
    />
  </div>
</template>
<script>
import ModalAnnouncement from "@/components/Layout/Header/ModalAnnouncement.vue";
import BzbsAnnouncement from "@/core/service/BzbsAnnouncement.js";

export default {
  name: "Announcement",
  components: { ModalAnnouncement },
  props: {
    data: Array,
    clicktabs: String,
    sumUnRead: Number,
  },
  data() {
    return {
      showModalAnnouncement: false,
      conditionsCheckBox: false,
      dataPreview: [],
    };
  },
  methods: {
    previewPost(item) {
      this.showModalAnnouncement = true;
      this.dataPreview = item;
    },
    checkBox(value) {
      this.conditionsCheckBox = value;
    },
    closeModalAnnouncement() {
      let isEmergencyRequiredAccept = null;
      let isCallEmergency = false;
      if (this.dataPreview.post_type == "Emergency") {
        isEmergencyRequiredAccept = this.dataPreview.is_required_accept;
        if (isEmergencyRequiredAccept && this.conditionsCheckBox) {
          isCallEmergency = true;
        } else if (!isEmergencyRequiredAccept && this.dataPreview.is_unread) {
          isCallEmergency = true;
        }
      }
      if (this.dataPreview.is_unread) {
        return new Promise((resolve, reject) => {
          let params = {
            id: this.dataPreview.id,
            is_accept: this.conditionsCheckBox,
          };
          BzbsAnnouncement.apiReadAnnouncement(params)
            .then((res) => {
              console.log(res);
              this.showModalAnnouncement = false;
              this.$emit("getAllAnnouncement", isCallEmergency);
              this.conditionsCheckBox = false;
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        this.showModalAnnouncement = false;
      }
      this.$emit("getAllAnnouncement", isCallEmergency);
    },
  },
};
</script>
<style></style>
