<template>
  <div :style="{ minHeight: '360px' }">
    <div v-if="showLayout">
      <!-- Modal -->
      <a-modal
        v-model="isShowModal"
        class="process-modal"
        centered
        :width="650"
        :closable="false"
      >
        <template slot="footer">
          <a-button type="primary" @click="handlemodalCancelError">
            {{ lbls.checkpermission.btn.close }}
          </a-button>
        </template>
        <div>
          <div class="title-modal">
            <span style="color: #faad14; margin-right: 16px">
              <a-icon style="color: #faad14" type="exclamation-circle" />
            </span>
            <span>{{ titleModal }}</span>
          </div>
          <div class="content-modal">
            <p>{{ contentModal }}</p>
            <div v-if="errtransaction_id" style="font-size: 12px">
              Transaction ID : {{ errtransaction_id }}
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import userAPI from "@/core/Page/ManageUserAPI";
import Cache from "@/helper/Cache";
import redirectmsAPI from "@/core/Page/RedirectmsAPI";
export default {
  name: "ManageUser",
  data() {
    return {
      isShowModal: false,
      showLayout: false,
      titleModal: "",
      errtransaction_id: "",
      contentModal: "",
    };
  },
  // beforeCreate() {
  //   this.handleLoading(true);
  // },
  created() {
    // this.exAccount = Cache.get("exAccount");
    // Cache.remove("exAccount");
    // this.handleLoading(true);
    this.init();
  },
  methods: {
    init() {
      this.menuList = [];
      this.callGetCurrentAccount().then((result) => {
        if (result && (result.owner_account || result.working_account)) {
          Cache.remove("exAccount");
          this.exAccount = {};
          this.exAccount.account = result.working_account
            ? result.working_account
            : null;
          this.exAccount.owner_account = result.owner_account
            ? result.owner_account
            : null;
          Cache.set("exAccount", this.exAccount);
          // setTimeout(() => {
          //   this.callGetUserPermission();
          // }, 500);
        } else {
          //this.exAccount = Cache.get("exAccount");
          this.$router.push({
            name: "unauthorized",
            query: {
              form: "chceckpermission",
            },
          });
        }
      });
    },
    callGetUserPermission() {
      let params = {
        account_id: this.exAccount.account.id,
      };
      userAPI
        .getUsePermission(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.features.length > 0) {
                this.permissiondata = res.data.result.features;
                this.permissiondata.forEach((element) => {
                  if (element.name == "Application") {
                    this.menuList.push({
                      key: "1",
                      to: {
                        name: "manageapplication",
                      },
                    });
                  }
                  if (element.name == "Cross-Account") {
                    this.menuList.push({
                      key: "2",
                      to: {
                        name: "managecrossaccount",
                      },
                    });
                  }
                  if (element.name == "Role-And-User") {
                    this.menuList.push({
                      key: "4",
                      to: {
                        name: "manageroles",
                      },
                    });
                    this.menuList.push({
                      key: "5",
                      to: {
                        name: "manageusers",
                      },
                    });
                  }
                  this.menuList.sort(function (a, b) {
                    if (a.key < b.key) {
                      return -1;
                    }
                    if (a.key > b.key) {
                      return 1;
                    }
                    return 0;
                  });
                  setTimeout(() => {
                    this.goToPage(this.menuList[0].to.name);
                  }, 500);
                });
              } else {
                this.isShowModal = true;
                this.titleModal = this.lbls.checkpermission.modal.title_warning;
                this.contentModal =
                  this.lbls.checkpermission.modal.msg_error_not_menu;
                this.handleLoading(false);
              }
              this.showLayout = true;
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetCurrentAccount() {
      return new Promise((resolve) => {
        redirectmsAPI
          .postCurrentAccount(this.package_name)
          .then((res) => {
            resolve(res.data.result);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
          });
      });
    },
    goToPage(page) {
      window.location.href = page;
    },
    handlemodalCancelError() {
      this.isShowModal = false;
      this.errtransaction_id = "";
      if (this.err_status == 401) {
        this.$router.push({ name: "Home" });
      }
      // if (this.err_id == 2001) {
      //   this.$router.push({ name: "CheckPermission" });
      // }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.editable-row-operations a {
  margin-right: 8px;
}
</style>
