<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content modal-with-contents"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
        </div>
      </template>
      <label class="mb-3">{{
        lbls.crossaccountdetail_page.modal_process.title_select_account
      }}</label>
      <div class="custom-card">
        <div
          :class="{ 'hidden-body': !isShowCardAccount }"
          class="custom-card-header"
          @click="() => (isShowCardAccount = !isShowCardAccount)"
        >
          <div v-if="!objSelect.id">
            {{ lbls.crossaccountdetail_page.modal_process.title_account_list }}
            ({{ modalprocess.data.length }})
          </div>
          <div v-if="objSelect.id">
            <div>
              <span class="font-weight-bold"
                >{{
                  lbls.crossaccountdetail_page.modal_process.card_account
                    .account_name
                }}
                :
              </span>
              <span class="font-weight-normal">{{
                objSelect.account.name || "-"
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold"
                >{{
                  lbls.crossaccountdetail_page.modal_process.card_account
                    .account_display_name
                }}
                :
              </span>
              <span class="font-weight-normal">{{
                objSelect.owner_user.DisplayName || "-"
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold"
                >{{
                  lbls.crossaccountdetail_page.modal_process.card_account.owner
                }}
                :
              </span>
              <span class="font-weight-normal">
                {{ objSelect.owner_user.name || "-" }}
              </span>
            </div>
            <div>
              <span class="font-weight-bold"
                >{{
                  lbls.crossaccountdetail_page.modal_process.card_account.email
                }}
                :
              </span>
              <span class="font-weight-normal">{{
                objSelect.owner_user.Email
              }}</span>
            </div>
          </div>
        </div>
        <div
          :class="{ overlayed: !isShowCardAccount }"
          class="custom-card-body"
        >
          <div class="card-body">
            <a-input-search
              v-model="searchData"
              :placeholder="lbls.crossaccountdetail_page.input_search"
              class="input-search mb-2"
              size="large"
              allow-clear
              @blur="onSearch"
              @search="onSearch"
            >
            </a-input-search>
            <div v-if="objSelect.id">
              {{
                lbls.crossaccountdetail_page.modal_process.title_account_list
              }}
              ({{ modalprocess.data.length }})
            </div>
            <a-card
              v-for="item in modalprocess.data"
              :key="item.account.id"
              :style="{ width: '100%' }"
              :class="{ 'is-select': item.is_select }"
              @click="handleSelectAccount(item)"
            >
              <div>
                <span class="font-weight-bold"
                  >{{
                    lbls.crossaccountdetail_page.modal_process.card_account
                      .account_name
                  }}
                  :
                </span>
                <span>{{ item.account.name || "-" }}</span>
              </div>
              <div>
                <span class="font-weight-bold"
                  >{{
                    lbls.crossaccountdetail_page.modal_process.card_account
                      .account_display_name
                  }}
                  :
                </span>
                <span class="font-weight-normal">{{
                  item.owner_user.DisplayName || "-"
                }}</span>
              </div>
              <div>
                <span class="font-weight-bold">
                  {{
                    lbls.crossaccountdetail_page.modal_process.card_account
                      .owner
                  }}
                  :
                </span>
                <span class="font-weight-normal">
                  {{ item.owner_user.name || "-" }}
                </span>
              </div>
              <div>
                <span class="font-weight-bold"
                  >{{
                    lbls.crossaccountdetail_page.modal_process.card_account
                      .email
                  }}
                  :
                </span>
                <span class="font-weight-normal">{{
                  item.owner_user.Email
                }}</span>
              </div>
            </a-card>
          </div>
        </div>
      </div>
      <label v-if="objSelect.id" class="mt-3 mb-3">{{
        lbls.crossaccountdetail_page.modal_process.title_select_package
      }}</label>
      <div v-if="objSelect.id" class="custom-card">
        <div
          :class="{ 'hidden-body': !isShowCardPackage }"
          class="custom-card-header"
          @click="() => (isShowCardPackage = !isShowCardPackage)"
        >
          <div v-if="!objSelectPackage.id">
            {{ lbls.crossaccountdetail_page.modal_process.title_package_list }}
            ({{ dataPackage.length }})
          </div>
          <div v-if="objSelectPackage.id">
            <div>
              <span class="font-weight-bold">Package : </span>
              <span class="font-weight-normal">{{
                objSelectPackage.name || "-"
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold">Description : </span>
              <span class="font-weight-normal">{{
                objSelectPackage.description || "-"
              }}</span>
            </div>
          </div>
        </div>
        <div
          :class="{ overlayed: !isShowCardPackage }"
          class="custom-card-body"
        >
          <div class="card-body">
            <a-card
              v-for="item in dataPackage"
              :key="item.id"
              :style="{ width: '100%' }"
              :class="{ 'is-select': item.is_select }"
              @click="handleSelectPackage(item)"
            >
              <div>
                <span class="font-weight-bold">Package : </span>
                <span class="font-weight-normal">{{ item.name || "-" }}</span>
              </div>
              <div>
                <span class="font-weight-bold">Description : </span>
                <span class="font-weight-normal">{{
                  item.description || "-"
                }}</span>
              </div>
            </a-card>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import Cache from "@/helper/Cache.js";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      disabledok: true,
      isShowCardAccount: true,
      isShowCardPackage: true,
      objDataSave: {},
      objDataAccount: {},
      objSelect: {},
      objSelectPackage: {},
      dataPackage: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataAccount = this.modalprocess.data;
    },
    setIsSelect(id, data) {
      data.forEach((item) => {
        item.is_select = false;
        if (item.id == id) {
          item.is_select = true;
        }
      });
    },
    callGetListAccount() {
      this.exAccount = Cache.get("exAccount");
      let params = {
        account_id: this.exAccount.account.id,
        target_account_id: this.objSelect.id,
      };
      manageCrossAccountAPI
        .getSearchAccountPackage(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataPackage = res.data.result.items;
            this.dataPackage.forEach((item) => {
              item.is_select = false;
            });
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleSelectAccount(item) {
      this.setIsSelect(item.id, this.objDataAccount);
      this.objSelect = {};
      this.dataPackage = [];
      this.objSelect = item;
      this.isShowCardAccount = !this.isShowCardAccount;
      this.callGetListAccount();
      this.checkBTN();
    },
    handleSelectPackage(item) {
      this.setIsSelect(item.id, this.dataPackage);
      this.objSelectPackage = {};
      this.objSelectPackage = item;
      this.isShowCardPackage = !this.isShowCardPackage;
      this.checkBTN();
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.objDataSave = {
        target_account_id: this.objSelect.id,
        target_package_id: this.objSelectPackage.id,
      };
      this.$emit("actionModal", this.objDataSave);
    },
    checkBTN() {
      this.disabledok = true;
      if (this.objSelect.id && this.objSelectPackage.id) {
        this.disabledok = false;
      }
    },
    onSearch() {
      this.$emit("onsearch", this.searchData);
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-card {
  border: 1px solid var(--primary-gold-bzb-primary-bzb);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  .custom-card-header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 12px;
    background: var(--primary-gold-bzb-gold-9);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out;
  }
  .custom-card-body {
    position: relative;
    transition: all 0.4s ease-in-out;
    height: 450px;
    overflow: auto;
    .card-body {
      display: flex;
      gap: 12px;
      width: 100%;
      flex-wrap: wrap;
    }
    .ant-card {
      margin-bottom: 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    }
    .ant-card-head {
      padding: 0 12px;
      .ant-card-head-title {
        font-size: 14px !important;
        font-weight: 600 !important;
      }
    }
    .ant-card-body {
      padding: 12px;
    }
    .ant-card:hover {
      border: 1px solid var(--primary-gold-bzb-primary-bzb);
      background: var(--primary-gold-bzb-gold-9);
      border-radius: 12px;
      cursor: pointer;
    }
  }
  .overlayed {
    height: 0;
    overflow: hidden;
    border-radius: 8px;
  }
  .hidden-body {
    border-radius: 8px;
  }
  .is-select {
    border: 1px solid var(--primary-gold-bzb-primary-bzb);
    background: var(--primary-gold-bzb-gold-9);
    border-radius: 12px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
@import "@/style/_mixin.scss";
.modal-with-contents .ant-modal-body {
  min-height: 550px;
  .input-search {
    max-width: 100%;
  }
}
</style>
