<template>
  <!-- <div class="box-loader" :class="{ loaded: !isShow }">
    <lottie-vue-player
      :src="Config.themeUrl + '/json/loading.json'"
      :background-color="'transparent'"
      :loop="true"
      :autoplay="true"
      class="loaderjson"
    />
  </div> -->
  <div class="box-loader" :class="{ loaded: !isShow }">
    <div class="wrapper-loaading">
      <span class="loader"></span>
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    isShow: Boolean,
    text: String,
  },
};
</script>


<style lang="scss" scope>
.box-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}
.loader {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  &::before,
  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 6px solid #f9a601;
    animation: prixClipFix 2s linear infinite;
  }
  &::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: #2d2d2d;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}
.wrapper-loaading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  font-weight: 500;
  p {
    margin: 10px 0 0;
    color: #f9a601;
    font-family: Tahoma, sans-serif;
  }
}
</style>