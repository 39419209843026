<template>
  <div class="layout-auth-create page-layout">
    <div
      class="layout-background"
      :style="{ 'background-image': 'url(' + banner + ')' }"
    ></div>
    <div class="layout-content py-5">
      <div class="content-box">
        <div class="content-title">
          {{ lbls.createaccount_page.ms_account.title_page }}
        </div>
        <div class="content-msaccout mt-3">
          <div class="text-center"><a-avatar :src="avatar" /></div>
          <div class="msaccout-item mt-3">
            <div class="item-title-subject">
              {{ lbls.createaccount_page.ms_account.ms_email }}
            </div>
            <div class="item-title-detail ml-2">
              {{ msAccount.idTokenClaims?.emails[0] }}
            </div>
          </div>
          <div class="msaccout-item mt-3">
            <div class="item-title-subject">
              {{ lbls.createaccount_page.ms_account.ms_name }}
            </div>
            <div class="item-title-detail ml-2">
              {{ msAccount.idTokenClaims?.name }}
            </div>
          </div>
          <div class="msaccout-item mt-3">
            <div class="item-title-subject">
              {{ lbls.createaccount_page.ms_account.ms_given_name }}
            </div>
            <div class="item-title-detail ml-2">
              {{ msAccount.idTokenClaims?.given_name }}
            </div>
          </div>
          <div class="msaccout-item mt-3">
            <div class="item-title-subject">
              {{ lbls.createaccount_page.ms_account.ms_family_name }}
            </div>
            <div class="item-title-detail ml-2">
              {{ msAccount.idTokenClaims.family_name }}
            </div>
          </div>
        </div>
        <div class="content-title mt-4">
          {{ lbls.createaccount_page.title_page }}
        </div>
        <div class="content-detail">
          <a-form :form="form" layout="vertical" @submit="handleSubmit">
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item
                  :label="lbls.createaccount_page.input_name.name"
                  :validate-status="is_duplicate"
                >
                  <a-input
                    v-decorator="[
                      'name',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbls.createaccount_page.input_name.required,
                          },
                          {
                            min: 4,
                            message: lbls.createaccount_page.input_name.min,
                          },
                        ],
                      },
                    ]"
                    :placeholder="
                      lbls.createaccount_page.input_name.placeholder
                    "
                    :max-length="maxLengthName"
                    @blur="callCheckDuplicateName"
                    @keyup="countChar"
                  />
                </a-form-item>
                <p class="total-char" href="">
                  {{ totalCharName.toLocaleString() }} /
                  {{ maxLengthName.toLocaleString() }}
                </p>
                <p
                  style="
                    margin-top: -20px !important;
                    color: #f5222d;
                    text-align: left;
                  "
                >
                  {{ helpmsg }}
                </p>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item
                  :label="lbls.createaccount_page.input_description.name"
                  :validate-status="descriptionError() ? 'error' : ''"
                  :help="descriptionError() || ''"
                >
                  <a-textarea
                    v-decorator="[
                      'description',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbls.createaccount_page.input_description
                                .required,
                          },
                          {
                            min: 4,
                            message:
                              lbls.createaccount_page.input_description.min,
                          },
                        ],
                      },
                    ]"
                    :placeholder="
                      lbls.createaccount_page.input_description.placeholder
                    "
                    :auto-size="{ minRows: 3, maxRows: 3 }"
                    :max-length="maxLengthDescription"
                    @keyup="countChar"
                  />
                </a-form-item>
                <p class="total-char" href="">
                  {{ totalCharDescription.toLocaleString() }} /
                  {{ maxLengthDescription.toLocaleString() }}
                </p>
              </a-col>
            </a-row>
            <div class="d-flex justify-content-between">
              <a-button size="large" @click="goToPage('Redirectms')">
                back
              </a-button>
              <a-button
                :disabled="disablebtnok"
                size="large"
                type="primary"
                @click="handleSubmit"
              >
                {{ lbls.createaccount_page.btn_save }}
              </a-button>
            </div>
          </a-form>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visibleModel"
      :centered="true"
      :closable="false"
      :title="titleModal"
    >
      <template #footer>
        <a-button type="primary" @click="handleOk">OK</a-button>
      </template>
      <p>{{ contentModal }}</p>
    </a-modal>
  </div>
</template>
<script>
import Vue from "vue";
import Cache from "@/helper/Cache";
// import Account from "@/helper/AccountHelper";
import redirectmsAPI from "@/core/Page/RedirectmsAPI";
export default {
  name: "Login",
  data() {
    return {
      defaultLanguage: "Eng",
      banner: require("@/assets/images/auth/BG@3x.jpg"),
      logo: require("@/assets/images/LogoPowered.png"),
      avatar: require("@/assets/images/avatar.png"),
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      maxLengthName: 100,
      totalCharName: 0,
      maxLengthDescription: 4000,
      totalCharDescription: 0,
      disablebtnok: true,
      is_duplicate: "",
      helpmsg: "",
      msAccount: {},
      visibleModel: false,
      titleModal: "",
      contentModal: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    getLanguageList() {
      return [
        {
          title: this.lbls.language_title.en,
          key: "en",
        },
        {
          title: this.lbls.language_title.th,
          key: "th",
        },
      ];
    },
    init() {
      // Account.clearCache();
      this.handleLoading(false);
      let isBzbsLocale = Cache.get("BzbsLocale");
      if (!isBzbsLocale) {
        Cache.set("BzbsLocale", "en");
        window.location.reload();
      }
      var ismsToken = Cache.get("msToken");
      if (!ismsToken) {
        this.handleOk();
      }
      this.msAccount = Cache.get("msAccount");
      console.log(this.msAccount, "msAccount");
    },
    handleMenuClick(e) {
      const nowlocale = Vue.$cookies.get("BzbsLocale");
      if (nowlocale == e.key) {
        return;
      }
      this.handleLoading(true);
      Vue.$cookies.set("BzbsLocale", e.key);
      window.location.reload();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          this.postCreateAccount(value);
        }
      });
    },
    postCreateAccount(value) {
      this.handleLoading(true);
      var valueSave = {
        account_name: value.name,
        account_description: value.description,
      };
      this.objForSave = JSON.stringify(valueSave);
      redirectmsAPI
        .postCreateAccount(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.callGetCheckAccount();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          let errorcontent = "Is Someting Error";
          this.err_status = "";
          if (error.response) {
            this.err_status = error.response.status;
            if (this.err_status == 401) {
              errorcontent =
                this.lbls.layoutmaster.modal.msg_content_unauthorized;
            }
            if (error.response.statusText) {
              errorcontent = error.response.statusText;
              this.errtransaction_id = error.response.data.transaction_id
                ? error.response.data.transaction_id
                : "";
              if (error.response.data.error)
                errorcontent =
                  error.response.data && error.response.data.error.length > 0
                    ? error.response.data.error[0].message
                    : error.response.statusText;
            }
          }
          setTimeout(() => {
            this.$warning({
              title: "This is a warning message",
              content: errorcontent,
            });
            this.handleLoading(false);
          }, 500);
        });
    },
    callGetCheckAccount() {
      redirectmsAPI
        .getCheckAccount()
        .then((res) => {
          if (res.status == "200") {
            Cache.remove("exAccount");
            let data = res.data.result;
            document.cookie = "exAccount=" + JSON.stringify(data);
            setTimeout(() => {
              window.location = window.location.origin;
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          let errorcontent = "Is Someting Error";
          this.err_status = "";
          if (error.response) {
            this.err_status = error.response.status;
            if (this.err_status == 401) {
              errorcontent =
                this.lbls.layoutmaster.modal.msg_content_unauthorized;
            }
            if (error.response.statusText) {
              errorcontent = error.response.statusText;
              this.errtransaction_id = error.response.data.transaction_id
                ? error.response.data.transaction_id
                : "";
              if (error.response.data.error)
                errorcontent =
                  error.response.data && error.response.data.error.length > 0
                    ? error.response.data.error[0].message
                    : error.response.statusText;
            }
          }
          setTimeout(() => {
            this.$warning({
              title: "This is a warning message",
              content: errorcontent,
            });
            this.handleLoading(false);
          }, 500);
        });
    },
    callCheckDuplicateName(e) {
      this.is_duplicate = "";
      if (e.target.value.length < 4) {
        this.form.validateFields(["name"]);
        this.is_duplicate = "error";
      } else {
        let params = {
          account_name: e.target.value,
        };
        redirectmsAPI
          .getCheckDuplicateName(params)
          .then((res) => {
            if (res.data) {
              if (res.data.result) {
                if (res.data.result.is_duplicate) {
                  this.is_duplicate = "error";
                  this.helpmsg = this.lbls.createaccount_page.helpmsg;
                  this.disablebtnok = true;
                }
              }
            }
          })
          .catch((error) => {
            this.idModal = "error";
            let errorcontent = "Is Someting Error";
            this.err_status = "";
            if (error.response) {
              this.err_status = error.response.status;
              if (this.err_status == 401) {
                errorcontent =
                  this.lbls.layoutmaster.modal.msg_content_unauthorized;
              }
              if (error.response.statusText) {
                errorcontent = error.response.statusText;
                this.errtransaction_id = error.response.data.transaction_id
                  ? error.response.data.transaction_id
                  : "";
                if (error.response.data.error)
                  errorcontent =
                    error.response.data && error.response.data.error.length > 0
                      ? error.response.data.error[0].message
                      : error.response.statusText;
              }
            }
            setTimeout(() => {
              this.visibleModel = true;
              this.titleModal = this.lbls.layoutmaster.modal.title_error;
              this.contentModal = errorcontent;
              this.handleLoading(false);
            }, 500);
          });
      }
    },
    descriptionError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched("description") && getFieldError("description");
    },
    countChar(e) {
      this.disablebtnok = true;
      if (e.target.id == "coordinated_name") {
        this.totalCharName = e.target.value.length;
        this.is_duplicate = "";
        this.helpmsg = "";
        if (e.target.value.length < 4) {
          this.is_duplicate = "error";
        }
      } else {
        this.totalCharDescription = e.target.value.length;
      }
      this.checkBTN();
    },
    checkBTN() {
      if (
        this.totalCharName >= 4 &&
        this.totalCharDescription >= 4 &&
        this.is_duplicate != "error"
      ) {
        this.disablebtnok = false;
      }
    },
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field]);
    },
    handleOk() {
      this.handleLoading(true);
      window.location = window.location.origin;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.layout-auth-create {
  min-height: 100vh;
  @include flex();
  @include background-style();
  position: relative;
  background-color: #eef0f8;
  .layout-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include background-style();
    background-attachment: fixed;
  }
  .box-text-auth {
    bottom: 30px;
    position: absolute;
    align-items: center;
    width: 100%;
    .text-auth {
      float: left;
      color: #fff;
      font-size: 10px;
      margin: 10px;
    }
  }
  .box-language-auth {
    float: right;
    top: 20px;
    position: absolute;
    right: 35px;
    a {
      color: rgb(196 196 196);
      text-decoration: none;
      opacity: 0.3;
    }
  }
  .text-auth {
    color: #fff;
    font-size: 10px;
    margin: 10px;
  }
  .layout-content {
    font-size: 16px;
    max-width: 100%;
    z-index: 1;
    padding: 0 15px;
    margin: auto;
    .logo {
      margin-top: 22px;
      position: absolute;
      img {
        max-width: 70px;
        @include breakpoint-max($mobile-md) {
          max-width: 50px;
        }
      }
      .title {
        color: $color-white;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    .content-box {
      background-color: #fff;
      color: $color-body;
      padding: 24px;
      .content-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
        text-align: center;
      }
      .content-detail {
        line-height: 1.5;
        color: $grey-grey-3;
        margin-top: 16px;
        label {
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      .btn-create {
        margin-top: 16px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        &.active {
          color: #fff !important;
          border: solid 1px $color-primary !important;
          background-color: $color-primary !important;
        }
      }
    }
    .content-msaccout {
      border-bottom: 1px solid $grey-grey-7;
      padding-bottom: 16px;
      line-height: 1.2;
      font-size: 14px;
      .ant-avatar {
        width: 50px;
        height: 50px;
      }
      .msaccout-item {
        .item-title-subject {
          opacity: 0.7;
        }
        .item-title-detail {
          font-weight: 700;
          color: $color-primary;
        }
      }
    }
  }
  .ant-form-explain {
    text-align: left;
  }
  .ant-btn {
    padding: 0 15px !important;
  }
}
</style>
