import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getProductList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion + "/application";
  },
  pathV2: function () {
    return this.config().apiBaseUrl + this.config().apiVersion2 + "/application";
  },
  getCheckDuplicateName: async function (params) {
    var strUrl = this.path() + "/check_duplicate_application_name";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getSearch: async function (params) {
    var strUrl = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getApplicationDetail: async function (params) {
    var strUrl = this.path() + "/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  postCreateApplication: async function (params) {
    var url = this.path() + "/create";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(url, this.token(), params);
  },
  postCreateApplicationLogo: async function (params) {
    var url = this.path() + "/create_with_logo";
    await Util.checkToken();
    return BzbsApi.connectBzbsPost(url, this.token(), params);
  },
  postEditApplication: async function (params) {
    var url = this.path() + "/update";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(url, this.token(), params);
  },
  postChangePackage: async function (params) {
    var url = this.path() + "/change_package";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(url, this.token(), params);
  },
  postUpdateLogo: async function (params) {
    var url = this.path() + "/update_logo";
    await Util.checkToken();
    return BzbsApi.connectBzbsPost(url, this.token(), params);
  },
  getApplicationsByPackage: async function (params) {
    var strUrl = this.pathV2() + "/get_by_package";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
};
