<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page page-create-owner-account"
      >
        <Breadcrumbs
          :databreadcrumbs="breadcrumbs"
          :title="lbls.create_account.title_page"
        />
        <a-card v-if="isShowSkeleton">
          <a-skeleton />
        </a-card>
        <div v-else>
          <a-card class="card-active mt-0">
            <a-form :form="form" layout="vertical" @submit="handleSave">
              <a-row :gutter="16">
                <a-col :span="24">
                  <div class="d-flex flex-center-between">
                    <div>
                      <h3>{{ lbls.create_account.account_details }}</h3>
                    </div>
                    <div class="d-flex align-items-center">
                      <a-space>
                        <div>{{ lbls.create_account.support }}</div>
                        <a-form-item class="pb-0">
                          <a-switch
                            v-decorator="[
                              'is_support',
                              {
                                initialValue: objDataSave.is_support,
                              },
                            ]"
                            :checked="objDataSave.is_support"
                            @change="(e) => (objDataSave.is_support = e)"
                          />
                        </a-form-item>
                      </a-space>
                    </div>
                  </div>
                  <hr />
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.company_name.name ||
                            "Company Name"
                          }}</span>
                        </template>
                        <a-input
                          v-decorator="[
                            'account_name',
                            {
                              initialValue: objDataSave.account_name,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.company_name.required,
                                },
                                {
                                  min: 1,
                                  message: lbls.create_account.company_name.min,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :placeholder="
                            lbls.create_account.company_name.placeholder
                          "
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.owner_email.name ||
                            "Owner Email"
                          }}</span>
                        </template>
                        <a-input
                          v-decorator="[
                            'email',
                            {
                              initialValue: objDataSave.email,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.owner_email.required,
                                },
                                {
                                  type: 'email',
                                  message:
                                    lbls.create_account.owner_email.valid,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :placeholder="
                            lbls.create_account.owner_email.placeholder
                          "
                          @change="changeEmail"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="windowWidth <= '840' ? 6 : 4">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.providers_type.name ||
                            "Providers Type"
                          }}</span>
                        </template>
                        <a-select
                          v-decorator="[
                            'email_server',
                            {
                              initialValue: objDataSave.email_server,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.providers_type.required,
                                },
                              ],
                            },
                          ]"
                          show-search
                          :placeholder="
                            lbls.create_account.providers_type.placeholder
                          "
                        >
                          <a-select-option
                            v-for="(item, index) in optionEmailServer"
                            :key="index"
                            :value="item.value"
                            :disabled="item.is_disabled"
                          >
                            <img
                              :src="item.img"
                              style="max-width: 16px"
                              class="mr-1"
                            />
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16">
                    <a-col :span="18">
                      <a-form-item>
                        <template slot="label">
                          <span>{{
                            lbls.create_account.description.name ||
                            "Description"
                          }}</span>
                        </template>
                        <a-textarea
                          v-decorator="[
                            'account_description',
                            {
                              initialValue: objDataSave.account_description,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    lbls.create_account.description.required,
                                },
                                {
                                  min: 1,
                                  message: lbls.create_account.description.min,
                                },
                              ],
                            },
                          ]"
                          :placeholder="
                            lbls.create_account.description.placeholder
                          "
                          :rows="4"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :span="24" class="mt-3">
                  <h3>
                    {{ lbls.create_account.assign_package_and_application }}
                  </h3>
                  <hr />
                  <a-button
                    class="mb-3"
                    type="primary"
                    :disabled="disabledAddItem"
                    @click="addItem"
                  >
                    <a-icon type="plus-circle" />
                    {{ lbls.create_account.btn.add_item }}
                  </a-button>
                  <a-row :gutter="16">
                    <div v-for="(item, index) in dataItems" :key="item.key">
                      <a-col :span="18">
                        <a-card class="mb-3">
                          <a-row :gutter="16">
                            <a-col :span="12">
                              <a-form-item
                                :validate-status="validate()"
                                :help="
                                  validate() == 'error'
                                    ? 'Please change your package, This package is duplicate.'
                                    : null
                                "
                              >
                                <template slot="label">
                                  <span>{{
                                    lbls.create_account.package_list.name ||
                                    "Packcage"
                                  }}</span>
                                </template>
                                <a-select
                                  v-decorator="[
                                    'items[' + index + '].package_id',
                                    {
                                      initialValue: item.package,
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            lbls.create_account.package_list
                                              .required,
                                        },
                                      ],
                                    },
                                  ]"
                                  show-search
                                  :placeholder="
                                    lbls.create_account.package_list.placeholder
                                  "
                                  @search="getListPackage"
                                  @change="
                                    (e) => {
                                      item.package = e;
                                      handleChangePackage(e);
                                    }
                                  "
                                >
                                  <a-select-option
                                    v-for="(
                                      optionItem, optionIndex
                                    ) in optionPackage"
                                    :key="optionIndex"
                                    :value="[
                                      optionItem.name,
                                      optionItem.id,
                                      optionItem.type,
                                    ]"
                                  >
                                    {{ optionItem.name }}
                                  </a-select-option>
                                </a-select>
                              </a-form-item>
                            </a-col>
                            <a-col :span="12">
                              <a-form-item>
                                <template slot="label">
                                  <span>{{
                                    lbls.create_account.application_id.name ||
                                    "Application ID"
                                  }}</span>
                                </template>
                                <a-select
                                  v-decorator="[
                                    'items[' + index + '].application_ids',
                                    {
                                      initialValue: item.application,
                                    },
                                  ]"
                                  show-search
                                  :placeholder="
                                    lbls.create_account.application_id
                                      .placeholder
                                  "
                                  mode="multiple"
                                  :disabled="item.package.length == 0"
                                  @search="(e) => getListApplication(e)"
                                  @change="
                                    (e) => {
                                      item.application = e;
                                    }
                                  "
                                >
                                  <a-select-option
                                    v-for="(
                                      optionItem, optionIndex
                                    ) in filteredOptionsApp"
                                    :key="optionIndex"
                                    :value="
                                      optionItem.id +
                                      ' (' +
                                      optionItem.name +
                                      ')'
                                    "
                                  >
                                    {{ optionItem.id }} ({{ optionItem.name }})
                                  </a-select-option>
                                </a-select>
                              </a-form-item>
                            </a-col>
                          </a-row>
                        </a-card>
                      </a-col>
                      <a-col :span="4">
                        <a
                          v-if="index != 0"
                          class="remove"
                          @click="handleRemove(item.key)"
                        >
                          <a-icon type="delete" />
                          {{ lbls.create_account.btn.remove }}
                        </a>
                      </a-col>
                    </div>
                  </a-row>
                </a-col>
              </a-row>

              <div class="custom-footer-create-account">
                <a-button type="primary" @click="handleSave">
                  {{ lbls.role_page.btn.save }}
                </a-button>
              </div>
            </a-form>
          </a-card>
        </div>

        <!-- Modal -->
        <div v-if="isModalResponse">
          <ModalAlert
            :modalalert="modalAlert"
            :alertdata="alertData"
            @handlemodalok="handlemodalOk"
          >
          </ModalAlert>
        </div>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import userAPI from "@/core/Page/ManageUserAPI";
import accountAPI from "@/core/Page/ManageAccountAPI";
import CommonMixin from "@/mixin/CommonMixin";
import ResponsiveMixin from "@/mixin/ResponsiveMixin";

export default {
  name: "CreateOwnerAccount",
  components: {
    AppLayout,
    ModalAlert,
  },
  mixins: [CommonMixin, ResponsiveMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      objDataSave: {
        account_name: null,
        email: null,
        email_server: [],
        account_description: null,
        is_support: true,
        items: [],
      },
      dataItems: [{ package: [], application: [], key: 1 }],
      permission_key: "cross",
      action_key: "account-create",
      breadcrumbs: [],
      isShowSkeleton: false,
      isModalResponse: false,
      contentModal: "",
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      page: 1,
      pageSize: 100,
      optionPackage: [],
      optionApp: [],
      optionEmailServer: [
        {
          value: "office_365",
          name: "Office 365",
          img: require("@/assets/images/server_type/office-365.png"),
          is_disabled: false,
        },
        {
          value: "hotmail_or_outlook",
          name: "Hotmail/Outlook",
          img: require("@/assets/images/server_type/microsoft.png"),
          is_disabled: false,
        },
        {
          value: "google",
          name: "Google",
          img: require("@/assets/images/server_type/google.png"),
          is_disabled: false,
        },
        {
          value: "local_account",
          name: "Local account",
          img: require("@/assets/images/server_type/local.png"),
          is_disabled: false,
        },
      ],
      disabledAddItem: true,
      searchData: null,
      isCustom: false
    };
  },
  computed: {
    filteredOptionsApp() {
      return this.checkApplication();
    },
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.handleLoading(false);
      this.getCombo();
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.invitation_account,
        name: "InvitationAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.create_account.title_page,
        name: "CreateOwnerAccount",
      });
    },
    validate() {
      var statuserr = "";
      var isStatus = this.checkPackage();
      if (isStatus) statuserr = "error";
      return statuserr;
    },
    getCombo() {
      this.getListPackage();
      this.getListApplication();
    },
    getListPackage(search) {
      this.optionPackage = [];
      this.searchData = search;
      return new Promise((resolve, reject) => {
        let params = {
          page: this.page,
          page_size: this.pageSize,
        };
        if (this.searchData) params.search = this.searchData;

        userAPI
          .getPackageCustom(params)
          .then((res) => {
            if (res?.data?.result?.items.length > 0) {
              this.optionPackage = res.data.result.items;
            }
            this.$nextTick(() => {
              this.checkPackageCustom();
              this.$forceUpdate();
              resolve(res);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getListApplication(name) {
      console.log("getListApplication : ", name);
      this.optionApp = [];
      return new Promise((resolve, reject) => {
        let params = {
          page: this.page,
          page_size: this.pageSize,
        };
        if (name) params.search = name;

        userAPI
          .getBzbsApplication(params)
          .then((res) => {
            if (res?.data?.result?.items.length > 0) {
              this.optionApp = res.data.result.items;
            }
            this.$nextTick(() => {
              this.$forceUpdate();
              resolve(res);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addItem() {
      var key =
        Math.max.apply(
          Math,
          this.dataItems.map(function (o) {
            return o.key;
          })
        ) + parseInt(1);
      if (key === Number.NEGATIVE_INFINITY) key = parseInt(1);
      this.dataItems.push({ key: key, package: [], application: [] });
      this.removeOptionPackageCustom();
    },
    changeEmail(e) {
      console.log("changeEmail : ", e.target.value);
      this.form.setFieldsValue({ email_server: "" });
      this.checkEmail(e.target.value);
    },
    checkEmail(email) {
      if (email) {
        _.forEach(this.optionEmailServer, (item) => {
          if (email.includes("@gmail.com")) {
            if (
              item.value == "office_365" ||
              item.value == "hotmail_or_outlook"
            ) {
              item.is_disabled = true;
            } else {
              item.is_disabled = false;
            }
          } else if (
            email.includes("@hotmail.com") ||
            email.includes("@outlook.com")
          ) {
            if (item.value == "office_365" || item.value == "google") {
              item.is_disabled = true;
            } else {
              item.is_disabled = false;
            }
          } else if (email.includes("@buzzebees.com")) {
            if (item.value == "hotmail_or_outlook" || item.value == "google") {
              item.is_disabled = true;
            } else {
              item.is_disabled = false;
            }
          } else {
            item.is_disabled = false;
          }
        });
      }
    },
    handleRemove(key) {
      var index = this.dataItems
        .map((x) => {
          return x.key;
        })
        .indexOf(key);

      this.dataItems.splice(index, 1);
      this.$nextTick(() => {
        this.getListPackage(this.searchData);
        this.checkPackageCustom();
        this.$forceUpdate();
      });
    },
    handleChangePackage() {
      this.checkPackage();
      this.checkPackageCustom();
    },
    checkPackage() {
      if (this.dataItems.length > 0) {
        const seen = new Set();
        for (const key of this.dataItems) {
          const keyValue = key.package[1];
          if (seen.has(keyValue)) {
            return true; // Duplicate found
          }
          seen.add(keyValue);
        }
        return false;
      } else {
        return false;
      }
    },
    checkPackageCustom() {
      this.isCustom = false;
      if (this.dataItems.length > 0) {
        _.forEach(this.dataItems, (item) => {
          if (item.package.length > 0) {
            if (item.package[2] == "Customized") {
              this.disabledAddItem = true;
              this.isCustom = true;
            } else if (item.package[2] == "Standard") {
              this.disabledAddItem = false;
              if (this.dataItems.length > 1) {
                this.removeOptionPackageCustom();
              }
            }
          }
        });
      }
    },
    removeOptionPackageCustom() {
      if (this.optionPackage.length > 0) {
        this.optionPackage = _.filter(this.optionPackage, (item) => {
          if (item.type != "Customized") return item;
        });
      }
    },
    checkApplication() {
      if (this.dataItems.length > 0) {
        var flatMap = this.dataItems.flatMap((o) => o.application);
        let itemIds = [];
        _.forEach(flatMap, (id) => {
          if (id) {
            if (id.includes("(")) {
              itemIds.push(id.split("(")[0].trim());
            }
          }
        });
        return this.optionApp.filter((o) => !itemIds.includes(o.id));
      } else {
        return this.optionApp;
      }
    },
    handleSave(e) {
      e.preventDefault();
      this.handleLoading(true);
      this.form.validateFieldsAndScroll((err, values) => {
        console.log(values);
        if (!err) {
          let params = {
            account_description: values.account_description,
            account_name: values.account_name,
            email: values.email,
            email_server: values.email_server,
            is_support: values.is_support,
            is_custom: this.isCustom ?? false,
          };
          if (values.items.length > 0) {
            _.forEach(values.items, (item) => {
              if (item.application_ids.length > 0) {
                _.forEach(item.application_ids, (id, index) => {
                  if (id.includes("(")) {
                    item.application_ids[index] = id.split("(")[0].trim();
                  }
                });
              }
              if (item.package_id != null) {
                if (item.package_id.length > 0) {
                  item.package_id = item.package_id[1];
                }
              }
            });
            params.items = values.items;
          }

          setTimeout(() => {
            this.callAPICreateAccount(params);
          }, 500);
        } else {
          this.handleLoading(false);
        }
      });
    },
    callAPICreateAccount(params) {
      return new Promise((resolve) => {
        accountAPI
          .postCreateOwnerAccount(params)
          .then((res) => {
            this.handleLoading(false);
            this.showModalSuccess();
            resolve(res);
          })
          .catch((error) => {
            this.handleLoading(false);
            this.errorApi(error);
            resolve(error);
          });
      });
    },
    showModalSuccess() {
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        title: "Success",
        content: "You have successfully",
        centered: true,
        autoFocusButton: null,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
        onOk: () => {
          this.$router.push({ name: "InvitationAccount" });
        },
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        this.$router.push({ name: "InvitationAccount" });
      }, secondsToGo * 1000);
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";

.ant-card-bordered {
  &.card-active {
    border: 1px solid var(--primary-gold-bzb-primary-bzb);
  }
}

.page-create-owner-account {
  margin-bottom: 70px;

  .ant-select-selection-selected-value {
    overflow: initial !important;
  }
}

.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected.disabled-app {
  pointer-events: none;
  color: #b0b0b0;
  cursor: not-allowed;
}

.custom-footer-create-account {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 4;
  padding: 14px 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
</style>
