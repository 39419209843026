<template>
  <div class="layout-auth page-layout layout-Invite">
    <div
      class="layout-background"
      :style="{ 'background-image': 'url(' + banner + ')' }"
    ></div>
    <div v-if="islogininvite" class="layout-content py-5">
      <div class="content-box">
        <div class="content-title content-title--line text-center">
          {{
            mylbl("invite_page.name").includes("not found")
              ? "Buzzebees Permission Management"
              : mylbl("invite_page.name")
          }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="content-detail" v-html="desc_line1"></div>
        <div class="content-detail" v-html="desc_line2"></div>
        <div class="pb-lg-0 text-center">
          <button class="btn btn-primary py-2" @click="signinAD">
            {{
              mylbl("invite_page.btn.btn_signin").includes("not found")
                ? "Signin"
                : mylbl("invite_page.btn.btn_signin")
            }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="isShowResultInvite" class="layout-content py-5">
      <div class="content-box" style="max-width: 850px">
        <div class="content-title content-title--line text-center">
          <span v-if="resultInvite.err">
            <div>
              {{ mylbl("invite_page.name") }}
            </div>
            <div class="title-erroe">
              <!-- {{ mylbl("invite_page.title_error") }} -->
              {{ resultInvite.content }}
            </div>
            <div class="content-detail" style="text-align: left">
              <div>Error Code: {{ resultInvite.name }}</div>
            </div>
            <div class="content-detail" style="text-align: left">
              Error Message: {{ resultInvite.content }}
            </div>
            <div class="content-detail" style="text-align: left">
              Transaction ID: {{ resultInvite.id }}
            </div>
          </span>
          <span v-else>
            <div class="title-success">
              {{ mylbl("invite_page.name") }}
            </div>
            <div class="massages-success">
              {{ mylbl("invite_page.desc_success") }}
            </div>
            <div class="massages-success">
              {{ mylbl("invite_page.massages_success") }}
            </div>
            <button class="btn btn-primary py-2" @click="closetab">
              {{ mylbl("invite_page.btn.btn_close") }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cache from "@/helper/Cache";
import AccountHelper from "@/helper/AccountHelper";
import TimerHelper from "@/helper/TimerHelper.js";
import { config } from "@/config/bzbsconfig.js";
import * as Msal from "@azure/msal-browser";
const myMSAL = new Msal.PublicClientApplication(config.msalConfig);

export default {
  name: "Login",
  data() {
    return {
      defaultLanguage: "Eng",
      productName: "",
      banner: require("@/assets/images/auth/Aare@3x.png"),
      logo: require("@/assets/images/LogoPowered.png"),
      islogininvite: true,
      isShowResultInvite: false,
      timeHelper: new TimerHelper(),
      desc_line1: "",
      desc_line2: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    getLanguageList() {
      return [
        {
          title: this.lbl.language_title.en,
          key: "en",
        },
        {
          title: this.lbl.language_title.th,
          key: "th",
        },
      ];
    },
    beforeCreate() {
      Cache.set("BzbsLocale", "en");
    },
    init() {
      AccountHelper.clearCache();
      this.handleLoading(false);
      let resultInvite = Cache.get("resultInvite");
      Cache.remove("resultInvite");
      let inviteobj = Cache.get("inViteCode");
      Cache.remove("inViteCode");
      let raraminviteobj = new URLSearchParams(inviteobj);
      let name = "";
      let main_url = "";
      if (raraminviteobj.has("product_name")) {
        name = raraminviteobj.get("product_name");
      }
      if (raraminviteobj.has("product_main_url")) {
        main_url = raraminviteobj.get("product_main_url");
      }
      this.productName = name ? name : config.appName;
      this.redirectUrlTo = main_url;
      if (!this.$route.query.code && !resultInvite) {
        this.$router.push({ name: "Home" });
      }
      if (resultInvite) {
        this.islogininvite = false;
        this.isShowResultInvite = true;
        this.resultInvite = resultInvite;
      }
      let account_name = this.$route.query.account_name;
      account_name =
        '<span class="title-account-name"> ' + account_name + "</span>";
      let text_desc_line1 = this.mylbl("invite_page.desc_line1").includes(
        "not found"
      )
        ? "You have been invited to join {account_name} account."
        : this.mylbl("invite_page.desc_line1");
      this.desc_line1 = text_desc_line1.replace("{account_name}", account_name);
      let invited_email = this.$route.query.invited_email;
      invited_email =
        '<span class="title-invited-email"> ' + invited_email + "</span>";
      let text_desc_line2 = this.mylbl("invite_page.desc_line1").includes(
        "not found"
      )
        ? "Please sign in with the invited email {invited_email}"
        : this.mylbl("invite_page.desc_line2");
      this.desc_line2 = text_desc_line2.replace(
        "{invited_email}",
        invited_email
      );
    },
    handleMenuClick(e) {
      const nowlocale = Vue.$cookies.get("BzbsLocale");
      if (nowlocale == e.key) {
        return;
      }
      this.handleLoading(true);
      Vue.$cookies.set("BzbsLocale", e.key);
      window.location.reload();
    },
    signinAD() {
      // this.$router.push({ name: "Redirectms" });
      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
        state: "state&" + window.location.search.substring(1),
      };
      myMSAL.logoutRedirect(logoutRequest);
    },
    goto() {
      if (this.redirectUrlTo) {
        window.location = this.redirectUrlTo;
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    closetab() {
      window.close();
    },
    setRunTimeout: function () {
      this.isExpireTime = false;
      this.timeHelper.stopTimer();
      this.timeHelper.resetTimer();
      this.timeHelper.initialTimer(10 * 1, null, false, false);
      this.timeHelper.runTimer(() => {
        this.setNewRunTimeout();
      });
    },
    setNewRunTimeout: function () {
      this.timeHelper.stopTimer();
      this.timeHelper.resetTimer();
      this.goto();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.layout-Invite {
  min-height: 100vh;
  @include flex();
  @include background-style();
  position: relative;
  background-color: #eef0f8;
  .layout-background {
    opacity: 0.43;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include background-style();
    background-attachment: fixed;
  }
  .box-text-auth {
    bottom: 30px;
    position: absolute;
    align-items: center;
    width: 100%;
    .text-auth {
      float: left;
      color: #fff;
      font-size: 10px;
      margin: 10px;
    }
  }
  .box-language-auth {
    float: right;
    top: 20px;
    position: absolute;
    right: 35px;
    a {
      color: rgb(196 196 196);
      text-decoration: none;
      opacity: 0.3;
    }
  }
  /* .dropdown-menu {
    min-width: 1rem;
    background: inherit;
    border: inherit;
    transform: translate3d(-10px, -65px, 0px) !important;
    font-size: 10px;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #666666 !important;
    text-decoration: none !important;
    background-color: #ffffff !important;
  } */
  .text-auth {
    color: #fff;
    font-size: 10px;
    margin: 10px;
  }
  .btn.btn-primary {
    font-size: 10px;
    color: #666666;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
  .btn.btn-primary:focus:not(.btn-text),
  .btn.btn-primary.focus:not(.btn-text) {
    color: #666666;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #666666;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  .layout-content {
    font-size: 16px;
    max-width: 100%;
    z-index: 1;
    padding: 0 15px;
    margin: auto;
    .logo {
      margin-top: 22px;
      position: absolute;
      img {
        max-width: 70px;
        @include breakpoint-max($mobile-md) {
          max-width: 50px;
        }
      }
      .title {
        color: $color-white;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    .title-success {
      color: $green-green-3;
    }
    .massages-success {
      line-height: 1.5;
      margin: 16px 0 0;
      font-size: 22px;
    }
    .content-box {
      background-color: $white-primary;
      border-radius: 12px;
      border: solid 1px $blue-light;
      padding: 60px 40px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      min-width: 670px;
      border-radius: 6px;
      text-align: center;
      .content-title {
        font-size: 40px;
        line-height: 1.2;
        color: $grey-grey-1;
      }
      .content-detail {
        font-size: 16px;
        line-height: 1.5;
        color: $grey-grey-3;
        margin: 16px 0 0;
      }
    }
    .btn.btn-primary {
      margin-top: 25px;
      height: 40px;
      width: 150px;
      padding: 0 24px;
      font-size: 16px;
      color: $white-primary;
      border: solid 1px $color-primary;
      background-color: $color-primary;
    }
    .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
    .btn.btn-primary:focus:not(.btn-text),
    .btn.btn-primary.focus:not(.btn-text) {
      color: $white-primary;
      border: solid 1px $color-primary;
      background-color: $color-primary;
    }
    .title-account-name {
      color: $blue-primary;
      font-size: 18px;
    }
    .title-invited-email {
      color: $color-primary;
      font-size: 18px;
    }
    .title-erroe {
      color: red;
      font-size: 20px;
      margin: 15px 0;
    }
  }
}
</style>
