<template>
  <div class="campaign-list-package-table box-icon-a-disabled">
    <a-table
      id="campaign-list-package-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 331, x: 860 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.invitation_page.btn.ok,
        filterReset: lbls.invitation_page.btn.reset,
      }"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="margin-bottom: 8px; display: block; font-size: 12px"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-email" slot-scope="text">
        {{ text }}
      </template>
      <template slot="template-account-name" slot-scope="text">
        {{ text }}
      </template>
      <template slot="template-status" slot-scope="text, record">
        <a-tag
          v-if="record.is_already_member"
          :color="'purple'"
          class="tag-status"
        >
          <a-badge
            :color="'purple'"
            :text="lbls.invitation_page.tbl_colum_member"
          />
        </a-tag>
        <div v-if="!record.is_already_member">
          <a-tag v-if="text" :color="'green'" class="tag-status">
            <a-badge
              :status="'success'"
              :text="lbls.invitation_page.tbl_colum_accepted"
            />
          </a-tag>
          <a-tag v-else :color="'red'" class="tag-status">
            <a-badge
              :status="'error'"
              :text="lbls.invitation_page.tbl_colum_unaccepted"
            />
          </a-tag>
        </div>
        <div v-if="text" class="title-accepted-date">
          {{ formatDateString(record.accepted_date) }}
        </div>
      </template>
      <template slot="template-role-name" slot-scope="text, record">
        <span v-if="text && text.length > 0" class="role-custom">
          <div v-for="(tag, index) in text" :key="tag.id">
            <div v-if="index <= checkShowMore(record)">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <span v-if="record.auto_assigned_role_names.length > 1"
                    >{{ index + 1 }}.
                  </span>
                  <span>{{ tag }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="text.length > 3"
            class="show-hide-custom mt-2"
            @click="loadMore(record)"
          >
            <span>
              {{
                record.isShowMore
                  ? lbls.role_page.btn.hide
                  : lbls.role_page.btn.more
              }}
            </span>
            <span v-if="!record.isShowMore"> ({{ text.length }})</span>
            <span class="ml-2">
              <!-- <img
                  :class="{ 'img-rotate': record.isShowMore }"
                  style="height: 4px"
                  :src="IConArrowDownPrimary"
                /> -->
              <a-icon v-if="record.isShowMore" type="caret-up" />
              <a-icon v-else type="caret-down" />
            </span>
          </div>
        </span>
        <span v-else> - </span>
      </template>
      <template slot="accepted" slot-scope="text">
        <span class="status-active" :class="{ inactive: !text }">
          {{ text === true ? "Accepted" : "Inaccept" }}
        </span>
      </template>
      <template slot="template-date" slot-scope="text">
        <div>
          {{ formatDateString(text) }}
        </div>
      </template>
      <template slot="template-expired-date" slot-scope="text, record">
        <div
          :class="{
            expired_date: record.is_expired && !record.is_accepted,
          }"
        >
          {{ formatDateString(text) }}
        </div>
      </template>
      <template slot="template-delete" slot-scope="text, record">
        <div class="action-list">
          <a-tooltip
            :disabled="
              record.is_already_member ||
              record.is_accepted ||
              record.is_expired ||
              !isPermissionDelete
            "
          >
            <template slot="title">
              {{ lbls.invitation_page.btn.delete }}
            </template>
            <a @click="handleDelete(record)">
              <a-icon type="delete" />
            </a>
          </a-tooltip>
        </div>
      </template>
      <template slot="template-created-by" slot-scope="text, record">
        <div>{{ record.created_name }}</div>
        <div class="lbl-user-email">{{ record.created_by }}</div>
      </template>
      <template slot="template-package" slot-scope="text">
        <div class="row-name-custom">
          <div v-if="text.length > 0">
            <div v-for="(item, index) in text" :key="index">
              <div class="d-flex align-items-end">
                <div class="item-name text-ellipsis">
                  {{ item.name }}
                </div>
                <div class="item-see-more">
                  <div
                    v-if="item.applications.length > 0"
                    class="btn-toggle d-flex align-items-center"
                  >
                    <div class="column-name-text-caption ml-1">
                      ({{ item.applications.length }})
                    </div>
                    <a-tooltip placement="top">
                      <template slot="title">
                        {{ lbls.package_page.btn.more }}
                      </template>
                      <a @click="handleClickIcon(item.applications)">
                        <a-icon type="monitor" />
                      </a>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </div>
      </template>
    </a-table>

    <div v-if="isShowModalDetails">
      <ModalDetails
        :conditionmodal="conditionModal"
        :datalist="dataList"
        @actionModalClose="actionModalClose"
      >
      </ModalDetails>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import ModalDetails from "@/module/Cross/ManageInvitationAccount/components/Modal/ModalDetails.vue";

export default {
  name: "ManageInvitation",
  components: {
    ModalDetails,
  },
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "user",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      roleFilters: [],
      productFilters: [],
      isFilters: false,
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "160px",
          keys: "tbl_colum_accepted",
          dataIndex: "is_accepted",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "tbl_colum_member",
              value: "member",
            },
            {
              text: "tbl_colum_accepted",
              value: "true",
            },
            {
              text: "tbl_colum_unaccepted",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            if (value == "member") {
              return record.is_already_member;
            }
            if (value != "member") {
              value = value == "true";
              return record.is_accepted === value && !record.is_already_member;
            }
          },
        },
        {
          id: "Company Name",
          title: "Company Name",
          keys: "tbl_colum_company_name",
          dataIndex: "account_name",
          width: "180px",
          scopedSlots: { customRender: "template-account-name" },
          sorter: (a, b) => a.account_name.localeCompare(b.account_name),
        },
        {
          id: "Email",
          title: "Email",
          keys: "tbl_colum_email",
          dataIndex: "invited_email",
          width: "250px",
          scopedSlots: { customRender: "template-email" },
          sorter: (a, b) => a.invited_email.localeCompare(b.invited_email),
        },
        {
          id: "Package",
          title: "Package",
          keys: "tbl_colum_package",
          dataIndex: "items",
          width: "180px",
          scopedSlots: { customRender: "template-package" },
        },
        {
          id: "Invite By",
          title: "Invite By",
          keys: "tbl_colum_invite_by",
          dataIndex: "created_by",
          width: "200px",
          scopedSlots: { customRender: "template-created-by" },
        },
        {
          id: "InviteDate",
          title: "Invite Date",
          keys: "tbl_colum_invite_date",
          dataIndex: "created_date",
          width: "180px",
          scopedSlots: { customRender: "template-date" },
        },
        {
          id: "ExpiredDate",
          title: "Expired Date",
          keys: "tbl_colum_expired_date",
          dataIndex: "expired_date",
          width: "180px",
          scopedSlots: { customRender: "template-expired-date" },
        },
        {
          title: "",
          width: "65px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-delete" },
        },
      ],
      formatdate: "DD  MMM  YYYY - hh:mm A",
      isPermissionDelete: true,
      isShowModalDetails: false,
      conditionModal: {},
      dataList: [],
    };
  },
  watch: {
    datatable() {
      this.setDataFilters();
      this.data = this.datatable;
      _.forEach(this.data, (element) => {
        if (element.is_already_member) {
          element.is_accepted = true;
        }
      });
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      _.forEach(this.data, (element) => {
        if (element.is_already_member) {
          element.is_accepted = true;
        }
      });
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.setDataFilters();
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.invitation_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.invitation_page[filters.text];
          });
        }
      });
    },
    setDataFilters() {
      this.roleFilters.push({
        text: "-",
        value: "none",
      });
      this.productFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.datatable, (element) => {
        if (element.auto_assigned_role_names) {
          _.forEach(element.auto_assigned_role_names, (item) => {
            this.roleFilters.push({
              text: item,
              value: item,
            });
          });
        }
        if (element.product_display_name) {
          this.productFilters.push({
            text: element.product_display_name,
            value: element.product_display_name,
          });
        }
      });
      this.roleFilters = [
        ...new Map(this.roleFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumnsRole = this.columns.find((o) => o.id == "Role");
      if (findcolumnsRole) {
        findcolumnsRole.filters = this.roleFilters;
      }
      this.productFilters = [
        ...new Map(
          this.productFilters.map((item) => [item.text, item])
        ).values(),
      ];
      let findcolumnsProduct = this.columns.find(
        (o) => o.id == "ProductDisplayName"
      );
      if (findcolumnsProduct) {
        findcolumnsProduct.filters = this.productFilters;
      }
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    formatDate(date) {
      if (date == "" || date == "-" || date == undefined) {
        return "-";
      } else {
        return moment(date).format(this.formatdate);
      }
    },
    handleDelete(record) {
      this.$emit("handledelete", record);
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["is_accepted"]
        ? filters["is_accepted"]
        : [];
      let isFiltersProduct = filters["product_display_name"]
        ? filters["product_display_name"]
        : [];
      let isFiltersRole = filters["product_display_name"]
        ? filters["product_display_name"]
        : [];
      isFiltersStatus = isFiltersStatus.length > 0;
      isFiltersProduct = isFiltersProduct.length > 0;
      isFiltersRole = isFiltersRole.length > 0;
      isFilters = isFiltersStatus || isFiltersProduct || isFiltersRole;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    checkShowMore(record) {
      let lengthData = 2;
      if (record.isShowMore) {
        lengthData = record.auto_assigned_role_names.length;
      }
      return lengthData;
    },
    loadMore(record) {
      record.isShowMore = record.isShowMore == true;
      record.isShowMore = !(record.isShowMore != false);
      this.$forceUpdate();
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleClickIcon(applications) {
      console.log("handleClickIcon : ", applications);
      this.dataList = applications;
      this.conditionModal = {
        visible: true,
        title: `${this.lbls.invitation_page.modal.application_list} (${applications.length})`,
      };
      this.isShowModalDetails = true;
    },
    actionModalClose() {
      this.dataList = [];
      this.isShowModalDetails = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.custom-column-email {
  display: flex;
  .column-email-left {
    width: 16px;
  }
  .column-email-right {
    width: calc(100% - 16px);
  }
}
.role-custom {
  .img-rotate {
    transform: rotate(180deg);
  }
  .show-hide-custom {
    cursor: pointer;
    font-size: 12px;
    color: $color-primary !important;
    font-weight: 600;
  }
}
.title-accepted-date {
  font-size: 10px;
  font-weight: 500;
  line-height: 2;
  opacity: 0.6;
}
.disabled {
  color: var(--text-light-text-disable) !important;
  pointer-events: none;
}
.lbl-user-email {
  font-size: 12px;
  line-height: 1.5;
  color: #b5b5c3;
}
.campaign-list-package-table {
  .row-name-custom {
    .text-ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .item-name {
      max-width: calc(100% - 46px);
    }
    .item-see-more {
      display: flex;
      align-items: center;
      width: 46px;
    }
  }
}
</style>
