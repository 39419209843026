<template>
  <div v-if="data.name" class="item-role">
    <div class="item-role-header flex-center-between">
      <div>
        <a-input-search
          v-model="searchData"
          :placeholder="lbls.role_page.input_search"
          class="input-search"
          size="large"
          @blur="onSearch"
          @search="onSearch"
        >
          <template slot="suffix">
            <a-icon
              v-if="isSearchData"
              style="margin-right: 5px; font-size: 10px"
              type="close-circle"
              theme="filled"
              class="icon-close-search-input"
              @click="closeSearchData()"
            />
          </template>
        </a-input-search>
      </div>
      <div class="d-flex">
        <a-button
          type="primary"
          ghost
          :disabled="dataApplications.length == 0"
          @click="
            goToPageAndQuery('ManageSetAllExcouldPerMission', $route.query.id)
          "
        >
          {{ lbls.role_page.btn.excould_permission_all_applications }}
        </a-button>
        <a-button
          class="ml-3"
          type="danger"
          :disabled="dataApplications.length == 0"
          @click="handleDeleteAllApp()"
        >
          <a-icon type="delete" />
          {{ lbls.role_page.btn.delete_apps }}
        </a-button>
      </div>
    </div>
    <div class="item-role-body">
      <div v-if="isShowSkeleton" class="background-white mt-3 p-5">
        <a-skeleton />
      </div>
      <div
        v-else
        class="mt-3"
        :class="{
          'item-apps-inrole': dataApplications.length >= 2,
          'item-apps-inrole-min': dataApplications.length < 2,
        }"
      >
        <div>
          <a-tooltip>
            <template slot="title">
              {{ lbls.role_page.btn.add_more_apps }}
            </template>
            <div
              class="item-apps add-item flex-center-center"
              :class="{ 'item-apps-width': dataApplications.length < 2 }"
              @click="handleAdd"
            >
              <div>
                <span class="icon-add-app">
                  <!-- <img :src="IConAddBlue" /> -->
                  <a-icon type="plus-circle" />
                </span>
                <span class="ml-1"> {{ lbls.role_page.btn.more_app }}</span>
              </div>
            </div>
          </a-tooltip>
        </div>
        <div
          v-for="item in dataApplications"
          :key="item.id"
          class="item-apps"
          :class="{ 'item-apps-width': dataApplications.length < 2 }"
          @click="goToPageQuery('ManageSetExcouldPerMission', item)"
        >
          <div class="item-apps-top">
            <div class="logo-custom">
              <img
                :src="`${item.application.logo_url}?v=${datetimestamp}`"
                @error="handleError"
              />
            </div>
            <div class="label-item ml-2">
              <div class="label-name-app">{{ item.application.name }}</div>
              <div class="label-app-id mt-1">
                {{ item.application.buzzebees_application_id }}
              </div>
              <div class="label-package">
                <div class="label-package-box-left">
                  <a-tooltip>
                    <template slot="title">
                      <span>{{ lbls.role_page.title_package }}: </span>
                      <span class="label-name-title">
                        {{ item.package.display_name }}
                      </span>
                    </template>
                    {{ item.package.display_name }}
                  </a-tooltip>
                </div>
                <div class="label-package-box-right ml-2">
                  <span class="label-name-product">
                    <a-tooltip>
                      <template slot="title">
                        <div
                          v-for="(product, i) in item.permission_products"
                          :key="product.id"
                        >
                          {{ i + 1 }}. {{ product.display_name }}
                        </div>
                      </template>
                      {{ lbls.role_page.title_product }}:
                      {{ item.permission_products.length }}
                    </a-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item-apps-bottom label-desc mt-2 flex-center-between">
            <div>
              <span>{{ lbls.role_page.title_last_update }}: </span>
              <span class="label-name-title"
                >{{ formatDateString(item.last_modified_date) }}
              </span>
            </div>
            <div class="d-flex action-list">
              <a-tooltip>
                <template slot="title">
                  {{ lbls.role_page.btn.delete_app }}
                </template>
                <a @click="handleDeleteApp(item.application)">
                  <a-icon type="delete" />
                </a>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  {{ lbls.role_page.btn.set_excould_permission }}
                </template>
                <router-link
                  :to="{
                    name: 'ManageSetExcouldPerMission',
                    query: {
                      id: $route.query.id,
                      appid: item.application.id,
                      name: data.name,
                    },
                  }"
                >
                  <a-icon type="arrow-right" />
                </router-link>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 mb-3 pl-4 label-apps-length">
      <ShowTotal
        v-if="!isShowSkeleton"
        :datashowtitle="dataApplications"
        :conditiontable="conditionTable"
        :totallist="dataApplications.length"
        @callgetlist="callGetList"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import Cache from "@/helper/Cache.js";
import ShowTotal from "@/components/Layout/ShowTotal";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import PermissionHelper from "@/helper/PermissionHelper";

export default {
  name: "PageHeader",
  components: {
    ShowTotal,
  },
  props: {
    datapageheader: {
      type: Object,
    },
    dataapplications: {
      type: Array,
    },
    hasmoredata: {
      type: Boolean,
    },
  },
  data() {
    return {
      innerHeight: 0,
      lastscrollTop: 0,
      logo: require("@/assets/images/bzbs-logo1.png"),
      data: {},
      conditionTable: {},
      dataApplications: [],
      searchData: "",
      nameSearch: "",
      switchLoading: false,
      isShowSkeleton: true,
      isDeleteApp: false,
      hasMoredata: false,
      isSearchData: false,
      datetimestamp: moment().format("x"),
      configAdminName: PermissionHelper.configAdminName(),
    };
  },
  watch: {
    datapageheader() {
      this.data = _.cloneDeep(this.datapageheader);
      this.$forceUpdate();
    },
    dataapplications() {
      this.dataApplications = _.cloneDeep(this.dataapplications);
      this.isShowSkeleton = false;
      this.tableContent = document.querySelector(".item-role-body");
      this.hasMoredata = _.cloneDeep(this.hasmoredata);
      this.conditionTable = {
        hasMoreData: this.hasMoredata,
        isFilters: false,
      };
      this.onScroll();
      this.$forceUpdate();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".item-role-body");
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = _.cloneDeep(this.datapageheader);
      this.exAccount = Cache.get("exAccount");
    },
    onScroll() {
      if (this.tableContent) {
        this.tableContent.addEventListener("scroll", (event) => {
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
          let scrollPersent = (currentScroll / maxScroll) * 100;
          if (scrollPersent >= 95 && this.hasMoredata && isScrollDown) {
            this.hasMoredata = false;
            this.callGetList();
          } else {
            return;
          }
          this.lastscrollTop = event.target.scrollTop;
        });
      }
    },
    handleError(event) {
      event.target.src = this.logo;
    },
    handleAdd() {
      this.$emit("handleadd");
    },
    callGetList() {
      this.handleLoading(true);
      this.$emit("callgetlist");
    },
    handleDeleteRole() {
      this.$emit("handledeleterole");
    },
    handleDeleteApp(item) {
      this.isDeleteApp = true;
      this.$emit("handledeleteapp", item);
      setTimeout(() => {
        this.isDeleteApp = false;
      }, 100);
    },
    handleDeleteAllApp() {
      this.$emit("handledeleteall");
    },
    handleCopy(item) {
      this.$emit("handlecopy", item);
    },
    onSearch() {
      let text = this.searchData;
      this.isSearchData = this.searchData != "";
      if (text != this.nameSearch) {
        this.nameSearch = text != undefined ? text : "";
        this.isShowSkeleton = true;
        this.$emit("onsearch", this.searchData);
      } else {
        return;
      }
    },
    changeSwitch(checked) {
      this.data.is_active = checked;
      this.switchLoading = true;
      this.callPostEditData();
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        role_name: this.data.name,
        role_description: this.data.description,
        is_active: this.data.is_active,
      };
      let valueJson = _.cloneDeep(JSON.stringify(valueSave));
      manageRoleAPI
        .postEditRole(valueJson)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.openNotificationWithIcon("success");
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.lbls.role_page.modal.title_success,
        description: this.lbls.role_page.modal.content_update_success,
      });
      this.switchLoading = false;
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      this.$forceUpdate();
    },
    goToPageQuery(page, item) {
      if (this.isDeleteApp || this.isCopy) return;
      this.$router.push({
        name: page,
        query: {
          id: this.$route.query.id,
          appid: item.application.id,
          name: this.data.name,
        },
      });
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.ant-modal-content {
  .ant-modal-body {
    color: red;
  }
}
.item-role {
  border: 1px solid $grey-grey-7;
  border-radius: 8px;
  line-height: 1.2;
  .item-role-header {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid $grey-grey-7;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    .ant-divider,
    .ant-divider-vertical {
      top: 5px;
      height: 16px;
      margin: 0 10px;
    }
  }
  .add-item {
    border: 1px dashed #91d5ff !important;
    background: #e6f7ff !important;
    color: #40a9ff;
    font-weight: normal;
    cursor: pointer;
  }
  .item-apps {
    border: 1px solid $grey-grey-7;
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    height: 110px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    .label-name-title {
      font-weight: normal;
    }
    .ant-divider,
    .ant-divider-vertical {
      top: 1px;
    }
  }
  .item-apps-width {
    width: 356px;
  }
  .item-apps:hover {
    border: 1px solid $color-primary;
  }
  .item-role-body {
    padding: 16px;
    max-height: calc(100vh - 295px);
    overflow: auto;
    .item-apps-inrole {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
    }
    .item-apps-inrole-min {
      display: flex;
      gap: 16px;
    }
    .role-body-box-left {
      width: calc(100% - 250px);
    }
    .role-body-box-right {
      width: 250px;
      text-align: right;
    }
    .btn-custom-delete {
      img {
        margin-top: -4px;
      }
    }
  }
  .icon-add-app {
    img {
      width: 22px;
      margin-top: -2px;
    }
  }
  .icon-arrow-next {
    width: 15px;
    cursor: pointer;
    img {
      width: 12px;
      margin-top: -2px;
    }
  }
  .icon-copy {
    width: 14px;
    cursor: pointer;
    img {
      width: 14px;
      margin-top: -3px;
    }
  }
  .icon-width-18 {
    margin-top: -10px;
  }
  .icon-danger-custom {
    margin-top: -2px;
    width: 18px;
    height: auto;
  }
  .icon-delete {
    width: 20px;
    cursor: pointer;
    img {
      width: 18px;
      margin-top: -3px;
    }
  }
  .item-apps-top {
    display: flex;
    cursor: pointer;
    width: 100%;
    .logo-custom {
      width: 50px;
      border: 1px solid var(--grey-grey-8);
      border-radius: 4px;
      display: flex;
      text-align: center;
      justify-content: center;
      img {
        width: 100%;
        border-radius: 4px;
      }
    }
    .label-name-app {
      font-weight: 600;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .label-app-id {
      color: $grey-grey-5;
      font-size: 12px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .label-package {
      color: $grey-grey-5;
      font-size: 12px;
      display: flex;
      align-items: center;
      width: 100%;
      .label-package-box-left {
        max-width: calc(100% - 110px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .label-package-box-right {
        width: 100px;
      }
    }
    .label-name-product {
      border: 1px solid;
      border-radius: 25px;
      padding: 0px 8px;
      font-weight: normal;
    }
  }
  .label-item {
    display: block;
    line-height: 1.2;
    width: calc(100% - 50px);
  }
  .item-apps-bottom {
    font-size: 12px;
  }
  .label-name {
    font-weight: 600;
    font-size: 18px;
    .label-apps-length {
      opacity: 0.5;
      font-size: 16px;
    }
  }
  .label-desc {
    color: $grey-grey-5;
  }
  .icon-edit {
    cursor: pointer;
  }
  .action-list a {
    padding: 0 5px;
  }
}
</style>
