<template>
  <AppLayout>
    <!-- <div class="title-page-new">
        <PageBreadcrumba
          v-if="isSelectPackage"
          :datapagebreadcrumb="dataPageBreadcrumb"
        />
        <PageBreadcrumbb
          v-if="isComparePackage"
          :datapagebreadcrumb="dataPageBreadcrumb"
          @onselectpackage="onSelectPackage"
        />
      </div> -->
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :databreadcrumbs="breadcrumbs"
        :title="lbls.application_page.selectpackage.title_page"
        :totallist="totalList"
      />
      <div class="row">
        <PageHeader @onsearch="onSearch($event)" @oncompare="onCompare" />
      </div>
      <div
        v-if="isSelectPackage"
        id="box-details"
        class="manage-page-detail selectpackage"
      >
        <a-skeleton v-if="isShowSkeleton" class="mt-3" />
        <div v-if="data.length > 0 && !isShowSkeleton" class="page-wrapper">
          <carousel :per-page="itemPerPage">
            <slide v-for="item in data" :key="item.id">
              <ItemPackage
                :dataitempackage="item"
                @handlecreate="handleCreate($event)"
              />
            </slide>
          </carousel>
        </div>
        <div
          v-if="data.length == 0 && !isShowSkeleton"
          class="w-100 text-center bg-white p-5"
        >
          <a-empty :image="simpleImage" />
        </div>
      </div>
      <div v-if="isComparePackage" class="page-wrapper">
        <Comparedatatest :datacompare="dataCompare" />
      </div>
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import managePackageAPI from "@/core/Page/ManagePackageAPI";
import ManageApplicationAPI from "@/core/Page/ManageApplicationAPI";
import Cache from "@/helper/Cache.js";
import { Empty } from "ant-design-vue";
import { Carousel, Slide } from "vue-carousel";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import PageHeader from "@/module/Application/SelectPackage/components/Layout/PageHeader.vue";
import ItemPackage from "@/module/Application/SelectPackage/components/Layout/ItemPackage.vue";
import ModalProcess from "@/module/Application/SelectPackage/components/Modal/ModalProcess.vue";
import Comparedatatest from "@/module/Application/ComparePackage/page/ComparePackage.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import managePermissionAPI from "@/core/Page/ManagePermissionAPI";

export default {
  name: "SelectPackage",
  components: {
    Carousel,
    Slide,
    AppLayout,
    PageHeader,
    ItemPackage,
    ModalProcess,
    Comparedatatest,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "application",
      action_key: "create",
      isShowSkeleton: true,
      isSelectPackage: true,
      isComparePackage: false,
      data: [],
      breadcrumbs: [],
      objSelectPackage: [],
      titleModalProcess: "",
      isModalProcess: false,
      isModalResponse: false,
      modalAlert: {},
      alertData: {},
      dataObjSelect: {},
      modalprocess: {},
      dataCompare: [],
      itemPerPage: 3,
      totalList: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.checkLayout);
    });
    this.checkLayout();
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.callGetList();
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.application,
        name: "ManageApplication",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.selectpackage,
        name: "ManageApplication",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.callGetList();
      } else {
        return;
      }
    },
    onCompare() {
      this.isSelectPackage = false;
      this.isComparePackage = true;
    },
    onSelectPackage() {
      this.isSelectPackage = true;
      this.isComparePackage = false;
    },
    callGetList() {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
      };
      if (this.nameSearch) {
        params.package_name = this.nameSearch;
      }
      managePackageAPI
        .getPackageList(params)
        .then((res) => {
          this.isShowSkeleton = false;
          this.$forceUpdate();
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                this.data = res.data.result.items;
                this.totalList = res.data.result.items.length;
              }
            }
          }
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleCreate(item) {
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.application_page.drw_title_create;
      this.objSelectPackage = item;
      this.dataObjSelect = item;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
      };
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    postAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        package_id: this.objSelectPackage.id,
        application_name: this.objDataSave.name,
        application_description: this.objDataSave.description,
        buzzebees_application_Id: this.objDataSave.buzzebees_application_Id,
      };
      if (this.objDataSave.application_logo) {
        valueSave.application_logo = this.objDataSave.application_logo;
      }
      ManageApplicationAPI.postCreateApplicationLogo(valueSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.isShowSkeleton = true;
              this.callAPIPostDefaultRole();
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal =
                  this.lbls.application_page.modal.title_success;
                this.contentModal =
                  this.lbls.application_page.modal.content_create_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callAPIPostDefaultRole() {
      var params = {
        account_id: this.exAccount.account.id,
        package_id: this.objSelectPackage.id,
      };
      managePermissionAPI
        .postAddDefaultRole(params)
        .then((res) => {
          console.log("postAddDefaultRole : ", res);
          this.handleLoading(false);
        })
        .catch(() => {
          this.handleLoading(false);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      setTimeout(() => {
        this.goToPage("ManageApplication");
      }, 1000);
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      setTimeout(() => {
        this.goToPage("ManageApplication");
      }, 1000);
    },
    handlemodalOk() {
      if (this.idModal == "error") return;
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.handleLoading(true);
        this.objDataSave = value;
        this.postAddData();
      }
    },
    onChange(e, item) {
      if (e.target.checked) {
        this.dataCompare.push(item);
      } else {
        this.dataCompare = this.dataCompare.filter((data) => {
          return data.id !== item.id;
        });
      }
      console.log(this.dataCompare, "this.dataCompare");
    },
    checkId(item) {
      let findobj = this.dataCompare.find((o) => o.id === item);
      if (findobj) {
        return false;
      } else {
        return true;
      }
    },
    onResize() {
      this.checkLayout();
    },
    checkLayout() {
      let boxDetail = document.querySelector("#box-details");
      this.boxDetailWidth = boxDetail.offsetWidth;
      if (this.boxDetailWidth > 1350) this.itemPerPage = 4;
      if (this.boxDetailWidth > 1700) this.itemPerPage = 5;
      if (this.boxDetailWidth > 2080) this.itemPerPage = 6;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
/* For demo */
.selectpackage {
  margin: 0;
  height: calc(100vh - 280px);
  .page-wrapper {
    height: 100%;
    background: $color-white;
    border-radius: $border-radius;
    padding: 4px 8px;
    .VueCarousel {
      height: calc(100% - 10px);
    }
    .VueCarousel-wrapper {
      height: 100% !important;
    }
    .VueCarousel-inner {
      height: 100% !important;
    }
    .VueCarousel-dot,
    .VueCarousel-dot-container {
      margin-top: 0 !important;
    }
    .VueCarousel-dot--active {
      background-color: #ffb133 !important;
    }
  }
  .item {
    flex-grow: 1;
    min-width: 300px;
    height: 100%;
    border: solid 1px #ffb133;
    background-color: #fff;
    border-radius: 10px;
    cursor: grab;
    transform: scale(0.95);
    .item-header {
      height: 90px;
      line-height: 1.5;
      padding: 16px;
      border-bottom: solid 1px #ffb133;
      display: flex;
      justify-content: space-between;
      .header-name {
        color: $black-black-1;
      }
      .header-title {
        color: $green-green-3;
        font-size: 28px;
      }
    }
    .item-body {
      height: calc(100% - 200px);
      padding: 8px 16px;
      font-size: 12px;
      line-height: 2;
      border-bottom: solid 1px #ffb133;
      overflow: auto;
      .body-description {
        color: $grey-grey-4;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 16px;
        opacity: 0.8;
      }
      .body-detail {
        font-size: 14px;
      }
    }
    .item-footer {
      height: 110px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-btn-custom {
        height: 45px;
        border-radius: 6px;
        background-color: #ffa726;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .item:hover {
    transform: scale(1) !important;
    box-shadow: 0 0 10px #0003;
  }
}
</style>
