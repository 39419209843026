<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content custom-radio-swith-accout"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          {{ modalprocess.title }}
        </div>
      </template>
      <div>
        <a-radio-group
          v-model="modalprocess.dataaccountselect"
          :options="modalprocess.dataaccount"
          :disabled="modalprocess.dataaccount.disabled"
          @change="onChange"
        />
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
  },
  data() {
    return {
      disabledok: true,
    };
  },
  watch: {
    modalprocess() {
      this.disabledok = true;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = _.cloneDeep(this.modalprocess.dataaccountselect);
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.objDataSave);
    },
    onChange(e) {
      this.disabledok = true;
      if (e.target.value != this.objDataSave) {
        this.objDataSave = e.target.value;
        this.disabledok = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-radio-swith-accout {
  .ant-radio-wrapper {
    display: block;
    height: 30px;
  }
}
</style>
