<template>
  <div>
    <a-modal
      id="modalprocess"
      v-model="modalprocess.visible"
      :width="650"
      centered
      class="modal-process"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
        </div>
      </template>
      <div>
        <div class="your-package">
          <div class="title-select-package">
            {{ lbls.comparerole_page.modal.select.name }}
          </div>
          <div>
            <a-select
              v-model="selectPackage"
              show-search
              style="width: 100%; color: #999"
              @change="handleChange"
            >
              <a-select-option
                v-for="(item, index) in objDataSave"
                :key="index"
                :value="item.name"
              >
                <span>{{ item.name }}</span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <div class="bottom-modalprocess">
        <a-space size="large">
          <a-button @click="handleClose">
            {{
              modalprocess.cancelText
                ? modalprocess.cancelText
                : lbls.comparerole_page.btn.cancel
            }}
          </a-button>
          <a-button
            type="primary"
            :disabled="!dataSelect.name"
            @click="handleSave"
          >
            {{
              modalprocess.okText
                ? modalprocess.okText
                : lbls.comparerole_page.btn.save
            }}
          </a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Cache from "@/helper/Cache.js";
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Array,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      disabledok: true,
      objDataSave: {},
      is_duplicate: "",
      helpmsg: "",
      maxLengthAppID: 100,
      totalCharAppID: 0,
      maxLengthName: 100,
      totalCharName: 0,
      maxLengthDescription: 4000,
      totalCharDescription: 0,
      selectPackage: "",
      dataSelect: {},
      dataCompare: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.objDataSave = this.dataobjselect;
      this.selectPackage = this.lbls.comparerole_page.modal.select.placeholder;
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      if (!this.dataSelect.name) return;
      this.modalprocess.visible = false;
      this.$emit("actionModal", this.dataSelect);
      // this.selectPackage = this.mylbl("comparepackage_page.select.placeholder");
    },
    handleChange(value) {
      let findobj = this.dataobjselect.find((o) => o.name === value);
      this.dataSelect = findobj;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
