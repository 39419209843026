<template>
  <div class="col-12 d-md-flex justify-content-between align-items-center mb-4">
    <div class="col-lg-6 col-md-6 col-sm-12 text mb-4 mb-md-0 pl-0 pr-0">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.applicationaccount_page.input_search"
        class="input-search"
        size="large"
        @blur="onSearch"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>
    <div
      class="col-lg-6 col-md-6 col-sm-12 button-action-list d-flex justify-content-end box-action-campaignlist pl-0 pr-0"
    >
      <a-button
        v-if="isPermissionCrossTransfer"
        type="primary"
        ghost
        @click="goToPageAndQuery('TransferOwnerAccount', cross_account_id, account_name)"
      >
        <a-icon type="retweet" />
        {{ lbls.menu.transferowneraccount }}
      </a-button>
      <a-button
        class="ml-3"
        type="primary"
        :disabled="!isPermissionAssignApp"
        @click="handleCreate()"
      >
        <a-icon type="plus-circle" />
        {{ lbls.applicationaccount_page.btn.assign }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    datapageheader: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "cross",
      data: {},
      searchData: "",
      isSearchData: false,
      isPermissionAssignApp: true,
      isPermissionCrossTransfer: false,
      cross_account_id: null,
      account_name: null
    };
  },
  watch: {
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapageheader;
      this.cross_account_id = this.data?.cross_account_id ?? null
      this.account_name = this.data?.account_name ?? null
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionAssignApp = this.checkArrowPermission(
            "cross-existing-add",
            permission_page
          );
          this.isPermissionCrossTransfer = this.checkArrowPermission(
            "cross-transfer-owner-adjust",
            permission_page
          );
        }
      }
    },
    handleCreate() {
      this.$emit("handlecreate");
    },
    onSearch() {
      this.isSearchData = this.searchData != "";
      this.$emit("onsearch", this.searchData);
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
