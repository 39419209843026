<template>
  <div id="app">
    <!-- <Loading id="appLoading" :is-show="$store.state.isAppLoad" /> -->
    <Loading
      v-if="currentPage?.path != '/' && currentPage?.name != 'Redirectms'"
      id="appLoading"
      :is-show="$store.state.isAppLoad"
    />
    <router-view />
  </div>
</template>
<script>
import moment from "moment";
/* eslint-disable */
import Loading from "@/components/Loading/Loading";
import { config } from "@/config/bzbsconfig.js";

export default {
  name: "Permission-Management",
  components: {
    Loading,
  },
  data() {
    return {
      currentPage: "",
    };
  },
  mounted() {
    let theme = document.createElement("link");
    theme.type = "text/css";
    theme.rel = "stylesheet";
    theme.href = `${config.themeUrl}/css/${
      config.themeVersion
    }.css?v=${moment().format("x")}`;
    document.head.appendChild(theme);
    this.currentPage = this.$router.currentRoute;
  },
  created() {
    this.currentPage = this.$router.currentRoute;
  },
};
</script>
<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "@/style/main.scss";
</style>
