<template>
  <div class="setrole-header flex-center-between">
    <div v-if="datapageheader.name" class="header-left">
      <div class="lable-name">
        <div class="d-flex">
          <div>
            <img
              v-if="datapageheader.is_active"
              class="icon-width-18-custom"
              :src="IConActive"
            />
            <img v-else class="icon-width-18-custom" :src="IConInctive" />
          </div>
          <div class="ml-2">{{ datapageheader.name }}</div>
        </div>
      </div>
      <div class="lable-desc">
        {{ datapageheader.description }}
      </div>
    </div>
    <div class="header-right">
      <div>
        <span>
          <a-tooltip>
            <template slot="title">
              {{ lbls.role_page.btn.assign_to_role }}
            </template>
            <router-link
              :to="{
                name: 'ManageAssigntoRole',
                query: {
                  id: $route.query.id,
                },
              }"
            >
              <a-button class="btn-custom-edit">
                <img class="icon-width-20" :src="IConAddUser" />
              </a-button>
            </router-link>
          </a-tooltip>
        </span>
        <a-divider type="vertical" />
        <span>
          <a-tooltip>
            <template slot="title">
              {{ lbls.role_page.btn.edit }}
            </template>
            <a-button class="btn-custom-edit" @click="handleEdit()">
              <img class="icon-width-20" :src="IConEdit" />
            </a-button>
          </a-tooltip>
        </span>
        <a-divider type="vertical" />
        <span>
          <a-tooltip>
            <template slot="title">
              {{ lbls.role_page.btn.delete }}
            </template>
            <a-button class="btn-custom-edit" @click="handleDelete()">
              <img class="icon-width-18" :src="IConDelete" />
            </a-button>
          </a-tooltip>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    datapageheader: {
      type: Object,
    },
  },
  data() {
    return {
      data: {},
      searchData: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapageheader;
    },
    handleEdit() {
      this.$emit("handleedit");
    },
    handleDelete() {
      this.$emit("handledelete");
    },
    onSearch() {
      this.$emit("onsearch", this.searchData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
