<template>
  <div>
    <a-modal
      id="modaldetail"
      v-model="conditionmodal.visible"
      centered
      class="modal-with-content modal-with-details"
      :class="conditionmodal.isView ? 'modal-with-content-custom-app' : ''"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <span>{{ conditionmodal.title }}</span>
      </template>
      <div v-if="conditionmodal.isList" class="mb-3">
        <div v-if="datalist && datalist.length > 0" class="item-detail">
          <div class=""></div>
          <div class="right w-100 border-left-0">
            <div
              v-for="(product, index) in datalist"
              :key="product.display_name"
              class="title-product-name mb-1"
            >
              <div>{{ index + 1 }}. {{ product.display_name }}</div>
              <div
                class="name-secondary"
                :class="{ 'name-secondary-ten': index + 1 > 9 }"
              >
                {{ product.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="conditionmodal.isView" class="modal-show-apps-body">
        <ListApplication
          :dataapp="datalist"
          :hasmoredataapp="condition?.hasMoreData"
          @loadmore="onLoadMore($event)"
          @onsearch="onSearch($event)"
        ></ListApplication>
      </div>
      <template slot="footer">
        <a-button size="large" @click="handleClose">
          {{ lbls.package_page.btn.close }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
import ListApplication from "@/module/Package/components/List/Application.vue";

export default {
  name: "ManageProduct",
  components: { ListApplication },
  mixins: [CommonMixin, ErrorApiMixin],
  props: {
    conditionmodal: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
    datalist: {
      type: Array,
    },
    condition: {
      type: Object,
    },
  },
  data() {
    return {
      disabledok: true,
      showFieldPrice: false,
      loading: false,
      showLoadingMore: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    handleClose() {
      this.conditionmodal.visible = false;
      this.$emit("actionModal", "close");
    },
    onLoadMore() {
      this.$emit("callback", "View");
    },
    onSearch($event) {
      this.$emit("onsearchview", $event);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";

.modal-with-content-custom-app {
  .ant-modal-body {
    max-height: inherit !important;
  }
}
</style>
