<template>
  <div>
    <a-modal
      id="modaldetail"
      v-model="conditionmodal.visible"
      centered
      class="modal-with-content modal-with-details modal-with-content-custom-app"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <span>{{ conditionmodal.title }}</span>
      </template>
      <div v-if="datalist && datalist.length > 0" class="item-detail mb-3">
        <div class=""></div>
        <div class="right w-100 border-left-0">
          <div
            v-for="(product, index) in datalist"
            :key="product.id"
            class="title-product-name mb-1"
          >
            <div>{{ index + 1 }}. {{ product.name }}</div>
            <div class="name-secondary">
              {{ product.application_id }}
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button size="large" @click="handleClose">
          {{ lbls.package_page.btn.close }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import CommonMixin from "@/mixin/CommonMixin";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";

export default {
  name: "ManageProduct",
  mixins: [CommonMixin, ErrorApiMixin],
  props: {
    conditionmodal: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
    datalist: {
      type: Array,
    },
    condition: {
      type: Object,
    },
  },
  data() {
    return {
      disabledok: true,
      showFieldPrice: false,
      loading: false,
      showLoadingMore: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    handleClose() {
      this.conditionmodal.visible = false;
      this.$emit("actionModalClose");
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";

.modal-with-content-custom-app {
  .ant-modal-body {
    max-height: inherit !important;
  }
}
</style>
