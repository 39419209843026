<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :is-action-flex="true"
        :databreadcrumbs="breadcrumbs"
        :title="dataPageBreadcrumb.name"
      >
        <template v-if="!isShowSkeleton" #actions>
          <div class="action-list">
            <a-tooltip placement="topLeft">
              <template slot="title">
                {{ lbls.role_page.btn.assign_applications_to_role }}
              </template>
              <router-link
                :to="{
                  name: 'ManageAssignApplicationstoRole',
                  query: {
                    id: $route.query.id,
                  },
                }"
              >
                <a-icon type="plus-square" />
              </router-link>
            </a-tooltip>
            <a-tooltip placement="topLeft">
              <template slot="title">
                {{ lbls.role_page.btn.manage }}
              </template>
              <router-link
                :to="{
                  name: 'ManageRoleSetPerMission',
                  query: {
                    id: $route.query.id,
                  },
                }"
              >
                <a-icon type="setting" />
              </router-link>
            </a-tooltip>
          </div>
        </template>
      </Breadcrumbs>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-show="!isShowSkeleton" class="manage-role-assigns">
        <a-row class="flex-center">
          <a-col :sm="24" :md="11" :lg="11">
            <TableList
              :datatable="dataAvailableUser"
              :dataselect="idUserForAdd"
              :databtn="'add'"
              :record="record"
              @onsearch="onSearchAdd($event)"
              @checkboxall="onChangesCheckboxAddAll($event)"
              @setdataforsave="setDataForSave($event)"
              @callsaveuser="callAddUserInRole($event)"
            />
          </a-col>
          <a-col :sm="4" :md="2" :lg="2" class="custom-btn">
            <div class="text-center">
              <div>
                <a-tooltip>
                  <template slot="title">
                    {{ lbls.role_page.btn.add_user_select }}
                  </template>
                  <a-button
                    :disabled="idUserForAdd.length == 0"
                    type="primary"
                    class="vertical-initial"
                    @click="callAddUserInRole()"
                  >
                    <a-icon type="right" />
                  </a-button>
                </a-tooltip>
              </div>
              <div class="mt-2">
                <a-tooltip>
                  <template slot="title">
                    {{ lbls.role_page.btn.delete_user_select }}
                  </template>
                  <a-button
                    :disabled="idUserForDelete.length == 0"
                    type="primary"
                    class="vertical-initial"
                    :class="{
                      'btn-custom-delete': idUserForDelete.length > 0,
                    }"
                    @click="callDeleteUserInRole()"
                  >
                    <a-icon type="left" />
                  </a-button>
                </a-tooltip>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="11" :lg="11">
            <TableList
              :datatable="dataUserList"
              :dataselect="idUserForDelete"
              :databtn="'delete'"
              :record="record"
              @onsearch="onSearchDelete($event)"
              @checkboxall="onChangesCheckboxDeleteAll($event)"
              @setdataforsave="setDataForSave($event)"
              @callsaveuser="callDeleteUserInRole($event)"
            />
          </a-col>
        </a-row>
      </div>
      <!-- Modal -->
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRole from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import TableList from "@/module/Role/ManageAssigntoRole/components/Table/TableListCustom";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    TableList,
    ModalAlert,
  },
  data() {
    return {
      permission_key: "role",
      action_key: "assign",
      breadcrumbs: [],
      isShowSkeleton: true,
      isModalResponse: false,
      idModal: "",
      titleModal: "",
      contentModal: "",
      searchDataAdd: "",
      searchDataDelete: "",
      record: {},
      dataAvailableUser: [],
      dataUserList: [],
      idUserForAdd: [],
      idUserForDelete: [],
      alertData: {},
      modalAlert: {},
      dataPageBreadcrumb: {},
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.callGetAvailableUser();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assigntorole,
        name: "InviteUsersDetail",
      });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.record = res.data.result;
              this.dataPageBreadcrumb = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetAvailableUser() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.record.id,
      };
      manageRole
        .getAvailabletUser(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_selected = false;
              item.dataAvailableUser = true;
              if (this.idUserForAdd.length > 0) {
                _.forEach(this.idUserForAdd, (id_add) => {
                  if (item.id == id_add) {
                    item.is_selected = true;
                  }
                });
              }
            });
            this.dataAvailableUser = res.data.result.items;
            this.dataAvailableUserStart = _.cloneDeep(res.data.result.items);
            if (this.searchDataAdd) {
              this.onSearchFunction(this.searchDataAdd, "dataAvailableUser");
            }
            this.callGetUserList();
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetUserList() {
      let params = {
        account_id: this.exAccount.account.id,
      };
      if (this.record) {
        if (this.record.id) {
          params.role_id = this.record.id;
        }
      }
      manageRole
        .getListUser(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_selected = false;
              item.dataUserList = true;
              _.forEach(this.idUserForDelete, (id_delete) => {
                if (item.id == id_delete) {
                  item.is_selected = true;
                }
              });
            });
            this.dataUserList = res.data.result.items;
            this.dataUserListStart = _.cloneDeep(res.data.result.items);
            if (this.searchDataDelete) {
              this.onSearchFunction(this.searchDataDelete, "dataUserList");
            }
            this.handleLoading(false);
            this.isShowSkeleton = false;
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callAddUserInRole(user_ids) {
      this.handleLoading(true);
      let idUserForAdd = [];
      if (user_ids) {
        idUserForAdd.push(user_ids);
        this.idUserForAdd = this.idUserForAdd.filter((data) => {
          return data != idUserForAdd;
        });
      } else if (this.idUserForAdd.length > 0) {
        idUserForAdd = this.idUserForAdd;
        this.idUserForAdd = [];
      }
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.record.id,
        user_ids: idUserForAdd,
      };
      manageRole
        .postAddUserInRole(params)
        .then((res) => {
          if (res.data) {
            if (res.data.is_success) {
              this.titleModal = this.lbls.user_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_create_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callDeleteUserInRole(user_ids) {
      this.handleLoading(true);
      let idUserForDelete = [];
      if (user_ids) {
        idUserForDelete.push(user_ids);
        this.idUserForDelete = this.idUserForDelete.filter((data) => {
          return data != idUserForDelete;
        });
      } else if (this.idUserForDelete.length > 0) {
        idUserForDelete = this.idUserForDelete;
        this.idUserForDelete = [];
      }
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.record.id,
        user_ids: idUserForDelete,
      };
      manageRole
        .postDeleteUserInRole(params)
        .then((res) => {
          if (res.data) {
            if (res.data.is_success) {
              this.titleModal = this.lbls.user_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.callGetAvailableUser();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.callGetAvailableUser();
    },
    onChangesCheckboxAddAll(checked) {
      this.idUserForAdd = [];
      _.forEach(this.dataAvailableUser, (item) => {
        item.is_selected = checked;
      });
      this.setUserForAdd();
    },
    onChangesCheckboxDeleteAll(checked) {
      this.idUserForDelete = [];
      _.forEach(this.dataUserList, (item) => {
        item.is_selected = checked;
      });
      this.setUserForDelete();
    },
    setDataForSave(item) {
      if (item.dataUserList) {
        this.setUserForDelete();
      }
      if (item.dataAvailableUser) {
        this.setUserForAdd();
      }
    },
    setUserForAdd() {
      _.forEach(this.dataAvailableUser, (item) => {
        if (item.is_selected) {
          this.idUserForAdd.push(item.id);
        } else {
          this.idUserForAdd = this.idUserForAdd.filter((data) => {
            return data != item.id;
          });
        }
      });
      this.idUserForAdd = [...new Set(this.idUserForAdd)];
    },
    setUserForDelete() {
      _.forEach(this.dataUserList, (item) => {
        if (item.is_selected) {
          this.idUserForDelete.push(item.id);
        } else {
          this.idUserForDelete = this.idUserForDelete.filter((data) => {
            return data != item.id;
          });
        }
      });
      this.idUserForDelete = [...new Set(this.idUserForDelete)];
    },
    onSearchAdd(e) {
      this.searchDataAdd = e;
      if (this.oldText != this.searchDataAdd) {
        this.oldTextAdd = this.searchDataAdd;
        this.onSearchFunction(this.searchDataAdd, "dataAvailableUser");
      }
    },
    onSearchDelete(e) {
      this.searchDataDelete = e;
      if (this.oldText != this.searchDataDelete) {
        this.oldTextDelete = this.searchDataDelete;
        this.onSearchFunction(this.searchDataDelete, "dataUserList");
      }
    },
    onSearchFunction(text, key) {
      if (this.oldText != this.searchDataAdd) {
        let keystart = key + "Start";
        this.oldText = this.searchData;
        this[key] = this[keystart].filter((item) => {
          return item.email
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        });
        this[key].sort((a, b) => b.is_selected - a.is_selected);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.manage-role-assigns {
  .ant-btn {
    min-width: inherit;
  }
}
</style>
