<template>
  <a-modal
    v-model="isShow"
    width="600px"
    class="modal-announcement"
    :title="data.title"
    :closable="false"
    :centered="true"
    :mask-closable="false"
    @cancel="closeModal('Emergency')"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="data.content"></div>
    <template slot="footer">
      <a-checkbox
        v-if="
          data.is_unread != null
            ? data.is_required_accept && data.is_unread
            : data.is_required_accept
        "
        @change="onChange"
      >
        {{ lbls.announcement.checkbox }}
      </a-checkbox>
      <span v-else :class="{ 'blank-checkbox': !data.is_required_accept }"
        >&nbsp;</span
      >
      <a-button key="submit" type="primary" @click="closeModal('Emergency')">
        {{ lbls.btn.close }}
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import ResponsiveMixin from "@/mixin/ResponsiveMixin";

export default {
  name: "Announcement",
  components: {},
  mixins: [ResponsiveMixin],
  props: {
    isShow: Boolean,
    data: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onChange(e) {
      this.$emit("checkBox", e.target.checked);
    },
    closeModal(type) {
      this.$emit("closeModalAnnouncement", type);
    },
  },
};
</script>
