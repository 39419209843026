import Moment from 'moment';

/********* Property *********
* @property {Number} currentTimestamp
* @property {String} displayTime
* @property {Number} expireIn
* @property {Number} expireTimestamp
* @property {Boolean} interval
* @property {Boolean} isExpired
* @property {Boolean} isRunning
* @property {Boolean} isShowDay
* @property {String} seperator
****************************/

/********** Method ***********
* @function initialTimer
* @function initialTimerWithExpireTimestamp
* @function resetTime
* @function runTimer
* @function stopTimer
* @function timerExpired
* @function timeFormatter
****************************/

export default function Timer() {
    this.currentTimestamp = '';
    this.displayTime = '';
    this.expireIn = 60;
    this.expireTimestamp = '';
    this.interval = null;
    this.isExpired = false;
    this.isRunning = false;
    this.isShowDay = false;
    this.isShowHour = false;
    this.seperator = '';

    this.initialTimer = (expireIn, seperator, isShowHour, isShowDay) => {
        this.expireIn = expireIn || 60;
        this.seperator = seperator || ':';
        this.isShowHour = isShowHour || false;
        this.isShowDay = isShowDay || false;
    };
    this.initialTimerWithExpireTimestamp = (expireTimestamp, seperator, isShowHour, isShowDay) => {
        this.expireIn = expireTimestamp;
        this.seperator = seperator || ':';
        this.isShowHour = isShowHour || false;
        this.isShowDay = isShowDay || false;
    };
    this.resetTimer = () => {
        this.currentTimestamp = '';
        this.expireTimestamp = '';
        this.displayTime = '';
        this.expireTimestamp = '';
        this.isExpired = false;
        this.isRunning = false;
    };
    this.runTimer = (expiredCallback) => {
        this.currentTimestamp = Math.floor(new Date() / 1000);
        this.expireTimestamp = this.expireTimestamp || this.currentTimestamp + this.expireIn;
        this.isRunning = true;

        this.timeFormatter();
        this.interval = setInterval(() => {
            this.timeFormatter(expiredCallback);
        }, 1000);
    };
    this.stopTimer = () => {
        this.isRunning = false;
        clearInterval(this.interval);
    };
    this.timerExpired = () => {
        this.isExpired = true;
        this.stopTimer();
    };
    this.timeFormatter = (expiredCallback) => {
        var timestampDiff = Moment.unix(this.expireTimestamp) - Moment.unix(this.currentTimestamp);

        if(timestampDiff <= 0) {
            timestampDiff = 0;
            this.timerExpired();

            if(expiredCallback)
                expiredCallback();
        }
        //Add 1 Second
        this.currentTimestamp++;

        //Calculate day.
        var dayDiff = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
        var haveDay = parseInt(dayDiff) <= 0 ? false : true;
        if(this.isShowDay)
            timestampDiff -= dayDiff * 1000 * 60 * 60 * 24;

        if (dayDiff <= 9)
            dayDiff = "0" + dayDiff;

        //Calculate hour.
        var hourDiff = Math.floor(timestampDiff / 1000 / 60 / 60);
        var haveHour = parseInt(hourDiff) <= 0 ? false : true;
        if (this.isShowHour)
            timestampDiff -= hourDiff * 1000 * 60 * 60;

        if (hourDiff <= 9)
            hourDiff = "0" + hourDiff;

        //Calculate minute.
        var minuteDiff = Math.floor(timestampDiff / 1000 / 60);
        var haveMinute = parseInt(minuteDiff) <= 0 ? false : true;
        timestampDiff -= minuteDiff * 1000 * 60;

        if (minuteDiff <= 9)
            minuteDiff = "0" + minuteDiff;

        //Calculate second.
        var secondDiff = "" + Math.floor(timestampDiff / 1000);
        var haveSecond = parseInt(secondDiff) <= 0 ? false : true;
        if (secondDiff <= 9)
            secondDiff = "0" + secondDiff;

        var timeLeft = '';
        if (this.isShowDay) {
            if (haveDay)
                timeLeft += dayDiff + this.seperator;
            else
                timeLeft += '00' + this.seperator;
        }

        if (this.isShowHour) {
            if (haveHour)
                timeLeft += hourDiff + this.seperator;
            else
                timeLeft += '00' + this.seperator;
        }

        //Always show minute.
        if (haveMinute)
            timeLeft += minuteDiff + this.seperator;
        else
            timeLeft += '00' + this.seperator;

        //Always show second.
        if (haveSecond)
            timeLeft += secondDiff;
        else
            timeLeft += '00';

        this.displayTime = timeLeft;
    };
}
