import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getRoleList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return (
      this.config().apiBaseUrl + this.config().apiVersion + "/cross_account"
    );
  },
  pathV2: function () {
    return (
      this.config().apiBaseUrl + this.config().apiVersion2 + "/cross_account"
    );
  },
  getSearch: async function (params) {
    var strUrl = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getSearchApplication: async function (params) {
    var strUrl = this.path() + "/search_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getSearchBzbsApplication: async function (params) {
    var strUrl = this.path() + "/search_buzzebees_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getSearchAccountPackage: async function (params) {
    var strUrl = this.path() + "/get_account_packages";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  postSaveBzbsApplication: async function (params) {
    var strUrl = this.path() + "/assign_buzzebees_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  deleteBzbsApplication: async function (params) {
    var strUrl = this.path() + "/delete_buzzebees_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsDeleteBody(strUrl, this.token(), params);
  },
  postTransferOwnerAccount: async function (params) {
    var strUrl = this.pathV2() + "/transfer_owner_account";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  getBuzzebeesApplication: async function (params) {
    var strUrl = this.pathV2() + "/get_buzzebees_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
};
