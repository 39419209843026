<template>
  <a-tabs
    v-if="data"
    :default-active-key="clickTabs"
    force-render
    @change="callback"
  >
    <a-tab-pane key="1">
      <span slot="tab">
        <a-badge :count="data.length" show-zero class="count-list">
          {{ lbls.announcement.all_post }}
        </a-badge>
      </span>
      <ContentAnnouncement
        :data="data"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
    <a-tab-pane key="2" :disabled="sumUnRead > 0 ? false : true">
      <span slot="tab">
        <a-badge :count="sumUnRead" show-zero>
          {{ lbls.announcement.unread }}
        </a-badge>
      </span>
      <ContentAnnouncement
        :data="data"
        :sum-un-read="sumUnRead"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
    <a-tab-pane key="3" :disabled="sumRead > 0 ? false : true">
      <span slot="tab">
        <a-badge :count="sumRead" show-zero class="count-list">
          {{ lbls.announcement.read }}
        </a-badge>
      </span>
      <ContentAnnouncement
        :data="data"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import ResponsiveMixin from "@/mixin/ResponsiveMixin";
import ContentAnnouncement from "@/components/Layout/Header/ContentAnnouncement.vue";

export default {
  name: "Announcement",
  components: { ContentAnnouncement },
  mixins: [ResponsiveMixin],
  props: {
    isShow: Boolean,
    data: Array,
    sumRead: Number,
    sumUnRead: Number,
  },
  data() {
    return {
      clickTabs: "1",
    };
  },
  created() {},
  methods: {
    callback(key) {
      this.clickTabs = key;
    },
    getAllAnnouncement(event) {
      this.$emit("getAllAnnouncement", event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
