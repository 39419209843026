import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getProductList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion + "/account";
  },
  getCheckDuplicateName: function (objparams) {
    var strUrl = this.path() + "/check_duplicate_account_name";
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getCheckAccount: async function () {
    var params = {};
    var strUrl = this.path() + "/check_new_account";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getCurrentAccount: function () {
    var params = {};
    var strUrl = this.path() + "/get_current_working_account";
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getManagedAccount: function () {
    var params = {};
    var strUrl = this.path() + "/get_managed_accounts";
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  postAccepInvitation: function (params) {
    var strUrl =
      this.config().apiBaseUrl +
      this.config().apiVersion +
      "/user/accept_invitation";
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  postSwithcAccount: function (params) {
    var strUrl = this.path() + "/switch_working_account";
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  postCreateAccount: function (data) {
    var strUrl = this.path() + "/create";
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postCurrentAccount: async function () {
    var strUrl =
      this.config().apiBaseUrl +
      this.config().apiVersion2 +
      "/account/check_working_account";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token());
  },
};
