<template>
  <div class="campaign-list-table box-icon-a-disabled">
    <div class="title-table-remark">
      {{ title_table_description }}
    </div>
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 330, x: 860 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.user_page.btn.ok,
        filterReset: lbls.user_page.btn.reset,
      }"
      :custom-row="customRowTable"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-email" slot-scope="text, record">
        <div class="custom-status-text">
          <div>{{ text }}</div>
          <div v-if="record.identity_provider" class="text-secondary-custom">
            {{ lbls.user_page.tbl_identity_provider
            }}{{ record.identity_provider }}
          </div>
          <div
            v-if="record.is_owner_account"
            class="owner-account"
            style="font-size: 12px"
          >
            <span>
              <a-icon
                class="icon"
                style="vertical-align: 1.5px !important"
                type="check-circle"
              />
            </span>
            <span>{{ lbls.user_page.tbl_owner_user }}</span>
          </div>
        </div>
      </template>
      <template slot="template-status" slot-scope="text">
        <a-tag v-if="text" :color="'green'" class="tag-status">
          <a-badge
            :status="'success'"
            :text="lbls.user_page.title_status_active"
          />
        </a-tag>
        <a-tag v-else :color="'red'" class="tag-status">
          <a-badge
            :status="'error'"
            :text="lbls.user_page.title_status_inactive"
          />
        </a-tag>
      </template>
      <template slot="template-name" slot-scope="text, record">
        <div class="custom-status-text">
          <div>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>
                  {{ lbls.user_page.tbl_colum_display_name }}
                </span>
              </template>
              {{ record.display_name }}
            </a-tooltip>
          </div>
          <div class="text-secondary-custom">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>
                  {{ lbls.user_page.tbl_colum_fullname }}
                </span>
              </template>
              {{ record.full_name }}
            </a-tooltip>
          </div>
        </div>
      </template>
      <template slot="template-display-name" slot-scope="text, record">
        <div>{{ record.display_name }}</div>
      </template>
      <template slot="template-fullname" slot-scope="text, record">
        <div>{{ record.full_name }}</div>
      </template>
      <template slot="template-role" slot-scope="text, record">
        <span v-if="text.length > 0" class="features-custom">
          <div v-for="(tag, index) in text" :key="tag.id">
            <div v-if="index <= checkShowMore(record)">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <span v-if="record.roles.length > 1"> {{ index + 1 }}. </span>
                  <span>{{ tag.name }}</span>
                  <!-- <a-tooltip placement="left">
                    <template slot="title">
                      {{ lbls.user_page.btn.edit_role }} : {{ tag.name }}
                    </template>
                    <div
                      class="custom-goto-page"
                      @click="goTo('ManageAssignApplicationstoRole', tag)"
                    >
                      <span v-if="record.roles.length > 1">
                        {{ index + 1 }}.
                      </span>
                      <span>{{ tag.name }}</span>
                    </div>
                  </a-tooltip> -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="text.length > 3"
            class="show-hide-custom mt-2"
            @click="loadMore(record)"
          >
            <span>
              {{
                record.isShowMore
                  ? lbls.role_page.btn.hide
                  : lbls.role_page.btn.more
              }}
            </span>
            <span v-if="!record.isShowMore"> ({{ text.length }})</span>
            <span class="ml-2">
              <!-- <img
                :class="{ 'img-rotate': record.isShowMore }"
                style="height: 4px"
                :src="IConArrowDownPrimary"
              /> -->
              <a-icon v-if="record.isShowMore" type="caret-up" />
              <a-icon v-else type="caret-down" />
            </span>
          </div>
        </span>
        <span v-else> - </span>
      </template>
      <template slot="template-edit" slot-scope="text, record">
        <div class="action-list">
          <a-tooltip :disabled="!isPermissionSetting" placement="top">
            <template slot="title">
              {{ lbls.user_page.btn.edit_role }}
            </template>
            <a @click="handleSetRole(record)">
              <a-icon type="setting" />
            </a>
          </a-tooltip>
          <a-tooltip :disabled="!isPermissionEdit" placement="top">
            <template slot="title">
              {{ lbls.user_page.btn.edit }}
            </template>
            <a @click="handleEdit(record)">
              <a-icon type="edit" />
            </a>
          </a-tooltip>
          <span>
            <a-tooltip
              :disabled="!isPermissionDelete || record.is_owner_account"
              placement="topLeft"
            >
              <template slot="title">
                {{ lbls.user_page.btn.delete }}
              </template>
              <a @click="handleDelete(record)">
                <a-icon type="delete" />
              </a>
            </a-tooltip>
          </span>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "user",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      roleFilters: [],
      isFilters: false,
      title_table_description: "",
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "135px",
          keys: "tbl_colum_status",
          dataIndex: "is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.is_active === value;
          },
        },
        {
          id: "Name",
          title: "Name",
          keys: "tbl_colum_name",
          dataIndex: "display_name",
          width: "300px",
          scopedSlots: { customRender: "template-name" },
        },
        {
          id: "Email",
          title: "Email",
          keys: "tbl_colum_email",
          dataIndex: "email",
          width: "350px",
          sorter: (a, b) => a.email.localeCompare(b.email),
          scopedSlots: { customRender: "template-email" },
        },
        {
          id: "Role",
          title: "Role",
          keys: "tbl_colum_role",
          dataIndex: "roles",
          width: "350px",
          scopedSlots: { customRender: "template-role" },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return record.roles.length == 0;
            } else {
              let findobj = record.roles.find((o) => o.id === value);
              if (findobj) {
                record.idfind = findobj.id;
              }
              return record.idfind === value;
            }
          },
        },
        {
          title: "",
          width: "100px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionSetting: true,
      isPermissionEdit: true,
      isPermissionDelete: true,
    };
  },
  watch: {
    datatable() {
      this.setDataFilters();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
      this.title_table_description = this.lbls.user_page.title_table_description
        ? this.lbls.user_page.title_table_description
        : "*Double-click the table row to assign roles.";
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.title_table_description = this.lbls.user_page.title_table_description
      ? this.lbls.user_page.title_table_description
      : "*Double-click the table row to assign roles.";
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.setDataFilters();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionSetting = this.checkArrowPermission(
            "user-setting",
            permission_page
          );
          this.isPermissionEdit = this.checkArrowPermission(
            "user-edit",
            permission_page
          );
          this.isPermissionDelete = this.checkArrowPermission(
            "user-delete",
            permission_page
          );
        }
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.user_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.user_page[filters.text];
          });
        }
      });
    },
    setDataFilters() {
      this.roleFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.datatable, (element) => {
        if (element.roles) {
          _.forEach(element.roles, (item) => {
            this.roleFilters.push({
              text: item.name,
              value: item.id,
            });
          });
        }
      });
      this.roleFilters = [
        ...new Map(this.roleFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumns = this.columns.find((o) => o.id == "Role");
      if (findcolumns) {
        findcolumns.filters = this.roleFilters;
      }
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    goTo(page, tag) {
      if (tag) {
        let routeData = this.$router.resolve({
          name: page,
          query: { id: tag.id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(page);
      }
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    handleSetRole(record) {
      this.$emit("handlesetrole", record);
    },
    handleDelete(record) {
      this.$emit("handledelete", record);
    },
    handleDeleteRole(record, tag) {
      let data = {
        record: record,
        tag: tag,
      };
      this.$emit("handledeleterole", data);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onChangeStatus(checkedValues) {
      this.valueStatus = "";
      if (checkedValues.length == 1) {
        this.valueStatus = checkedValues[0];
        this.data = this.data.filter((x) => x.is_active == this.valueStatus);
      } else {
        this.data = this.dataStart;
      }
    },
    loadMore(record) {
      record.isShowMore = record.isShowMore == true;
      record.isShowMore = !(record.isShowMore != false);
      this.$forceUpdate();
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["is_active"] ? filters["is_active"] : [];
      let isFiltersRoles = filters["roles"] ? filters["roles"] : [];
      isFiltersStatus = isFiltersStatus.length > 0;
      isFiltersRoles = isFiltersRoles.length > 0;
      isFilters = isFiltersStatus || isFiltersRoles;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    checkShowMore(record) {
      let lengthData = 2;
      if (record.isShowMore) {
        lengthData = record.roles.length;
      }
      return lengthData;
    },
    customRowTable(record, index) {
      return {
        attrs: {
          id: index,
        },
        on: {
          dblclick: () => {
            this.handleSetRole(record);
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.btn-role {
  width: 50px;
  .ant-divider,
  .ant-divider-vertical {
    top: 0px !important;
    width: 1px !important;
    height: 10px !important;
    margin: 0 3px !important;
  }
}
.features-custom {
  .show-hide-custom {
    cursor: pointer;
    font-size: 12px;
    color: $color-primary !important;
    font-weight: 600;
  }
  .img-rotate {
    transform: rotate(180deg);
  }
}
</style>
