<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new flex-center-between">
        <span>
          <PageBreadcrumb :datapagebreadcrumb="dataPageBreadcrumb" />
        </span>
        <span class="set-test-beta">
          <router-link
            :to="{
              name: 'ManageRoleSetRoles',
              query: {
                id: $route.query.id,
              },
            }"
          >
            Origianl Version
          </router-link>
        </span>
      </div>
      <div class="manage-setrole manage-page-detail">
        <PageHeader
          :datapageheader="dataPageHeader"
          @handleedit="handleEdit"
          @handledelete="handleDelete"
        />
        <div class="setrole-body">
          <div v-if="dataPackages.length > 0" class="body-package">
            <div class="package-label">
              <span>Package :</span>
            </div>
            <div class="package-item">
              <span>
                <a-tabs
                  :default-active-key="dataPackages[0].package.id"
                  tab-position="top"
                  @prevClick="callback"
                  @nextClick="callback"
                  @change="callbacks"
                >
                  <a-tab-pane
                    v-for="item in dataPackages"
                    :key="item.package.id"
                    :tab="item.package.name"
                  >
                  </a-tab-pane>
                </a-tabs>
              </span>
            </div>
          </div>
          <div class="body-products">
            <div v-for="item in dataPackages" :key="item.package.id">
              <div v-if="tabActive == item.package.id">
                <div class="text-right">
                  <span v-if="item.collapse">
                    <a-button
                      class="btn-custom-reset btn-detail-user"
                      size="large"
                      :disabled="tabItemActive != 1"
                      @click="setCollapseAll(item)"
                    >
                      <span><a-icon type="arrows-alt" /></span>
                      <span class="ml-2">Expand All</span>
                    </a-button>
                  </span>
                  <span v-else>
                    <a-button
                      class="btn-custom-reset btn-detail-user"
                      size="large"
                      :disabled="tabItemActive != 1"
                      @click="setCollapseAll(item)"
                    >
                      <span><a-icon type="shrink" /></span>
                      <span class="ml-2">Collapse All</span>
                    </a-button>
                  </span>
                </div>
                <div class="product-list">
                  <div class="product-label-title">
                    <a-tabs type="card" @change="changetab">
                      <a-tab-pane v-if="item.products" key="1">
                        <template slot="tab">
                          <div>
                            <span>
                              {{ lbls.role_page.title_product }}
                            </span>
                            <span> ({{ item.products.length }})</span>
                          </div>
                        </template>
                        <div class="box-item">
                          <div
                            v-for="product in item.products"
                            :key="product.id"
                            class="product-item"
                          >
                            <div class="product-name flex-center-between">
                              <div
                                class="name-box-left"
                                @click="toggles(product)"
                              >
                                <span class="mr-2">
                                  <img
                                    class="arrow-show-data custom-size-10"
                                    :class="{
                                      'arrow-hide-data': product.isShow,
                                    }"
                                    :src="IConArrowDown"
                                  />
                                </span>
                                <span>{{ product.name }}</span>
                                <span class="lable-length">
                                  {{ checkFeature(product.features) }}/{{
                                    product.features.length
                                  }}</span
                                >
                              </div>
                              <div class="flex-center-between name-box-right">
                                <div class="lable-selece-all mt-1 mr-2">
                                  <span>Select All</span>
                                </div>
                                <div>
                                  <a-switch
                                    :checked="
                                      checkFeatureSelect(product.features)
                                    "
                                    @change="
                                      changeSwitchSelectAll($event, product)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="product.isShow"
                              class="product-body mt-3"
                            >
                              <div
                                v-if="product.data_levels"
                                class="label-title mt-3 mb-1"
                              >
                                {{ lbls.role_page.title_data_level }}
                              </div>
                              <div
                                v-if="product.data_levels"
                                class="features-list"
                              >
                                <a-radio-group
                                  v-model="product.idDataLv"
                                  @change="onChangeRadio($event, product)"
                                >
                                  <a-radio value="none"> None </a-radio>
                                  <a-radio
                                    v-for="lv in product.data_levels"
                                    :key="lv.id"
                                    :value="lv.id"
                                  >
                                    {{ lv.name }}
                                  </a-radio>
                                </a-radio-group>
                              </div>
                              <div class="label-title mt-3 mb-1">
                                {{ lbls.role_page.title_features }}
                              </div>
                              <div class="features-list">
                                <div
                                  v-for="feature in product.features"
                                  :key="feature.id"
                                  class="feature-item flex-center-between"
                                >
                                  <span>{{ feature.name }}</span>
                                  <span>
                                    <a-switch
                                      :checked="feature.is_selected"
                                      size="small"
                                      @change="
                                        changeSwitch($event, feature, product)
                                      "
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="product.isShow"
                              class="product-body mt-3"
                            >
                              <div class="mt-3 text-right custome-icon">
                                <span>
                                  <a-button
                                    :disabled="
                                      product.dataforcheck ==
                                      product.dataforcheckStrat
                                    "
                                    type="primary"
                                    class="btn-custom-save"
                                    @click="
                                      handleSave(product, item.subscription.id)
                                    "
                                  >
                                    {{ lbls.role_page.btn.save }}
                                  </a-button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-tab-pane>
                      <!-- <a-tab-pane v-if="item.applications" key="2">
                        <template slot="tab">
                          <div>
                            <span>
                              {{ mylbl("role_page.title_application") }}
                            </span>
                            <span> ({{ item.applications.length }})</span>
                          </div>
                        </template>
                        <div class="product-item">
                          <div class="product-name flex-center-between">
                            <div
                              class="name-box-left"
                              @click="toggles(item.applications)"
                            >
                              <span class="mr-2">
                                <img
                                  class="arrow-show-data custom-size-10"
                                  :class="{
                                    'arrow-hide-data': item.applications.isShow,
                                  }"
                                  :src="IConArrowDown"
                                />
                              </span>
                              <span>{{
                                mylbl("role_page.title_application")
                              }}</span>
                              <span class="lable-length">
                                {{ checkFeature(item.applications) }}/{{
                                  item.applications.length
                                }}</span
                              >
                            </div>
                            <div class="flex-center-between name-box-right">
                              <div class="lable-selece-all mt-1 mr-2">
                                <span>Select All</span>
                              </div>
                              <div>
                                <a-switch
                                  :checked="
                                    checkFeatureSelect(item.applications)
                                  "
                                  @change="
                                    changeSwitchSelectAll(
                                      $event,
                                      item.applications
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="item.applications.isShow"
                            class="product-body mt-3"
                          >
                            <div class="features-list">
                              <div
                                v-for="application in item.applications"
                                :key="application.id"
                                class="feature-item flex-center-between"
                              >
                                <span>{{ application.name }}</span>
                                <span>
                                  <a-switch
                                    :checked="application.is_selected"
                                    size="small"
                                    @change="
                                      changeSwitch(
                                        $event,
                                        application,
                                        item.applications
                                      )
                                    "
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="item.applications.isShow"
                            class="product-body mt-3"
                          >
                            <div class="mt-3 text-right custome-icon">
                              <span>
                                <a-button
                                  :disabled="
                                    item.applications.dataforcheck ==
                                    item.applications.dataforcheckStrat
                                  "
                                  type="primary"
                                  class="btn-custom-save"
                                  @click="
                                    handleSaveApp(
                                      item.applications,
                                      item.subscription.id
                                    )
                                  "
                                >
                                  {{ mylbl("role_page.btn.save") }}
                                </a-button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </a-tab-pane>
                      <a-tab-pane v-if="item.cross_applications" key="3">
                        <template slot="tab">
                          <div>
                            <span>
                              {{ mylbl("role_page.title_cross") }}
                            </span>
                            <span> ({{ item.cross_applications.length }})</span>
                          </div>
                        </template>
                        <div class="product-item">
                          <div class="product-name flex-center-between">
                            <div
                              class="name-box-left"
                              @click="toggles(item.cross_applications)"
                            >
                              <span class="mr-2">
                                <img
                                  class="arrow-show-data custom-size-10"
                                  :class="{
                                    'arrow-hide-data':
                                      item.cross_applications.isShow,
                                  }"
                                  :src="IConArrowDown"
                                />
                              </span>
                              <span>{{ mylbl("role_page.title_cross") }}</span>
                              <span class="lable-length">
                                {{ checkFeature(item.cross_applications) }}/{{
                                  item.cross_applications.length
                                }}</span
                              >
                            </div>
                            <div class="flex-center-between name-box-right">
                              <div class="lable-selece-all mt-1 mr-2">
                                <span>Select All</span>
                              </div>
                              <div>
                                <a-switch
                                  :checked="
                                    checkFeatureSelect(item.cross_applications)
                                  "
                                  @change="
                                    changeSwitchSelectAll(
                                      $event,
                                      item.cross_applications
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="item.cross_applications.isShow"
                            class="product-body mt-3"
                          >
                            <div class="features-list">
                              <div
                                v-for="crossapp in item.cross_applications"
                                :key="crossapp.id"
                                class="feature-item flex-center-between"
                              >
                                <span>{{ crossapp.name }}</span>
                                <span>
                                  <a-switch
                                    :checked="crossapp.is_selected"
                                    size="small"
                                    @change="
                                      changeSwitch(
                                        $event,
                                        crossapp,
                                        item.cross_applications
                                      )
                                    "
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="item.cross_applications.isShow"
                            class="product-body mt-3"
                          >
                            <div class="mt-3 text-right custome-icon">
                              <span>
                                <a-button
                                  :disabled="
                                    item.cross_applications.dataforcheck ==
                                    item.cross_applications.dataforcheckStrat
                                  "
                                  type="primary"
                                  class="btn-custom-save"
                                  @click="
                                    handleSaveCrossApp(
                                      item.cross_applications,
                                      item.subscription.id
                                    )
                                  "
                                >
                                  {{ mylbl("role_page.btn.save") }}
                                </a-button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </a-tab-pane> -->
                    </a-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import PageHeader from "@/module/Role/ManageSetRole/components/Layout/PageHeader.vue";
// import BodyProduct from "@/module/Role/ManageSetRole/components/Layout/BodyProduct.vue";
// import BodyApp from "@/module/Role/ManageSetRole/components/Layout/BodyApp.vue";
import PageBreadcrumb from "@/module/Role/ManageSetRole/components/Layout/PageBreadcrumb.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    PageHeader,
    // BodyApp,
    // BodyProduct,
    PageBreadcrumb,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      isShowSkeleton: true,
      data: [],
      dataRole: {},
      dataPackages: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      tabActive: "",
      tabItemActive: "1",
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      dataPageHeader: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.role_page.modal.btn.delete;
      this.titleModal = this.lbls.role_page.modal.title_confirm;
      this.contentModal =
        this.lbls.role_page.modal.content_delete + this.objSelect.name + " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataRole = res.data.result.role ? res.data.result.role : {};
            if (res.data.result.items) {
              let items = res.data.result.items;
              let dataPackages = [];
              _.forEach(items, (element) => {
                if (
                  element.products ||
                  element.applications ||
                  element.cross_applications
                ) {
                  dataPackages.push(element);
                  if (element.products) {
                    _.forEach(element.products, (product) => {
                      product.isShow = true;
                      product.isEdit = false;
                      product.dataforcheckStrat = this.setValueCheck(product);
                      product.dataforcheck = _.cloneDeep(
                        product.dataforcheckStrat
                      );
                    });
                  }
                  if (element.applications) {
                    element.applications.isShow = true;
                    element.applications.isEdit = false;
                    element.applications.dataforcheckStrat = this.setValueCheck(
                      element.applications
                    );
                    element.applications.dataforcheck = _.cloneDeep(
                      element.applications.dataforcheckStrat
                    );
                  }
                  if (element.cross_applications) {
                    element.cross_applications.isShow = true;
                    element.cross_applications.isEdit = false;
                  }
                }
                element.collapse = false;
              });
              this.dataPackages = dataPackages;
              console.log(this.dataPackages, "this.dataPackages");
              if (dataPackages && this.tabActive == "") {
                this.tabActive = this.dataPackages[0].package.id;
              }
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.openNotificationWithIcon("success");
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    handleSave(product, sub_id) {
      this.handleLoading(true);
      let itemselect = [];
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: sub_id,
      };
      if (product.features) {
        _.forEach(product.features, (feature) => {
          if (feature.is_selected) {
            itemselect.push(feature.id);
          }
        });
        record.product_id = product.id;
        record.feature_ids = itemselect;
      }
      if (product.data_levels && product.idDataLv != "none") {
        record.data_level_id = product.idDataLv;
      }
      record = JSON.stringify(record);
      Promise.all([this.callPostPermissionProduct(record)]).then(
        (resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess == "success") {
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.openNotificationWithIcon("success");
            product.dataforcheckStrat = this.setValueCheck(product);
            product.dataforcheck = _.cloneDeep(product.dataforcheckStrat);
          }
          this.handleLoading(false);
        }
      );
    },
    handleSaveProduct(product) {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: this.subScription,
      };
      record.product_id = product.id;
      record.feature_ids = product.itemSelect;
      if (product.data_levels && product.valueRadio) {
        record.data_level_id = product.valueRadio;
      }
      record = JSON.stringify(record);
      this.callPostPermissionProduct(record);
    },
    handleSaveApp(items, sub_id) {
      this.handleLoading(true);
      let itemselect = [];
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: sub_id,
      };
      _.forEach(items, (item) => {
        if (item.is_selected) {
          itemselect.push(item.id);
        }
      });
      record.application_ids = itemselect;
      record = JSON.stringify(record);
      Promise.all([this.callPostPermissionApplication(record)]).then(
        (resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess == "success") {
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.openNotificationWithIcon("success");
            items.dataforcheckStrat = this.setValueCheck(items);
            items.dataforcheck = _.cloneDeep(items.dataforcheckStrat);
          }
          this.handleLoading(false);
          this.$forceUpdate();
        }
      );
    },
    handleSaveCrossApp(items, sub_id) {
      this.handleLoading(true);
      let itemselect = [];
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: sub_id,
      };
      _.forEach(items, (item) => {
        if (item.is_selected) {
          itemselect.push(item.id);
        }
      });
      record.cross_application_ids = itemselect;
      record = JSON.stringify(record);
      Promise.all([this.callPostPermissionCross(record)]).then(
        (resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess == "success") {
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.openNotificationWithIcon("success");
            items.dataforcheckStrat = this.setValueCheck(items);
            items.dataforcheck = _.cloneDeep(items.dataforcheckStrat);
          }
          this.handleLoading(false);
          this.$forceUpdate();
        }
      );
    },
    callPostPermissionProduct(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionProduct(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    callPostPermissionApplication(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionApplication(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    callPostPermissionCross(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionCross(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    resetFieldOther() {
      this.valueDataLv = "";
      this.valueDataLvRadio = "";
      this.isShowListDatavl = false;
      this.listOfFeature = [];
      this.valuecheckbox = [];
      this.listOfDatalv = [];
      this.valuecheckboxApp = [];
      this.listOfFeatureApp = [];
      this.valuecheckboxCross = [];
      this.listOfFeatureCross = [];
      this.data.forEach((element) => {
        element.isEditShowCheckboxApp = false;
        element.isEditShowCheckboxCross = false;
        if (element.products) {
          if (element.products.length > 0) {
            element.products.forEach((products) => {
              products.isEditShowCheckbox = false;
              products.isShowListDatavl = false;
            });
          }
        }
      });
      let temp = this.data;
      this.data = [];
      this.data = temp;
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = true;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (this.isEdit) {
        this.handleLoading(false);
      }
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
      }
    },
    changetab(val) {
      this.tabItemActive = val;
      console.log(this.tabItemActive);
    },
    callback(val) {
      console.log(val);
    },
    callbacks(val) {
      this.tabActive = val;
    },
    handleBack(item) {
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    handleBackrecord(event, item, valuename) {
      event.stopPropagation();
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      if (valuename == "app") {
        findpackage.applications = this.dataStrar;
        findpackage.applications.isShow = true;
      }
      if (valuename == "cross_app") {
        findpackage.cross_applications = this.dataStrar;
        findpackage.cross_applications.isShow = true;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    onChangesCheckbox(e, item) {
      let checked = e.target.checked;
      item.is_selected = checked;
      if (checked) {
        this.featureSelect.push(item.id);
      } else {
        this.featureSelect = this.featureSelect.filter((data) => {
          return data != item.id;
        });
      }
      this.$forceUpdate();
    },
    handleClickManage(value, item) {
      if (!value.isShow) value.isShow = true;
      this.dataStrar = _.cloneDeep(value);
      this.subScription = item.subscription.id;
      this.featureSelect = [];
      _.forEach(this.dataPackages, (element) => {
        if (element.products) {
          _.forEach(element.products, (product) => {
            product.isEdit = false;
          });
        }
        if (element.applications) {
          element.applications.isEdit = false;
        }
        if (element.cross_applications) {
          element.cross_applications.isEdit = false;
        }
      });
      value.isEdit = !value.isEdit;
      this.$forceUpdate();
    },
    onChangeRadio(e, datas) {
      _.forEach(datas.data_levels, (datalevel) => {
        datalevel.is_selected = false;
        if (e.target.value == datalevel.id) {
          datalevel.is_selected = true;
        }
      });
      datas.idDataLv = e.target.value;
      datas.dataforcheck = this.setValueCheck(datas);
      console.log(datas, "datas");
      this.$forceUpdate();
    },
    checkFeature(features) {
      let itemselect = [];
      _.forEach(features, (feature) => {
        if (feature.is_selected) {
          itemselect.push(feature);
        }
      });
      return itemselect.length;
    },
    checkFeatureSelect(features) {
      let itemselect = [];
      _.forEach(features, (feature) => {
        if (feature.is_selected) {
          itemselect.push(feature);
        }
      });
      return itemselect.length == features.length;
    },
    changeSwitch(checked, value, item) {
      console.log(value, "value");
      value.is_selected = checked;
      value.dataforcheck = this.setValueCheck(item);
      this.$forceUpdate();
    },
    setValueCheck(value) {
      let dataforcheck = [];
      if (value.data_levels) {
        let findobj = value.data_levels.find((o) => o.is_selected === true);
        if (findobj) {
          value.idDataLv = findobj.id;
        } else {
          value.idDataLv = "none";
        }
        dataforcheck.push(value.idDataLv);
      }
      let items = value.features ? value.features : value;
      _.forEach(items, (item) => {
        if (item.is_selected) {
          dataforcheck.push(item.id);
        }
      });
      value.dataforcheck = JSON.stringify(dataforcheck);
      return JSON.stringify(dataforcheck);
    },
    changeSwitchSelectAll(checked, value) {
      console.log(value, "value");
      let items = value.features ? value.features : value;
      let dataforcheck = [];
      if (value.data_levels) {
        let findobj = value.data_levels.find((o) => o.is_selected === true);
        if (findobj) {
          value.idDataLv = findobj.id;
        } else {
          value.idDataLv = "none";
        }
        dataforcheck.push(value.idDataLv);
      }
      _.forEach(items, (item) => {
        item.is_selected = checked;
        if (item.is_selected) {
          dataforcheck.push(item.id);
        }
      });
      value.dataforcheck = JSON.stringify(dataforcheck);
      if (!value.isShow) {
        value.isShow = !value.isShow;
      }
      this.$forceUpdate();
    },
    toggles(value) {
      if (value.isEdit) return;
      value.isShow = !value.isShow;
      this.$forceUpdate();
    },
    setCollapseAll(value) {
      value.collapse = !value.collapse;
      _.forEach(value.products, (item) => {
        item.isShow = !value.collapse;
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
#body_detail {
  border-top: 1px solid $grey-grey-7;
}
.manage-setrole {
  margin-top: 0px;
  height: calc(100vh - 130px);
  overflow: hidden;
  .setrole-header {
    background: #fff;
    .header-left {
      width: calc(100% - 120px);
      padding: 8px;
      line-height: 1.5;
      .lable-name {
        font-weight: 600;
        font-size: 18px;
      }
      .lable-desc {
        color: $grey-grey-5;
      }
    }
    .header-right {
      text-align: right;
      width: 120px;
      padding-right: 20px;
    }
  }
  .setrole-body {
    .body-package {
      display: flex;
      font-size: 14px;
      padding: 16px 0;
      padding-right: 0;
      background: #fafafa;
      .package-label {
        width: 95px;
        border-bottom: 1px solid #e8e8e8;
        line-height: 3;
        font-weight: 600;
        padding-left: 16px;
      }
      .package-item {
        width: calc(100% - 95px);
      }
    }
    .body-products {
      background: #fafafa;
      padding: 16px;
      padding-top: 0;
      line-height: 1.5;
      .product-label-title {
        font-weight: 600;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 12px;
        font-size: 16px;
      }
      .label-title {
        font-size: 12px;
        color: #b5b5c3;
      }
      .lable-length {
        color: $color-primary;
      }
      .lable-selece-all {
        font-size: 12px;
        color: #b5b5c3;
        font-weight: 500;
      }
      .box-item {
        height: calc(100vh - 365px);
        overflow: auto;
      }
      .product-list {
        .ant-btn-lg {
          min-width: inherit;
        }
        .anticon svg {
          margin-top: -6px;
        }
      }
      .product-item {
        background: #fff;
        padding: 16px;
        border: solid 1px #d9d9d9;
        margin-top: 16px;
        display: grid;
        .product-name {
          font-size: 15px;
          font-weight: 600;
          cursor: pointer;
          .name-box-left {
            width: calc(100% - 110px);
          }
          .name-box-right {
            width: 110px;
          }
        }
        .features-list {
          background: #f8f8f8;
          padding: 8px;
        }
        .feature-item {
          border: solid 1px #f8f8f8;
          padding: 8px;
          width: 50%;
          float: left;
          background: #fff;
        }
        .product-body {
          display: grid;
          border-top: 1px solid #d9d9d9;
        }
      }
    }
  }
  .ant-tabs-bar {
    margin: 0;
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: $color-primary;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .arrow-show-data {
    width: 12px;
    transform: rotate(0);
    margin-top: -3px;
  }
  .arrow-hide-data {
    transform: rotate(180deg);
    opacity: 0.25;
  }
  .custom-size-10 {
    width: 10px !important;
  }
  .btn-custom-cancel,
  .btn-custom-cancel:focus,
  .btn-custom-cancel:hover {
    padding: 2px 8px;
    width: 55px;
    border: solid 1px $color-primary !important;
    height: inherit;
    color: $color-primary !important;
    font-size: 12px;
  }
  .feature-list {
    border-top: 1px solid #d9d9d9;
    margin: 12px 16px;
    margin-top: 0;
    padding-top: 12px;
    .feature-item {
      display: inline-table;
      @include flexcol(12%);
      @include breakpoint-max($desktop-xxxl) {
        @include flexcol(20%);
      }
      @include breakpoint-max($desktop-xxl) {
        @include flexcol(25%);
      }
      @include breakpoint-max($desktop-xs) {
        @include flexcol(33.3%);
      }
      @include breakpoint-max($tablet-pro) {
        @include flexcol(50%);
      }
      @include breakpoint-max($tablet-xs) {
        @include flexcol(100%);
      }
    }
  }
  .checkbox-all {
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
  .label-title {
    font-size: 12px;
    line-height: 1.5;
    color: $grey-grey-6;
  }
}
</style>
