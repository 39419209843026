<template>
  <!-- <div class="box-loader" :class="{ loaded: !isShow }">
    <lottie-vue-player
      :src="Config.themeUrl + '/json/loading.json'"
      :background-color="'transparent'"
      :loop="true"
      :autoplay="true"
      class="loaderjson"
    />
  </div> -->
  <div class="box-loader" :class="{ loaded: !isShow }">
    <div class="wrapper-loaading">
      <span class="loader"></span>
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    isShow: Boolean,
    text: String,
  },
};
</script>
