<template>
  <div>
    <a-modal
      v-model="modalcondition.visible"
      centered
      class="modal-with-content-custom"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalcondition.title }}</span>
          <span v-if="modalcondition.isCopiesPerMission"> : </span>
          <span v-if="modalcondition.isCopiesPerMission" class="name-content">
            <span>{{ dataselectforcopy.application.name }}</span>
            <span class="text-secondary-custom">
              ({{ dataselectforcopy.package.display_name }})
            </span>
          </span>
        </div>
      </template>
      <div class="modal-show-apps-body">
        <div
          v-if="!isonSearch && dataAll.length == 0 && dataSelect.length == 0"
          class="custom-show-nodata"
        >
          <a-empty :image="simpleImage" />
        </div>
        <a-row v-else>
          <a-col :span="12" class="pr-3">
            <label class="mb-3">All</label>
            <div class="box-loader" :class="{ loaded: !showLoading }">
              <lottie-vue-player
                :src="Config.themeUrl + '/json/loading.json'"
                :background-color="'transparent'"
                :loop="true"
                :autoplay="true"
                class="loaderjson"
              />
            </div>
            <ListItems
              id="0"
              :datatype="modalcondition.type"
              :dataapp="dataAll"
              :hasmoredataapp="hasMoredata"
              @callgetlist="callGetList"
              @onsearch="onSearch($event)"
              @clickadd="clickAdd($event)"
              @clickaddall="clickAddAll($event)"
            >
            </ListItems>
          </a-col>
          <a-col :span="12" class="pl-3">
            <label class="mb-3">
              {{ lbls.role_page.modal_add_app.title_selected }}
              <span v-if="modalcondition.type == 0" class="label-add">
                {{ lbls.role_page.modal_add_app.title_selected_add }}
              </span>
              <span v-if="modalcondition.type == 1" class="label-add">
                {{ lbls.role_page.modal_add_app.title_selected_copy }}
              </span>
              <span v-if="modalcondition.type == 2" class="label-delete">
                {{ lbls.role_page.modal_add_app.title_selected_delete }}
              </span>
            </label>
            <ListItems
              v-if="dataSelect.length > 0"
              id="1"
              :datatype="modalcondition.type"
              :dataapp="dataSelect"
              :hasmoredataapp="hasMoredata"
              @onsearch="onSearch($event)"
              @clickremove="clickRemove($event)"
              @clickremoveall="clickRemoveAll($event)"
            >
            </ListItems>
            <div v-else class="no-item-app"></div>
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalcondition.cancelText
              ? modalcondition.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button
          :type="modalcondition.type == 2 ? 'danger' : 'primary'"
          :disabled="checkDisablebtn()"
          @click="handleSave"
        >
          {{
            modalcondition.okText
              ? modalcondition.okText
              : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Empty } from "ant-design-vue";
import { config } from "@/config/bzbsconfig.js";
import ListItems from "@/module/Role/ManageAssignRole/components/Layout/ListItemModal.vue";
export default {
  name: "ManageProduct",
  components: {
    ListItems,
  },
  props: {
    modalcondition: {
      type: Object,
    },
    dataapplications: {
      type: Array,
    },
    dataselectforcopy: {
      type: Object,
    },
    isshowskeletonmodal: {
      type: Boolean,
    },
    hasmoredata: {
      type: Boolean,
    },
    showloading: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasMoredata: true,
      showLoading: false,
      isonSearch: false,
      nameSearch: "",
      searchData: "",
      dataAll: [],
      dataSelect: [],
      Config: config,
    };
  },
  watch: {
    dataapplications() {
      this.dataAll = _.cloneDeep(this.dataapplications);
      this.hasMoredata = _.cloneDeep(this.hasmoredata);
      this.setDataForShow();
    },
    hasmoredata() {
      this.hasMoredata = _.cloneDeep(this.hasmoredata);
    },
    showloading() {
      this.showLoading = _.cloneDeep(this.showloading);
    },
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataAll = _.cloneDeep(this.dataapplications);
      this.hasMoredata = _.cloneDeep(this.hasmoredata);
      this.showLoading = _.cloneDeep(this.showloading);
      this.setDataForShow();
    },
    handleClose() {
      this.modalcondition.visible = false;
      this.$emit("actionModal", "close");
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onSearch(e) {
      this.isonSearch = true;
      this.$emit("onsearch", e);
    },
    handleSave() {
      this.$emit("actionModal", this.dataSelect);
    },
    checkDisablebtn() {
      let disable = true;
      if (this.dataSelect.length > 0) {
        disable = false;
      }
      return disable;
    },
    setDataForShow() {
      _.forEach(this.dataAll, (item, i) => {
        item.seq = i + 1;
      });
      if (this.dataSelect.length > 0) {
        this.setDataSelect();
      } else {
        this.showLoading = false;
      }
    },
    setDataSelect() {
      _.forEach(this.dataSelect, (item) => {
        this.dataAll = this.dataAll.filter((data) => {
          if (data.id == item.id) {
            item.seq = data.seq;
          }
          return data.id != item.id;
        });
      });
      this.showLoading = false;
    },
    clickAdd(item) {
      this.dataSelect.push(item);
      this.dataAll = this.dataAll.filter((data) => {
        return data.id != item.id;
      });
      this.dataSelect.sort((a, b) => a.seq - b.seq);
      this.dataAll.sort((a, b) => a.seq - b.seq);
      this.$forceUpdate();
    },
    clickRemove(item) {
      this.dataAll.push(item);
      this.dataSelect = this.dataSelect.filter((data) => {
        return data.id != item.id;
      });
      this.dataSelect.sort((a, b) => a.seq - b.seq);
      this.dataAll.sort((a, b) => a.seq - b.seq);
      this.$forceUpdate();
    },
    clickAddAll() {
      _.forEach(this.dataAll, (item) => {
        this.clickAdd(item);
      });
    },
    clickRemoveAll() {
      _.forEach(this.dataSelect, (item) => {
        this.clickRemove(item);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.no-item-app {
  background-color: #f0f2f5;
  height: calc(100vh - 286px);
}
.box-loader {
  position: absolute !important;
}
.label-add {
  color: var(--primary-gold-bzb-primary-bzb);
}
.label-delete {
  color: var(--red-red-6);
}
.custom-show-nodata {
  height: calc(100vh - 450px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
