import Vue from "vue";
import BzbsApi from "@/core/Http/BzbsApi.js";
import _ from "lodash";
import { config } from "@/config/bzbsconfig.js";

// import { th } from "@/helper/locale/th_1054";
// import { en } from "@/helper/locale/en_1033";

/**
 * @function getLocale
 * @function getLocaleCode
 * @function getLbl
 * @function getLocaleShort
 * @function setLocale
 */
export default {
  KEY_COOKIE_LOCALE: "BzbsLocale",
  defaultLocale: {
    code: 1033,
    short: "en",
  },
  config: function () {
    return config;
  },
  getLocale: function () {
    var locale = Vue.$cookies.get(this.KEY_COOKIE_LOCALE);
    if (locale == null) {
      locale = this.defaultLocale;
    }
    return locale;
  },
  getLocaleCode: function () {
    var localeObj = this.getLocale();
    return localeObj;
  },
  async callBlobVersion() {
    var date = new Date();
    var urlVersion =
      this.config().extBlobUrl +
      "/product-platform-external/language/version.json?v=" +
      date.getTime();
    const response = await BzbsApi.connectBzbsGetBlob(urlVersion);
    this.localVersion = response.data;
  },
  getLbl() {
    var version = this.localVersion;
    _.forEach(version, (item, keycode) => {
      var date = new Date();
      var urlLocale =
        this.config().extBlobUrl +
        "/product-platform-external/language/" +
        keycode +
        "/" +
        item.current_version +
        ".json?v=" +
        date.getTime();
      BzbsApi.connectBzbsGetBlob(urlLocale).then((response) => {
        localStorage.setItem(
          "setJsonLocal_" + keycode,
          JSON.stringify(response.data)
        );
        return response;
      });
    });
  },
  // getLbl: function () {
  //   if (this.getLocaleCode() == 'en') {
  //     return en
  //   } else {
  //     return th
  //   }
  // },
  getLocaleShort: function () {
    var localeObj = this.getLocale();
    return localeObj.short;
  },
  setLocale: function (localeCode) {
    var localeObj = {
      code: 1033,
      short: "en",
    };
    if (localeCode == 1033) {
      localeObj = {
        code: 1033,
        short: "en",
      };
    }
    let strLocale = JSON.parse(JSON.stringify(localeObj));
    Vue.$cookies.set(this.KEY_COOKIE_LOCALE, strLocale);
  },
};
