<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new flex-center-between">
        <PageBreadcrumb :datapagebreadcrumb="dataPageBreadcrumb" />
      </div>
      <div class="manage-page-detail compare-package">
        <div class="page-wrapper">
          <div class="item-compare-frist-colum">
            <div class="item-header">
              <div class="item-right">Role</div>
            </div>
            <div class="item-body">
              <div
                class="tabs"
                :class="{ tabactive: tabActive == 'feature' }"
                @click="menuClick('feature')"
              >
                Features
              </div>
              <div
                class="tabs"
                :class="{ tabactive: tabActive == 'product' }"
                @click="menuClick('product')"
              >
                Product
              </div>
              <div
                class="tabs"
                :class="{ tabactive: tabActive == 'link' }"
                @click="menuClick('link')"
              >
                Link Package
              </div>
            </div>
            <div class="item-footer">
              <div v-if="tabActive == 'feature'">
                <div class="item">Partition key</div>
                <div class="item">Row key</div>
                <div class="item">App ID</div>
                <div class="item">Type</div>
                <div class="item">Name</div>
                <div class="item">Status</div>
                <div class="item">User level</div>
              </div>
              <div v-if="tabActive == 'product'">
                <div class="item">User-Level</div>
                <div class="item">User-Level-Manage-Level</div>
                <div class="item">User-List</div>
                <div class="item">User-List-Account</div>
                <div class="item">
                  User-List-Account-Blacklist-Unblacklist-User
                </div>
                <div class="item">User-List-Account-Block-Unblock-User</div>
                <div class="item">User-List-Account-Reset-Password</div>
              </div>
              <div v-if="tabActive == 'link'">
                <div class="parent">Parent</div>
                <div class="item">
                  <span>Custom-Package-For-Sunvending</span>
                  <span class="parent">(Parent)</span>
                </div>
                <div class="item">
                  <span>Custom-Package-For-M150</span>
                  <span class="parent">(Parent)</span>
                </div>
                <div class="item">
                  <span>Custom-Package-For-Taro</span>
                  <span class="parent">(Parent)</span>
                </div>
                <div class="child">Child</div>
                <div class="item">
                  <span>Custom-Package-For-Nestlepro</span>
                  <span class="child">(Child)</span>
                </div>
                <div class="item">
                  <span>Custom-Package-For-Lifree</span>
                  <span class="child">(Child)</span>
                </div>
                <div class="item">
                  <span>Custom-Package-For-Chanel</span>
                  <span class="child">(Child)</span>
                </div>
                <div class="item">
                  <span>Custom-Package-For-PTTOR</span>
                  <span class="child">(Child)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="items-compare">
            <div v-if="datas[0]" class="item frist-colum">
              <div class="item-header">
                <div class="icon" @click="onChange(datas[0])">
                  <a-icon type="close-circle" />
                </div>
                <div class="title">{{ datas[0].name }}</div>
                <div class="description">{{ datas[0].description }}</div>
              </div>
              <div class="item-body">
                <div class="list">
                  <div v-if="tabActive == 'link'" class="parent text-white">
                    Parent
                  </div>
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <div v-if="tabActive == 'link'" class="child text-white">
                    child
                  </div>
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
              </div>
            </div>
            <div
              v-if="!datas[0]"
              class="item last-colum ondata frist-colum-nodata"
            >
              <div class="item-header">
                <a-button
                  class="btn-detail-user"
                  size="large"
                  @click="handleAddPackage()"
                >
                  <span class="icon-custom-add">
                    <img class="icon-width-18" :src="IConAddPrimary" />
                  </span>
                  Add
                </a-button>
              </div>
              <div class="item-body"></div>
            </div>
            <div v-if="datas[1]" class="item">
              <div class="item-header">
                <div class="icon" @click="onChange(datas[1])">
                  <a-icon type="close-circle" />
                </div>
                <div class="title">{{ datas[1].name }}</div>
                <div class="description">{{ datas[1].description }}</div>
              </div>
              <div class="item-body">
                <div class="list">
                  <div v-if="tabActive == 'link'" class="parent text-white">
                    Parent
                  </div>
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <div v-if="tabActive == 'link'" class="child text-white">
                    child
                  </div>
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConInctive" />
                </div>
              </div>
            </div>
            <div v-if="!datas[1]" class="item last-colum ondata">
              <div class="item-header">
                <a-button
                  class="btn-detail-user"
                  size="large"
                  @click="handleAddPackage()"
                >
                  <span class="icon-custom-add">
                    <img class="icon-width-18" :src="IConAddPrimary" />
                  </span>
                  Add
                </a-button>
              </div>
              <div class="item-body"></div>
            </div>
            <div v-if="datas[2]" class="item last-colum">
              <div class="item-header">
                <div class="icon" @click="onChange(datas[2])">
                  <a-icon type="close-circle" />
                </div>
                <div class="title">{{ datas[2].name }}</div>
                <div class="description">{{ datas[2].description }}</div>
              </div>
              <div class="item-body">
                <div class="list">
                  <div v-if="tabActive == 'link'" class="parent text-white">
                    Parent
                  </div>
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <div v-if="tabActive == 'link'" class="child text-white">
                    child
                  </div>
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConActive" />
                </div>
                <div class="list">
                  <img class="icon-width-16" :src="IConActive" />
                </div>
              </div>
            </div>
            <div v-if="!datas[2]" class="item last-colum ondata">
              <div class="item-header">
                <a-button
                  class="btn-detail-user"
                  size="large"
                  @click="handleAddPackage()"
                >
                  <span class="icon-custom-add">
                    <img class="icon-width-18" :src="IConAddPrimary" />
                  </span>
                  Add
                </a-button>
              </div>
              <div class="item-body"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import PageBreadcrumb from "@/module/Role/CompareRole/components/Layout/PageBreadcrumb.vue";
import ModalProcess from "@/module/Role/CompareRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ComparePackage",
  components: {
    AppLayout,
    PageBreadcrumb,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      isShowSkeleton: true,
      datas: [],
      objSelectPackage: [],
      titleModalProcess: "",
      isModalProcess: false,
      isModalResponse: false,
      dataPageBreadcrumb: {},
      modalAlert: {},
      alertData: {},
      dataObjSelect: [],
      modalprocess: {},
      dataPageHeader: {},
      dataCompare: [],
      tabActive: "feature",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.callGetList();
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch) {
        this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.callGetList();
      } else {
        return;
      }
    },
    callGetList() {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
      };
      manageRoleAPI
        .getSearch(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                this.data = res.data.result.items;
                this.isShowSkeleton = false;
              }
            }
          }
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handleAddPackage() {
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.comparerole_page.modal.tittle;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
      };
      if (this.dataObjSelect.length == 0) {
        this.dataObjSelect = this.data;
      }
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    actionModal(value) {
      if (value == "close") {
        setTimeout(() => {
          this.isModalProcess = false;
          return;
        }, 500);
      } else {
        this.datas.push(value);
        this.datas.forEach((element) => {
          this.dataObjSelect = this.dataObjSelect.filter((data) => {
            return data.id !== element.id;
          });
        });
        this.isModalProcess = false;
      }
    },
    onChange(item) {
      this.datas = this.datas.filter((data) => {
        return data.id !== item.id;
      });
      this.dataObjSelect = this.data;
      this.datas.forEach((element) => {
        this.dataObjSelect = this.dataObjSelect.filter((data) => {
          return data.id !== element.id;
        });
      });
    },
    menuClick(e) {
      this.tabActive = e;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.compare-package {
  $padding-left-right-16: 0 16px;
  $padding-left-right-8: 0 8px;
  $padding-all-custom-16: 16px;
  $padding-all-custom-8: 8px;
  $color-custom-33: #ffb133;
  $color-custom-3e: #e3e3e3;
  height: calc(100vh - 116px);
  margin: 0;
  padding: 0 16px 16px;
  .page-wrapper {
    background: $color-white;
    border-radius: $border-radius;
    height: 100%;
    width: 100%;
    display: flex;
  }
  .item-compare-frist-colum {
    height: 100%;
    width: 370px;
    border-radius: $border-radius;
    .item-header {
      color: #bdbdbd;
      font-size: 16px;
      font-weight: 700;
      height: 50px;
    }
    .item-right {
      text-align: right;
      padding: $padding-all-custom-8 $padding-all-custom-16;
    }
    .item-left {
      text-align: left;
    }
    .item-body {
      margin-top: 16px;
      display: flex;
      .tabs {
        width: calc(100% / 3);
        font-size: 14px;
        padding: $padding-left-right-8;
        color: #b1b1b1;
        cursor: pointer;
        border-bottom: solid 1px $color-custom-3e;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tabactive {
        opacity: 1;
        border: solid 1px $color-custom-3e;
        border-bottom: none;
        color: $color-custom-33;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }
    }
    .item-footer {
      height: calc(100% - 106px);
      overflow: auto;
      border: solid 1px $color-custom-3e;
      border-top: none;
      border-right: none;
      border-bottom-left-radius: $border-radius;
      .item {
        padding: $padding-all-custom-8;
        border-bottom: solid 1px $color-custom-3e;
      }
    }
  }
  .child {
    margin-top: 25px;
  }
  .parent,
  .child {
    font-size: 12px;
    color: $grey-grey-4;
    opacity: 0.8;
    padding: $padding-all-custom-8;
    padding-bottom: 0;
  }
  .items-compare {
    display: flex;
    width: calc(100% - 370px);
    .ondata {
      border: none !important;
      .item-header {
        height: 105px;
        border: dotted 2px $color-custom-3e !important;
        border-left: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-detail-user {
          margin-right: 0 !important;
        }
        .ant-btn-lg {
          min-width: 0 !important;
        }
      }
      .item-body {
        height: calc(100% - 105px);
        border: dotted 2px $color-custom-3e !important;
        border-left: none !important;
        border-top: none !important;
      }
    }
    .item {
      width: calc(100% / 3);
      border: solid 1px $color-custom-3e;
      border-left: none;
      .item-header {
        height: 105px;
        border-bottom: solid 1px $color-custom-3e;
        padding: $padding-all-custom-8;
        .icon {
          width: 100%;
          text-align: right;
          color: $color-custom-33;
          margin-top: -8px;
          cursor: pointer;
        }
        .title {
          margin-top: -18px;
          font-size: 16px;
          font-weight: 700;
          display: flex;
          align-items: center;
          width: 95%;
          line-height: 1.2;
        }
        .description {
          font-size: 12px;
          line-height: 1.2;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .item-body {
        .list {
          text-align: center;
          padding: $padding-all-custom-8;
          border-bottom: solid 1px $color-custom-3e;
        }
      }
    }
    .frist-colum-nodata {
      border-left: dotted 2px $color-custom-3e !important;
    }
    .frist-colum {
      border-left: solid 1px $color-custom-3e;
    }
    .last-colum {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
.modal-process {
  .your-package {
    margin-bottom: 24px;
  }
  .title-select-package {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}
</style>
