<template>
  <div>
    <a-modal
      v-model="modalalert.visible"
      centered
      class="alert-modal"
      :width="750"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <span>
          <a-icon
            v-if="modalalert.id == 'confirm'"
            style="color: #faad14"
            type="question-circle"
          />
          <a-icon
            v-if="modalalert.id == 'error'"
            style="color: #f5222d"
            type="exclamation-circle"
          />
        </span>
        <span>
          {{ modalalert.title }}
        </span>
      </template>
      <template slot="footer">
        <a-button v-if="modalalert.id == 'confirm'" @click="handleCancel">
          {{
            modalalert.cancelText
              ? modalalert.cancelText
              : lbls.user_page.btn.close
          }}
        </a-button>
        <a-button
          :type="modalalert.isDelete ? 'danger' : 'primary'"
          @click="handleOk"
        >
          {{ modalalert.okText ? modalalert.okText : lbls.user_page.btn.close }}
        </a-button>
      </template>
      <div>
        <div v-if="modalalert.isDelete">{{ modalalert.message }}</div>
        <div v-if="!modalalert.isDelete">
          <div v-if="alertdata.error">
            <div v-if="alertdata.error.message">
              {{ alertdata.error.message }}
            </div>
            <div
              v-if="alertdata.transaction_id"
              class="mt-4 text-error-transaction"
            >
              Transaction ID : {{ alertdata.transaction_id }}
            </div>
          </div>
          <div v-else>
            <span>{{ alertdata.status }} - {{ alertdata.description }}</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ManageProduct",
  props: {
    modalalert: {
      type: Object,
    },
    alertdata: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      console.log("test");
    },
    handleOk() {
      this.handleCancel();
      this.$emit("handlemodalok");
    },
    handleCancel() {
      this.modalalert.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.btn-custom-width {
  min-width: 150px;
}
</style>
