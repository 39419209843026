<template>
  <div>
    <AppLayout>
      <div :class="`${$route.name}`" class="permission-page page">
        <Breadcrumbs
          :is-action-flex="true"
          :databreadcrumbs="breadcrumbs"
          :title="dataPageHeader ? dataPageHeader.name : null"
        >
          <template v-if="!isShowSkeleton" #actions>
            <div class="action-list">
              <div v-if="dataApplicationNew" class="box-app">
                <div class="label-name">
                  {{ dataApplicationNew.name }}
                  <span v-if="dataApplicationNew.package" class="label-package">
                    Package: {{ dataApplicationNew.package.name }}
                  </span>
                </div>
                <div class="logo-custom">
                  <img
                    :src="dataApplicationNew.logo_url"
                    @error="handleError"
                  />
                </div>
              </div>
              <a-tooltip
                v-if="dataPageHeader.is_default_role"
                :title="lbls.role_page.btn.reset_role"
                placement="bottomRight"
              >
                <a @click="handleResetRole()">
                  <a-icon type="sync" />
                </a>
              </a-tooltip>
              <a-tooltip
                :title="lbls.role_page.btn.assign_to_role"
                placement="topLeft"
              >
                <a
                  @click="
                    goToPageAndQuery('ManageAssigntoRole', $route.query.id)
                  "
                >
                  <a-icon type="usergroup-add" />
                </a>
              </a-tooltip>
              <a-tooltip
                :title="lbls.role_page.btn.assign_applications_to_role"
                placement="topLeft"
              >
                <router-link
                  :to="{
                    name: 'ManageAssignApplicationstoRole',
                    query: { id: $route.query.id },
                  }"
                >
                  <a-icon type="plus-square" />
                </router-link>
              </a-tooltip>
              <a-tooltip
                v-if="$route.name != 'ManageRoleSetPerMission'"
                :title="lbls.role_page.btn.manage"
                placement="topLeft"
              >
                <router-link
                  :to="{
                    name: 'ManageRoleSetPerMission',
                    query: { id: $route.query.id },
                  }"
                >
                  <a-icon type="setting" />
                </router-link>
              </a-tooltip>
              <a-tooltip
                placement="topLeft"
                :title="lbls.role_page.btn.delete"
                :disabled="dataPageHeader.is_default_role"
              >
                <a
                  :disabled="dataPageHeader.is_default_role"
                  @click="handleDeleteRole()"
                >
                  <a-icon type="delete" />
                </a>
              </a-tooltip>
              <a-tooltip
                :title="lbls.role_page.setrole.title_change_status"
                placement="topLeft"
              >
                <a-switch
                  :loading="switchLoading"
                  :checked="dataPageHeader.is_active"
                  :disabled="
                    dataPageHeader.is_default_role &&
                    dataPageHeader.name == configAdminName
                  "
                  size="small"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </div>
          </template>
        </Breadcrumbs>
        <a-card v-if="isShowSkeleton">
          <a-skeleton />
        </a-card>
        <div
          v-if="!isShowSkeleton"
          class="manage-page-detail set-permission-app"
        >
          <div v-if="dataProductsNew?.length > 0" class="set-permission-body">
            <div class="box-left">
              <b>{{ lbls.role_page.title_product }}</b>
              <div class="box-top">
                <a
                  v-for="item in dataProductsNew"
                  :key="item.id"
                  class="item-button-href flex-center"
                  :class="{ active: item.name == productActive }"
                  @click="scrollToProduct(item.name)"
                >
                  {{ item.display_name }}
                  <span v-if="item.isSetPerMission">
                    <a-icon
                      type="star"
                      theme="twoTone"
                      two-tone-color="#f79443"
                    />
                  </span>
                </a>
              </div>
              <div class="box-bottom">
                <div class="d-flex flex-center-between">
                  <span>
                    <a-icon
                      theme="twoTone"
                      two-tone-color="#eb2f96"
                      type="profile"
                    />
                    Menu layer
                  </span>
                  <span>
                    <a-icon type="ordered-list" />
                    Feature layer
                  </span>
                  <template>
                    <a-tooltip
                      v-if="checkCollapse()"
                      :title="lbls.btn.collapse"
                      placement="topLeft"
                    >
                      <a-button
                        id="collapse"
                        size="small"
                        class="custom-btn-collapse"
                        @click="setCollapse($event, null)"
                      >
                        <span><a-icon type="shrink" /></span>
                      </a-button>
                    </a-tooltip>
                    <a-tooltip v-else :title="lbls.btn.expand" placement="top">
                      <a-button
                        id="expand"
                        size="small"
                        class="custom-btn-collapse"
                        @click="setCollapse($event, null)"
                      >
                        <span><a-icon type="arrows-alt" /></span>
                      </a-button>
                    </a-tooltip>
                  </template>
                </div>
              </div>
            </div>
            <div class="box-right" @scroll="onScroll($event)">
              <div
                v-for="item in dataProductsNew"
                :id="item.name"
                :key="item.id"
                class="product-wrapper"
                :class="{ active: item.name == productActive }"
              >
                <div class="item-title-product">
                  <b>{{ item.display_name }}</b>
                  <a-tooltip
                    v-if="item.activeKey?.length > 0"
                    :title="lbls.btn.collapse"
                    placement="top"
                  >
                    <a-button
                      id="collapse"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, item)"
                    >
                      <span><a-icon type="shrink" /></span>
                    </a-button>
                  </a-tooltip>
                  <a-tooltip v-else :title="lbls.btn.expand" placement="top">
                    <a-button
                      id="expand"
                      size="small"
                      class="custom-btn-collapse"
                      @click="setCollapse($event, item)"
                    >
                      <span><a-icon type="arrows-alt" /></span>
                    </a-button>
                  </a-tooltip>
                </div>
                <a-collapse
                  v-model="item.activeKey"
                  @change="changeActivekey($event, item)"
                >
                  <a-collapse-panel
                    v-if="item.data_levels"
                    key="1"
                    :header="lbls.role_page.title_data_level"
                  >
                    <a-radio-group
                      v-model="item.idDataLv"
                      @change="onChangeDataLv($event, item)"
                    >
                      <!-- <a-radio value="none"> None </a-radio> -->
                      <a-radio
                        v-for="lv in item.data_levels"
                        :key="lv.id"
                        :value="lv.id"
                      >
                        {{ lv.display_name }}
                      </a-radio>
                    </a-radio-group>
                  </a-collapse-panel>
                  <a-collapse-panel
                    v-if="item.newmenulist"
                    key="2"
                    header="Menu"
                  >
                    <div slot="extra" @click="handleClickExtra">
                      {{ item.is_selected }}
                      <a-checkbox
                        :checked="setCheckAllMenu(item)"
                        :indeterminate="setIndeterminatemenu(item)"
                        :disabled="dataPageHeader.name == configAdminName"
                        @change="onCheckAllMenu($event, item)"
                      >
                        <span>
                          <span>
                            {{ lbls.role_page.set_permission.select_all_menu }}
                            ({{ item.featuresSelect.length }}/{{
                              item.featuresAll.length
                            }})
                          </span>
                        </span>
                      </a-checkbox>
                      <a-tooltip
                        v-if="item.activeKey.includes('2') && expandAllMenu"
                        placement="top"
                        :title="lbls.btn.collapse"
                      >
                        <a-button
                          id="collapse"
                          size="small"
                          class="custom-btn-collapse"
                          @click="setCollapseMenu($event, item)"
                        >
                          <span><a-icon type="shrink" /></span>
                        </a-button>
                      </a-tooltip>
                      <a-tooltip
                        v-else
                        placement="top"
                        :title="lbls.btn.expand"
                      >
                        <a-button
                          id="expand"
                          size="small"
                          class="custom-btn-collapse"
                          @click="setCollapseMenu($event, item)"
                        >
                          <span><a-icon type="arrows-alt" /></span>
                        </a-button>
                      </a-tooltip>
                    </div>
                    <div v-if="item.isShowMenu">
                      <a-tree
                        v-if="item.newmenulist"
                        v-model="item.menuChecked"
                        checkable
                        :tree-data="item.newmenulist"
                        :default-expand-all="expandAllMenu"
                        :disabled="dataPageHeader.name == configAdminName"
                        @check="onCheckMenuNew($event, item)"
                      >
                        <template slot="title" slot-scope="text">
                          <a v-if="!text.isFeature">
                            <a-icon
                              theme="twoTone"
                              two-tone-color="#eb2f96"
                              type="profile"
                            />
                          </a>
                          <a v-if="text.isFeature">
                            <a-icon type="ordered-list" />
                          </a>
                          {{ text.title }}
                          <span v-if="!text.isFeature" class="lable-length"
                            >({{ text.children.length }})</span
                          >
                        </template>
                      </a-tree>
                    </div>
                  </a-collapse-panel>
                  <a-collapse-panel
                    v-if="item.features"
                    key="4"
                    :header="lbls.role_page.title_features"
                  >
                    <div slot="extra" @click="handleClickExtra">
                      <a-checkbox
                        v-if="item.features.length > 1"
                        :checked="setCheckAll(item.features)"
                        :indeterminate="setIndeterminate(item.features)"
                        :disabled="dataPageHeader.name == configAdminName"
                        @change="onChangAllCheckBox($event, item.features)"
                      >
                        <span>
                          {{
                            lbls.role_page.set_permission.select_all_features
                          }}
                          <!-- ({{ item.featuresSelect.length }}/{{
                            item.featuresAll.length
                          }}) -->
                        </span>
                      </a-checkbox>
                    </div>
                    <div class="feature-list">
                      <a-checkbox
                        v-for="feature in item.features"
                        :key="feature.id"
                        :checked="feature.is_selected"
                        class="feature-item"
                        :disabled="dataPageHeader.name == configAdminName"
                        @change="onChangesCheckbox($event, feature)"
                      >
                        <span>{{ feature.display_name }}</span>
                      </a-checkbox>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
          </div>
          <div v-else>
            <a-empty :image="imgEmpty" :image-style="{ height: '150px' }" />
          </div>
        </div>
      </div>
    </AppLayout>
    <div v-if="!isShowSkeleton" class="custom-footer-setpermission">
      <a-button
        :disabled="disablebtnok || dataPageHeader.name == configAdminName"
        type="primary"
        @click="handleSave()"
      >
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import { config } from "@/config/bzbsconfig.js";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import managePermissionAPI from "@/core/Page/ManagePermissionAPI";
import PermissionHelper from "@/helper/PermissionHelper";

export default {
  name: "ManageRoleSetPerMission",
  components: {
    AppLayout,
  },
  data() {
    return {
      breadcrumbs: [],
      imgEmpty: config.themeUrl + "/img/empty.svg",
      isShowSkeleton: true,
      dataPageHeader: {},
      dataProductsNew: [],
      disablebtnok: true,
      productActive: "",
      expandAllMenu: true,
      switchLoading: false,
      dataApplicationNew: null,
      configAdminName: PermissionHelper.configAdminName(),
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    $route(to) {
      if (to.name == "ManageRoleSetPerMission") {
        this.isShowSkeleton = true;
        this.init();
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.setBreadcrumbs();
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        this.callGetDetail().then((resgetdetail) => {
          let isCallListSuccess = resgetdetail;
          if (isCallListSuccess) {
            this.nameSearch = null;
            if (this.$route.name == "ManageRoleSetPerMission") {
              this.callPermissionProducts();
            }
            if (
              this.$route.name == "ManageSetExcouldPerMission" ||
              this.$route.name == "ManageSetAllExcouldPerMission"
            ) {
              this.callPermissionExcould().then((result) => {
                if (result) {
                  this.dataProductsNew = result.products;
                  if (result.application) {
                    result.application.logo_url = result.application.logo_url
                      ? result.application.logo_url
                      : this.logo_default;
                    if (this.$route.query.appid) {
                      this.dataApplicationNew = result.application;
                    }
                  }
                  this.dataProductForExcould = result.excould
                    ? result.excould.features_ids
                    : [];
                  this.isSetDataForCheckSave = true;
                  if (this.dataProductsNew && this.dataProductsNew.length > 0) {
                    this.setDataProductsNew();
                  }
                  this.handleLoading(false);
                  this.isShowSkeleton = false;
                }
              });
            }
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    handleClickExtra(event) {
      event.stopPropagation();
    },
    setBreadcrumbs() {
      if (this.$route.name == "ManageRoleSetPerMission") {
        this.breadcrumbs = [
          {
            breadcrumbName: this.lbls.menu.rolemanagement,
            name: "ManageRoles",
          },
          {
            breadcrumbName: this.lbls.menu.assignrolemanagement,
            name: "ManageRoleSetPerMission",
          },
        ];
      }
      if (this.$route.name == "ManageSetExcouldPerMission") {
        this.breadcrumbs = [
          {
            breadcrumbName: this.lbls.menu.rolemanagement,
            name: "ManageRoles",
          },
          {
            breadcrumbName: this.lbls.menu.assignapplicationstorole,
            name: "ManageAssignApplicationstoRole",
          },
          {
            breadcrumbName: this.lbls.menu.excouldrolemanagement,
            name: null,
          },
        ];
      }
      if (this.$route.name == "ManageSetAllExcouldPerMission") {
        this.breadcrumbs = [
          {
            breadcrumbName: this.lbls.menu.rolemanagement,
            name: "ManageRoles",
          },
          {
            breadcrumbName: this.lbls.menu.assignapplicationstorole,
            name: "ManageAssignApplicationstoRole",
          },
          {
            breadcrumbName:
              this.lbls.role_page.btn.excould_permission_all_applications,
            name: null,
          },
        ];
      }
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callPermissionProducts() {
      let params = {};
      let is_role_config = false;
      if (this.$route.query.name) {
        let role_name = this.$route.query?.name?.toLowerCase();
        role_name = role_name.replace(/ /g, "");
        is_role_config = role_name.includes("configpermission");
        if (is_role_config) {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
            is_internal_external: true,
          };
        } else {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
          };
        }
      } else {
        params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
      }
      managePermissionAPI
        .getPermissionProducts(params)
        .then((res) => {
          if (res.data && res.data.result) {
            if (res.data.result.products != undefined) {
              let result = res.data.result;
              let dataset = {
                records: result.products,
              };
              this.dataProductsNew = dataset.records;
              this.isSetDataForCheckSave = true;
              if (this.dataProductsNew && this.dataProductsNew.length > 0) {
                this.setDataProductsNew();
              }
              this.handleLoading(false);
              this.isShowSkeleton = false;
            } else {
              this.handleLoading(false);
              this.isShowSkeleton = false;
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPermissionExcould() {
      let params = {};
      let is_role_config = false;
      if (this.$route.query.name) {
        let role_name = this.$route.query?.name?.toLowerCase();
        role_name = role_name.replace(/ /g, "");
        is_role_config = role_name.includes("configpermission");
        if (is_role_config) {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
            is_internal_external: true,
          };
        } else {
          params = {
            account_id: this.exAccount.account.id,
            role_id: this.$route.query.id,
          };
        }
      } else {
        params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
      }
      let result;
      return new Promise((resolve, reject) => {
        if (this.$route.query.appid) {
          params.application_id = this.$route.query.appid;
          managePermissionAPI
            .getPermissionExcouldApplications(params)
            .then((res) => {
              if (res.data && res.data.result) {
                result = res.data.result;
              }
              resolve(result);
            })
            .catch((error) => {
              this.idModal = "error";
              this.handleLoading(true);
              this.errorApi(error);
              reject(error);
            });
        } else {
          managePermissionAPI
            .getPermissionExcould(params)
            .then((res) => {
              if (res.data && res.data.result) {
                result = res.data.result;
              }
              resolve(result);
            })
            .catch((error) => {
              this.idModal = "error";
              this.handleLoading(true);
              this.errorApi(error);
              reject(error);
            });
        }
      });
    },
    handleError(event) {
      event.target.src = this.logo_default;
    },
    setDataProductsNew() {
      _.forEach(this.dataProductsNew, (element, index) => {
        element.activeKey = [];
        if (element.data_levels) {
          element.activeKey.push("1");
          let findobj = element.data_levels.find((o) => o.is_selected === true);
          if (findobj) {
            element.idDataLv = findobj.id;
          } else {
            element.idDataLv = "none";
          }
        } //set data menu
        if (element.menus?.length > 0) {
          element.menus.sort((a, b) =>
            a.display_name.localeCompare(b.display_name)
          );
          this.menuChecked = [];
          this.featuresAll = [];
          this.featuresSelect = [];
          element.newmenulist = this.onSetNewMenu(element.menus);
          element.menuChecked = this.menuChecked;
          element.featuresAll = this.featuresAll;
          element.featuresSelect = this.featuresSelect;
          element.isShowMenu = this.expandAllMenu;
          if (element.newmenulist) {
            element.activeKey.push("2");
          }
        } //set data features
        if (element.features) {
          element.activeKey.push("4");
        }
        if (index == 0) {
          this.productActive = element.name;
        }
        element.activeKeyStart = element.activeKey;
      });
      this.isSetDataForCheckSave = true;
      this.setDataForSave();
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    setDataForSave() {
      this.dataProductForSave = [];
      this.featuresSelect = [];
      _.forEach(this.dataProductsNew, (element) => {
        let dataItemForSave = {};
        let featuresSelectAll = [];
        element.isSetPerMission = false;
        if (element.featuresSelect && element.featuresSelect.length > 0) {
          _.forEach(element.featuresSelect, (feature) => {
            featuresSelectAll.push(feature);
            this.featuresSelect.push(feature);
          });
          dataItemForSave.feature_ids = featuresSelectAll;
        }
        if (element.idDataLv && element.idDataLv != "none") {
          dataItemForSave.data_level_id = element.idDataLv;
        }
        if (element.features) {
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              featuresSelectAll.push(feature.id);
            }
          });
          if (featuresSelectAll.length > 0) {
            dataItemForSave.feature_ids = featuresSelectAll;
          }
        }
        if (isEmpty(dataItemForSave)) {
          element.isSetPerMission = isEmpty(dataItemForSave);
          dataItemForSave.id = element.id;
          this.dataProductForSave.push(dataItemForSave);
        }
        function isEmpty(obj) {
          return Object.keys(obj).length > 0;
        }
      });
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    onSetNewMenu(items) {
      let newmenulist = [];
      _.forEach(items, (item) => {
        let children = [];
        let data = item.menus ? item.menus : item.features;
        let isFeature = false;
        let isFeatureChildren = item.features && item.features.length > 0;
        if (data && data.length > 0) {
          data.sort((a, b) => a.display_name.localeCompare(b.display_name));
          children = this.onSetNewMenu(data);
        }
        // this.checkedKeysMenuAll.push(item.id);
        if (item.is_selected != null) {
          isFeature = true;
          this.featuresAll.push(item.id);
          if (item.is_selected) {
            this.menuChecked.push(item.id);
            this.featuresSelect.push(item.id);
          }
        }
        newmenulist.push({
          title: item.display_name,
          key: item.id,
          id: item.id,
          sequence: item.sequence,
          children: children,
          isFeature: isFeature,
          isFeatureChildren: isFeatureChildren,
        });
      });
      return newmenulist;
    },
    checkCollapse() {
      let activeKey = [];
      _.forEach(this.dataProductsNew, (item) => {
        if (item.activeKey && item.activeKey.length > 0) {
          activeKey.push(item);
        }
      });
      return activeKey.length;
    },
    setCollapse(e, item) {
      let productActive = _.cloneDeep(this.productActive);
      this.$nextTick(() => {
        if (item) {
          if (e.target.id == "collapse") {
            item.activeKey = [];
          }
          if (e.target.id == "expand") {
            item.activeKey = item.activeKeyStart;
          }
        } else {
          _.forEach(this.dataProductsNew, (item) => {
            if (e.target.id == "collapse") {
              item.activeKey = [];
            }
            if (e.target.id == "expand") {
              item.activeKey = item.activeKeyStart;
            }
          });
        }
        this.$nextTick(() => {
          this.$forceUpdate();
          setTimeout(() => {
            if (productActive) {
              const boxRight = document.querySelector(".box-right");
              const targetElement = document.getElementById(productActive);
              this.productActive = productActive;
              if (boxRight && targetElement) {
                boxRight.scrollTo({
                  top: targetElement.offsetTop - boxRight.offsetTop - 8,
                });
              }
            }
          }, 1000);
        });
      });
    },
    setCollapseMenu(e, item) {
      if (e.target.id == "expand" && !item.activeKey.includes("2")) {
        item.activeKey = item.activeKeyStart;
        this.$forceUpdate();
        this.$nextTick(() => {
          this.expandAllMenu = !this.expandAllMenu;
        });
      } else {
        this.expandAllMenu = !this.expandAllMenu;
      }
      item.isShowMenu = !item.isShowMenu;
      this.$forceUpdate();

      this.$nextTick(() => {
        item.isShowMenu = !item.isShowMenu;
        this.$forceUpdate();
      });
    },
    onScroll(event) {
      if (this.isShowSkeleton) return;
      var scrollDiv = null;
      let scrollData = [];
      _.forEach(this.dataProductsNew, (item) => {
        scrollDiv = document.getElementById(item.name);
        if (scrollDiv) {
          scrollDiv = scrollDiv.offsetTop;
          scrollData.push({
            offsetTop: scrollDiv,
            id: item.id,
            name: item.name,
          });
        }
      });
      var startBoxData = document
        .querySelector(".set-permission-body")
        .getBoundingClientRect().top;
      let scrollTop = event.target.scrollTop + startBoxData;
      const dataObjSelectscroll = scrollData.filter((data) => {
        return data.offsetTop < scrollTop;
      });
      if (dataObjSelectscroll.length > 0) {
        this.productActive = dataObjSelectscroll.slice(-1)[0].name;
      }
    },
    changeActivekey(key, item) {
      item.activeKey = key;
      this.$forceUpdate();
    },
    onChangeDataLv(e, item) {
      let datalv = e.target.value;
      _.forEach(item.data_levels, (element) => {
        element.is_selected = false;
        if (datalv == element.id) {
          element.is_selected = true;
        }
      });
      item.idDataLv = e.target.value;
      this.setDataForSave();
      this.$forceUpdate();
    },
    setCheckAllMenu(item) {
      return item.featuresSelect.length == item.featuresAll.length;
    },
    setIndeterminatemenu(item) {
      return (
        item.featuresAll.length != item.featuresSelect.length &&
        item.featuresSelect.length > 0
      );
    },
    onCheckAllMenu(e, item) {
      let checked = e.target.checked;
      if (checked) {
        item.menuChecked = item.featuresAll;
        item.featuresSelect = item.featuresAll;
      } else {
        item.menuChecked = [];
        item.featuresSelect = [];
      }
      this.setDataForSave();
      this.$forceUpdate();
    },
    onCheckMenuNew(checkedKeys, item) {
      item.featuresSelect = [];
      _.forEach(checkedKeys, (element) => {
        let findobj = item.featuresAll.find((id) => id == element);
        if (findobj) {
          item.featuresSelect.push(findobj);
        }
      });
      this.setDataForSave();
      this.$forceUpdate();
    },
    setCheckAll(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return featuresSelect.length == features.length;
    },
    setIndeterminate(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return (
        featuresSelect.length > 0 && featuresSelect.length != features.length
      );
    },
    onChangAllCheckBox(e, features) {
      _.forEach(features, (item) => {
        this.onChangesCheckbox(e, item);
      });
    },
    onChangesCheckbox(e, feature) {
      let checked = e.target.checked;
      feature.is_selected = checked;
      this.setDataForSave();
      this.$forceUpdate();
    },
    setShowLengthItem(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      let lengthItem =
        "(" + featuresSelect.length + "/" + features.length + ")";
      return lengthItem;
    },
    handleSave() {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
      };
      if (this.$route.name == "ManageRoleSetPerMission") {
        record.products = this.dataProductForSave;
        // record = JSON.stringify(record);
        managePermissionAPI
          .postPermissionProducts(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_update_success;
              this.isSetDataForCheckSave = true;
              this.expandAllMenu = true;
              // this.settestcheckallproduct();
              this.showModalSuccess();
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
          });
      }
      if (this.$route.name == "ManageSetExcouldPerMission") {
        record.application_id = this.$route.query.appid;
        // record.features_ids = this.dataProductForExcould;
        record.features_ids = this.featuresSelect;
        managePermissionAPI
          .postPermissionExcouldApplications(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_update_success;
              this.isSetDataForCheckSave = true;
              this.expandAllMenu = true;
              // this.settestcheckallproduct();
              this.showModalSuccess();
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
          });
      }
      if (this.$route.name == "ManageSetAllExcouldPerMission") {
        // record.features_ids = this.dataProductForExcould;
        record.features_ids = this.featuresSelect;
        this.$confirm({
          centered: true,
          autoFocusButton: null,
          title: "Are you sure to save ?",
          content: "This will affect all applications.",
          okText: this.lbls.role_page.btn.save,
          cancelText: this.lbls.role_page.btn.cancel,
          onOk: () => {
            managePermissionAPI
              .postPermissionExcouldAllApplications(record)
              .then((res) => {
                if (res.status == "200" && res.data.result) {
                  this.idModal = "success";
                  this.titleModal = this.lbls.role_page.modal.title_success;
                  this.contentModal =
                    this.lbls.role_page.modal.content_update_success;
                  this.isSetDataForCheckSave = true;
                  this.expandAllMenu = true;
                  // this.settestcheckallproduct();
                  this.showModalSuccess();
                }
              })
              .catch((error) => {
                this.idModal = "error";
                this.handleLoading(true);
                this.errorApi(error);
              });
          },
          onCancel: () => {
            this.handleLoading(false);
          },
        });
      }
    },
    scrollToProduct(productName) {
      const boxRight = document.querySelector(".box-right");
      const targetElement = document.getElementById(productName);
      this.productActive = productName;
      if (boxRight && targetElement) {
        boxRight.scrollTo({
          top: targetElement.offsetTop - boxRight.offsetTop - 8,
          behavior: "smooth",
        });
      }
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
        onOk: () => {
          if (this.isDelete) {
            this.$router.push("manageroles");
          } else {
            // this.disablebtnok = true;
            // // this.$router.go();
            this.init();
            this.$nextTick(() => {
              this.$forceUpdate();
            });
          }
        },
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        setTimeout(() => {
          if (this.isDelete) {
            this.$router.push("manageroles");
          } else {
            // this.disablebtnok = true;
            // // this.$router.go();
            this.init();
            this.$nextTick(() => {
              this.$forceUpdate();
            });
          }
        }, 500);
      }, secondsToGo * 1000);
    },
    resetCondition() {
      this.isAddApp = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.hasMoreData = false;
      this.isShowModalSelectApp = false;
      this.page = 0;
      this.pageCopy = 0;
      this.dataApplicationsModal = [];
      this.objDataSave = [];
      this.nameSearchModal = "";
    },
    handleResetRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.$confirm({
        title: this.lbls.role_page.modal.title_confirm,
        content: this.lbls.role_page.modal.content_reset_role,
        centered: true,
        autoFocusButton: null,
        okText: "OK",
        cancelText: "Cancel",
        closable: true,
        onOk: () => {
          this.callPostResetRole();
        },
      });
    },
    handleDeleteRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_delete,
        content: this.objSelect.name,
        okText: this.lbls.role_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
      });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isShowSkeleton = true;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostResetRole() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .postResetRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isShowSkeleton = true;
            this.$success({
              title: this.lbls.role_page.modal.title_success,
              content: this.lbls.role_page.modal.content_update_success,
              centered: true,
              autoFocusButton: null,
              closable: true,
              okText: "OK",
              onOk: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    changeSwitch(checked) {
      this.dataPageHeader.is_active = checked;
      this.switchLoading = true;
      this.callPostEditData();
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        role_name: this.dataPageHeader.name,
        role_description: this.dataPageHeader.description,
        is_active: this.dataPageHeader.is_active,
      };
      let valueJson = _.cloneDeep(JSON.stringify(valueSave));
      manageRoleAPI
        .postEditRole(valueJson)
        .then((res) => {
          if (res.status == "200" && res.data.result) {
            this.idModal = "success";
            this.openNotificationWithIcon("success");
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.lbls.role_page.modal.title_success,
        description: this.lbls.role_page.modal.content_update_success,
      });
      this.switchLoading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.permission-page {
  .sec-action {
    .box-app {
      display: flex;
      margin-right: var(--spacing-xxs);
      padding-right: var(--spacing-xs);
      border-right: 1px solid var(--grey-grey-8);
      align-items: center;
    }
    .logo-custom {
      border: 1px solid var(--grey-grey-8);
      border-radius: 4px;
      img {
        border-radius: 4px;
        width: 40px;
      }
    }
    .label-name {
      font-weight: 600;
      padding-right: var(--spacing-xs);
      font-size: 1rem;
      display: inline-grid;
    }
    .label-package {
      display: block;
      color: var(--grey-grey-5);
      font-size: var(--spacing-s);
      font-weight: normal;
      line-height: 1;
    }
  }
  .set-permission-body {
    height: calc(100vh - 243px);
    // padding: var(--spacing-xs);
    background: #fff;
    display: flex;
    .box-left {
      display: flex;
      flex-direction: column;
      width: 260px;
      padding: var(--spacing-xs);
      border-right: 1px solid var(--grey-grey-7);
      overflow-y: auto;
      b {
        margin-bottom: var(--spacing-xs);
      }
      .item-button-href {
        color: var(--primary-secoundary-color);
        padding: var(--spacing-xxs) var(--spacing-xs);
        &.active {
          border-right: 2px solid var(--primary-gold-bzb-primary-bzb);
          background: var(--primary-gold-bzb-gold-9);
          font-weight: 500;
          opacity: 1;
          color: var(--primary-gold-bzb-primary-bzb);
        }
        span {
          margin-left: var(--spacing-xxs);
          display: inline-block;
          vertical-align: middle;
        }
      }
      .box-top {
        overflow-y: auto;
        height: calc(100% - 68px);
      }
      .box-bottom {
        padding: var(--spacing-xs) 0px 0px 0px;
        background-color: white;
        box-sizing: border-box;
        span {
          vertical-align: middle;
        }
        .anticon-ordered-list {
          color: var(--primary-gold-bzb-primary-bzb);
        }
      }
    }
    .box-right {
      width: calc(100% - 200px);
      padding: var(--spacing-xs);
      height: 100%;
      overflow: auto;
      .product-wrapper {
        padding: var(--spacing-xs);
        background: var(--bg-bg-light);
        &.active {
          border: 1px solid var(--primary-gold-bzb-primary-bzb);
        }
        + .product-wrapper {
          margin-top: var(--spacing-xs);
        }
      }
      .lable-check-all {
        color: var(--grey-grey-1);
      }
      .feature-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        .feature-item + .feature-item {
          margin-left: 0;
          font-weight: 400;
        }
      }
      .set-border-bottom {
        border-bottom: 1px solid var(--grey-grey-7);
      }
      .item-title-product {
        color: var(--primary-secoundary-color);
        padding-left: var(--spacing-xs);
        margin-bottom: var(--spacing-xs);
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) 40px;
        line-height: 30px;
        position: relative;
        .ant-collapse-extra {
          display: inline-block;
          float: none;
          margin-left: var(--spacing-m);
          button {
            position: absolute;
            right: var(--spacing-xs);
            top: var(--spacing-xs);
          }
        }
      }
      .item-title-feature {
        .title-item {
          font-size: 12px;
          opacity: 0.7;
        }
        .label-item {
          background: #fff;
          padding: 12px;
        }
      }
      .title-item-list {
        cursor: pointer;
      }
      a {
        color: var(--primary-gold-bzb-primary-bzb);
        text-decoration: none;
        .anticon {
          margin-top: -2px;
        }
      }
      a:hover {
        color: var(--primary-gold-bzb-gold-6);
        text-decoration: none;
      }
    }
    @include breakpoint-max($tablet-md) {
      position: relative;
      display: block;
      height: calc(100vh - 285px);
      .box-left {
        padding: 0;
        display: block;
        position: absolute;
        top: -40px;
        right: 0;
        z-index: 2;
        width: 30px;
        height: 30px;
        border: none;
        b,
        .box-top {
          display: none;
        }
        .box-bottom {
          padding: 0;
          background: none;
          .d-flex > span {
            display: none;
          }
        }
      }
      .box-right {
        width: 100%;
        display: block;
      }
    }
  }
  .set-permission-app {
    border-top: 1px solid var(--grey-grey-7);
  }
}
</style>
