<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        :databreadcrumbs="breadcrumbs"
        :title="dataPageHeader.name"
      />
      <div class="set-test-beta">
        <router-link
          :to="{
            name: 'ManageRoleSetRoles',
            query: {
              id: $route.query.id,
            },
          }"
        >
          Old Version
        </router-link>
      </div>
      <div v-if="isShowSkeleton" class="background-white p-5">
        <a-skeleton />
      </div>
      <div v-show="showDataApplications">
        <DataApplications
          :datapageheader="dataPageHeader"
          :dataapplications="dataApplications"
          :hasmoredata="hasMoreDataApp"
          @callgetlist="callGetList"
          @handleadd="handleAdd"
          @handleedit="handleEdit"
          @onsearch="onSearch($event)"
          @handlecopy="handleCopy($event)"
          @handlecopies="handleCopies($event)"
          @handledeleterole="handleDeleteRole()"
          @handledeleteapp="handleDeleteApp($event)"
          @handledeleteall="handleDeleteAllApp()"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalAddApps">
        <ModalAddApps
          :modaladdapps="modalAddApps"
          :dataapplications="dataApplicationsModal"
          :dataselectforcopy="dataSelecttoCopy"
          :hasmoredata="hasMoreData"
          @callgetlist="callGetAvailableApp"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalAddApps>
      </div>
      <div v-if="isModalAddPermissionApps">
        <ModalAddPermissionApps
          :modaladdapps="modalAddApps"
          :isshowskeletonmodal="isShowSkeletonModal"
          :dataapplications="dataApplicationsModal"
          :dataselectforcopy="dataSelecttoCopy"
          :hasmoredata="hasMoreData"
          @callgetlist="checkCallGetData"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalAddPermissionApps>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import DataApplications from "@/module/Role/ManageSetRole/components/Layout/SetDataApplications.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAddApps from "@/module/Role/ManageSetRole/components/Modal/ModalAddApps.vue";
import ModalAddPermissionApps from "@/module/Role/ManageSetRole/components/Modal/ModalAddPermissionAppsNew.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    DataApplications,
    ModalProcess,
    ModalAddApps,
    ModalAddPermissionApps,
    ModalAlert,
  },
  data() {
    return {
      breadcrumbs: [],
      isShowSkeleton: true,
      isShowSkeletonModal: true,
      data: [],
      dataRole: {},
      dataPackages: [],
      dataApplications: [],
      dataApplicationsModal: [],
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      isModalProcess: false,
      isModalAddApps: false,
      isModalAddPermissionApps: false,
      isModalResponse: false,
      titleModalProcess: "",
      tabActive: "",
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      dataSelecttoCopy: {},
      dataPageBreadcrumb: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      nameSearch: "",
      nameSearchModal: "",
      dataPageHeader: {},
      page: 0,
      page_size: 20,
      hasMoreData: false,
      hasMoreDataApp: false,
      showDataApplications: false,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.pageGetlist = 0;
            this.dataApplications = [];
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.rolemanagement,
        name: "ManageRoles",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.assignrolemanagement,
        name: "InviteUsersDetail",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        // this.isShowSkeleton = true;
        this.nameSearch = text != undefined ? text : "";
        this.pageGetlist = 0;
        this.dataApplications = [];
        this.callGetList();
      } else {
        return;
      }
    },
    onSearchModal(searchData) {
      let text = searchData;
      if (text != this.nameSearchModal) {
        this.isShowSkeletonModal = true;
        this.nameSearchModal = text != undefined ? text : "";
        if (this.isCopiesPerMission) {
          this.pageCopy = 0;
          this.dataApplicationsModal = [];
          this.callGetListForCoppy();
        }
        if (this.isAddApp) {
          this.page = 0;
          this.dataApplicationsModal = [];
          this.callGetAvailableApp();
        }
        if (this.isDeleteAllApp) {
          this.pageGetlistDelete = 0;
          this.dataApplicationsModal = [];
          this.callGetListForDelete();
        }
      } else {
        return;
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
      this.isModalAddApps = false;
      this.isModalAddPermissionApps = false;
      this.isShowSkeleton = true;
      this.showDataApplications = false;
    },
    showModalAddApps() {
      this.handleLoading(false);
      this.isModalAddPermissionApps = true;
      let hasmoredata = false;
      if (this.isAddApp) {
        hasmoredata = this.hasMoreData;
      }
      if (this.isCopiesPerMission || this.isDeleteApp) {
        hasmoredata = this.hasMoreDataApp;
      }
      this.modalAddApps = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isAddApp: this.isAddApp,
        isDeleteApp: this.isDeleteApp,
        isCopyPerMission: this.isCopyPerMission,
        isCopiesPerMission: this.isCopiesPerMission,
        hasmoredata: hasmoredata,
      };
    },
    resetCondition() {
      this.isAddApp = false;
      this.isCopyPerMission = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.page = 0;
      this.page_size = 25;
      this.dataApplicationsModal = [];
      this.nameSearchModal = "";
      this.dataPackageCopy = null;
      this.objDataSave = [];
    },
    resetConditionCopy() {
      this.isAddApp = false;
      this.isCopyPerMission = false;
      this.isCopiesPerMission = false;
      this.isEdit = false;
      this.isDelete = false;
      this.isDeleteApp = false;
      this.isDeleteAllApp = false;
      this.hasMoreDataApp = false;
      this.nameSearchModal = "";
      this.dataPackageCopy = null;
      this.objDataSave = [];
      this.dataApplicationsModal = [];
    },
    handleAdd() {
      this.resetCondition();
      this.handleLoading(true);
      this.isAddApp = true;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_add;
      this.callGetAvailableApp();
    },
    handleCopy(data) {
      this.resetCondition();
      this.handleLoading(true);
      this.isCopyPerMission = true;
      this.isCopiesPerMission = false;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_copy;
      this.dataSelecttoCopy = data;
      this.dataPackageCopy = data.package;
      this.callGetList();
    },
    handleCopies(data) {
      this.resetConditionCopy();
      this.handleLoading(true);
      this.isCopyPerMission = false;
      this.isCopiesPerMission = true;
      this.titleModalProcess = this.lbls.role_page.modal_add_app.title_copy;
      this.dataSelecttoCopy = data;
      this.dataPackageCopy = data.package;
      this.dataApplicationsModal = [];
      this.nameSearchModal = "";
      this.pageCopy = 0;
      this.callGetListForCoppy();
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDeleteRole() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_delete,
        content: this.objSelect.name,
        okText: this.lbls.role_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handleDeleteAllApp() {
      this.resetConditionCopy();
      this.handleLoading(true);
      this.isDeleteApp = true;
      this.isDeleteAllApp = true;
      this.pageGetlistDelete = 0;
      this.callGetListForDelete();
      // _.forEach(this.dataApplications, (item) => {
      //   if (item.permission_products)
      //     this.dataApplicationsModal.push({
      //       id: item.application.id,
      //       name: item.application.name,
      //       owner: item.owner_account.name,
      //       package_dsname: item.package.display_name,
      //       package_name: item.package.name,
      //       is_selected: false,
      //     });
      // });
      // if (this.dataApplicationsModal.length > 0) {
      //   this.titleModalProcess = this.lbls.role_page.modal_add_app.title_remove;
      //   this.showModalAddApps();
      // } else {
      //   this.idModal = "error";
      //   this.titleModal = this.lbls.role_page.modal_add_app.title_remove;
      //   this.contentModal = this.lbls.role_page.modal_add_app.content_remove;
      //   this.modalAlert = {
      //     id: this.idModal,
      //     visible: true,
      //     title: this.titleModal,
      //     message: this.contentModal,
      //     isDelete: this.isDeleteApp,
      //   };
      //   this.isModalResponse = true;
      // }
    },
    handleDeleteApp(item) {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDeleteApp = true;
      this.objDataSave.push(item.id);
      this.$confirm({
        class: "custom-width-confirm",
        centered: true,
        autoFocusButton: null,
        title: this.lbls.role_page.modal.content_remove_apps,
        content: item.name,
        okText: this.lbls.role_page.modal.btn.remove,
        okType: "danger",
        cancelText: this.lbls.role_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteDataApp();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    callGetList() {
      this.pageGetlist = this.pageGetlist + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.pageGetlist,
        page_size: 100,
      };
      if (!this.dataPackageCopy && this.nameSearch) {
        params.application_name = this.nameSearch;
      }
      // if (this.dataPackageCopy) {
      //   params.package_id = this.dataPackageCopy.id;
      // }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.dataApplications.push(...result);
            this.hasMoreDataApp = paging.has_more_data;
            this.totalList = this.totalList;
            if (this.isCopiesPerMission) {
              this.setDataCopies(result);
            }
            if (this.isDeleteApp) {
              this.handleDeleteAllApp();
            }
          }
          this.isShowSkeleton = false;
          this.showDataApplications = true;
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetListForCoppy() {
      this.pageCopy = this.pageCopy + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        package_id: this.dataPackageCopy.id,
        page: this.pageCopy,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.hasMoreDataApp = paging.has_more_data;
            this.setDataCopies(result);
          }
          this.isShowSkeleton = false;
          this.showDataApplications = true;
          this.handleLoading(false);
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetListForDelete() {
      this.pageGetlistDelete = this.pageGetlistDelete + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.pageGetlistDelete,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getApplications(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let result = res.data.result.items;
            let paging = res.data.result.paging;
            this.hasMoreDataApp = paging.has_more_data;
            _.forEach(result, (item) => {
              if (item.permission_products)
                this.dataApplicationsModal.push({
                  id: item.application.id,
                  name: item.application.name,
                  owner: item.owner_account.name,
                  package_dsname: item.package.display_name,
                  package_name: item.package.name,
                  is_selected: false,
                });
            });
            this.titleModalProcess =
              this.lbls.role_page.modal_add_app.title_remove;
            this.showModalAddApps();
          } else {
            this.handleLoading(false);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetAvailableApp() {
      this.hasMoreData = false;
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        page: this.page,
        page_size: 100,
      };
      if (this.nameSearchModal) {
        params.application_name = this.nameSearchModal;
      }
      manageRoleAPI
        .getAvailableApp(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let items = res.data.result.items;
            let paging = res.data.result.paging;
            _.forEach(items, (item) => {
              this.dataApplicationsModal.push({
                id: item.application.id,
                name: item.application.name,
                owner: item.owner_account.name,
                package_dsname: item.package.display_name,
                package_name: item.package.name,
                is_selected: false,
              });
            });
            this.hasMoreData = paging.has_more_data;
            this.showModalAddApps();
          }
          this.isShowSkeletonModal = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.objDataSave,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postAddApplications(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_add_apps_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostCopyData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        source_application_id: this.objDataSave[0].id,
        target_application_id: this.dataSelecttoCopy.application.id,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postCopyPermission(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostCopiesData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        source_application_id: this.dataSelecttoCopy.application.id,
        target_application_ids: this.objDataSave,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postCopiesPermission(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.isModalProcess = false;
              this.isModalAddApps = false;
              this.isModalAddPermissionApps = false;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
                this.dataPageHeader = res.data.result;
                this.handleLoading(false);
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        application_ids: this.objDataSave,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteDataApp() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_ids: this.objDataSave,
      };
      manageRoleAPI
        .postDeleteApplications(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.isModalProcess = false;
            this.isModalAddApps = false;
            this.isModalAddPermissionApps = false;
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_remove_apps_success;
              // this.openNotificationWithIcon("success");
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (
        this.isAddApp ||
        this.isCopyPerMission ||
        this.isCopiesPerMission
      ) {
        this.resetCondition();
        this.init();
      } else if (this.isDeleteApp) {
        _.forEach(this.objDataSave, (item) => {
          this.dataApplications = this.dataApplications.filter(
            (x) => x.application.id != item
          );
        });
        this.isShowSkeleton = false;
        this.showDataApplications = true;
        this.handleLoading(false);
      }
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (
        this.isAddApp ||
        this.isCopyPerMission ||
        this.isCopiesPerMission
      ) {
        this.resetCondition();
        this.init();
      } else if (this.isDeleteApp) {
        if (this.isDeleteAllApp) {
          this.resetCondition();
          this.resetConditionCopy();
          this.init();
        } else {
          _.forEach(this.objDataSave, (item) => {
            this.dataApplications = this.dataApplications.filter(
              (x) => x.application.id != item
            );
          });
          this.isShowSkeleton = false;
          this.showDataApplications = true;
          this.handleLoading(false);
        }
      }
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else if (this.isDeleteApp) {
        if (this.objDataSave.length == 0) {
          _.forEach(this.dataApplications, (item) => {
            this.objDataSave.push(item.id);
          });
        }
        this.callPostDeleteDataApp();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        this.isModalAddApps = false;
        this.isModalAddPermissionApps = false;
        this.resetConditionCopy();
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
        if (this.isAddApp) {
          this.objDataSave = value;
          this.callPostAddData();
        }
        if (this.isCopyPerMission) {
          this.objDataSave = value;
          this.callPostCopyData();
        }
        if (this.isCopiesPerMission) {
          this.objDataSave = value;
          this.callPostCopiesData();
        }
        if (this.isDeleteApp) {
          this.objDataSave = value;
          this.callPostDeleteDataApp();
        }
      }
    },
    setDataForCopy(result) {
      let featuresSelect = result.filter((item) => {
        return (
          item.permission_products.length > 0 &&
          item.application.id != this.dataSelecttoCopy.application.id
        );
      });
      if (featuresSelect.length > 0) {
        _.forEach(featuresSelect, (item) => {
          this.dataApplicationsModal.push({
            id: item.application.id,
            name: item.application.name,
            owner: item.owner_account.name,
            package_dsname: item.package.display_name,
            package_name: item.package.name,
            permission_products: item.permission_products,
            last_modified_date: item.last_modified_date,
            is_selected: false,
          });
        });
      }
      if (this.dataApplicationsModal.length > 0) {
        this.showModalAddApps();
      } else {
        this.titleModal = this.lbls.role_page.modal_add_app.title_copy;
        this.contentModal = this.lbls.role_page.modal_add_app.content_copy;
        this.showError();
        this.handleLoading(false);
      }
    },
    setDataCopies(result) {
      let dataselect = result
        ? _.cloneDeep(result)
        : _.cloneDeep(this.dataApplications);
      dataselect = dataselect.filter((item) => {
        return (
          item.package.id === this.dataSelecttoCopy.package.id &&
          item.application.id != this.dataSelecttoCopy.application.id
        );
      });
      if (dataselect.length > 0) {
        _.forEach(dataselect, (item) => {
          this.dataApplicationsModal.push({
            id: item.application.id,
            name: item.application.name,
            owner: item.owner_account.name,
            package_dsname: item.package.display_name,
            package_name: item.package.name,
            permission_products: item.permission_products,
            last_modified_date: item.last_modified_date,
            is_selected: false,
          });
        });
      }
      if (this.dataApplicationsModal.length > 0) {
        this.showModalAddApps();
      } else {
        this.titleModal = this.lbls.role_page.modal_add_app.title_copy;
        this.contentModal = this.lbls.role_page.modal_add_app.content_copy;
        this.showError();
        this.handleLoading(false);
      }
    },
    showError() {
      this.$error({
        centered: true,
        autoFocusButton: null,
        title: this.titleModal,
        content: this.contentModal,
        okType: "default",
        okText: "Close",
      });
    },
    checkCallGetData() {
      if (this.isAddApp) {
        this.callGetAvailableApp();
      }
      if (this.isCopiesPerMission) {
        this.callGetListForCoppy();
      }
      if (this.isDeleteApp) {
        this.callGetListForDelete();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-footer-setpermission {
  position: absolute;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 63px;
  background: #fff;
  bottom: 0;
  width: 100%;
  .btn-custom-delete {
    img {
      margin-top: -4px;
    }
  }
}
</style>
