<template>
  <div>
    <a-modal
      v-model="modaladdapps.visible"
      centered
      class="modal-with-content-custom"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modaladdapps.title }}</span>
          <span v-if="modaladdapps.isCopiesPerMission"> : </span>
          <span v-if="modaladdapps.isCopiesPerMission" class="name-content">
            {{ dataselectforcopy.application.name }}
          </span>
        </div>
      </template>
      <div class="modal-addapps-new">
        <div class="modal-addapps-top">
          <div class="modal-top-label">
            <div class="flex-center">
              <span>{{ lbls.role_page.modal_add_app.title_apps }}</span>
            </div>
          </div>
          <div class="mt-3">
            <a-input-search
              v-model="searchData"
              :placeholder="lbls.role_page.modal_add_app.input_search"
              class="w-100"
              size="large"
              @blur="onSearch"
              @search="onSearch"
            >
            </a-input-search>
          </div>
          <div
            v-if="modaladdapps.isCopiesPerMission"
            class="custom-show-checkbox mt-3"
          >
            <a-checkbox
              :disabled="data.length == 0"
              :checked="checkSelectAll()"
              :indeterminate="checkedIndeterminateCopy()"
              class="flex-center"
              @change="changeSwitchAll($event)"
            >
              <span> Select all ({{ checkLength() }}/{{ data.length }}) </span>
            </a-checkbox>
          </div>
          <div
            v-if="modaladdapps.isCopiesPerMission"
            class="label-max-item"
            :class="{ 'label-max-item-error': this.objDataSave.length > 200 }"
          >
            * Maximum selection 200 items
          </div>
          <div
            v-if="modaladdapps.isAddApp || modaladdapps.isDeleteApp"
            class="custom-show-checkbox mt-3"
          >
            <div>
              <a-checkbox
                :disabled="data.length == 0"
                :checked="checkedAllItem()"
                :indeterminate="checkedIndeterminate()"
                class="flex-center"
                @change="changeSwitch($event)"
              >
                <span>
                  Select all ({{ checkLength() }}/{{ data.length }})
                </span>
              </a-checkbox>
            </div>
          </div>
        </div>
        <div class="modal-select-addapps mt-3">
          <div v-if="!isshowskeletonmodal && data.length == 0">
            <a-empty :image="simpleImage" />
          </div>
          <div
            v-if="
              isshowskeletonmodal &&
              !modaladdapps.isCopiesPerMission &&
              !modaladdapps.isDeleteApp
            "
            class="background-white p-5"
          >
            <a-skeleton />
          </div>
          <div v-if="modaladdapps.isAddApp || modaladdapps.isDeleteApp">
            <div
              v-if="checkShowItemSelect()"
              class="body-items text-center show-border-bottom mb-3"
            >
              <div
                v-for="item in objFromSearchData"
                :key="item.name"
                class="item-app"
                :class="{ 'item-app-active': item.is_selected }"
                @click="clickSelect(item)"
              >
                <div>
                  <div class="label-app">
                    <div class="logo-custom"><img :src="logo" /></div>
                    <div class="label-item ml-2">
                      <div class="flex-center-between">
                        <div
                          :id="'n-' + item.id"
                          class="label-name-app wrap-text-one-line"
                        >
                          {{ item.name }}
                        </div>
                        <div v-if="showWidth">
                          <span v-if="checkWidht('n-' + item.id) > 162">
                            <div class="action-list">
                              <a-tooltip placement="topLeft">
                                <template slot="title">
                                  {{ item.name }}
                                </template>
                                <a>
                                  <a-icon type="question-circle" />
                                </a>
                              </a-tooltip>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="flex-center-between">
                        <div
                          :id="'d-' + item.id"
                          class="label-package wrap-text-one-line"
                        >
                          {{ item.package_dsname }}
                        </div>
                        <div v-if="showWidth">
                          <span v-if="checkWidht('d-' + item.id) > 162">
                            <div class="action-list">
                              <a-tooltip placement="topLeft">
                                <template slot="title">
                                  {{ item.package_dsname }}
                                </template>
                                <a>
                                  <a-icon type="question-circle" />
                                </a>
                              </a-tooltip>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="dataforselect" class="body-items text-center">
              <div
                v-for="item in data"
                :key="item.id"
                class="item-app"
                :class="{ 'item-display-none': item.is_selected }"
                @click="clickSelect(item)"
              >
                <div v-if="!item.is_selected" class="label-app">
                  <div class="logo-custom"><img :src="logo" /></div>
                  <div class="label-item ml-2">
                    <div class="flex-center-between">
                      <div
                        :id="'n-' + item.id"
                        class="label-name-app wrap-text-one-line"
                      >
                        {{ item.name }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('n-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                cccc{{ item.name }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="flex-center-between">
                      <div
                        :id="'d-' + item.id"
                        class="label-package wrap-text-one-line"
                      >
                        {{ item.package_dsname }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('d-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.package_dsname }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="modaladdapps.isCopiesPerMission">
            <div
              v-if="checkShowItemSelect()"
              class="body-items text-center show-border-bottom mb-3"
            >
              <div
                v-for="item in objFromSearchData"
                :key="item.name"
                class="item-app"
                :class="{ 'item-app-copy-active': item.is_selected }"
                @click="clickSelect(item)"
              >
                <div class="label-app">
                  <div class="logo-custom"><img :src="logo" /></div>
                  <div class="label-item ml-2">
                    <div class="flex-center-between">
                      <div
                        :id="'n-' + item.id"
                        class="label-name-app wrap-text-one-line"
                      >
                        {{ item.name }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('n-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.name }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="flex-center-between">
                      <div
                        :id="'d-' + item.id"
                        class="label-package wrap-text-one-line"
                      >
                        {{ item.package_dsname }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('d-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.package_dsname }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="dataforselect" class="body-items text-center">
              <div
                v-for="item in data"
                :key="item.id"
                class="item-app"
                :class="{ 'item-display-none': item.is_selected }"
                @click="clickSelect(item)"
              >
                <div v-if="!item.is_selected" class="label-app">
                  <div class="logo-custom"><img :src="logo" /></div>
                  <div class="label-item ml-2">
                    <div class="flex-center-between">
                      <div
                        :id="'n-' + item.id"
                        class="label-name-app wrap-text-one-line"
                      >
                        {{ item.name }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('n-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.name }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="flex-center-between">
                      <div
                        :id="'d-' + item.id"
                        class="label-package wrap-text-one-line"
                      >
                        {{ item.package_dsname }}
                      </div>
                      <div v-if="showWidth">
                        <span v-if="checkWidht('d-' + item.id) > 162">
                          <div class="action-list">
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.package_dsname }}
                              </template>
                              <a>
                                <a-icon type="question-circle" />
                              </a>
                            </a-tooltip>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-loader" :class="{ loaded: !showLoading }">
            <lottie-vue-player
              :src="Config.themeUrl + '/json/loading.json'"
              :background-color="'transparent'"
              :loop="true"
              :autoplay="true"
              class="loaderjson"
            />
          </div>
        </div>
        <div class="mt-3">
          <span v-if="data.length && data.length > 0">Showing 1 to </span>
          <span>{{ data.length }} items</span>
          <span
            v-if="modaladdapps.hasmoredata"
            class="custom-title-load-more"
            @click="callGetList()"
          >
            Load More</span
          >
        </div>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modaladdapps.cancelText
              ? modaladdapps.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button
          type="primary"
          :disabled="checkDisablebtn()"
          @click="handleSave"
        >
          {{
            modaladdapps.okText ? modaladdapps.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Empty } from "ant-design-vue";
import { config } from "@/config/bzbsconfig.js";
export default {
  name: "ManageProduct",
  props: {
    modaladdapps: {
      type: Object,
    },
    dataapplications: {
      type: Array,
    },
    dataselectforcopy: {
      type: Object,
    },
    isshowskeletonmodal: {
      type: Boolean,
    },
  },
  data() {
    return {
      logo: require("@/assets/images/bzbs-logo1.png"),
      disabledok: true,
      hasMoredata: true,
      showLoading: false,
      showWidth: false,
      nameSearch: "",
      searchData: "",
      data: [],
      objDataSave: [],
      objFromSearchData: [],
      innerHeight: 0,
      lastscrollTop: 0,
      Config: config,
    };
  },
  watch: {
    dataapplications() {
      this.showWidth = false;
      this.data = _.cloneDeep(this.dataapplications);
      _.forEach(this.data, (item) => {
        let findobj = this.objDataSave.find((o) => o === item.id);
        if (findobj) {
          item.is_selected = true;
        }
      });
      this.objFromSearchData = this.objFromSearchData.filter((data) => {
        return data.is_selected;
      });
      this.data.sort((a, b) => b.is_selected - a.is_selected);
      this.hasMoredata = _.cloneDeep(this.modaladdapps.hasmoredata);
      this.showLoading = false;
      setTimeout(() => {
        this.showWidth = true;
        setTimeout(() => {
          const element = document.getElementById("dataforselect");
          element.scrollIntoView();
        }, 200);
      });
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    setTimeout(() => {
      this.tableContent = document.querySelector(".modal-select-addapps");
      this.onScroll();
    });
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = [];
      this.objFromSearchData = [];
      this.data = _.cloneDeep(this.dataapplications);
      this.hasMoredata = _.cloneDeep(this.modaladdapps.hasmoredata);
      _.forEach(this.data, (item) => {
        if (item.is_selected) {
          this.objDataSave.push(item.id);
          this.objFromSearchData.push(item);
        }
      });
      setTimeout(() => {
        this.showWidth = true;
        setTimeout(() => {
          const element = document.getElementById("dataforselect");
          element.scrollIntoView();
        }, 200);
      });
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (scrollPersent >= 98 && isScrollDown && this.hasMoredata) {
          this.hasMoredata = false;
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleClose() {
      this.modaladdapps.visible = false;
      this.$emit("actionModal", "close");
    },
    callGetList() {
      // this.handleLoading(true);
      this.showLoading = true;
      this.$emit("callgetlist");
    },
    onSearch() {
      this.$emit("onsearch", this.searchData); // if (this.modaladdapps.isAddApp) {
      //   this.$emit("onsearch", this.searchData);
      // } else if (this.modaladdapps.isCopiesPerMission) {
      //   this.showWidth = false;
      //   this.onSearchCopy();
      // }
    },
    onSearchCopy() {
      if (this.nameSearch != this.searchData) {
        this.nameSearch = this.searchData;
        this.objDataSave = [];
        this.objFromSearchData = [];
        this.data = this.dataapplications.filter((item) => {
          return item.name
            .toString()
            .toLowerCase()
            .includes(this.nameSearch.toLowerCase());
        });
        if (this.modaladdapps.isCopiesPerMission) {
          _.forEach(this.data, (element) => {
            element.is_selected = false;
          });
        }
        setTimeout(() => {
          this.showWidth = true;
        });
      }
    },
    handleSave() {
      this.$emit("actionModal", this.objDataSave);
    },
    checkedAllItem() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked == this.data.length;
    },
    checkedIndeterminate() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked != this.data.length;
    },
    checkLength() {
      let dataselect = this.data.filter((item) => {
        return item.is_selected;
      });
      return dataselect.length;
    },
    changeSwitch(e) {
      let checked = e.target.checked;
      _.forEach(this.data, (item) => {
        item.is_selected = checked;
        this.setDataForSave(item);
      });
      this.objDataSave = [...new Set(this.objDataSave)];
      this.$forceUpdate();
    },
    checkSwitchItem(item) {
      let findobj = this.objDataSave.find((o) => o === item.id);
      if (findobj) {
        return true;
      } else {
        return false;
      }
    },
    clickSelect(item) {
      item.is_selected = !item.is_selected;
      this.setDataForSave(item);
      this.objDataSave = [...new Set(this.objDataSave)];
    },
    setDataForSave(item) {
      if (item.is_selected) {
        this.objDataSave.push(item.id);
        this.objFromSearchData.push(item);
        this.objFromSearchData = this.objFromSearchData.filter(
          (obj, index) =>
            this.objFromSearchData.findIndex((item) => item.id === obj.id) ===
            index
        );
      } else {
        this.objDataSave = this.objDataSave.filter((data) => {
          return data != item.id;
        });
        this.objFromSearchData = this.objFromSearchData.filter((data) => {
          return data.id != item.id;
        });
      }
      this.objFromSearchData = [...new Set(this.objFromSearchData)];
      this.$forceUpdate();
    },
    checkSelect() {
      let itemselect = [];
      _.forEach(this.data, (role) => {
        if (role.is_selected) {
          itemselect.push(role.id);
        }
      });
      return itemselect;
    },
    checkDisablebtn() {
      let disable = true;
      if (this.objDataSave.length > 0) {
        if (!this.modaladdapps.isCopiesPerMission) {
          disable = false;
        } else if (
          this.modaladdapps.isCopiesPerMission &&
          this.objDataSave.length <= 200
        ) {
          disable = false;
        }
      }
      return disable;
    },
    checkSelectAll() {
      let itemselect = [];
      itemselect = this.checkSelect();
      return itemselect.length > 0 && itemselect.length == this.data.length;
    },
    checkShowItemSelect() {
      let showitem = false;
      if (
        this.modaladdapps.isAddApp &&
        !this.isshowskeletonmodal &&
        this.objFromSearchData.length > 0
      ) {
        showitem = true;
      }
      if (
        this.modaladdapps.isCopiesPerMission &&
        this.objFromSearchData.length > 0
      ) {
        showitem = true;
      }
      if (this.modaladdapps.isDeleteApp && this.objFromSearchData.length > 0) {
        showitem = true;
      }
      return showitem;
    },
    checkedIndeterminateCopy() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked != this.data.length;
    },
    changeSwitchAll(e) {
      let checked = e.target.checked;
      _.forEach(this.data, (item) => {
        item.is_selected = checked;
        this.setDataForSave(item);
      });
      this.objDataSave = [...new Set(this.objDataSave)];
      this.$forceUpdate();
    },
    checkWidht(item) {
      var test = document.getElementById(item);
      return test.offsetWidth;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.modal-addapps-new {
  color: #000;
  position: relative;
  .ant-modal-content {
    .ant-modal-body {
      max-height: inherit;
      overflow: hidden;
    }
  }
  .modal-addapps-top {
    margin-bottom: 16px;
    .modal-top-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .custom-show-label-copy,
    .custom-show-checkbox {
      padding-bottom: 16px;
      border-bottom: 1px solid $grey-grey-7;
    }
    .label-app-name {
      color: $color-primary;
    }
  }
  .modal-select-addapps {
    height: calc(100vh - 400px);
    overflow: auto;
    padding-bottom: 32px;
    .body-items {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 0 8px;
    }
    .item-app {
      width: 32.6%;
      padding: 8px;
      border: 1px solid $grey-grey-7;
      border-radius: 8px;
      color: $color-body;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      .label-app {
        display: flex;
        word-break: break-word;
      }
      .logo-custom {
        width: 40px;
        img {
          width: 35px;
        }
      }
      .label-item {
        width: calc(100% - 45px);
        line-height: 1.5;
        text-align: left;
      }
      .label-name-app {
        font-weight: 600;
        font-size: 14px;
      }
      .label-package {
        font-weight: normal;
        font-size: 12px;
        color: $grey-grey-5;
      }
    }
    .item-app:hover {
      border: 1px solid $color-primary;
    }
    .body-item-app {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    .item-app-copy {
      width: 250px;
      background: #fff;
      border: 1px solid #e8e8e8 !important;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      .label-product {
        display: flex;
      }
      .item-app-top {
        padding: 12px;
      }
      .item-app-bottom {
        padding: 12px;
        padding-bottom: 0;
        padding-left: 0;
        opacity: 0.7;
      }
      .label-package {
        font-size: 12px;
        opacity: 0.5;
      }
      .label-product {
        opacity: 0.8;
      }
    }
    .item-app-copy-active {
      border: 1px solid $color-primary !important;
      background: $color-white-f5e;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
    }
    .item-app-copy:hover {
      border: 1px solid $color-primary !important;
    }
    .item-app-active {
      border: 1px solid $color-primary;
      background: $color-white-f5e;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
    }
  }
  .modal-copyapp-body {
    padding: 16px;
    background: #f9f9f9;
    border: 1px solid #e8e8e8 !important;
  }
  .custome-switch {
    position: absolute;
    right: 8px;
    top: 3px;
  }
  .box-loader {
    position: absolute !important;
  }
  .label-max-item {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.6;
    margin-top: -17px;
  }
  .label-max-item-error {
    color: red;
  }
  .item-display-none {
    display: none;
  }
  .show-border-bottom {
    border-bottom: 1px solid #dcdddf;
    padding-bottom: 16px !important;
  }
}
</style>
