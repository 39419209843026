<template>
  <AppLayout :disable-menu="true">
    <div class="content-wrapper">
      <Unauthorized />
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import Unauthorized from "@/components/Auth/Unauthorized";

export default {
  name: "PageUnAuthorized",
  components: {
    AppLayout,
    Unauthorized,
  },
  metaInfo() {
    return {
      title: `Unauthorized - ${this.pageTitle}`,
    };
  },
  data() {
    return {};
  },
  created() {
    this.handleLoading(false);
  },
};
</script>
