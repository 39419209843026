import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      IConAdd: require("@/assets/images/Icon/Icon_Add.svg"),
      IConAddPrimary: require("@/assets/images/Icon/Icon_AddPrimary.svg"),
      IConAddBlue: require("@/assets/images/Icon/Icon_AddBlue.svg"),
      IConEdit: require("@/assets/images/Icon/Icon_Edit.svg"),
      IConAddUser: require("@/assets/images/Icon/Icon_Add-user.svg"),
      IConActive: require("@/assets/images/Icon/Icon_Active-circle.svg"),
      IConInctive: require("@/assets/images/Icon/Icon_Inctive-circle.svg"),
      IConInfo: require("@/assets/images/Icon/Icon_Info-circle.svg"),
      IConBookmark: require("@/assets/images/Icon/Icon_Bookmark.svg"),
      IConCross: require("@/assets/images/Icon/Icon_Cross.svg"),
      IConDelete: require("@/assets/images/Icon/Icon_Delete.svg"),
      IConDeleteWhite: require("@/assets/images/Icon/Icon_DeleteW.svg"),
      IConManage: require("@/assets/images/Icon/Icon_Manage.svg"),
      IConAngleLeft: require("@/assets/images/Icon/Icon_Angle-left.svg"),
      IConAngleLeftWhite: require("@/assets/images/Icon/Icon_Angle_left_white.svg"),
      IConAngleRight: require("@/assets/images/Icon/Icon_Angle-right.svg"),
      IConAngleRightWhite: require("@/assets/images/Icon/Icon_Angle_right_white.svg"),
      IConCompare: require("@/assets/images/Icon/Icon_Compare.svg"),
      IConCopy: require("@/assets/images/Icon/Icon_Copy.svg"),
      IConPolygon: require("@/assets/images/Icon/Icon_Polygon.svg"),
      IConArrowDown: require("@/assets/images/Icon/Icon_arrow-down.svg"),
      IConArrowNext: require("@/assets/images/Icon/Icon_Arrow_next.svg"),
      IConArrowDownPrimary: require("@/assets/images/Icon/Icon_arrow-down-primary.svg"),
      setHeightDefualt: 110,
      setWidthDefualt: 32,
      w_Input_350: "350px",
    };
  },
  computed: {
    localecode: {
      get() {
        return this.$store.state.localecode;
      },
    },
    lbls: {
      get() {
        return JSON.parse(
          localStorage.getItem("setJsonLocal_" + this.localecode)
        );
      },
      cache: false,
    },
  },
  methods: {
    truncate(num, places) {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    },
    handleLoading(value) {
      this.$store.commit("Loading", value);
    },
    // for ant select search option
    // filterOption(input, option) {
    //   console.log(input, option, "55");
    //   return (
    //     option.componentOptions.children[0].text
    //       .toLowerCase()
    //       .indexOf(input.toLowerCase()) >= 0
    //   );
    // },
    formatDate(timestamp, format = "DD-MMM-YYYY") {
      return moment.unix(timestamp).format(format);
    },
    // formatDateString(dateString, format = "DD-MMM-YYYY", calHours = null) {
    //   return moment(dateString).add(calHours, "hours").format(format);
    // },
    formatDateAM(dateString, format = "DD-MM-YYYY", calHours = null) {
      return moment(dateString).add(calHours, "hours").format(format);
    },
    formatDateString(
      dateString,
      format = "DD  MMM  YYYY - hh:mm A",
      calHours = 7
    ) {
      return moment(dateString).add(calHours, "hours").format(format);
    },
    formHasError(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field]);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    successResponse(text, router) {
      this.handleLoading(false);
      var texttitle = "";
      if (text) {
        texttitle = text;
      } else {
        texttitle = this.lbl.main.success_popup;
      }
      this.$success({
        title: texttitle,
        content: "",
        centered: true,
        autoFocusButton: null,
        closable: true,
        onOk: () => {
          if (typeof router !== "undefined") {
            if (!(typeof router === "boolean")) {
              this.$router.push(router);
              this.handleLoading(true);
            }
          } else {
            this.$router.go();
            this.handleLoading(true);
          }
        },
      });
    },
    parseInt(value) {
      if (typeof value === "string") {
        return parseInt(value);
      } else if (typeof value === "number") {
        return value;
      }
    },
    mylbl(path) {
      const myValue = _.get(
        this.lbls,
        path,
        " 'lbls." + path + "'" + " not found"
      );
      return myValue;
    },
    goToPage(pagename) {
      this.$router.push({
        name: pagename,
      });
    },
    goToPageAndQuery(pagename, query_id, query_name) {
      if (query_id && query_name) {
        this.$router.push({
          name: pagename,
          query: { id: query_id, name: query_name },
        });
      } else if (query_id) {
        this.$router.push({
          name: pagename,
          query: { id: query_id },
        });
      } else if (query_name) {
        this.$router.push({
          name: pagename,
          query: { name: query_name },
        });
      } else {
        this.$router.push({
          name: pagename,
        });
      }
    },
    goToNewTab(page, query_id) {
      let routeData = "";
      if (query_id) {
        routeData = this.$router.resolve({
          name: page,
          query: { id: query_id },
        });
      } else {
        routeData = this.$router.resolve({
          name: page,
        });
      }
      window.open(routeData.href, "_blank");
    },
    boxDetailHeight() {
      let boxdetail = document.querySelector("#box-detail");
      let boxdetailheight = boxdetail.offsetHeight;
      return boxdetailheight;
    },
    boxDetailWidth() {
      let boxdetail = document.querySelector("#box-detail");
      let boxdetailwidth = boxdetail.offsetWidth;
      return boxdetailwidth;
    },
    unFixedColumn(columns) {
      var last = columns[columns.length - 1];
      last = Object.keys(last)
        .filter((key) => !key.includes("fixed"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: last[key],
          });
        }, {});
      return last;
    },
    fixedColumn(columns) {
      var last = columns[columns.length - 1];
      last.fixed = "right";
      return last;
    },
    checkShowBtn(data) {
      let showbtn = false;
      let featurelists = this.$store.state.objfeaturelists;
      let findobj = undefined;
      if (featurelists.length > 0) {
        findobj = featurelists.find((o) => o.name === data);
        showbtn = findobj != undefined;
      }
      return showbtn;
    },
    handlePermissionPage(permissino_key, action_key) {
      let featuresCurrent = [];
      let page_current = this.$store.state.menu.filter((item) => {
        return item?.name == permissino_key;
      });
      if (page_current[0]?.page?.features) {
        let features = page_current[0]?.page?.features;
        features.forEach((elements) => {
          featuresCurrent.push(elements.name);
        });
      }
      if (action_key || featuresCurrent.length == 0) {
        let new_key = `${permissino_key}-${action_key}`;
        let is_view = featuresCurrent.includes(new_key);
        if (!is_view) {
          this.$router.push({
            name: "unauthorized",
          });
        }
      } else {
        return featuresCurrent;
      }
    },
    checkArrowPermission(key, list) {
      let is_arrow = false;
      if (key && list?.length > 0) {
        is_arrow = list.includes(key);
      }
      return is_arrow;
    },
  },
};
