<template>
  <a-modal
    v-model="isshow"
    width="85%"
    class="modal-sla"
    :closable="true"
    :centered="true"
    :mask-closable="false"
    :footer="null"
    @cancel="closeModal()"
  >
    <template slot="title">
      {{ dataSLA.title || "" }}
      <span class="last-date mt-1">
        ( Last Update :
        {{
          dataSLA.updated_date
            ? formatDateAM(dataSLA.updated_date, "DD/MMM/YYYY")
            : "-"
        }}
        )
      </span>
    </template>
    <div class="header-bg">
      <img :src="bgHeader" class="img" />
      <div class="center">
        <div class="title">Service Level Agreement</div>
        <div class="sub-title">of Buzzebees's service</div>
      </div>
    </div>
    <div class="content">
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="dataSLA.content || ''"></div>
    </div>
  </a-modal>
</template>
<script>
import BzbsAnnouncement from "@/core/service/BzbsAnnouncement.js";
import { config } from "@/config/bzbsconfig.js";

export default {
  name: "SLA",
  components: {},
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      dataSLA: {
        title: "",
        content: "",
      },
      isshow: this.isShow,
      bgHeader: config.themeUrl + "/img/bg_header_sla.png",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSLA();
    },
    getSLA() {
      this.dataSLA = {};
      return new Promise((resolve) => {
        BzbsAnnouncement.apiGetSLA()
          .then((res) => {
            if (res.data.result) {
              this.dataSLA = res.data.result;
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss">
.modal-sla {
  .ant-modal-body {
    max-height: 85vh !important;
  }
  .header-bg {
    position: relative;
    .img {
      height: 70px;
      width: 100%;
    }
    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      text-align: center;
    }
    .title {
      color: var(--primary-gold-bzb-gold-2);
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
    }
    .sub-title {
      color: var(--primary-gold-bzb-gold-4);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .content {
    padding: 20px;
  }
  .last-date {
    color: var(--grey-grey-5);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
