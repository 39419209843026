<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new flex-center-between">
        <PageBreadcrumb :datapagebreadcrumb="dataPageBreadcrumb" />
      </div>
      <div class="manage-setrole manage-page-detail">
        <PageHeader
          :datapageheader="dataPageHeader"
          @handleedit="handleEdit($event)"
        />
        <!-- <a-skeleton v-if="isShowSkeleton" class="mt-3 body-detail" /> -->
        <div class="setrole-body">
          <div v-if="dataPackages.length > 0" class="body-package">
            <div class="package-label">
              <span>Package :</span>
            </div>
            <div class="package-item">
              <span>
                <a-tabs
                  :default-active-key="dataPackages[0].package.id"
                  tab-position="top"
                  @prevClick="callback"
                  @nextClick="callback"
                  @change="callbacks"
                >
                  <a-tab-pane
                    v-for="item in dataPackages"
                    :key="item.package.id"
                    :tab="item.package.name"
                  >
                  </a-tab-pane>
                </a-tabs>
              </span>
            </div>
          </div>
          <div class="body-product">
            <div v-for="item in dataPackages" :key="item.package.id">
              <span v-if="tabActive == item.package.id">
                <div v-if="item.products">
                  <div
                    v-for="product in item.products"
                    :key="product.id"
                    class="product-items"
                  >
                    <BodyProduct
                      :dataproduc="product"
                      @handleclickmanage="handleClickManage($event, item)"
                      @handleback="handleBack($event)"
                      @handlesave="handleSaveProduct($event)"
                    />
                  </div>
                </div>
                <div v-if="item.applications" class="product-items">
                  <BodyApp
                    titlepage="Application"
                    :dataapp="item.applications"
                    :isedit="item.applications.isEdit"
                    @handleclickmanage="handleClickManage($event, item)"
                    @handleback="handleBack($event)"
                    @handlesave="handleSaveApp($event)"
                  />
                </div>
                <div v-if="item.cross_applications" class="product-items">
                  <BodyApp
                    titlepage="Cross Application"
                    :dataapp="item.cross_applications"
                    :isedit="item.cross_applications.isEdit"
                    @handleclickmanage="handleClickManage($event, item)"
                    @handleback="handleBack($event)"
                    @handlesave="handleSaveCrossApp($event)"
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import PageHeader from "@/module/Role/ManageSetRole/components/Layout/PageHeader.vue";
import BodyProduct from "@/module/Role/ManageSetRole/components/Layout/BodyProduct.vue";
import BodyApp from "@/module/Role/ManageSetRole/components/Layout/BodyApp.vue";
import PageBreadcrumb from "@/module/Role/ManageSetRole/components/Layout/PageBreadcrumb.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    PageHeader,
    BodyApp,
    BodyProduct,
    PageBreadcrumb,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      isShowSkeleton: true,
      data: [],
      dataRole: {},
      dataPackages: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      tabActive: "",
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      dataPageHeader: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.role_page.modal.btn.delete;
      this.titleModal = this.lbls.role_page.modal.title_confirm;
      this.contentModal =
        this.lbls.role_page.modal.content_delete + this.objSelect.name + " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataRole = res.data.result.role ? res.data.result.role : {};
            if (res.data.result.items) {
              let items = res.data.result.items;
              let dataPackages = [];
              _.forEach(items, (element) => {
                if (
                  element.products ||
                  element.applications ||
                  element.cross_applications
                )
                  dataPackages.push(element);
                if (element.products) {
                  _.forEach(element.products, (product) => {
                    product.isShow = true;
                    product.isEdit = false;
                  });
                }
                if (element.applications) {
                  element.applications.isShow = true;
                  element.applications.isEdit = false;
                }
                if (element.cross_applications) {
                  element.cross_applications.isShow = true;
                  element.cross_applications.isEdit = false;
                }
              });
              this.dataPackages = dataPackages;
              if (dataPackages && this.tabActive == "") {
                this.tabActive = this.dataPackages[0].package.id;
              }
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.openNotificationWithIcon("success");
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    handleSaveProduct(product) {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: this.subScription,
      };
      record.product_id = product.id;
      record.feature_ids = product.itemSelect;
      if (product.data_levels && product.valueRadio) {
        record.data_level_id = product.valueRadio;
      }
      record = JSON.stringify(record);
      this.callPostPermissionProduct(record);
    },
    handleSaveApp(item) {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: this.subScription,
      };
      record.application_ids = item.itemSelect;
      record = JSON.stringify(record);
      this.callPostPermissionApplication(record);
    },
    handleSaveCrossApp(item) {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: this.subScription,
      };
      record.cross_application_ids = item.itemSelect;
      record = JSON.stringify(record);
      this.callPostPermissionCross(record);
    },
    callPostPermissionProduct(record) {
      manageRoleAPI
        .postPermissionProduct(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostPermissionApplication(record) {
      manageRoleAPI
        .postPermissionApplication(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostPermissionCross(record) {
      manageRoleAPI
        .postPermissionCross(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    resetFieldOther() {
      this.valueDataLv = "";
      this.valueDataLvRadio = "";
      this.isShowListDatavl = false;
      this.listOfFeature = [];
      this.valuecheckbox = [];
      this.listOfDatalv = [];
      this.valuecheckboxApp = [];
      this.listOfFeatureApp = [];
      this.valuecheckboxCross = [];
      this.listOfFeatureCross = [];
      this.data.forEach((element) => {
        element.isEditShowCheckboxApp = false;
        element.isEditShowCheckboxCross = false;
        if (element.products) {
          if (element.products.length > 0) {
            element.products.forEach((products) => {
              products.isEditShowCheckbox = false;
              products.isShowListDatavl = false;
            });
          }
        }
      });
      let temp = this.data;
      this.data = [];
      this.data = temp;
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = true;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else {
        this.init();
      }
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
      }
    },
    callback(val) {
      console.log(val);
    },
    callbacks(val) {
      this.tabActive = val;
    },
    handleBack(item) {
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    handleBackrecord(event, item, valuename) {
      event.stopPropagation();
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      if (valuename == "app") {
        findpackage.applications = this.dataStrar;
        findpackage.applications.isShow = true;
      }
      if (valuename == "cross_app") {
        findpackage.cross_applications = this.dataStrar;
        findpackage.cross_applications.isShow = true;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    onChangesCheckbox(e, item) {
      let checked = e.target.checked;
      item.is_selected = checked;
      if (checked) {
        this.featureSelect.push(item.id);
      } else {
        this.featureSelect = this.featureSelect.filter((data) => {
          return data != item.id;
        });
      }
      this.$forceUpdate();
    },
    handleClickManage(value, item) {
      if (!value.isShow) value.isShow = true;
      this.dataStrar = _.cloneDeep(value);
      this.subScription = item.subscription.id;
      this.featureSelect = [];
      _.forEach(this.dataPackages, (element) => {
        if (element.products) {
          _.forEach(element.products, (product) => {
            product.isEdit = false;
          });
        }
        if (element.applications) {
          element.applications.isEdit = false;
        }
        if (element.cross_applications) {
          element.cross_applications.isEdit = false;
        }
      });
      // if (value.features) {
      //   _.forEach(value.features, (feature) => {
      //     if (feature.is_selected) {
      //       this.featureSelect.push(feature.id);
      //     }
      //   });
      // } else {
      //   _.forEach(value, (element) => {
      //     if (element.is_selected) {
      //       this.featureSelect.push(element.id);
      //     }
      //   });
      // }
      value.isEdit = !value.isEdit;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
#body_detail {
  border-top: 1px solid $grey-grey-7;
}
.manage-setrole {
  margin-top: 0px;
  height: calc(100vh - 130px);
  overflow: hidden;
  .setrole-header {
    background: #fff;
    .header-left {
      width: calc(100% - 120px);
      padding: 8px;
      line-height: 1.5;
      .lable-name {
        font-weight: 600;
        font-size: 18px;
      }
      .lable-desc {
        color: $grey-grey-5;
      }
    }
    .header-right {
      text-align: right;
      width: 120px;
      padding-right: 20px;
    }
  }
  .setrole-body {
    .body-package {
      display: flex;
      font-size: 14px;
      padding: 16px;
      padding-bottom: 16px;
      padding-right: 0;
      background: #fafafa;
      .package-label {
        width: 75px;
        border-bottom: 1px solid #e8e8e8;
        line-height: 3;
        font-weight: 600;
      }
      .package-item {
        width: calc(100% - 75px);
      }
    }
    .body-product {
      background: #fafafa;
      padding: 16px;
      padding-top: 0;
      line-height: 1.5;
      height: calc(100vh - 270px);
      overflow: auto;
      .product-items {
        background: #fff;
        margin-bottom: 8px;
        /* padding: 8px 16px 12px; */
        border: solid 1px #d9d9d9;
      }
      .product-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        /* padding-top: 4px;
        padding-bottom: 8px;
        margin-bottom: 8px; */
        font-size: 16px;
        .custome-icon {
          margin-top: -5px;
        }
      }
      .product-body {
        .feature-item {
          .feature-babel {
            opacity: 0.8;
          }
          .status-icon {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-top: -3px;
          }
          .active {
            background: #2eb506;
          }
          .inactive {
            background: #ff2d2d;
          }
        }
      }
    }
  }
  .ant-tabs-bar {
    margin: 0;
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .arrow-show-data {
    width: 12px;
    transform: rotate(0);
    margin-top: -3px;
  }
  .arrow-hide-data {
    transform: rotate(180deg);
    opacity: 0.25;
  }
  .custom-size-10 {
    width: 10px !important;
  }
  .btn-custom-cancel,
  .btn-custom-cancel:focus,
  .btn-custom-cancel:hover {
    padding: 2px 8px;
    width: 55px;
    border: solid 1px $color-primary !important;
    height: inherit;
    color: $color-primary !important;
    font-size: 12px;
  }
  .feature-list {
    border-top: 1px solid #d9d9d9;
    margin: 12px 16px;
    margin-top: 0;
    padding-top: 12px;
    .feature-item {
      display: inline-table;
      @include flexcol(12%);
      @include breakpoint-max($desktop-xxxl) {
        @include flexcol(20%);
      }
      @include breakpoint-max($desktop-xxl) {
        @include flexcol(25%);
      }
      @include breakpoint-max($desktop-xs) {
        @include flexcol(33.3%);
      }
      @include breakpoint-max($tablet-pro) {
        @include flexcol(50%);
      }
      @include breakpoint-max($tablet-xs) {
        @include flexcol(100%);
      }
    }
  }
  .checkbox-all {
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    margin-bottom: 14px;
  }
  .label-title {
    font-size: 12px;
    line-height: 1.5;
    color: $grey-grey-6;
  }
}
</style>
