import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getCompanyList
 */
export default {
  config: function () {
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  pathV2: function () {
    return (
      this.config().apiBaseUrl + this.config().apiVersion2 + "/account"
    );
  },
  postCreateOwnerAccount: async function (params) {
    var strUrl = this.pathV2() + "/create_account";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
};
