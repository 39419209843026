<template>
  <div class="page-layout page-redirect">
    <Loading id="appLoading" :is-show="true" :text="textprocess" />
  </div>
</template>
<script>
import * as Msal from "@azure/msal-browser";
import { config } from "@/config/bzbsconfig.js";
import Cache from "@/helper/Cache";
import RedirectmsAPI from "@/core/Page/RedirectmsAPI";
import Account from "@/helper/AccountHelper";
import Loading from "@/components/Loading/Loading.vue";
const myMSAL = new Msal.PublicClientApplication(config.msalConfig);
myMSAL.handleRedirectPromise().then((response) => {
  if (response) {
    handleResponse(response);
  }
});
function selectAccount() {
  const currentAccounts = myMSAL.getAllAccounts();
  if (currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId
          .toUpperCase()
          .includes(config.b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(config.b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === config.msalConfig.auth.clientId
    );
    if (accounts.length > 1) {
      if (
        accounts.every(
          (account) => account.localAccountId != accounts[0].localAccountId
        )
      ) {
        signOut();
      }
    }
  }
}
selectAccount();
async function handleResponse(response) {
  if (
    response.idTokenClaims["tfp"].toUpperCase() ===
    config.b2cPolicies.names.signUpSignIn.toUpperCase()
  ) {
    const date = new Date();
    date.setHours(date.getHours() + 24);
    var expires =
      "; expires=" + date.toUTCString() + "; path=/; SameSite=None; Secure;";
    document.cookie = "msToken=" + response.accessToken + expires;
    document.cookie = "msAccount=" + JSON.stringify(response.account) + expires;
    const msUser = {
      first_name: response.account.idTokenClaims.given_name,
      last_name: response.account.idTokenClaims.family_name,
      email: response.account.username,
    };
    document.cookie = "msUser=" + JSON.stringify(msUser) + expires;
    window.location = window.location.origin + "/redirectms";
  } else {
    selectAccount();
  }
}
function signIn() {
  myMSAL.loginRedirect(config.loginRequest);
}
function signOut() {
  const logoutRequest = {
    postLogoutRedirectUri: config.msalConfig.auth.redirectUri,
  };
  myMSAL.logoutRedirect(logoutRequest);
}
export default {
  name: "Redirectms",
  components: {
    Loading,
  },
  data() {
    return {
      isShowModal: false,
      errtransaction_id: "",
      titleModal: "",
      contentModal: "",
      idModal: "",
      textprocess: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.textprocess = "Process SSO ...";
      this.handleLoading(true);
      Cache.remove("logout");
      if (Cache.get("msToken")) {
        this.checkPermission();
      } else {
        signIn();
      }
    },
    checkPermission() {
      this.textprocess = "Process get permission ...";
      this.callGetCurrentAccount().then((result) => {
        console.log(result);
        if (result && (result.owner_account || result.working_account)) {
          const exAccount = {
            account: result.working_account ? result.working_account : null,
            owner_account: result.owner_account ? result.owner_account : null,
          };
          Cache.set("exAccount", exAccount);
          Account.getApplication().then((response) => {
            if (response.applications.length) {
              let applications = response.applications;
              let is_account_bzbs =
                exAccount?.account?.name.includes("Buzzebees Platform");
              let app_bzbs = [];
              if (is_account_bzbs && applications.length > 1) {
                app_bzbs = applications.filter((item) => {
                  return item.name == "Buzzebees";
                });
              }
              if (app_bzbs.length) {
                applications = app_bzbs;
              }
              Cache.set("app", applications[0].application_id);
              Account.callGetMenu(applications[0].application_id).then(() => {
                Account.setDefaultMenu();
              });
            } else {
              // Account.callGetMenu().then(() => {
              //   Account.callGetUserPermission(exAccount.account.id).then(() => {
              //     console.log("callGetMenu");
              //     Account.setDefaultMenuEx();
              //   });
              // });
              this.gotoUnauthorized();
            }
          });
        } else {
          this.gotoUnauthorized();
        }
      });
    },
    gotoUnauthorized() {
      Account.callGetMenu().then(() => {
        this.$router.push({
          name: "unauthorized",
        });
      });
    },
    callGetCurrentAccount() {
      return new Promise((resolve, reject) => {
        RedirectmsAPI.postCurrentAccount()
          .then((res) => {
            resolve(res.data.result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.hideModal {
  visibility: hidden;
  opacity: 0;
}
</style>
