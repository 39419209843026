import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Vue from "vue";
import Util from "@/helper/Utility.js";

/**
 * @method apiGetLastAnnouncement
 * @method apiGetListAnnouncement
 * @method apiReadAnnouncement
 * */

export default {
  config: function () {
    return Vue.bzbsConfig;
  },
  token: function () {
    return Cache.get("msToken");
  },
  apiGetLastAnnouncement: async function (params) {
    var url =
      this.config().apiAnnouncement +
      "/api/v1.0/announcement/get_last_announcement";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  apiGetListAnnouncement: async function (params) {
    var url =
      this.config().apiAnnouncement +
      "/api/v1.0/announcement/get_list_announcement";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  apiReadAnnouncement: async function (params) {
    var url =
      this.config().apiAnnouncement +
      "/api/v1.0/announcement/add_read_announcement";
    await Util.checkToken();
    return BzbsApi.connectBzbsPost(url, this.token(), params);
  },
  apiGetTermAndCondition: async function (params) {
    var url =
      this.config().apiAnnouncement +
      "/api/v1.0/announcement/get_term_and_condition";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  apiGetSLA: async function (params) {
    var url = this.config().apiAnnouncement + "/api/v1.0/announcement/get_sla";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
};
