<template>
  <div>
    <a-table
      id="campaign-list-table"
      row-key="id"
      class="objdata-table"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 331, x: 860 }"
      :pagination="false"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-app-id" slot-scope="text">
        <div>{{ text ? text : "-" }}</div>
      </template>
      <template slot="template-app-name" slot-scope="text">
        <div>{{ text ? text : "-" }}</div>
      </template>
      <template slot="template-name" slot-scope="text">
        <div>{{ text ? text : "-" }}</div>
      </template>
      <template slot="template-prefix" slot-scope="text">
        <div>{{ text ? text : "-" }}</div>
      </template>
      <template slot="template-edit" slot-scope="text, record">
        <div class="action-list">
          <a-tooltip :disabled="!isPermissionDelete" placement="top">
            <template slot="title">
              {{ lbls.user_page.btn.edit_role }}
              {{ lbls.applicationaccount_page.btn.delete }}
            </template>
            <a @click="handleDelete(record)">
              <a-icon type="delete" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "cross",
      innerHeight: 0,
      lastscrollTop: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      isFilters: false,
      columns: [
        {
          title: "Application ID",
          keys: "tbl_colum_application_id",
          dataIndex: "id",
          width: "300px",
        },
        {
          title: "Application Name",
          keys: "tbl_colum_application_name",
          dataIndex: "app_name",
          width: "300px",
          sorter: (a, b) => a.app_name.localeCompare(b.app_name),
          scopedSlots: { customRender: "template-app-name" },
        },
        {
          title: "Subscription",
          keys: "tbl_colum_name",
          width: "250px",
          dataIndex: "name",
          sorter: (a, b) => a.name.localeCompare(b.name),
          scopedSlots: { customRender: "template-name" },
        },
        {
          title: "Prefix",
          keys: "tbl_colum_prefix",
          width: "120px",
          dataIndex: "prefix",
          sorter: (a, b) => a.prefix.localeCompare(b.prefix),
          scopedSlots: { customRender: "template-prefix" },
        },
        {
          title: "",
          width: "65px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionDelete: true,
    };
  },
  // watch: {
  //   datatable() {
  //     this.$forceUpdate();
  //   },
  // },
  watch: {
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionDelete = this.checkArrowPermission(
            "cross-existing-delete",
            permission_page
          );
        }
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.applicationaccount_page[column.keys];
        }
      });
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleCreate(record) {
      this.$emit("handlecreate", record);
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    handleDelete(record) {
      this.$emit("handledelete", record);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.features-custom {
  ul {
    padding-inline-start: 15px;
    margin-block-end: 2px;
  }
  .show-hide-custom {
    margin-top: 8px;
    cursor: pointer;
    color: #00a3ff;
  }
}
</style>
