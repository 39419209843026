<template>
  <div>
    <div class="product-header" @click="toggles(dataapp)">
      <div>
        <span class="mr-2">
          <img
            class="arrow-show-data custom-size-10"
            :class="{
              'arrow-hide-data': dataapp.isShow,
            }"
            :src="IConArrowDown"
          />
        </span>
        <span>{{ titlepage }}</span>
      </div>
      <div v-if="isedit" class="custome-icon">
        <span>
          <a-button
            class="btn-custom-cancel"
            @click="handleBack($event, dataapp)"
          >
            {{ lbls.role_page.btn.cancel }}
          </a-button>
        </span>
        <span>
          <a-button
            type="primary"
            class="btn-custom-save"
            @click="handleSave($event, dataapp)"
          >
            {{ lbls.role_page.btn.save }}
          </a-button>
        </span>
      </div>
      <div
        v-else
        class="custome-icon"
        @click="handleClickManage($event, dataapp)"
      >
        <a-icon type="setting" />
      </div>
    </div>
    <div v-if="dataapp.isShow" class="product-body feature-list">
      <div v-if="isedit">
        <div class="checkbox-all">
          <a-checkbox
            :checked="setCheckAll(data)"
            :indeterminate="setIndeterminate(data)"
            @change="onChangAllCheckBox($event, data)"
          >
            <span class="title-checkbox-all"> Check all </span>
          </a-checkbox>
        </div>
        <a-checkbox
          v-for="app in dataapp"
          :key="app.id"
          :checked="app.is_selected"
          class="feature-item"
          @change="onChangesCheckbox($event, app)"
        >
          <span>{{ app.name }}</span>
        </a-checkbox>
      </div>
      <div v-else>
        <div v-for="app in dataapp" :key="app.id" class="feature-item">
          <div class="d-flex">
            <div>
              <span
                class="status-icon active"
                :class="{ inactive: !app.is_selected }"
              >
              </span>
            </div>
            <div class="feature-babel ml-2">
              {{ app.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "PageBreadcrumb",
  props: {
    dataapp: {
      type: Array,
    },
    isedit: {
      type: Boolean,
    },
    titlepage: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      itemSelect: [],
    };
  },
  watch: {
    dataapp() {
      this.data = this.dataapp;
      this.$forceUpdate();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.dataapp;
    },
    toggles(value) {
      if (value.isEdit) return;
      value.isShow = !value.isShow;
      this.$forceUpdate();
    },
    handleClickManage(event, item) {
      event.stopPropagation();
      this.itemSelect = [];
      _.forEach(item, (element) => {
        if (element.is_selected) {
          this.itemSelect.push(element.id);
        }
      });
      this.$emit("handleclickmanage", item);
      this.$forceUpdate();
    },
    handleBack(event, item) {
      event.stopPropagation();
      this.$emit("handleback", item);
      this.$forceUpdate();
    },
    handleSave(event, item) {
      event.stopPropagation();
      item.itemSelect = this.itemSelect;
      this.$emit("handlesave", item);
    },
    onChangesCheckbox(e, item) {
      this.itemSelect = [];
      let checked = e.target.checked;
      item.is_selected = checked;
      if (checked) {
        this.itemSelect.push(item.id);
      } else {
        this.itemSelect = this.itemSelect.filter((data) => {
          return data != item.id;
        });
      }
      this.$forceUpdate();
    },
    setCheckAll(data) {
      return this.itemSelect.length == data.length;
    },
    setIndeterminate(data) {
      return (
        this.itemSelect.length > 0 && this.itemSelect.length != data.length
      );
    },
    onChangAllCheckBox(e, data) {
      let checked = e.target.checked;
      console.log(data, "data");
      console.log(checked, "checked");
      this.itemSelect = [];
      if (checked) {
        _.forEach(data, (element) => {
          element.is_selected = true;
          this.itemSelect.push(element.id);
        });
        this.$forceUpdate();
      } else {
        _.forEach(data, (element) => {
          element.is_selected = false;
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
