import Vue from "vue";
export default {
  /**
   *
   * @method get
   * @method set
   * @method remove
   * @method removePopCache
   *
   */

  get: function (key) {
    return Vue.$cookies.get(key) || null;
  },
  set: function (key, value) {
    Vue.$cookies.set(key, value, "1d", "/", "", true, "None", false);
  },
  remove: function (key) {
    Vue.$cookies.remove(key);
  },
  removeAll: async function () {
    const date = new Date();
    date.setHours(date.getHours() + 24);
    let expires =
      "=; max-age=-1; expires=" +
      date.toUTCString() +
      " path=/; SameSite=None; Secure";
    await document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + expires;
    });
  },
};
