<template>
  <div>
    <a-list
      :grid="{ gutter: 16, column: 4 }"
      :data-source="data"
      :pagination="false"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-card hoverable class="card-app">
          <div style="height: 350px; overflow: auto">
            <div class="box-data">
              <div
                style="
                  display: flex;
                  margin-bottom: 25px;
                  justify-content: space-between;
                "
              >
                <div>
                  <div class="title">
                    {{ mylbl("application_page.tbl_colum_name") }}
                  </div>
                  <div class="name">{{ item.application.name }}</div>
                </div>
                <div
                  style="
                    margin-right: 7px;
                    margin-top: 7px;
                    min-width: 85px;
                    text-align: end;
                  "
                >
                  <span
                    class="status-active"
                    :class="{ inactive: !item.application.is_active }"
                  >
                    {{
                      item.application.is_active === true
                        ? "Active"
                        : "Inactive"
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="box-data">
              <div class="title">
                {{ mylbl("application_page.tbl_colum_description") }}
              </div>
              <div class="name">{{ item.application.description }}</div>
            </div>
            <div class="box-data">
              <div class="title">
                {{ mylbl("application_page.tbl_colum_package") }}
              </div>
              <div class="name">
                {{
                  item.package
                    ? item.package.name
                      ? item.package.name
                      : "-"
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div class="button">
            <a-button class="btn-custom-view" @click="handleEdit(item)">
              {{ mylbl("application_page.btn.viewmore") }}
            </a-button>
          </div>
        </a-card>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "ManageProduct",
  components: {},
  props: {
    datalist: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      innerHeight: 0,
      lastscrollTop: 0,
      searchInput: null,
      data: [],
      dataStart: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datalist;
      this.dataStart = this.datalist;
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.mylbl("application_page." + [column.keys]);
        }
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
