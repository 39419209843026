var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{staticClass:"objdata-table",attrs:{"id":"campaign-list-table","row-key":"id","columns":_vm.columns,"data-source":_vm.data,"scroll":{ y: _vm.innerHeight - 331, x: 860 },"pagination":false},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block","font-size":"12px"},attrs:{"placeholder":`${_vm.lbls.btn.search}${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleTableSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary"},on:{"click":() => _vm.handleTableSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" "+_vm._s(_vm.lbls.btn.search)+" ")]),_c('a-button',{staticStyle:{"width":"90px"},on:{"click":() => _vm.handleTableReset(clearFilters)}},[_vm._v(" "+_vm._s(_vm.lbls.btn.reset)+" ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"template-app-id",fn:function(text){return [_c('div',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"template-app-name",fn:function(text){return [_c('div',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"template-name",fn:function(text){return [_c('div',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"template-prefix",fn:function(text){return [_c('div',[_vm._v(_vm._s(text ? text : "-"))])]}},{key:"template-edit",fn:function(text, record){return [_c('div',{staticClass:"action-list"},[_c('a-tooltip',{attrs:{"disabled":!_vm.isPermissionDelete,"placement":"top"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.user_page.btn.edit_role)+" "+_vm._s(_vm.lbls.applicationaccount_page.btn.delete)+" ")]),_c('a',{on:{"click":function($event){return _vm.handleDelete(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],2)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }