<template>
  <div class="page-layout page-logout"></div>
</template>
<script>
import Account from "@/helper/AccountHelper";

export default {
  name: "LogoutAd",
  created() {
    this.init();
  },
  methods: {
    init() {
      Account.logout();
    },
  },
};
</script>
