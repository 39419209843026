<template>
  <div class="col-12 d-md-flex justify-content-between align-items-center mb-3">
    <div class="col-lg-6 col-md-6 col-sm-12 text mb-4 mb-md-0 pl-0 pr-0">
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.user_page.input_search"
        class="input-search"
        size="large"
        @blur="onSearch"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
    </div>
    <div
      class="col-lg-6 col-md-6 col-sm-12 button-action-list d-flex justify-content-end box-action-campaignlist pl-0 pr-0"
    >
      <a-button
        type="primary"
        ghost
        :disabled="!isPermissionInviteView"
        @click="goToPage('InviteUsersDetail')"
      >
        <a-icon type="user-add" />
        {{ lbls.user_page.btn.invitation }}
      </a-button>
      <a-button
        class="ml-3"
        type="primary"
        :disabled="!isPermissionInvite"
        @click="goToPage('InviteUsersMail')"
      >
        <a-icon type="plus-circle" />
        {{ lbls.user_page.btn.invite_user }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    datapageheader: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "user",
      data: {},
      searchData: "",
      isSearchData: false,
      isPermissionInvite: true,
      isPermissionInviteView: true,
    };
  },
  watch: {
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapageheader;
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionInvite = this.checkArrowPermission(
            "user-invite",
            permission_page
          );
          this.isPermissionInviteView = this.checkArrowPermission(
            "user-invite-view",
            permission_page
          );
        }
      }
    },
    handleCreate() {
      this.$emit("handlecreate");
    },
    onSearch() {
      this.isSearchData = this.searchData != "";
      this.$emit("onsearch", this.searchData);
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
