<template>
  <div class="page-404 py-4 text-center">
    <!-- <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/bzbs-logo1.png"
      /></router-link>
    </div> -->
    <div class="image-404">
      <img src="@/assets/images/error-config-banner.svg" />
    </div>
    <div class="box-show-error">
      <div class="show-error-subject">Configuration Error</div>
      <div class="show-error-title">Sorry, some config does not exist.</div>
      <div class="show-error-label">
        <div v-if="isApiSetting" class="error-box">
          <ul class="config-list">
            <strong>Please check Api Setting</strong>
            <li v-for="(item, index) in apiSetting" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div v-if="isMsalConfig" class="error-box">
          <ul class="config-list">
            <strong>Please check MSAL Config</strong>
            <li v-for="(item, index) in msalConfig" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorConfig",
  data() {
    return {
      apiSetting: null,
      msalConfig: null,
      isApiSetting: false,
      isMsalConfig: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.handleLoading(false);
    this.qsSearch = this.$route.query;
    this.init();
  },
  methods: {
    init() {
      if (this.$route) {
        if (this.$route.params) {
          if (Object.keys(this.$route.params).length > 0) {
            if (this.$route.params.APISetting) {
              this.apiSetting = this.$route.params.APISetting;
              if (Object.keys(this.$route.params.APISetting).length > 0) {
                this.isApiSetting = true;
              } else {
                this.isApiSetting = false;
              }
            }
            if (this.$route.params.MSALConfig) {
              this.msalConfig = this.$route.params.MSALConfig;
              if (Object.keys(this.$route.params.MSALConfig).length > 0) {
                this.isMsalConfig = true;
              } else {
                this.isMsalConfig = false;
              }
            }
          } else {
            this.$router.push({
              name: "Login",
            });
          }
        }
      }
    },
    handleLoading(value) {
      this.$store.commit("Loading", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.page-404 {
  .logo {
    img {
      width: 100px;
    }
  }
  .image-404 {
    img {
      max-width: 650px;
    }
  }
  .box-show-error {
    .show-error-subject {
      line-height: 1.2;
      font-size: 22px;
    }
    .show-error-title {
      color: rgba(0, 0, 0, 0.45);
      font-size: 18px;
      line-height: 1.6;
    }
    .show-error-label {
      margin-top: 20px;
      font-size: 18px;
      line-height: 1.5;
      color: red;
      .error-box {
        display: flex;
        justify-content: center;
      }
      .config-list {
        width: 580px;
        padding: 16px;
        border: 1px solid;
        border-radius: 6px;
        text-align: left;
        li {
          margin-left: 20px;
          line-height: 1.2;
        }
      }
    }
  }
}
</style>
