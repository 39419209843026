<template>
  <a-layout-header
    :class="[{ collapsed: iscollapsed }, $store.state.themecolor]"
  >
    <div class="row mx-0">
      <div class="col-6 pl-0">
        <div class="action-col d-sm-block d-flex align-items-center">
          <a-select
            v-if="appList && $route.params.appid"
            :default-value="$route.params.appid"
            class="app-select"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in appList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end pr-0">
        <div class="action-col d-flex">
          <a-dropdown v-if="user" :trigger="['click']" class="dropdownpad">
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <div class="account-text">
                <span class="account-name">
                  {{
                    exAccount?.account
                      ? `${user.first_name} ${user.last_name} | ${exAccount.account.name}`
                      : `${user.first_name} ${user.last_name} `
                  }}
                </span>
                <span class="account-role">
                  {{ user.email }}
                </span>
              </div>
              <i class="ibzb-down"></i>
            </a>
            <a-menu slot="overlay" class="header-dropdown">
              <a-menu-item v-if="exAccount">
                <a @click="switchaccount">
                  <a-icon type="swap" />
                  <span class="ml-2">{{
                    lbls.menu.dropdown_account.switchaccount
                  }}</span>
                </a>
              </a-menu-item>
              <a-menu-item-group title="Language">
                <a-menu-item key="language:en">
                  <a
                    :class="{ active: localecode == 'en' }"
                    @click="switchLocale('en')"
                  >
                    <img :src="img_Flag_En" />
                    <span class="text">English</span>
                  </a>
                </a-menu-item>
                <a-menu-item v-if="isWebTh" key="language:th">
                  <a
                    :class="{ active: localecode == 'th' }"
                    @click="switchLocale('th')"
                  >
                    <img :src="img_Flag_Th" />
                    <span class="text">ภาษาไทย</span>
                  </a>
                </a-menu-item>
              </a-menu-item-group>
              <a-menu-item class="logout">
                <a @click="logout">
                  <span class="text">Sign Out</span>
                  <img :src="img_logout" />
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <div v-else>
            <router-link :to="{ name: 'login' }" class="color-primary">
              {{ lbl.btn.login }}
            </router-link>
          </div>
          <a
            v-if="showFuncAnnouncement"
            class="announcement"
            @click="openAllAnnouncement"
          >
            <a-badge :count="sumUnRead">
              <span class="anticon">
                <i class="ibzb-noti"></i>
              </span>
            </a-badge>
          </a>
        </div>
      </div>
    </div>
    <ModalAnnouncement
      v-if="showModalEmergency"
      :is-show="showModalEmergency"
      :data="dataEmergency"
      @closeModalAnnouncement="closeModalAnnouncement('Emergency')"
      @checkBox="checkBox($event)"
    />
    <ModalAnnouncement
      v-if="showModalAnnouncement"
      :is-show="showModalAnnouncement"
      :data="dataAnnouncement"
      @closeModalAnnouncement="closeModalAnnouncement('Announcement')"
      @checkBox="checkBox($event)"
    />
    <a-modal
      v-model="showModalAnnouncementList"
      width="600px"
      class="modal-announcement-list modal-list"
      :closable="false"
      :centered="false"
      :mask="false"
      :mask-closable="true"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { display: 'none' } }"
      @cancel="closeModal"
    >
      <ModalAnnouncementList
        :data="dataListAnnouncement"
        :sum-read="sumRead"
        :sum-un-read="sumUnRead"
        @getAllAnnouncement="getAllListAnnouncement($event)"
      />
    </a-modal>
    <div
      v-if="showEmergency"
      class="alert-emergency"
      @click="showEmergencyDetail()"
    >
      <span class="text">
        <img :src="img_info" alt="info" class="icon" />
        {{ this.dataEmergency.title }}</span
      >
    </div>
    <!-- TermAndCondition -->
    <ModalTermAndCondition
      v-if="showModalTerm"
      :is-show="showModalTerm"
      :data="dataTerm"
      @closeModal="closeModalTermAndCondition()"
      @checkBox="checkBoxTermAndCondition($event)"
    >
    </ModalTermAndCondition>
  </a-layout-header>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import Account from "@/helper/AccountHelper";
import Cache from "@/helper/Cache";
import { config } from "@/config/bzbsconfig.js";
import Locale from "@/helper/locale/locale.js";
import ModalAnnouncement from "@/components/Layout/Header/ModalAnnouncement.vue";
import ModalAnnouncementList from "@/components/Layout/Header/ModalListAnnouncement.vue";
import ModalTermAndCondition from "@/components/Layout/Header/ModalTermAndCondition.vue";
import BzbsAnnouncement from "@/core/service/BzbsAnnouncement.js";

export default {
  name: "Header",
  components: {
    ModalAnnouncement,
    ModalAnnouncementList,
    ModalTermAndCondition,
  },
  props: {
    appList: Array,
    iscollapsed: Boolean,
  },
  data() {
    return {
      img_Flag_En: config.themeUrl + "/img/flag_en.svg",
      img_Flag_Th: config.themeUrl + "/img/flag_th.svg",
      img_logout: config.themeUrl + "/img/logout.svg",
      img_logo: config.themeUrl + "/img/logo-icon.svg",
      img_info: config.themeUrl + "/img/info.svg",
      showBtnAddMS: true,
      dataEmergency: {},
      dataListAnnouncement: {},
      showEmergency: false,
      showModalAnnouncement: false,
      showModalEmergency: false,
      conditionsCheckBox: false,
      showFuncAnnouncement: false,
      showModalAnnouncementList: false,
      sumRead: 0,
      sumUnRead: 0,
      interval: null,
      isWebTh: config.businessRule.webFor.TH,
      dataTerm: {},
      showModalTerm: false,
      checkBoxTerm: false,
    };
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed;
      },
      set(newValue) {
        this.$store.commit("setCollapsed", newValue);
      },
    },
    user: {
      get() {
        return Cache.get("msUser");
      },
    },
    exAccount: {
      get() {
        return Cache.get("exAccount");
      },
    },
    localecode: {
      get() {
        return this.$store.state.localecode;
      },
      set(newValue) {
        this.$store.commit("setLocalecode", newValue);
      },
    },
  },
  created() {
    let iswebForTH = config.businessRule.webFor.TH;
    let isUnauthorized = window.location.pathname == "/unauthorized";
    this.showFuncAnnouncement = iswebForTH && !isUnauthorized;
    this.getTermAndCondition();
    if (iswebForTH) {
      this.getAllAnnouncement();
    }
    if (
      window.location.pathname != "/unauthorized" &&
      config.businessRule.webFor.TH
    ) {
      this.showFuncAnnouncement = true;
      this.getAllAnnouncement();
    }
    if (
      !config.businessRule.webFor.TH ||
      window.location.pathname == "/unauthorized"
    ) {
      this.showEmergency = false;
      this.showFuncAnnouncement = false;
      this.$store.commit("showEmergency", this.showEmergency);
    }
  },
  methods: {
    getAccountMenuList() {
      return [];
    },
    logout() {
      this.handleLoading(true);
      setTimeout(() => {
        Account.logout();
      }, 500);
    },
    gotoSelectZone() {
      this.$router.push({ name: "signinzone" });
    },
    handleChange(e) {
      this.$router.replace({
        name: this.$route.name,
        params: { appid: e },
      });
      location.reload();
    },
    gotoMSflow() {
      Cache.set("redirectUrl", this.$route.path);
      this.$router.push({ name: "redirect-ms" });
    },
    switchLocale(langcode) {
      this.localecode = langcode;
      Cache.set("BzbsLocale", langcode);
      Locale.getLbl();
    },
    // setMenuLocale() {
    //   return new Promise((resolve, reject) => {
    //     BzbsUserManage.apiGetMenu()
    //       .then((res) => {
    //         if (res.data.result.length > 0) {
    //           localStorage.setItem("menu", JSON.stringify(res.data.result));
    //           if (localStorage.getItem("menu")) {
    //             this.menu = JSON.parse(localStorage.getItem("menu"));
    //           }
    //           resolve(res.data.result);
    //         }
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    switchaccount() {
      this.$emit("switchaccount");
    },
    createOwnerAccount() {
      window.location = window.location.origin + "/createaccount";
    },
    showEmergencyDetail() {
      this.showModalEmergency = true;
    },
    getAllAnnouncement() {
      return new Promise((resolve) => {
        Promise.all([
          this.getLastAnnouncement(),
          this.getLastAnnouncement(true),
        ]).then(() => {
          this.getAllListAnnouncement().then(() => {
            resolve();
          });
        });
      });
    },
    getLastAnnouncement(hasEmergency) {
      return new Promise((resolve, reject) => {
        let params = {};
        if (hasEmergency) {
          params.is_emergency = true;
        } else {
          if (
            this.$store.state.isCallAnnouncement ||
            Cache.get("callAnnouncement") == moment().format("YYYY-MM-DD")
          ) {
            resolve();
            return;
          }
        }
        BzbsAnnouncement.apiGetLastAnnouncement(params)
          .then((res) => {
            if (hasEmergency) {
              this.dataEmergency = res.data.result.announcement;
              let is_not_unauthorized =
                window.location?.pathname != "/unauthorized";
              if (this.dataEmergency && is_not_unauthorized) {
                this.showEmergency = true;
              } else {
                this.showEmergency = false;
              }
              this.$store.commit("showEmergency", this.showEmergency);
            } else {
              this.dataAnnouncement = res.data.result.announcement;
              this.$store.commit("callAnnouncement", true);
              if (this.dataAnnouncement) {
                this.interval = setInterval(() => {
                  if (!this.$store.state.isAppLoad) {
                    this.showModalAnnouncement = true;
                    Cache.set(
                      "callAnnouncement",
                      moment().format("YYYY-MM-DD")
                    );
                    clearInterval(this.interval);
                  }
                }, 3000);
                this.$store.state.isAppLoad;
              } else {
                this.showModalAnnouncement = false;
              }
            }
            resolve();
          })
          .catch((error) => {
            this.showEmergency = false;
            this.$store.commit("showEmergency", this.showEmergency);
            reject(error);
          });
      });
    },
    getAllListAnnouncement(event) {
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiGetListAnnouncement()
          .then((res) => {
            this.dataListAnnouncement = res.data.result;
            var Read = _.filter(this.dataListAnnouncement, (item) => {
              if (!item.is_unread) {
                return item;
              }
            });
            var UnRead = _.filter(this.dataListAnnouncement, (item) => {
              if (item.is_unread) {
                return item;
              }
            });
            this.sumRead = Read.length;
            this.sumUnRead = UnRead.length;
            if (event) {
              this.getLastAnnouncement(true);
            }
            resolve(res.data.result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTermAndCondition() {
      this.dataTerm = {};
      this.showModalTerm = false;
      return new Promise((resolve) => {
        BzbsAnnouncement.apiGetTermAndCondition()
          .then((res) => {
            if (res.data.result) {
              this.dataTerm = res.data.result;
              this.handleLoading(false);
              setTimeout(() => {
                this.showModalTerm = true;
              });
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    checkBoxTermAndCondition(value) {
      this.checkBoxTerm = value;
    },
    closeModalTermAndCondition() {
      return new Promise((resolve, reject) => {
        let params = {
          id: this.dataTerm.id,
          is_accept: this.checkBoxTerm,
        };
        BzbsAnnouncement.apiReadAnnouncement(params)
          .then(() => {
            this.checkBoxTerm = false;
            this.showModalTerm = false;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    closeModalAnnouncement(type) {
      return new Promise((resolve, reject) => {
        let params = {
          id:
            type == "Emergency"
              ? this.dataEmergency.id
              : this.dataAnnouncement.id,
          is_accept: this.conditionsCheckBox,
        };
        BzbsAnnouncement.apiReadAnnouncement(params)
          .then(() => {
            if (type == "Emergency") {
              this.showModalEmergency = false;
              this.getLastAnnouncement(true);
            } else {
              this.showModalAnnouncement = false;
            }
            this.conditionsCheckBox = false;
            this.getAllListAnnouncement();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkBox(value) {
      this.conditionsCheckBox = value;
    },
    openAllAnnouncement() {
      this.showModalAnnouncementList = true;
    },
    closeModal() {
      this.showModalAnnouncementList = false;
      this.getAllListAnnouncement();
    },
  },
};
</script>
