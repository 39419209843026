<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <span class="label-menu"> {{ lbls.menu.assignrolemanagement }}</span>
        <span> <a-divider type="vertical" /></span>
        <span
          class="label-name"
          role="button"
          @click="goToPage('manageroles')"
          >{{ lbls.menu.rolemanagement }}</span
        >
        <span class="next"> >> </span>
        <span class="label-page"> {{ lbls.role_page.setrole.title_page }}</span>
        <span class="ml-2">
          <a-badge
            :count="datapagebreadcrumb.totalList"
            :class="'count-list'"
            :overflow-count="999"
            show-zero
          />
        </span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "PageBreadcrumb",
  props: {
    datapagebreadcrumb: {
      type: Object,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapagebreadcrumb;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
