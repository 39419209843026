<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-custom-email"
      :mask-closable="false"
      :closable="false"
      @cancel="handleClose()"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
          <span v-if="modalprocess.isEdit" class="float-right">
            <span class="title-status">
              {{ lbls.user_page.tbl_colum_status }}
            </span>
            <span>
              <a-tooltip>
                <template slot="title">
                  <span v-if="objDataSave.is_active">
                    {{ lbls.user_page.title_status_active }}
                  </span>
                  <span v-else>
                    {{ lbls.user_page.title_status_inactive }}
                  </span>
                </template>
                <a-switch
                  :disabled="objDataSave.is_owner_account"
                  :checked="objDataSave.is_active"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </span>
          </span>
        </div>
      </template>
      <div v-if="modalprocess.isEdit" class="box-show-data-edit">
        <div
          v-if="objDataSave.is_owner_account"
          class="owner-account"
          style="text-align: center; margin-bottom: 10px; font-weight: 600"
        >
          <span>{{ lbls.user_page.tbl_owner_user }}</span>
        </div>
        <a-form :form="form" layout="vertical" @submit="handleSave">
          <a-row>
            <a-col :span="12">
              <div class="text-caption">
                {{ lbls.user_page.tbl_colum_email }}
              </div>
              <div class="font-weight-bold">{{ objDataSave.email }}</div>
              <div class="text-caption mt-2">
                {{ lbls.user_page.tbl_colum_name }}
              </div>
              <div class="font-weight-bold">{{ objDataSave.display_name }}</div>
              <div class="text-caption mt-2">
                {{ lbls.user_page.tbl_colum_fullname }}
              </div>
              <div class="font-weight-bold">{{ objDataSave.full_name }}</div>
            </a-col>
            <a-col :span="12" class="pl-3">
              <div v-if="modalprocess.isShowRole">
                <div class="text-caption">
                  {{ lbls.user_page.tbl_colum_role }}
                  ({{ objDataSave.roles.length }})
                </div>
                <div>
                  <div
                    v-for="(tag, index) in objDataSave.roles"
                    :key="tag.id"
                    class="font-weight-bold"
                  >
                    {{ index + 1 }}. {{ tag.name }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
// import XLSX from "xlsx";
import Cache from "@/helper/Cache.js";
export default {
  name: "ModalProcessUser",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      maxLengthName: 100,
      maxLengthFullName: 100,
      maxLengthEmail: 100,
      disabledok: true,
      objDataSave: {},
    };
  },
  created() {
    this.exAccount = Cache.get("exAccount");
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = this.dataobjselect;
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.objDataSave);
    },
    changeSwitch(checked) {
      this.disabledok = true;
      this.objDataSave.is_active = checked;
      this.checkBTN();
    },
    hasErrors(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(field) && getFieldError(field);
    },
    checkBTN() {
      if (this.modalprocess.isEdit) {
        // Check Data
        let newData = {
          is_active: this.objDataSave.is_active,
        };
        newData = JSON.stringify(newData);
        if (newData != this.dataobjselect.dataEditForCheck) {
          this.disabledok = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.modal-custom-email {
  .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-modal-content .ant-modal-body {
    padding: 0;
  }
  .anticon {
    vertical-align: 0;
  }
  .ant-steps-finish-icon {
    svg {
      margin-top: -3px;
    }
  }
  .ant-empty-description {
    opacity: 0.5;
  }
  .ant-steps-item-content {
    .ant-steps-item-title {
      line-height: 1;
    }
  }
  .box-show-data-edit {
    padding: 10px 25px;
  }
  .data-role {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .data-role-item {
      width: 380px;
    }
  }
  .title-role-name {
    color: $grey-grey-2;
    font-weight: 600;
  }
}
</style>
