<template>
  <div class="show-title-total-item">
    <span v-if="totallist > 0">
      <span>Showing</span>
      <span class="font-weight-bold"> 1 </span>
      <span>to </span>
    </span>
    <span class="font-weight-bold">{{ totallist }} </span>
    <span v-if="!conditiontable.isFilters">items</span>
    <span
      v-if="conditiontable.hasMoreData && !conditiontable.isFilters"
      class="title-load-more"
      @click="loadMore()"
    >
      Load More
    </span>
    <!-- <span
      v-if="conditiontable.hasMoreData && !conditiontable.isFilters"
      class="ml-1"
    >
      <a-select
        v-model="items"
        size="small"
        style="width: 60px"
        @change="handleChange"
      >
        <a-select-option v-for="item in listItems" :key="item">
          {{ item }}
        </a-select-option>
      </a-select>
    </span> -->
    <span v-if="conditiontable.isFilters">
      <span> out of </span>
      <span class="font-weight-bold">{{ data.length }}</span>
      <span> items </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "ShowTotal",
  
  props: {
    datashowtitle: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
    pagesize: {
      type: Number,
    },
    totallist: {
      type: Number,
    },
  },
  data() {
    return {
      data: [],
      hasMoreData: false,
      items: 25,
      listItems: [25, 50, 100],
    };
  },
  watch: {
    datashowtitle() {
      this.data = this.datashowtitle;
    },
    pagesize() {
      this.items = this.pagesize;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datashowtitle;
      this.items = this.pagesize;
      this.handleLoading(false);
    },
    loadMore() {
      this.handleLoading(true);
      this.$emit("callgetlist");
    },
    handleChange(value) {
      this.handleLoading(true);
      this.items = value;
      this.$emit("setpagesize", value);
    },
  },
};
</script>
