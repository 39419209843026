var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppLayout',[_c('div',{staticClass:"campaigns-detail-main campaigns-detail-page page",class:`${_vm.$route.name}`},[_c('Breadcrumbs',{attrs:{"is-action-flex":true,"databreadcrumbs":_vm.breadcrumbs,"title":_vm.dataPageHeader.name,"count":_vm.dataApplications ? _vm.dataApplications.length : null},scopedSlots:_vm._u([(!_vm.isShowSkeleton)?{key:"actions",fn:function(){return [_c('div',{staticClass:"action-list"},[_c('a-tooltip',{attrs:{"placement":"top","disabled":_vm.dataPageHeader.is_default_role &&
                _vm.dataPageHeader.name == _vm.configAdminName}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.edit)+" ")]),_c('a',{on:{"click":function($event){return _vm.handleEdit()}}},[_c('a-icon',{attrs:{"type":"edit"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.assign_to_role)+" ")]),_c('a',{on:{"click":function($event){return _vm.goToPageAndQuery('ManageAssigntoRole', _vm.$route.query.id)}}},[_c('a-icon',{attrs:{"type":"usergroup-add"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"topRight"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.manage)+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'ManageRoleSetPerMission',
                  query: {
                    id: _vm.$route.query.id,
                  },
                }}},[_c('a-icon',{attrs:{"type":"setting"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"topLeft","disabled":_vm.dataPageHeader.is_default_role}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.btn.delete)+" ")]),_c('a',{on:{"click":function($event){return _vm.handleDeleteRole()}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.setrole.title_change_status)+" ")]),_c('a-switch',{attrs:{"checked":_vm.dataPageHeader.is_active,"disabled":_vm.dataPageHeader.is_default_role &&
                  _vm.dataPageHeader.name == _vm.configAdminName,"size":"small"},on:{"change":_vm.changeSwitch}})],2)],1)]},proxy:true}:null],null,true)}),(_vm.isShowSkeleton)?_c('div',{staticClass:"background-white p-5"},[_c('a-skeleton')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataApplications),expression:"showDataApplications"}]},[_c('ApplicationsList',{ref:"applicationsList",attrs:{"datapageheader":_vm.dataPageHeader,"dataapplications":_vm.dataApplications,"hasmoredata":_vm.hasMoreDataApp},on:{"callgetlist":_vm.callGetList,"handleadd":_vm.handleAdd,"handleedit":_vm.handleEdit,"handledeleterole":_vm.handleDeleteRole,"onsearch":function($event){return _vm.onSearch($event)},"handledeleteapp":function($event){return _vm.handleDeleteApp($event)},"handledeleteall":function($event){return _vm.handleDeleteAllApp()}}})],1),(_vm.isModalProcess)?_c('div',[_c('ModalProcess',{attrs:{"modalprocess":_vm.modalprocess,"dataobjselect":_vm.dataObjSelect},on:{"actionModal":function($event){return _vm.actionModal($event)}}})],1):_vm._e(),(_vm.isShowModalSelectApp)?_c('div',[_c('ModalSelectApp',{attrs:{"modalcondition":_vm.modalCondition,"dataapplications":_vm.dataApplicationsModal,"hasmoredata":_vm.hasMoreData,"showloading":_vm.showLoading},on:{"callgetlist":_vm.checkCallGetData,"onsearch":function($event){return _vm.onSearchModal($event)},"actionModal":function($event){return _vm.actionModal($event)}}})],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }