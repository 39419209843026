<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <span class="label-menu"> {{ lbls.menu.comparepackage }}</span>
        <span> <a-divider type="vertical" /></span>
        <span
          class="label-name"
          role="button"
          @click="goToPage('manageapplication')"
          >{{ lbls.menu.application }}</span
        >
        <span class="next"> >> </span>
        <span
          class="label-name"
          role="button"
          @click="goToPage('selectpackage')"
          >{{ lbls.menu.selectpackage }}</span
        >
        <span class="next"> >> </span>
        <span class="label-page">
          {{ lbls.comparepackage_page.title_page }}</span
        >
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "PageBreadcrumb",
  props: {
    datapagebreadcrumb: {
      type: Object,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datapagebreadcrumb;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
