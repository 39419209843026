import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'

/**
 * @method apiRegister
 * @method apiLogin
 * @method getTransfer
 * @method postTransferApprove
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  apiRegister: function (params) {
    var url = this.config().gatewayUrl + '/usermanage/sys/adduser'
    return BzbsApi.connectBzbsPostBody(url, null, params)
  },
  apiLogin: function (params) {
    var url = this.config().gatewayUrl + '/authen/login'
    return BzbsApi.connectBzbsPostBody(url, null, params)
  },  
  getTransfer: function (params) {
    var url = this.config().gatewayUrl + '/member/transfer'
    return BzbsApi.connectBzbsGet(url, null, params)
  },
  postTransferApprove: function (params) {
    var url = this.config().gatewayUrl + '/member/transfer/approve'
    return BzbsApi.connectBzbsPostBody(url, null, params)
  }, 
}
