export default {
  /* eslint-disable */
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
  fileToBase64(url){    
			return new Promise(resolve => {
				var request = new XMLHttpRequest();
				request.open('GET', url, true);
        request.responseType = 'blob';
				request.onload = function() {
					var reader = new FileReader();
          console.log(request.response);
					reader.readAsDataURL(request.response);
					reader.onload =  function(e){
						resolve(e.target.result)
					};
				};
				request.send();
			});
  }
}

