<template>
  <div>
    <div class="product-header" @click="toggles(data)">
      <div>
        <span class="mr-2">
          <img
            class="arrow-show-data custom-size-10"
            :class="{
              'arrow-hide-data': data.isShow,
            }"
            :src="IConArrowDown"
          />
        </span>
        <span>{{ data.name }}</span>
      </div>
      <div v-if="data.isEdit" class="custome-icon">
        <span>
          <a-button class="btn-custom-cancel" @click="handleBack($event, data)">
            {{ lbls.role_page.btn.cancel }}
          </a-button>
        </span>
        <span>
          <a-button
            type="primary"
            class="btn-custom-save"
            @click="handleSave($event, data)"
          >
            {{ lbls.role_page.btn.save }}
          </a-button>
        </span>
      </div>
      <div v-else class="custome-icon" @click="handleClickManage($event, data)">
        <a-icon type="setting" />
      </div>
    </div>
    <div v-if="data.isShow" class="product-body feature-list">
      <div v-if="data.isEdit">
        <div v-if="data.data_levels">
          <div class="label-title mb-2">
            {{ lbls.role_page.title_data_level }}
          </div>
          <div>
            <a-radio-group v-model="valueRadio" @change="onChangeRadio">
              <a-radio value="none"> None </a-radio>
              <a-radio
                v-for="lv in data.data_levels"
                :key="lv.id"
                :value="lv.id"
              >
                {{ lv.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div v-if="data.features">
          <div class="label-title mb-2">
            {{ lbls.role_page.title_features }}
          </div>
          <div class="checkbox-all">
            <a-checkbox
              :checked="setCheckAll(data)"
              :indeterminate="setIndeterminate(data)"
              @change="onChangAllCheckBox($event, data)"
            >
              <span class="title-checkbox-all"> Check all </span>
            </a-checkbox>
          </div>
          <a-checkbox
            v-for="feature in data.features"
            :key="feature.id"
            :checked="feature.is_selected"
            class="feature-item"
            @change="onChangesCheckbox($event, feature)"
          >
            <span>{{ feature.name }}</span>
          </a-checkbox>
        </div>
      </div>
      <div v-else>
        <div v-if="data.data_levels" class="mb-3">
          <div class="label-title mb-2">
            {{ lbls.role_page.title_data_level }}
          </div>
          <div>
            <a-radio-group
              v-model="valueRadio"
              disabled
              @change="onChangeRadio"
            >
              <a-radio
                v-for="lv in data.data_levels"
                :key="lv.id"
                :value="lv.id"
              >
                {{ lv.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div v-if="data.features">
          <div class="label-title mb-2">
            {{ lbls.role_page.title_features }}
          </div>
          <div
            v-for="feature in data.features"
            :key="feature.id"
            class="feature-item"
          >
            <div class="d-flex">
              <div>
                <span
                  class="status-icon active"
                  :class="{ inactive: !feature.is_selected }"
                >
                </span>
              </div>
              <div class="feature-babel ml-2">
                {{ feature.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "PageBreadcrumb",
  props: {
    dataproduc: {
      type: Object,
    },
  },
  data() {
    return {
      data: [],
      featureSelect: [],
      valueRadio: "none",
    };
  },
  watch: {
    dataproduc() {
      this.data = this.dataproduc;
      this.$forceUpdate();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.dataproduc;
      if (this.data.data_levels) {
        _.forEach(this.data.data_levels, (element) => {
          if (element.is_selected) {
            this.valueRadio = element.id;
          }
        });
      }
      console.log(this.data, "this.data");
    },
    toggles(value) {
      if (value.isEdit) return;
      value.isShow = !value.isShow;
      this.$forceUpdate();
    },
    handleClickManage(event, item) {
      event.stopPropagation();
      this.featureSelect = [];
      _.forEach(item.features, (element) => {
        if (element.is_selected) {
          this.featureSelect.push(element.id);
        }
      });
      // if (item.data_levels) {
      //   _.forEach(item.data_levels, (element) => {
      //     if (element.is_selected) {
      //       this.valueRadio = element.id;
      //     }
      //   });
      // }
      this.$emit("handleclickmanage", item);
    },
    handleBack(event, item) {
      event.stopPropagation();
      this.$emit("handleback", item);
    },
    handleSave(event, product) {
      event.stopPropagation();
      product.itemSelect = this.featureSelect;
      if (product.data_levels) {
        product.valueRadio = this.valueRadio;
      }
      this.$emit("handlesave", product);
    },
    onChangesCheckbox(e, item) {
      this.featureSelect = [];
      let checked = e.target.checked;
      item.is_selected = checked;
      if (checked) {
        this.featureSelect.push(item.id);
      } else {
        this.featureSelect = this.featureSelect.filter((data) => {
          return data != item.id;
        });
      }
      this.$forceUpdate();
    },
    setCheckAll(data) {
      return this.featureSelect.length == data.features.length;
    },
    setIndeterminate(data) {
      return (
        this.featureSelect.length > 0 &&
        this.featureSelect.length != data.features.length
      );
    },
    onChangAllCheckBox(e, data) {
      let checked = e.target.checked;
      this.featureSelect = [];
      if (checked) {
        _.forEach(data.features, (features) => {
          features.is_selected = true;
          this.featureSelect.push(features.id);
        });
        this.$forceUpdate();
      } else {
        _.forEach(data.features, (features) => {
          features.is_selected = false;
        });
        this.$forceUpdate();
      }
    },
    onChangeRadio(data) {
      this.valueRadio = data.target.value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
