<template>
  <Layout v-if="isShowPageLogin">
    <div class="page-login">
      <div class="content-box">
        <div class="content-title text-center">
          <h3>Sign in to Permission Management</h3>
        </div>
        <div class="form-button login-form-ad">
          <a-button
            id="auth_login_signad_button"
            class="bg-microsoft"
            @click="clickLogin()"
          >
            <MicrosoftIcon />
            <span class="text-microsoft">Sign in with Microsoft</span>
          </a-button>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Vue from "vue";
import Cache from "@/helper/Cache";
import Layout from "@/components/Layout/auth.vue";
import MicrosoftIcon from "@/assets/images/auth/microsoft.svg";
import Account from "@/helper/AccountHelper";

export default {
  name: "Login",
  components: {
    Layout,
    MicrosoftIcon,
  },
  data() {
    return {
      defaultLanguage: "Eng",
      isShowPageLogin: false,
      banner: require("@/assets/images/auth/auth-banner.png"),
      logo: require("@/assets/images/auth/logo-notext.png"),
      logo_microsoft: require("@/assets/images/LogoPowered.png"),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query && this.$route.query.frominvite == 'true') {
        this.$router.push({ name: "Redirectms" });
      }
      if (Cache.get("msToken") && this.$store.state.menu.length > 0) {
        Account.setDefaultMenu();
      } else {
        this.isShowPageLogin = true;
        this.handleLoading(false);
      }
    },
    handleMenuClick(e) {
      const nowlocale = Vue.$cookies.get("BzbsLocale");
      if (nowlocale == e.key) {
        return;
      }
      this.handleLoading(true);
      Vue.$cookies.set("BzbsLocale", e.key);
      window.location.reload();
    },
    clickLogin() {
      this.$router.push({ name: "Redirectms" });
    },
  },
};
</script>
<style lang="scss" scoped>
// .bg-login {
//   background: url('../../../assets/background/bg_usermanagement.png');
//   background-size: cover;
//   background-position-x: center;
//   background-position-y: center;
//   background-repeat: no-repeat;
//   background-color: #000;
//   .box-login {
//     width: 420px;
//     margin: auto;
//     padding: 30px 40px 40px 40px;
//     color: #f79443;
//     border-radius: 6px;
//   }
//   .img-logo{
//     max-width: 100px;
//     place-self: center;
//     position: absolute;
//     margin-bottom: 209px;
//   }
//   .signin{
//     place-items: center;
//     place-content: center;
//     border-radius: 30px;
//     padding: 11px 10px 10px;
//     background-color: #0067b8;
//   }
//   .bzbs-logo {
//     width: 56px;
//   }
//   .wrapper-footer {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     left: 0;
//     margin: 0;
//     opacity: 0.88;
//     background-attachment: fixed;
//   }
//   .font-signin{
//     font-size: 16px;
//     font-weight: 500;
//   }
// }
body.body {
  min-width: 100%;
}
.layout-auth {
  .error-message {
    text-align: center;
    word-break: break-word;
  }
  .form-button {
    text-align: center;
    margin: 20px 0;
    display: block;
    width: 100%;
    overflow: hidden;
    button {
      margin-bottom: 0;
      padding: 11px 10px 10px;
      border: none;
    }
    .bg-microsoft {
      background: #0067b8;
      &:hover,
      &:focus,
      &:active {
        border-color: #0067b8;
        color: var(--color-white);
        opacity: 0.7;
      }
      img {
        display: initial;
      }
    }
    .disable {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  .login-form-checkbox {
    margin-bottom: -10px;
    .ant-checkbox-wrapper {
      padding: 0;
    }
  }
  .login-form-ad {
    margin: 0 0 15px;
    padding-top: 20px;
  }
  .box-or {
    padding: 0 15px;
    line-height: 1px;
    .border-or {
      border-bottom: solid 1px #cecece;
    }
    .text-or {
      color: #949494;
    }
  }
  .fw-600 {
    font-weight: 600;
  }
}
.list-users {
  padding: 0;
  margin: 20px 0 25px;
  li {
    display: inline-block;
    list-style: none;
    padding: 0 7px;
    a {
      color: var(--primary-gold-bzb-primary-bzb);
      padding: 5px;
    }
  }
}
</style>
