<template>
  <div>
    <a-modal
      v-model="modalcondition.visible"
      centered
      class="modal-with-content-custom"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalcondition.title }}</span>
          <span class="name-content">
            <span> {{ datauser.display_name }}</span>
            <span class="text-secondary-custom"> ({{ datauser.email }})</span>
          </span>
        </div>
      </template>
      <div class="modal-show-apps-body">
        <a-row>
          <a-col :span="12" class="pr-3">
            <label class="mb-3">All</label>
            <div
              v-if="isShowSkeleton"
              class="background-white p-5 no-item-role"
            >
              <a-skeleton />
            </div>
            <ListItems
              v-else
              id="0"
              :datatype="modalcondition.type"
              :dataapp="dataAll"
              :hasmoredataapp="hasMoredata"
              @callgetlist="callGetList"
              @onsearch="onSearch($event)"
              @clickadd="clickAdd($event)"
              @clickaddall="clickAddAll($event)"
            >
            </ListItems>
          </a-col>
          <a-col :span="12" class="pl-3">
            <label class="mb-3">Selected</label>
            <div
              v-if="isShowSkeleton"
              class="background-white p-5 no-item-role"
            >
              <a-skeleton />
            </div>
            <ListItems
              v-else
              id="1"
              :dataapp="dataSelect"
              :datauser="datauser"
              :hasmoredataapp="hasMoredata"
              @onsearch="onSearch($event)"
              @clickremove="clickRemove($event)"
              @clickremoveall="clickRemoveAll($event)"
            >
            </ListItems>
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalcondition.cancelText
              ? modalcondition.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="isDisableBtn" @click="handleSave">
          {{
            modalcondition.okText
              ? modalcondition.okText
              : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Empty } from "ant-design-vue";
import { config } from "@/config/bzbsconfig.js";
import ListItems from "@/module/User/ManageUsers/components/Layout/ListItemModal.vue";
export default {
  name: "ManageProduct",
  components: {
    ListItems,
  },
  props: {
    modalcondition: {
      type: Object,
    },
    datauser: {
      type: Object,
    },
    datarole: {
      type: Array,
    },
    dataroleuser: {
      type: Array,
    },
    dataselectforcopy: {
      type: Object,
    },
    isshowskeletonmodal: {
      type: Boolean,
    },
    hasmoredata: {
      type: Boolean,
    },
  },
  data() {
    return {
      isDisableBtn: true,
      hasMoredata: true,
      showLoading: false,
      isShowSkeleton: true,
      nameSearch: "",
      searchData: "",
      dataAll: [],
      dataSelect: [],
      Config: config,
    };
  },
  watch: {
    datarole() {
      this.dataAll = _.cloneDeep(this.datarole);
      this.isShowSkeleton = false;
      this.setDataForShow();
    },
    dataroleuser() {
      this.dataSelect = _.cloneDeep(this.dataroleuser);
      if (this.dataSelect.length > 0) {
        this.setDataSelect();
      }
      this.dataSelectStart = this.setItemSelect(this.dataSelect);
      this.checkDisablebtn();
    },
    hasmoredata() {
      this.hasMoredata = _.cloneDeep(this.hasmoredata);
    },
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataSelect = _.cloneDeep(this.dataroleuser);
      this.dataSelectStart = this.setItemSelect(this.dataSelect);
      this.checkDisablebtn();
    },
    handleClose() {
      this.modalcondition.visible = false;
      this.$emit("actionModal", "close");
    },
    callGetList() {
      this.showLoading = true;
      this.$emit("callgetlist");
    },
    onSearch(e) {
      this.$emit("onsearch", e);
    },
    handleSave() {
      this.$emit("actionModal", this.dataSelect);
    },
    setDataForShow() {
      _.forEach(this.dataAll, (item, i) => {
        item.seq = i + 1;
      });
      if (this.dataSelect.length > 0) {
        this.setDataSelect();
      } else {
        this.showLoading = false;
      }
    },
    setDataSelect() {
      _.forEach(this.dataSelect, (item) => {
        this.dataAll = this.dataAll.filter((data) => {
          if (data.id == item.id) {
            item.seq = data.seq;
          }
          return data.id != item.id;
        });
      });
      this.showLoading = false;
      this.checkDisablebtn();
      this.$forceUpdate();
    },
    clickAdd(item) {
      this.dataSelect.push(item);
      this.dataAll = this.dataAll.filter((data) => {
        return data.id != item.id;
      });
      this.dataSelect.sort((a, b) => a.seq - b.seq);
      this.dataAll.sort((a, b) => a.seq - b.seq);
      this.checkDisablebtn();
      this.$forceUpdate();
    },
    clickRemove(item) {
      this.dataAll.push(item);
      this.dataSelect = this.dataSelect.filter((data) => {
        return data.id != item.id;
      });
      this.dataAll.sort((a, b) => a.name.localeCompare(b.name));
      this.dataSelect.sort((a, b) => a.name.localeCompare(b.name));
      this.checkDisablebtn();
      this.$forceUpdate();
    },
    clickAddAll() {
      _.forEach(this.dataAll, (item) => {
        this.clickAdd(item);
      });
    },
    clickRemoveAll() {
      _.forEach(this.dataSelect, (item) => {
        this.clickRemove(item);
      });
    },
    checkDisablebtn() {
      let dataSelectStartJSON = JSON.stringify(this.dataSelectStart);
      let dataSelect = this.setItemSelect(this.dataSelect);
      let dataSelectJSON = JSON.stringify(dataSelect);
      this.isDisableBtn = true;
      if (dataSelectStartJSON != dataSelectJSON) {
        this.isDisableBtn = false;
      }
    },
    setItemSelect(data) {
      let newdata = [];
      _.forEach(data, (item) => {
        newdata.push(item.id);
      });
      return newdata;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.no-item-role {
  height: calc(100vh - 286px);
}
.box-loader {
  position: absolute !important;
}
</style>
