<template>
  <div class="box-list-item-apps">
    <div>
      <a-input-search
        v-model="searchData"
        :placeholder="lbls.role_page.modal_add_app.input_search"
        class="w-100"
        size="large"
        @blur="onSearch"
        @search="onSearch"
      >
        <template slot="suffix">
          <a-icon
            v-if="isSearchData"
            style="margin-right: 5px; font-size: 10px"
            type="close-circle"
            theme="filled"
            class="icon-close-search-input"
            @click="closeSearchData()"
          />
        </template>
      </a-input-search>
      <div class="mt-3 show-border-bottom">
        <a-icon
          v-if="id == 0 && data.length == 0"
          style="margin-top: -3px"
          type="plus-circle"
        />
        <a class="#">
          <a-icon
            v-if="id == 0 && data.length > 0"
            style="margin-top: -3px"
            type="plus-circle"
            :disabled="data.length == 0"
            @click="clickAddAll"
          />
          <a-icon
            v-if="id > 0 && datauser.is_owner_account"
            style="margin-top: -3px"
            type="minus-circle"
            class="icon-disabled"
          />
          <a-icon
            v-else-if="id > 0"
            style="margin-top: -3px"
            type="minus-circle"
            @click="clickRemoveAll"
          />
        </a>
        <span v-if="id > 0 && datauser.is_owner_account" class="ml-2">
          <span>Select all ({{ data.length }})</span>
        </span>
        <span
          v-else
          class="ml-2"
          :class="{ 'title-app-name': data.length > 0 }"
          @click="clickItemAll(id)"
        >
          <span>Select all ({{ data.length }})</span>
        </span>
      </div>
    </div>
    <div class="box-item-apps-body">
      <div v-for="(item, i) in data" :key="item.id" class="mb-2">
        <div class="d-flex align-items-start">
          <div>
            <a class="#">
              <a-icon
                v-if="id == 0"
                type="plus-circle"
                @click="clickAdd(item)"
              />
              <a-icon
                v-if="
                  id > 0 &&
                  datauser.is_owner_account &&
                  item.name == configAdminName
                "
                type="minus-circle"
                class="icon-disabled"
              />
              <a-icon
                v-else-if="id > 0"
                type="minus-circle"
                @click="clickRemove(item)"
              />
            </a>
          </div>
          <div
            v-if="
              id > 0 &&
              datauser.is_owner_account &&
              item.name == configAdminName
            "
            class="ml-2 title-app-name cursor-default"
          >
            <span
              >{{ i + 1 }}. {{ item.name }}
              <span class="owner-account" style="font-size: 12px">
                (
                <span>
                  <a-icon
                    class="icon"
                    style="vertical-align: 1.5px !important"
                    type="check-circle"
                  />
                </span>
                <span>{{ lbls.user_page.tbl_owner_user }}</span>
                )
              </span>
            </span>
          </div>
          <div v-else class="ml-2 title-app-name" @click="clickItem(item, id)">
            <span>{{ i + 1 }}. {{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item-apps-footer">
      <span v-if="id == 0">
        <span v-if="data.length && data.length > 0">Showing 1 to </span>
        <span>{{ data.length }} items</span>
      </span>
      <span v-else>
        <span>
          <span v-if="data.length && data.length > 0">Showing 1 to </span>
          <span>{{ data.length }} items</span>
        </span>
      </span>
      <span v-if="isSearchData && !isSearchAPI" class="font-weight-bold">
        <span> out of </span>
        <span>{{ dataapp.length }}</span>
        <span> items </span>
      </span>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import PermissionHelper from "@/helper/PermissionHelper";

export default {
  name: "PageBreadcrumb",
  props: {
    id: {
      type: String,
    },
    datatype: {
      type: Number,
    },
    dataapp: {
      type: Array,
    },
    hasmoredataapp: {
      type: Boolean,
    },
    datauser: {
      type: Object,
    },
  },
  data() {
    return {
      searchData: "",
      data: [],
      itemSelect: [],
      isSearchData: false,
      isSearchAPI: false,
      configAdminName: PermissionHelper.configAdminName(),
    };
  },
  watch: {
    dataapp() {
      this.data = _.cloneDeep(this.dataapp);
      if (!this.isSearchAPI && this.searchData != "") {
        this.onsearchFilterData();
      }
      this.$forceUpdate();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = _.cloneDeep(this.dataapp);
    },
    onSearch() {
      this.isSearchAPI = false;
      if (!this.isSearchData && this.data.length == 0) {
        return;
      }
      this.isSearchData = this.searchData != "";
      this.onsearchFilterData();
    },
    closeSearchData() {
      this.searchData = "";
      this.onSearch();
      this.$forceUpdate();
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    clickAddAll() {
      this.$emit("clickaddall");
    },
    clickRemoveAll() {
      this.$emit("clickremoveall");
    },
    clickAdd(item) {
      this.$emit("clickadd", item);
    },
    clickRemove(item) {
      this.$emit("clickremove", item);
    },
    clickItem(item, id) {
      if (id == 0) {
        this.$emit("clickadd", item);
      }
      if (id == 1) {
        this.$emit("clickremove", item);
      }
    },
    clickItemAll(id) {
      if (id == 0) {
        if (this.isSearchData) {
          _.forEach(this.data, (element) => {
            this.clickAdd(element);
          });
        } else {
          this.clickAddAll();
        }
      }
      if (id == 1) {
        if (this.isSearchData) {
          _.forEach(this.data, (element) => {
            this.clickRemove(element);
          });
        } else {
          this.clickRemoveAll();
        }
      }
    },
    onsearchFilterData() {
      this.data = this.dataapp.filter((item) => {
        return item.name
          .toString()
          .toLowerCase()
          .includes(this.searchData.toLowerCase());
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.modal-show-apps-body {
  .box-list-item-apps {
    border: 2px solid #f9a601;
    padding: 7px;
    font-weight: 500;
    background: #fff;
    .box-item-apps-body {
      height: calc(100vh - 450px);
      overflow: auto;
      .anticon {
        vertical-align: 5px;
      }
    }
    .box-item-apps-footer {
      border-top: 1px solid #dcdddf;
      padding-top: 8px;
      margin-top: 16px;
      min-height: 30px;
      font-weight: normal;
    }
    .title-app-name {
      line-height: 1;
      cursor: pointer;
      &.cursor-default {
        cursor: default;
      }
    }
    .icon-disabled {
      cursor: default;
      color: var(--text-light-text-disable) !important;
    }
  }
  .show-border-bottom {
    border-bottom: 1px solid #dcdddf;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
}
</style>
