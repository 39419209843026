<template>
  <div>
    <a-modal
      v-model="datamodal.visible"
      centered
      class="modal-with-content-custom modal-setrole"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div class="title-header">
          <div>{{ datamodal.title }}</div>
          <div class="title-email">{{ datamodal.userEmail }}</div>
        </div>
      </template>
      <div class="box-data-role-list">
        <div class="modal-addapps-top">
          <div class="custom-show-label-copy">
            {{ lbls.user_page.tbl_colum_role }}
          </div>
          <div class="mt-3">
            <a-input-search
              v-model="searchDataModal"
              :placeholder="lbls.user_page.setrole_modal.input_search"
              size="large"
              @blur="onSearchModal"
              @search="onSearchModal"
            >
            </a-input-search>
          </div>
          <div class="mt-3">
            <a-checkbox
              :disabled="data.length == 0"
              :checked="checkSelectAll()"
              :indeterminate="checkedIndeterminate()"
              class="flex-center"
              @change="changeSwitchAll($event)"
            >
              <span> Select all ({{ checkLength() }}/{{ data.length }})</span>
            </a-checkbox>
          </div>
        </div>
        <div class="show-data-roles mt-3">
          <div v-if="isShowDataLoading" class="text-center">
            <div>
              <img
                style="height: 40px"
                src="@/assets/images/processing-request.gif"
              />
            </div>
            <div>Please wait a moment.</div>
          </div>
          <div v-else class="data-roles">
            <div class="item-roles mb-3">
              <div v-for="role in dataroleSelectForShow" :key="role.name">
                <div
                  class="item-role"
                  :class="{ 'item-role-active': role.is_selected }"
                  @click="clickSelect(role)"
                >
                  <div class="box-name">
                    <template
                      v-for="(fragment, i) in role.name
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchDataModal})|(?=${searchDataModal})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() ===
                          searchDataModal.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </div>
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      <span>
                        {{ role.description }}
                      </span>
                    </template>
                    <div class="box-desc">{{ role.description }}</div>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div class="item-roles">
              <div
                v-for="role in data"
                :key="role.id"
                :class="{ 'item-role-active-display-none': role.is_selected }"
              >
                <div class="item-role" @click="clickSelect(role)">
                  <div class="box-name">
                    <template
                      v-for="(fragment, i) in role.name
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchDataModal})|(?=${searchDataModal})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() ===
                          searchDataModal.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </div>
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      <span>
                        {{ role.description }}
                      </span>
                    </template>
                    <div class="box-desc">{{ role.description }}</div>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            datamodal.cancelText
              ? datamodal.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{ datamodal.okText ? datamodal.okText : lbls.user_page.btn.save }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Empty } from "ant-design-vue";
import Cache from "@/helper/Cache.js";
export default {
  name: "ManageProduct",
  props: {
    datamodal: {
      type: Object,
    },
    dataroleuser: {
      type: Array,
    },
    datarolelist: {
      type: Array,
    },
    isshowloading: {
      type: Boolean,
    },
  },
  data() {
    return {
      disabledok: true,
      data: [],
      dataroleSelect: [],
      dataroleSelectForShow: [],
      itemselects: [],
      isSelectAll: false,
      searchDataModal: "",
      isShowDataLoading: true,
    };
  },
  watch: {
    isshowloading() {
      this.isShowDataLoading = this.isshowloading;
      this.setDataStart();
    },
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.exAccount = Cache.get("exAccount");
    this.init();
  },
  methods: {
    init() {
      this.isShowDataLoading = this.isshowloading;
      if (!this.isShowDataLoading) {
        this.setDataStart();
      }
    },
    setDataStart() {
      this.dataroleuser.forEach((element) => {
        let findobj = this.datarolelist.find((o) => o.id === element.id);
        if (findobj) {
          findobj.is_selected = true;
        }
      });
      this.dataroleSelectStart = this.setDataSelectRole();
      this.dataroleSelectForShow = this.datarolelist.filter((item) => {
        return item.is_selected;
      });
      console.log(this.dataroleSelectForShow, "this.dataroleSelectForShow");
      this.checkisSelectAll();
    },
    handleClose() {
      this.datamodal.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.dataroleSelect);
    },
    changeSwitch(checked, value) {
      value.is_selected = checked;
      if (checked) {
        this.dataroleSelect.push(value.id);
      } else if (!checked) {
        this.dataroleSelect = this.dataroleSelect.filter((item) => {
          return item != value.id;
        });
      }
      this.checkBTN();
      this.$forceUpdate();
    },
    changeSwitchAll(e) {
      let checked = e.target.checked;
      this.isSelectAll = checked;
      _.forEach(this.data, (role) => {
        role.is_selected = checked;
      });
      this.dataroleSelect = [];
      this.dataroleSelect = this.setDataSelectRole();
      this.checkBTN();
      this.$forceUpdate();
    },
    checkLength() {
      let dataselect = this.data.filter((item) => {
        return item.is_selected;
      });
      return dataselect.length;
    },
    checkBTN() {
      this.disabledok = true;
      let dataroleSelectStart = JSON.stringify(this.dataroleSelectStart);
      let dataroleSelect = JSON.stringify(this.dataroleSelect);
      if (dataroleSelectStart != dataroleSelect) {
        this.disabledok = false;
      }
    },
    clickSelect(role) {
      let checked = !role.is_selected;
      role.is_selected = checked;
      if (checked) {
        this.dataroleSelect.push(role.id);
        this.dataroleSelectForShow.push(role);
      } else if (!checked) {
        this.dataroleSelect = this.dataroleSelect.filter((item) => {
          return item != role.id;
        });
        this.dataroleSelectForShow = this.dataroleSelectForShow.filter(
          (item) => {
            return item.id != role.id;
          }
        );
      }
      console.log(this.dataroleSelectForShow, "this.dataroleSelectForShow");
      this.checkBTN();
      this.$forceUpdate();
    },
    checkSelect() {
      let itemselect = [];
      _.forEach(this.data, (role) => {
        if (role.is_selected) {
          itemselect.push(role.id);
        }
      });
      return itemselect;
    },
    checkSelectAll() {
      let itemselect = [];
      itemselect = this.checkSelect();
      return itemselect.length > 0 && itemselect.length == this.data.length;
    },
    checkedIndeterminate() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked != this.data.length;
    },
    checkisSelectAll() {
      this.isSelectAll = false;
      this.dataroleSelect = [];
      this.data = [];
      this.dataroleSelect = this.setDataSelectRole();
      if (this.datarolelist.length == this.dataroleSelect.length) {
        this.isSelectAll == true;
      }
      this.data = this.datarolelist;
      // this.data.sort((a, b) => {
      //   return b.is_select - a.is_select || b.is_active - a.is_active;
      // });
      this.data.sort((a, b) => b.is_active - a.is_active);
      this.data.sort((a, b) => b.is_selected - a.is_selected);
    },
    setDataSelectRole() {
      let dataSet = [];
      _.forEach(this.datarolelist, (role) => {
        if (role.is_selected) {
          dataSet.push(role.id);
        }
      });
      dataSet.sort(function (a, b) {
        return a.localeCompare(b);
      });
      return dataSet;
    },
    onSearchModal() {
      let text = this.searchDataModal;
      if (text != "" && text != this.oldtext) {
        this.oldtext = text;
        this.data = this.datarolelist.filter((item) => {
          return item.name
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        });
      } else if (text == "" && this.oldtext) {
        this.oldtext = text;
        this.checkisSelectAll();
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.modal-setrole {
  .ant-modal-body {
    overflow: hidden;
  }
  .highlight {
    background-color: $color-white-f5e;
  }
  .input-search-custom {
    margin-top: 0 !important;
  }
  .title-show-results {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .show-data-roles {
    padding-top: 16px;
    border-top: 1px solid #dcdddf;
    min-height: calc(100vh - 316px);
  }
  .data-roles {
    height: calc(100vh - 335px);
    overflow: auto;
    padding-bottom: 48px;
  }
  .box-data-role-list {
    display: contents;
  }
  .title-header {
    .title-header-name {
      font-size: 13px;
      color: $color-primary;
      justify-content: center;
    }
  }
  .item-roles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .item-role {
    width: 375px;
    padding: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    .box-name {
      font-size: 13px;
      font-weight: 600;
    }
    .box-desc {
      font-size: 12px;
      opacity: 0.7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .item-role-active {
    border: 1px solid $color-primary;
    background: $color-white-f5e;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
  }
  .item-role-active-display-none {
    display: none;
  }
  .item-role:hover {
    border: 1px solid $color-primary !important;
  }
  .role-item {
    width: 48%;
    float: left;
    padding: 8px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    .item-left {
      width: 30px;
    }
    .item-role {
      width: 48%;
      float: left;
      padding: 8px;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      .box-name {
        font-size: 14px;
        font-weight: 600;
      }
      .box-desc {
        font-size: 12px;
        opacity: 0.7;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .item-role-active {
      border: 1px solid $color-primary;
      background: $color-white-f5e;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
    }
    .item-right {
      width: 30px;
      text-align: right;
    }
  }
}
</style>
