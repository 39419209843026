var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{staticClass:"modal-with-content",attrs:{"centered":"","closable":false,"mask-closable":false},model:{value:(_vm.modalprocess.visible),callback:function ($$v) {_vm.$set(_vm.modalprocess, "visible", $$v)},expression:"modalprocess.visible"}},[_c('template',{slot:"title"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.modalprocess.title))]),(_vm.modalprocess.isEdit)?_c('span',{staticClass:"float-right"},[_c('span',{staticClass:"title-status"},[_vm._v(" "+_vm._s(_vm.lbls.role_page.tbl_colum_status)+" ")]),_c('span',[_c('a-tooltip',[_c('template',{slot:"title"},[(_vm.objDataSave.is_active)?_c('span',[_vm._v(" "+_vm._s(_vm.lbls.role_page.title_status_active)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.lbls.role_page.title_status_inactive)+" ")])]),_c('a-switch',{attrs:{"checked":_vm.objDataSave.is_active},on:{"change":_vm.changeSwitch}})],2)],1)]):_vm._e()])]),_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":_vm.lbls.role_page.input_name.name}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    initialValue: _vm.objDataSave.name,
                    rules: [
                      {
                        required: true,
                        message: _vm.lbls.role_page.input_name.required,
                      },
                      {
                        pattern: new RegExp(
                          /^[A-Za-zก-๙0-9:\[\]_ -]{1,100}$/
                        ),
                        message: _vm.lbls.role_page.input_name.pattern,
                      },
                      {
                        min: 4,
                        message: _vm.lbls.role_page.input_name.min,
                      },
                    ],
                  },
                ]),expression:"[\n                  'name',\n                  {\n                    initialValue: objDataSave.name,\n                    rules: [\n                      {\n                        required: true,\n                        message: lbls.role_page.input_name.required,\n                      },\n                      {\n                        pattern: new RegExp(\n                          /^[A-Za-zก-๙0-9:\\[\\]_ -]{1,100}$/\n                        ),\n                        message: lbls.role_page.input_name.pattern,\n                      },\n                      {\n                        min: 4,\n                        message: lbls.role_page.input_name.min,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"max-length":_vm.maxLengthName,"placeholder":_vm.lbls.role_page.input_name.placeholder,"disabled":_vm.modalprocess.isEdit && _vm.dataobjselect.is_default_role},on:{"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char",attrs:{"href":""}},[_vm._v(" "+_vm._s(_vm.objDataSave.name ? _vm.objDataSave.name.length : _vm.totalCharName.toLocaleString())+" / "+_vm._s(_vm.maxLengthName.toLocaleString())+" ")])],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":_vm.lbls.role_page.input_description.name,"validate-status":_vm.checkError('description') ? 'error' : '',"help":_vm.checkError('description') || ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'description',
                  {
                    initialValue: _vm.objDataSave.description,
                    rules: [
                      {
                        required: true,
                        message: _vm.lbls.role_page.input_description.required,
                      },
                      {
                        min: 4,
                        message: _vm.lbls.role_page.input_description.min,
                      },
                    ],
                  },
                ]),expression:"[\n                  'description',\n                  {\n                    initialValue: objDataSave.description,\n                    rules: [\n                      {\n                        required: true,\n                        message: lbls.role_page.input_description.required,\n                      },\n                      {\n                        min: 4,\n                        message: lbls.role_page.input_description.min,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":_vm.lbls.role_page.input_description.placeholder,"max-length":_vm.maxLengthDescription,"rows":4},on:{"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char",attrs:{"href":""}},[_vm._v(" "+_vm._s(_vm.objDataSave.description ? _vm.objDataSave.description.length : _vm.totalCharDescription.toLocaleString())+" / "+_vm._s(_vm.maxLengthDescription.toLocaleString())+" ")])],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.modalprocess.cancelText ? _vm.modalprocess.cancelText : _vm.lbls.role_page.btn.cancel)+" ")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabledok},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.modalprocess.okText ? _vm.modalprocess.okText : _vm.lbls.role_page.btn.save)+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }