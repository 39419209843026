<template>
  <div class="campaign-list-table box-icon-a-disabled">
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: tableHeight, x: 860 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.crossaccount_page.btn.ok,
        filterReset: lbls.crossaccount_page.btn.reset,
      }"
      class="custom-cross-account"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="template-name" slot-scope="text">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div>{{ text.name }}</div>
          </div>
          <div v-if="text.can_manage_cross_account" class="ml-1">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>
                  {{ lbls.crossaccount_page.tbl_colum_manage_account_can }}
                </span>
              </template>
              <a class="#"><a-icon type="book" /></a>
            </a-tooltip>
          </div>
        </div>
      </template>
      <template slot="template-status" slot-scope="text">
        <a-tag v-if="text" :color="'green'" class="tag-status">
          <a-badge
            :status="'success'"
            :text="lbls.crossaccount_page.title_status_active"
          />
        </a-tag>
        <a-tag v-else :color="'red'" class="tag-status">
          <a-badge
            :status="'error'"
            :text="lbls.crossaccount_page.title_status_inactive"
          />
        </a-tag>
      </template>
      <template slot="template-title-owner-user" slot-scope="text">
        <div v-if="text.name">
          <div>{{ text.name }}</div>
          <div class="lbl-user-date">
            {{ text.DisplayName }}
          </div>
        </div>
        <div v-else>-</div>
      </template>
      <template slot="template-title-email" slot-scope="text, record">
        <div>
          <div>{{ text }}</div>
          <div v-if="record.owner_user.IdentityProvider" class="lbl-user-date">
            {{ record.owner_user.IdentityProvider }}
          </div>
        </div>
      </template>
      <template slot="template-total-application" slot-scope="text, record">
        <div>
          <div v-if="text > '0'" class="click-gotos">
            <a-tooltip :disabled="!isPermissionView" placement="left">
              <template slot="title">
                {{ lbls.crossaccount_page.btn.goto }}
              </template>
              <a @click="goToPageWithRecord('CrossAccountDetail', record)">
                {{ numberWithCommas(text) }}
              </a>
            </a-tooltip>
          </div>
          <div v-else>{{ text }}</div>
        </div>
      </template>
      <template slot="template-edit" slot-scope="text, record">
        <div class="action-list">
          <a-tooltip :disabled="!isPermissionView" placement="left">
            <template slot="title">
              {{ lbls.crossaccount_page.btn.goto }}
            </template>
            <a @click="goToPageWithRecord('ApplicationAccount', record)">
              <a-icon type="setting" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
    tableHeight: {
      type: Number,
    },
  },
  data() {
    return {
      permission_key: "cross",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      optionsValue: [],
      isFilters: false,
      columns: [
        {
          title: "Status",
          width: "135px",
          keys: "tbl_colum_status",
          dataIndex: "account.is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.account.is_active === value;
          },
        },
        {
          title: "Cross Account",
          keys: "tbl_colum_name",
          dataIndex: "account",
          width: "320px",
          sorter: (a, b) => a.account.name.localeCompare(b.account.name),
          scopedSlots: { customRender: "template-name" },
        },
        {
          title: "Manage Accoun",
          keys: "tbl_colum_owner_user",
          dataIndex: "owner_user",
          width: "280px",
          // sorter: (a, b) => {
          //   if (!a.owner_user.name) return false;
          //   return a.owner_user.name.localeCompare(b.owner_user.name);
          // },
          scopedSlots: { customRender: "template-title-owner-user" },
          // onFilter: (value, record) =>
          //   record.owner_user.name
          //     .toString()
          //     .toLowerCase()
          //     .includes(value.toLowerCase()),
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (visible) {
          //     setTimeout(() => {
          //       this.searchInput.focus();
          //     });
          //   }
          // },
        },
        {
          title: "Email",
          keys: "tbl_colum_email",
          dataIndex: "owner_user.Email",
          width: "280px",
          // sorter: (a, b) => {
          //   if (!a.owner_user.Email) return false;
          //   return a.owner_user.Email.localeCompare(b.owner_user.Email);
          // },
          scopedSlots: { customRender: "template-title-email" },
          // onFilter: (value, record) =>
          //   record.owner_user.Email.toString()
          //     .toLowerCase()
          //     .includes(value.toLowerCase()),
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (visible) {
          //     setTimeout(() => {
          //       this.searchInput.focus();
          //     });
          //   }
          // },
        },
        {
          title: "Total Applications",
          keys: "tbl_colum_total_application",
          dataIndex: "total_application",
          width: "220px",
          align: "center",
          scopedSlots: { customRender: "template-total-application" },
          sorter: (a, b) => a.total_application - b.total_application,
        },
        {
          title: "",
          width: "65px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionView: true,
    };
  },
  watch: {
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionView = this.checkArrowPermission(
            "cross-existing-view",
            permission_page
          );
        }
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.crossaccount_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.crossaccount_page[filters.text];
          });
        }
      });
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.optionsValue = [];
      this.data = this.dataStart;
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["account.is_active"]
        ? filters["account.is_active"]
        : [];
      isFilters = isFiltersStatus.length > 0;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
    goToPageWithRecord(page, record) {
      this.$router.push({
        name: page,
        query: {
          id: record.id,
          name: record.account.name,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.custom-cross-account {
  .ant-table-tbody > tr > td {
    height: 60px;
  }
  .lbl-user-date {
    font-size: 12px;
    line-height: 1.5;
    color: #b5b5c3;
  }
}
</style>
