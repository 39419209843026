<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page manage-user-page"
    >
      <TitleBreadcrumbs
        v-if="lbls && lbls.user_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.user_page.title_page"
      />
      <div class="row">
        <PageHeader :disabled="isShowSkeleton" @onsearch="onSearch($event)" />
      </div>
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <TableList
          :datatable="data"
          :conditiontable="conditionTable"
          @callgetlist="callGetList"
          @handlesetrole="handleManageRole($event)"
          @handleedit="handleEdit($event)"
          @handledelete="handleDelete($event)"
          @handledeleterole="handleDeleteRole($event)"
          @onsearchtable="onSearchTable($event)"
        />
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isShowModalSelectRole">
        <ModalSelectItem
          :modalcondition="modalCondition"
          :datauser="dataRecord"
          :dataroleuser="dataRoleUser"
          :datarole="dataListofRole"
          @onsearch="onSearchModal($event)"
          @actionModal="actionModal($event)"
        >
        </ModalSelectItem>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import PageHeader from "@/module/User/ManageUsers/components/Layout/PageHeader.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/User/ManageUsers/components/Table/TableList";
import ModalProcess from "@/module/User/ManageUsers/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import ModalSelectItem from "@/module/User/ManageUsers/components/Modal/ModalSelectItem.vue";

export default {
  name: "ManageUser",
  components: {
    AppLayout,
    ShowTotal,
    PageHeader,
    ModalProcess,
    ModalAlert,
    TableList,
    ModalSelectItem,
  },
  data() {
    return {
      permission_key: "user",
      action_key: "view",
      breadcrumbs: [],
      isShowSkeleton: true,
      data: [],
      dataSelect: [],
      isEdit: false,
      isSetRole: false,
      isDeleteRole: false,
      isDelete: false,
      isShowRole: false,
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      totalList: 0,
      idModal: "",
      titleModal: "",
      modalbtnok: "",
      modalbtncancel: "",
      contentModal: "",
      nameSearch: "",
      page: 0,
      page_size: 25,
      pageRoleList: 0,
      conditionTable: {},
      dataObjSelect: {},
      alertData: {},
      modalprocess: {
        visible: false,
        title: "",
        key: "",
        message: "",
        okText: "",
        cancelText: "",
        isEdit: false,
      },
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      isShowModalSelectRole: false,
      dataListofRole: [],
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList();
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.usermanagement,
        name: "ManageUsers",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    handleEdit(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.titleModalProcess = this.lbls.user_page.modal_title_edit;
      this.dataRecord = record;
      this.isEdit = true;
      this.callGetDetail();
    },
    handleManageRole(record) {
      this.resetCondition();
      this.handleLoading(true);
      this.dataRecord = record;
      this.callGetDetail();
      this.isSetRole = true;
      this.titleModalProcess = this.lbls.user_page.setrole_modal.title_modal;
      this.modalCondition = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
      };
      this.handleLoading(false);
      this.isShowModalSelectRole = true;
    },
    handleDelete(record) {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.isDeleteUser = true;
      this.dataRecord = record;
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: this.lbls.user_page.modal.content_delete,
        content: this.dataRecord.display_name + ".",
        okText: this.lbls.user_page.modal.btn.delete,
        okType: "danger",
        cancelText: this.lbls.user_page.btn.cancel,
        icon: "delete",
        onOk: () => {
          this.handleLoading(true);
          this.callPostDeleteData();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    handleDeleteRole(data) {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.isDeleteRole = true;
      this.dataDelete = data.record;
      this.roleDelete = data.tag;
      this.modalbtnok = this.lbls.user_page.modal.btn.delete;
      this.modalbtncancel = this.lbls.user_page.btn.close;
      this.titleModal = this.lbls.user_page.modal.title_confirm;
      this.contentModal =
        this.lbls.user_page.modal.content_delete_role +
        " : " +
        this.roleDelete.name +
        " ?";
      this.showModalAlert();
    },
    handlemodalOk() {
      if (this.isDeleteRole) {
        this.handleLoading(true);
        this.callPostDeleteRole();
      } else if (this.isDeleteUser) {
        this.handleLoading(true);
        this.callPostDeleteData();
      }
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        this.resetCondition();
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        } else if (this.isSetRole) {
          this.objDataSave = value;
          this.callPostSaveRoles();
        }
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      if (this.isCreate) {
        let routeData = this.$router.resolve({
          name: "InviteUsersDetail",
        });
        window.open(routeData.href, "_blank");
      }
      this.setCallGetList();
    },
    showModalSuccess() {
      this.handleLoading(false);
      let secondsToGo = this.$store.state.secondstogo;
      const modal = this.$success({
        centered: true,
        autoFocusButton: null,
        title: this.contentModal,
        okType: "default",
        okText: `Ok (${secondsToGo})`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          okText: `Ok (${secondsToGo})`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
      this.setCallGetList();
    },
    showModalAlert() {
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    closeModalProcess() {
      this.isModalProcess = false;
      this.isShowModalSelectRole = false;
    },
    resetCondition() {
      this.roleDelete = [];
      this.dataSelect = [];
      this.dataRecord = [];
      this.dataDelete = [];
      this.dataRoleUser = [];
      this.dataRoleListGroup = [];
      this.dataListofRole = [];
      this.isEdit = false;
      this.isSetRole = false;
      this.isDelete = false;
      this.isDeleteRole = false;
      this.isDeleteUser = false;
      this.isShowRole = false;
      this.isShowModalSelectRole = false;
      this.dataObjSelect = {};
      this.alertdata = {};
      this.modalprocess = {};
      this.modalAlert = {};
      this.pageRoleList = 0;
    },
    setCallGetList() {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = this.page_size;
      this.data = [];
      this.callGetList();
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    setCallGetRoleList() {
      Promise.all([this.callGetRoleList()]).then((res) => {
        if (res[0]) {
          this.setCallGetRoleList();
        } else {
          if (this.isSetRole) {
            this.setDataSetRole();
          }
        }
      });
    },
    setDataSetRole() {
      if (this.dataRoleUser.length > 0) {
        this.dataRoleUser.forEach((element) => {
          let findobj = this.dataRoleListGroup.find((o) => o.id === element.id);
          if (findobj) {
            findobj.is_select = true;
          }
        });
      }
      setTimeout(() => {
        this.handleLoading(false);
        this.showModalProcess();
      }, 500);
    },
    setDataEdit() {
      let display_name = this.dataSelect.display_name
        ? this.dataSelect.display_name
        : "-";
      let first_name =
        this.dataSelect.first_name + " " ? this.dataSelect.first_name : "";
      first_name = first_name ? first_name + " " : "-";
      let last_name = this.dataSelect.last_name
        ? this.dataSelect.last_name
        : "";
      let fullname = first_name + last_name;
      this.dataSelect.display_name = display_name;
      this.dataSelect.full_name = fullname;
      const newData = {
        is_active: this.dataSelect.is_active,
      };
      this.dataObjSelect = this.dataSelect;
      this.dataEditForCheck = JSON.stringify(newData);
      this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isEdit: this.isEdit,
        isShowRole: this.isShowRole,
      };
      setTimeout(() => {
        this.handleLoading(false);
        this.showModalProcess();
      }, 500);
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      manageUsersAPI
        .getUserList(params)
        .then((res) => {
          this.totalList = 0;
          if (res.data) {
            if (res.data.result) {
              let item = res.data.result.items;
              let paging = res.data.result.paging;
              if (item.length > 0) {
                this.data.push(...res.data.result.items);
                this.data.forEach((elements) => {
                  let first_name = elements.first_name
                    ? elements.first_name + " "
                    : "-";
                  let last_name = elements.last_name ? elements.last_name : "";
                  let display_name = elements.display_name
                    ? elements.display_name
                    : "-";
                  elements.full_name = first_name + last_name;
                  elements.display_name = display_name;
                  elements.isShowMore = false;
                });
              }
              this.totalList = this.data.length;
              this.conditionTable = {
                hasMoreData: paging.has_more_data,
                isFilters: false,
                nameSearch: this.nameSearch,
              };
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      this.handleLoading(true);
      let params = {
        account_id: this.exAccount.account.id,
        user_id: this.dataRecord.id,
      };
      manageUsersAPI
        .getUser(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.roles) {
            if (res.data.result.roles.length > 0) {
              this.isShowRole = true;
            }
            this.dataSelect = res.data.result;
            this.dataRoleUser = res.data.result.roles;
            if (this.isEdit) {
              this.setDataEdit();
            }
            if (this.isSetRole) {
              this.callGetRoleList();
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetRoleList() {
      this.pageRoleList = this.pageRoleList + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.pageRoleList,
        page_size: 200,
      };
      manageRoleAPI
        .getSearch(params)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            let items = res.data.result.items;
            setTimeout(() => {
              this.dataListofRole.push(...items);
            });
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostAddData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        emails: this.objDataSave.emails,
        user_ids: this.objDataSave.user_ids,
      };
      if (this.objDataSave.role_ids) {
        valueSave.role_ids = this.objDataSave.role_ids;
      }
      if (this.objDataSave.product_id) {
        valueSave.product_id = this.objDataSave.product_id;
      }
      this.objForSave = JSON.stringify(valueSave);
      manageUsersAPI
        .postAddInviteUser(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_create_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        user_id: this.dataRecord.id,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageUsersAPI
        .postEditUser(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_update_success;
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostSaveRoles() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        user_id: this.dataRecord.id,
        role_ids: this.setDataForSave(),
      };
      var valueSaveJson = JSON.stringify(valueSave);
      manageUsersAPI
        .postSaveRoles(valueSaveJson)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_update_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      let params = {
        account_id: this.exAccount.account.id,
        user_id: this.dataRecord.id,
      };
      manageUsersAPI
        .deleteUser(params)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.user_page.modal.title_success;
              this.contentModal =
                this.lbls.user_page.modal.content_delete_success;
              this.showModalSuccess();
            }, 500);
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
          this.resetCondition();
        });
    },
    callPostDeleteRole() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.roleDelete.id,
        user_ids: [this.dataDelete.id],
      };
      manageRoleAPI
        .postDeleteUserInRole(params)
        .then((res) => {
          if (res.data) {
            if (res.data.is_success) {
              this.idModal = "success";
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.user_page.modal.title_success;
                this.contentModal =
                  this.lbls.user_page.modal.content_delete_role_success;
                // this.openNotificationWithIcon("success");
                this.showModalSuccess();
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
          this.resetCondition();
        });
    },
    setDataForSave() {
      let data = [];
      _.forEach(this.objDataSave, (item) => {
        data.push(item.id);
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.manage-page-detail-user {
  .data-role {
    opacity: 0.7;
  }
}
.owner-account {
  font-size: 18px;
  line-height: 1.5;
  color: $green-green-3;
  .icon {
    color: $green-green-3;
    margin-right: 3px;
  }
}

.manage-user-page {
  .ant-select-dropdown--multiple {
    display: none;
  }
}
</style>
