<template>
  <div>
    <a-modal
      v-model="modaladdapps.visible"
      centered
      class="modal-with-content-custom"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          {{ modaladdapps.title }}
        </div>
      </template>
      <div class="modal-addapps">
        <div class="modal-addapps-top">
          <div
            v-if="modaladdapps.isCopyPerMission"
            class="custom-show-label-copy mb-3"
          >
            <div>
              <span>{{ lbls.role_page.modal_add_app.title_select_copy }} </span>
              <span class="label-app-name">{{
                dataselectforcopy.application.name
              }}</span>
            </div>
          </div>
          <div class="modal-top-label">
            <div class="flex-center">
              <span>{{ lbls.role_page.modal_add_app.title_apps }}</span>
              <span v-if="modaladdapps.isCopyPerMission" class="ml-2">
                <a-badge
                  :count="data.length"
                  :class="'count-list'"
                  :overflow-count="999"
                  show-zero
                />
              </span>
            </div>
          </div>
          <div v-if="!modaladdapps.isDeleteApp" class="mt-3">
            <a-input-search
              v-model="searchData"
              :placeholder="lbls.role_page.modal_add_app.input_search"
              class="w-100"
              size="large"
              @blur="onSearch"
              @search="onSearch"
            >
            </a-input-search>
          </div>
          <div
            v-if="modaladdapps.isAddApp || modaladdapps.isDeleteApp"
            class="custom-show-checkbox mt-3"
          >
            <div>
              <a-checkbox
                :disabled="data.length == 0"
                :checked="checkedAllItem()"
                :indeterminate="checkedIndeterminate()"
                class="flex-center"
                @change="changeSwitch($event)"
              >
                <span> Select all ({{ checkLength() }}/{{ data.length }})</span>
              </a-checkbox>
            </div>
          </div>
        </div>
        <div class="modal-addapps-body mt-3">
          <div v-if="modaladdapps.isAddApp || modaladdapps.isDeleteApp">
            <div v-if="data.length == 0">
              <a-empty :image="simpleImage" />
            </div>
            <div class="body-items text-center">
              <div v-for="item in data" :key="item.id">
                <a-tooltip>
                  <template slot="title">
                    <div>
                      <span class="custom-tooltip-supject"
                        >{{ lbls.role_page.title_application }} :
                      </span>
                      <span>{{ item.name }}</span>
                    </div>
                    <div>
                      <span class="custom-tooltip-supject"
                        >{{ lbls.role_page.title_package }} :
                      </span>
                      <span>{{ item.package_dsname }}</span>
                    </div>
                  </template>
                  <div
                    class="item-app"
                    :class="{ 'item-app-active': item.is_selected }"
                    @click="clickSelect(item)"
                  >
                    <div class="label-app">
                      <div class="logo-custom"><img :src="logo" /></div>
                      <div class="label-item ml-2">
                        <div class="label-name-app wrap-text-one-line">
                          {{ item.name }}
                        </div>
                        <div class="label-package wrap-text-one-line">
                          {{ item.package_dsname }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div v-if="modaladdapps.isCopyPerMission" class="body-item-app">
            <div
              v-for="item in data"
              :key="item.id"
              class="item-app-copy"
              :class="{ 'item-app-copy-active': item.is_selected }"
              @click="clickSelect(item)"
            >
              <div class="item-app-top">
                <div>{{ item.name }}</div>
                <div class="label-package">{{ item.package_name }}</div>
              </div>
              <div class="item-app-bottom">
                <div class="label-product">
                  <ul>
                    <li
                      v-for="itme_package in item.permission_products"
                      :key="itme_package.id"
                    >
                      {{ itme_package.display_name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modaladdapps.cancelText
              ? modaladdapps.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button
          type="primary"
          :disabled="objDataSave.length == 0"
          @click="handleSave"
        >
          {{
            modaladdapps.okText ? modaladdapps.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Empty } from "ant-design-vue";
export default {
  name: "ManageProduct",
  props: {
    modaladdapps: {
      type: Object,
    },
    dataapplications: {
      type: Array,
    },
    dataselectforcopy: {
      type: Object,
    },
    hasmoredata: {
      type: Boolean,
    },
  },
  data() {
    return {
      logo: require("@/assets/images/bzbs-logo1.png"),
      disabledok: true,
      nameSearch: "",
      searchData: "",
      data: [],
      objDataSave: [],
      objFromSearchData: [],
      innerHeight: 0,
      lastscrollTop: 0,
    };
  },
  watch: {
    dataapplications() {
      this.data = _.cloneDeep(this.dataapplications);
      _.forEach(this.data, (item) => {
        let findobj = this.objDataSave.find((o) => o === item.id);
        if (findobj) {
          item.is_selected = true;
        }
      });
      this.objFromSearchData = this.objFromSearchData.filter((data) => {
        return data.is_selected;
      });
      this.data.sort((a, b) => b.is_selected - a.is_selected);
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    setTimeout(() => {
      this.tableContent = document.querySelector(".modal-addapps-body");
      this.onScroll();
    });
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = _.cloneDeep(this.dataapplications);
      _.forEach(this.data, (item) => {
        if (item.is_selected) {
          this.objDataSave.push(item.id);
          this.objFromSearchData.push(item);
        }
      });
      console.log(this.dataselectforcopy, "dataselectforcopy");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (scrollPersent >= 98 && isScrollDown && this.hasmoredata) {
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleClose() {
      this.modaladdapps.visible = false;
      this.$emit("actionModal", "close");
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onSearch() {
      if (this.modaladdapps.isAddApp) {
        this.$emit("onsearch", this.searchData);
      } else if (this.modaladdapps.isCopyPerMission) {
        this.onSearchCopy();
      }
    },
    onSearchCopy() {
      if (this.nameSearch != this.searchData) {
        this.nameSearch = this.searchData;
        this.objDataSave = [];
        this.objFromSearchData = [];
        this.data = this.dataapplications.filter((item) => {
          return item.name
            .toString()
            .toLowerCase()
            .includes(this.nameSearch.toLowerCase());
        });
        if (this.modaladdapps.isCopyPerMission) {
          _.forEach(this.data, (element) => {
            element.is_selected = false;
          });
        }
      }
    },
    handleSave() {
      console.log(this.objFromSearchData, "this.objFromSearchData");
      if (this.modaladdapps.isCopyPerMission) {
        this.$emit("actionModal", this.objFromSearchData);
      } else {
        this.$emit("actionModal", this.objDataSave);
      }
      // this.$emit("actionModal", this.objDataSave);
    },
    checkedAllItem() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked == this.data.length;
    },
    checkedIndeterminate() {
      let checked = this.data.filter((item) => {
        return item.is_selected;
      }).length;
      return checked > 0 && checked != this.data.length;
    },
    checkLength() {
      let dataselect = this.data.filter((item) => {
        return item.is_selected;
      });
      return dataselect.length;
    },
    changeSwitch(e) {
      let checked = e.target.checked;
      _.forEach(this.data, (item) => {
        item.is_selected = checked;
        this.setDataForSave(item);
      });
      if (!checked) {
        this.objFromSearchData = [];
      }
      this.objDataSave = [...new Set(this.objDataSave)];
    },
    checkSwitchItem(item) {
      let findobj = this.objDataSave.find((o) => o === item.id);
      if (findobj) {
        return true;
      } else {
        return false;
      }
    },
    clickSelect(item) {
      if (this.modaladdapps.isCopyPerMission) {
        this.objDataSave = [];
        this.objFromSearchData = [];
        _.forEach(this.data, (element) => {
          element.is_selected = false;
        });
      }
      item.is_selected = !item.is_selected;
      this.setDataForSave(item);
      this.objDataSave = [...new Set(this.objDataSave)];
    },
    setDataForSave(item) {
      if (item.is_selected) {
        this.objDataSave.push(item.id);
        this.objFromSearchData.push(item);
      } else {
        this.objDataSave = this.objDataSave.filter((data) => {
          return data != item.id;
        });
        this.objFromSearchData = this.objFromSearchData.filter((data) => {
          return data.id != item.id;
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
.modal-addapps {
  color: #000;
  position: relative;
  .ant-modal-content {
    .ant-modal-body {
      max-height: inherit;
      overflow: hidden;
    }
  }
  .modal-addapps-top {
    margin-bottom: 16px;
    .modal-top-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .custom-show-label-copy,
    .custom-show-checkbox {
      padding-bottom: 16px;
      border-bottom: 1px solid $grey-grey-7;
    }
    .label-app-name {
      color: $color-primary;
    }
  }
  .modal-addapps-body {
    height: calc(100vh - 400px);
    overflow: auto;
    .body-items {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .item-app {
      width: 240px;
      padding: 8px;
      border: 1px solid $grey-grey-7;
      border-radius: 8px;
      color: $color-body;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      .label-app {
        display: flex;
        width: 200px;
        word-break: break-word;
      }
      .logo-custom {
        width: 40px;
        img {
          width: 35px;
        }
      }
      .label-item {
        width: calc(100% - 40px);
        line-height: 1.5;
        text-align: left;
      }
      .label-name-app {
        font-weight: 600;
        font-size: 14px;
      }
      .label-package {
        font-weight: normal;
        font-size: 12px;
        color: $grey-grey-5;
      }
    }
    .item-app:hover {
      border: 1px solid $color-primary;
    }
    .body-item-app {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .item-app-copy {
      width: 250px;
      background: #fff;
      border: 1px solid #e8e8e8 !important;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      .label-product {
        display: flex;
      }
      .item-app-top {
        padding: 12px;
        border-bottom: 1px solid #e8e8e8 !important;
      }
      .item-app-bottom {
        padding: 12px;
        padding-bottom: 0;
        padding-left: 0;
        opacity: 0.7;
      }
      .label-package {
        font-size: 12px;
        opacity: 0.5;
      }
      .label-product {
        opacity: 0.8;
      }
    }
    .item-app-copy-active {
      border: 1px solid $color-primary !important;
      background: $color-white-f5e;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
    }
    .item-app-copy:hover {
      border: 1px solid $color-primary !important;
    }
    .item-app-active {
      border: 1px solid $color-primary;
      background: $color-white-f5e;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
    }
  }
  .modal-copyapp-body {
    padding: 16px;
    background: #f9f9f9;
    border: 1px solid #e8e8e8 !important;
  }
  .custome-switch {
    position: absolute;
    right: 8px;
    top: 3px;
  }
}
</style>
