<template>
  <div :style="{ minHeight: '360px' }">
    <AppLayout>
      <div class="title-page-new">
        <PageBreadcrumb :datapagebreadcrumb="dataPageBreadcrumb">
        </PageBreadcrumb>
      </div>
      <div class="manage-role-setrole-test manage-page-detail">
        <div id="mypage">
          <div v-if="objSelect.name" class="page-wrapper">
            <a-page-header>
              <template slot="title">
                <div class="d-flex">
                  <div v-if="objSelect.is_active">
                    <img class="icon-width-18-custom" :src="IConActive" />
                  </div>
                  <div v-else>
                    <img class="icon-width-18-custom" :src="IConInctive" />
                  </div>
                  <div class="ml-2">{{ objSelect.name }}</div>
                </div>
              </template>
              <template slot="extra">
                <span>
                  <a-tooltip>
                    <template slot="title">
                      {{ mylbl("role_page.btn.assign_to_role") }}
                    </template>
                    <a-button
                      class="btn-custom-edit"
                      @click="goTo('ManageAssigntoRole')"
                    >
                      <img class="icon-width-18" :src="IConAddUser" />
                    </a-button>
                  </a-tooltip>
                </span>
                <a-divider type="vertical" />
                <span>
                  <a-tooltip>
                    <template slot="title">
                      {{ mylbl("role_page.btn.edit") }}
                    </template>
                    <a-button class="btn-custom-edit" @click="handleEdit()">
                      <img class="icon-width-18" :src="IConEdit" />
                    </a-button>
                  </a-tooltip>
                </span>
                <a-divider type="vertical" />
                <span>
                  <a-tooltip>
                    <template slot="title">
                      {{ mylbl("role_page.btn.delete") }}
                    </template>
                    <a-button class="btn-custom-edit" @click="handleDelete()">
                      <img class="icon-width-18" :src="IConDelete" />
                    </a-button>
                  </a-tooltip>
                </span>
              </template>
            </a-page-header>
          </div>
          <div class="description">
            {{ objSelect.description }}
          </div>
        </div>
        <a-skeleton v-show="isShowSkeleton" class="mt-3 body-detail" />
        <div v-show="!isShowSkeleton" id="body_detail" class="body-detail-test">
          <div
            v-for="(item, index) in data"
            :key="item.subscription.id"
            class="box-data-test"
          >
            <div
              class="box-header"
              :class="{
                'row-even': index % 2 == 0,
                'row-active': !item.collapsed,
                'item-icon-active': !item.collapsed,
              }"
              @click="toggle(item)"
            >
              <div class="header-title">
                <span>
                  {{ item.package.name }}
                </span>
              </div>
              <div v-if="item.products" class="header-icon">
                <span>
                  <img class="icon-width-10" :src="IConPolygon" />
                </span>
              </div>
            </div>
            <div v-if="!item.collapsed" class="box-detail">
              <div v-if="item.products" class="box-item">
                <div
                  class="item-header"
                  :class="{ 'item-header-active': item.products.collapsed }"
                >
                  <div class="item-title">
                    <span> {{ mylbl("role_page.title_product") }} </span>
                  </div>
                  <div
                    class="item-icon"
                    :class="{ 'item-icon-active': !item.products.collapsed }"
                    @click="toggle(item.products)"
                  >
                    <span>
                      <img class="icon-width-10" :src="IConPolygon" />
                    </span>
                  </div>
                </div>
                <div v-if="!item.products.collapsed" class="item-detail">
                  <div v-for="product in item.products" :key="product.id">
                    <div class="item-product">
                      <div class="product-title">
                        <div class="icons-manage">
                          <span>
                            <a-tooltip>
                              <template slot="title">
                                {{ mylbl("role_page.btn.manage") }}
                              </template>
                              <a-button
                                class="btn-custom-edit"
                                @click="
                                  handleEditrecord(
                                    item.subscription,
                                    product,
                                    'product'
                                  )
                                "
                              >
                                <img class="icon-width-16" :src="IConManage" />
                              </a-button>
                            </a-tooltip>
                          </span>
                        </div>
                        <div class="title-label">{{ product.name }}</div>
                      </div>
                      <div class="product-detail">
                        <div v-if="product.isEditShowCheckbox">
                          <div
                            v-if="product.data_levels"
                            class="title-label-item"
                          >
                            {{ mylbl("role_page.title_data_level") }}
                          </div>
                          <div
                            v-if="
                              product.data_levels && product.isShowListDatavl
                            "
                            style="padding-bottom: 10px"
                          >
                            <a-radio-group
                              v-model="valueDataLv"
                              :options="product.dataLv"
                              @change="onChangeRadio"
                            />
                          </div>
                          <div class="title-label-item">
                            {{ mylbl("role_page.title_features") }}
                          </div>
                          <div
                            v-if="product.listOfFeature.length > 1"
                            class="checkall-role"
                          >
                            <a-checkbox
                              :indeterminate="
                                product.valuecheckbox.length > 0 &&
                                product.valuecheckbox.length <
                                  product.features.length
                              "
                              :checked="
                                product.features.length ==
                                product.valuecheckbox.length
                              "
                              class="check-all-lable"
                              @change="onCheckAllFeature(product)"
                            >
                              Check all
                            </a-checkbox>
                          </div>
                          <div>
                            <a-checkbox-group
                              v-model="product.valuecheckbox"
                              class="list-item"
                              name="checkboxgroup"
                              :options="product.listOfFeature"
                              @change="onChangesCheckbox"
                            />
                          </div>
                          <div class="btn-edit-item">
                            <span>
                              <a-button
                                class="btn-custom-edit-package"
                                @click="handleBackrecord()"
                              >
                                {{ mylbl("role_page.btn.cancel") }}
                              </a-button>
                            </span>
                            <span>
                              <a-button
                                type="primary"
                                class="btn-custom-save"
                                @click="
                                  handleSaverecord(
                                    item.subscription,
                                    product,
                                    'product'
                                  )
                                "
                              >
                                {{ mylbl("role_page.btn.save") }}
                              </a-button>
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-if="product.data_levels"
                            class="title-label-item"
                          >
                            {{ mylbl("role_page.title_data_level") }}
                          </div>
                          <div
                            v-show="
                              product.data_levels && !product.isShowListDatavl
                            "
                            style="padding-bottom: 10px"
                          >
                            <a-radio-group
                              v-model="product.isdefault"
                              disabled
                              :options="product.data_levels"
                              @change="onChangeRadio"
                            />
                          </div>
                          <div class="title-label-item">
                            {{ mylbl("role_page.title_features") }}
                          </div>
                          <div
                            v-for="feature in product.features"
                            :key="feature.id"
                            class="features-items"
                          >
                            <div style="display: flex">
                              <div>
                                <span v-if="feature.is_selected">
                                  <span class="status-icon active"> </span>
                                </span>
                                <span v-if="!feature.is_selected">
                                  <span class="status-icon inactive"> </span>
                                </span>
                              </div>
                              <div class="ml-2">{{ feature.name }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.applications" class="box-item">
                <div
                  class="item-header"
                  :class="{
                    'item-header-active': item.applications.collapsed,
                  }"
                >
                  <div class="item-title">
                    <span v-if="!item.applications.collapsed">
                      <a-tooltip>
                        <template slot="title">
                          {{ mylbl("role_page.btn.manage") }}
                        </template>
                        <a-button
                          class="btn-custom-edit"
                          @click="handleEditrecord('', item, 'applications')"
                        >
                          <img class="icon-width-16" :src="IConManage" />
                        </a-button>
                      </a-tooltip>
                    </span>
                    <span> {{ mylbl("role_page.title_application") }} </span>
                  </div>
                  <div
                    class="item-icon"
                    :class="{
                      'item-icon-active': !item.applications.collapsed,
                    }"
                    @click="toggle(item.applications)"
                  >
                    <span>
                      <img class="icon-width-10" :src="IConPolygon" />
                    </span>
                  </div>
                </div>
                <div
                  v-if="!item.applications.collapsed"
                  class="item-detail custom-cross"
                >
                  <div v-if="item.isEditShowCheckboxApp">
                    <div class="checkall-role">
                      <a-checkbox
                        :indeterminate="
                          item.valuecheckboxApp.length > 0 &&
                          item.valuecheckboxApp.length <
                            item.applications.length
                        "
                        :checked="
                          item.applications.length ==
                          item.valuecheckboxApp.length
                        "
                        @change="onCheckAllApplication(item)"
                      >
                        Check all
                      </a-checkbox>
                    </div>
                    <div>
                      <a-checkbox-group
                        v-model="item.valuecheckboxApp"
                        class="list-feature"
                        name="checkboxgroup"
                        :options="item.listOfFeatureApp"
                        @change="onChangesCheckbox"
                      />
                    </div>
                    <div
                      v-if="item.isEditShowCheckboxApp"
                      class="btn-edit-item"
                    >
                      <span>
                        <a-button
                          class="btn-custom-edit-package"
                          @click="handleBackrecord()"
                        >
                          {{ mylbl("role_page.btn.cancel") }}
                        </a-button>
                      </span>
                      <span>
                        <a-button
                          class="btn-custom-save"
                          @click="
                            handleSaverecord(
                              item.subscription,
                              item,
                              'applications'
                            )
                          "
                        >
                          {{ mylbl("role_page.btn.save") }}
                        </a-button>
                      </span>
                    </div>
                  </div>
                  <div v-else class="feature-list">
                    <div
                      v-for="application in item.applications"
                      :key="application.id"
                      class="features-item"
                    >
                      <div class="d-flex">
                        <div>
                          <span
                            v-if="application.is_selected"
                            class="status-icon active"
                          >
                          </span>
                          <span
                            v-if="!application.is_selected"
                            class="status-icon inactive"
                          >
                          </span>
                        </div>
                        <div class="ml-2">{{ application.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.cross_applications" class="box-item">
                <div
                  class="item-header"
                  :class="{
                    'item-header-active': item.cross_applications.collapsed,
                  }"
                >
                  <div class="item-title">
                    <span v-if="!item.cross_applications.collapsed">
                      <a-tooltip>
                        <template slot="title">
                          {{ mylbl("role_page.btn.manage") }}
                        </template>
                        <a-button
                          class="btn-custom-edit"
                          @click="handleEditrecord('', item, 'cross')"
                        >
                          <img class="icon-width-16" :src="IConManage" />
                        </a-button>
                      </a-tooltip>
                    </span>
                    <span> {{ mylbl("role_page.title_cross") }} </span>
                  </div>
                  <div
                    class="item-icon"
                    :class="{
                      'item-icon-active': !item.cross_applications.collapsed,
                    }"
                    @click="toggle(item.cross_applications)"
                  >
                    <span>
                      <img class="icon-width-10" :src="IConPolygon" />
                    </span>
                  </div>
                </div>
                <div
                  v-if="!item.cross_applications.collapsed"
                  class="item-detail custom-cross"
                >
                  <div v-if="item.isEditShowCheckboxCross">
                    <div class="checkall-role">
                      <a-checkbox
                        :indeterminate="
                          item.valuecheckboxCross.length > 0 &&
                          item.valuecheckboxCross.length <
                            item.cross_applications.length
                        "
                        :checked="
                          item.cross_applications.length ==
                          item.valuecheckboxCross.length
                        "
                        @change="onCheckAllCross(item)"
                      >
                        Check all
                      </a-checkbox>
                    </div>
                    <div>
                      <a-checkbox-group
                        v-model="item.valuecheckboxCross"
                        name="checkboxgroup"
                        class="list-feature"
                        :options="item.listOfFeatureCross"
                        @change="onChangesCheckbox"
                      />
                    </div>
                    <div class="btn-edit-item">
                      <span v-if="item.isEditShowCheckboxCross">
                        <a-button
                          class="btn-custom-edit-package"
                          @click="handleBackrecord()"
                        >
                          {{ mylbl("role_page.btn.cancel") }}
                        </a-button>
                      </span>
                      <span v-if="item.isEditShowCheckboxCross">
                        <a-button
                          type="primary"
                          class="btn-custom-save"
                          @click="
                            handleSaverecord(item.subscription, item, 'cross')
                          "
                        >
                          {{ mylbl("role_page.btn.save") }}
                        </a-button>
                      </span>
                      <span v-if="!item.isEditShowCheckboxCross">
                        <a-tooltip>
                          <template slot="title">
                            {{ mylbl("role_page.btn.manage") }}
                          </template>
                          <a-button
                            class="btn-custom-edit"
                            @click="handleEditrecord('', item, 'cross')"
                          >
                            <img class="icon-width-18" :src="IConManage" />
                          </a-button>
                        </a-tooltip>
                      </span>
                    </div>
                  </div>
                  <div v-else class="feature-list">
                    <div
                      v-for="cross in item.cross_applications"
                      :key="cross.id"
                      class="features-item"
                    >
                      <div class="d-flex">
                        <div>
                          <span
                            v-if="cross.is_selected"
                            class="status-icon active"
                          >
                          </span>
                          <span
                            v-if="!cross.is_selected"
                            class="status-icon inactive"
                          >
                          </span>
                        </div>
                        <div class="ml-2">{{ cross.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div v-if="isModalProcess">
        <ModalProcess
          :modalprocess="modalprocess"
          :dataobjselect="dataObjSelect"
          @actionModal="actionModal($event)"
        >
        </ModalProcess>
      </div>
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
import PageBreadcrumb from "@/module/Role/ManageSetRole/components/Layout/PageBreadcrumb.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    PageBreadcrumb,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      isShowSkeleton: true,
      data: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      tabActive: "product",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callGetList();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.mylbl("role_page.drw_title_edit");
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.mylbl("role_page.modal.btn.delete");
      this.titleModal = this.mylbl("role_page.modal.title_confirm");
      this.contentModal =
        this.mylbl("role_page.modal.content_delete") +
        this.objSelect.name +
        " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    goTo(page) {
      this.$router.push({
        name: page,
        query: { id: this.$route.query.id },
      });
    },
    toggle(value) {
      if (
        !value.length &&
        !value.applications &&
        !value.applications &&
        !value.products
      )
        return;
      let newvalue = value.collapsed;
      if (newvalue) {
        newvalue = false;
      } else {
        newvalue = true;
      }
      value.collapsed = newvalue;
      this.handleBackrecord();
      this.$forceUpdate();
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data) {
            if (res.data.result) {
              if (res.data.result.items) {
                res.data.result.items.forEach((element) => {
                  element.id = element.package.id;
                  element.collapsed = true;
                  if (element.products && element.products.length > 0) {
                    element.products.collapsed = false;
                    element.products.forEach((products) => {
                      products.isshow = true;
                      products.idParent = element.package.id;
                      if (
                        products.data_levels &&
                        products.data_levels.length > 0
                      ) {
                        products.data_levels.forEach((data_levels) => {
                          data_levels.label = data_levels.name;
                          data_levels.value = data_levels.id;
                          if (data_levels.is_selected) {
                            products.isdefault = data_levels.id;
                          }
                        });
                      }
                      if (products.features && products.features.length > 0) {
                        products.features.forEach((features) => {
                          features.idParent = products.id;
                        });
                      }
                    });
                  }
                  if (element.applications && element.applications.length > 0) {
                    element.applications.collapsed = false;
                  }
                  if (
                    element.cross_applications &&
                    element.cross_applications.length > 0
                  ) {
                    element.cross_applications.collapsed = false;
                  }
                });
                this.data = res.data.result.items;
                this.totalList = this.data.length;
                console.log(this.data);
                this.dataPageBreadcrumb = {
                  totalList: this.totalList,
                };
                this.myoffsetHeight =
                  document.getElementById("mypage").offsetHeight;
                var element = document.getElementById("body_detail");
                this.myoffsetHeight = this.myoffsetHeight + 15;
                element.style.height =
                  "calc(100% - " + this.myoffsetHeight + "px)";
              }
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        role_description: this.objDataSave.description,
        is_active: this.is_activeForShow,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.mylbl("role_page.modal.title_success");
                this.contentModal = this.mylbl(
                  "role_page.modal.content_update_success"
                );
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.mylbl("role_page.modal.title_success");
              this.contentModal = this.mylbl(
                "role_page.modal.content_delete_success"
              );
              this.openNotificationWithIcon("success");
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.postDeleteData();
      } else {
        this.postEditData();
      }
      this.isModalResponse = false;
    },
    handlemodalCancel() {
      this.errtransaction_id = "";
      this.isModalResponse = false;
      this.isDelete = false;
    },
    handlemodalCancelError() {
      if (this.isDelete) {
        this.$router.push({ name: "ManageRoles" });
      }
      if (this.ispostPermission) {
        this.ispostPermission = false;
        this.callGetList();
      }
      this.errtransaction_id = "";
      this.isModalResponse = false;
      if (this.idModal == "success") {
        this.isEdit = false;
        this.page = 0;
        this.page_size = 20;
        this.data = [];
        if (!this.isDelete) {
          this.callGetList();
        }
        this.isDelete = false;
        this.closeModalProcess();
      }
      if (this.err_status == 401) {
        this.$router.push({ name: "Home" });
      }
      if (this.err_id == 2001) {
        this.$router.push({ name: "CheckPermission" });
      }
      if (this.err_id == 13001 || this.err_id == 13002) {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    onCheckAllFeature(item) {
      if (item.valuecheckbox && item.valuecheckbox.length > 0) {
        item.valuecheckbox = [];
      } else {
        item.features.forEach((features) => {
          item.valuecheckbox.push(features.id);
        });
      }
      this.$forceUpdate();
    },
    onCheckAllApplication(item) {
      if (item.valuecheckboxApp && item.valuecheckboxApp.length > 0) {
        item.valuecheckboxApp = [];
      } else {
        item.applications.forEach((application) => {
          item.valuecheckboxApp.push(application.id);
        });
      }
      this.$forceUpdate();
    },
    onCheckAllCross(item) {
      if (item.valuecheckboxCross && item.valuecheckboxCross.length > 0) {
        item.valuecheckboxCross = [];
      } else {
        item.cross_applications.forEach((cross_applications) => {
          item.valuecheckboxCross.push(cross_applications.id);
        });
      }
      this.$forceUpdate();
    },
    onChangesCheckbox() {
      this.$forceUpdate();
    },
    onChangeRadio(e) {
      this.valueDataLvRadio = [];
      this.valueDataLvRadio = e.target.value;
    },
    handleEditrecord(sub, objdata, from) {
      this.resetFieldOther();
      if (from == "product") {
        if (objdata.features) {
          if (objdata.features.length > 0) {
            objdata.features.forEach((element) => {
              this.listOfFeature.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckbox.push(element.id);
              }
            });
          }
        }
        if (objdata.data_levels) {
          if (objdata.data_levels.length > 0) {
            this.isShowListDatavl = true;
            objdata.data_levels.forEach((element) => {
              this.listOfDatalv.push({
                label: element.name,
                value: element.id,
                is_selected: element.is_selected,
                key: 1,
              });
              if (element.is_selected) {
                this.valueDataLv = element.id;
              }
            });
            const newData = {
              label: "None",
              value: "",
              key: 0,
            };
            this.listOfDatalv.push(newData);
            this.listOfDatalv.sort((a, b) => a.key - b.key);
          }
        }
        if (objdata.features || objdata.features) {
          this.data.forEach((element) => {
            if (element.subscription.id == sub.id) {
              if (element.products.length > 0) {
                element.products.forEach((products) => {
                  if (products.id == objdata.id) {
                    products.isEditShowCheckbox = true;
                    products.valuecheckbox = this.valuecheckbox;
                    products.listOfFeature = this.listOfFeature;
                    products.dataLv = this.listOfDatalv;
                    products.isShowListDatavl = this.isShowListDatavl;
                  }
                });
              }
            }
          });
        }
      }
      if (from == "applications") {
        if (objdata.applications) {
          if (objdata.applications.length > 0) {
            objdata.applications.forEach((element) => {
              this.listOfFeatureApp.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckboxApp.push(element.id);
              }
            });
          }
          this.data.forEach((element) => {
            if (element.subscription.id == objdata.subscription.id) {
              if (element.applications.length > 0) {
                element.isEditShowCheckboxApp = true;
                element.listOfFeatureApp = this.listOfFeatureApp;
                element.valuecheckboxApp = this.valuecheckboxApp;
              }
            }
          });
        }
      }
      if (from == "cross") {
        if (objdata.cross_applications) {
          if (objdata.cross_applications.length > 0) {
            objdata.cross_applications.forEach((element) => {
              this.listOfFeatureCross.push({
                label: element.name,
                value: element.id,
              });
              if (element.is_selected) {
                this.valuecheckboxCross.push(element.id);
              }
            });
          }
          this.data.forEach((element) => {
            if (element.subscription.id == objdata.subscription.id) {
              if (element.applications.length > 0) {
                element.isEditShowCheckboxCross = true;
                element.listOfFeatureCross = this.listOfFeatureCross;
                element.valuecheckboxCross = this.valuecheckboxCross;
              }
            }
          });
        }
      }
    },
    handleBackrecord() {
      this.resetFieldOther();
    },
    handleSaverecord(sub, objdata, from) {
      this.handleLoading(true);
      this.dataSelect = objdata;
      this.clickfrom = from;
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
        subscription_id: sub.id,
      };
      if (from == "product") {
        record.product_id = objdata.id;
        if (objdata.valuecheckbox && objdata.valuecheckbox.length > 0) {
          record.feature_ids = objdata.valuecheckbox;
        }
        if (this.valueDataLvRadio != "" || this.valueDataLv.length > 0) {
          record.data_level_id = this.valueDataLvRadio
            ? this.valueDataLvRadio
            : this.valueDataLv;
        }
        record = JSON.stringify(record);
        this.postPermissionProduct(record);
      }
      if (from == "applications") {
        if (objdata.valuecheckboxApp.length > 0) {
          record.application_ids = objdata.valuecheckboxApp;
        }
        record = JSON.stringify(record);
        this.dataSelect = objdata;
        this.postPermissionApplication(record);
      }
      if (from == "cross") {
        if (objdata.valuecheckboxCross.length > 0) {
          record.cross_application_ids = objdata.valuecheckboxCross;
        }
        record = JSON.stringify(record);
        this.postPermissionCross(record);
      }
    },
    postPermissionProduct(record) {
      manageRoleAPI
        .postPermissionProduct(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.mylbl("role_page.modal.title_success");
                this.contentModal = this.mylbl(
                  "role_page.modal.content_update_success"
                );
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postPermissionApplication(record) {
      manageRoleAPI
        .postPermissionApplication(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.mylbl("role_page.modal.title_success");
                this.contentModal = this.mylbl(
                  "role_page.modal.content_update_success"
                );
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    postPermissionCross(record) {
      manageRoleAPI
        .postPermissionCross(record)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
              setTimeout(() => {
                this.titleModal = this.mylbl("role_page.modal.title_success");
                this.contentModal = this.mylbl(
                  "role_page.modal.content_update_success"
                );
                this.openNotificationWithIcon("success");
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    resetFieldOther() {
      this.valueDataLv = "";
      this.valueDataLvRadio = "";
      this.isShowListDatavl = false;
      this.listOfFeature = [];
      this.valuecheckbox = [];
      this.listOfDatalv = [];
      this.valuecheckboxApp = [];
      this.listOfFeatureApp = [];
      this.valuecheckboxCross = [];
      this.listOfFeatureCross = [];
      this.data.forEach((element) => {
        element.isEditShowCheckboxApp = false;
        element.isEditShowCheckboxCross = false;
        if (element.products) {
          if (element.products.length > 0) {
            element.products.forEach((products) => {
              products.isEditShowCheckbox = false;
              products.isShowListDatavl = false;
            });
          }
        }
      });
      let temp = this.data;
      this.data = [];
      this.data = temp;
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = true;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else if (this.clickfrom == "product") {
        this.setDataProduct();
      } else if (this.clickfrom == "applications") {
        this.setDataApp();
      } else if (this.clickfrom == "cross") {
        this.setDataCross();
      } else {
        this.handleLoading(false);
        this.isShowSkeleton = false;
      }
    },
    setDataProduct() {
      let idDataLevel = this.valueDataLvRadio
        ? this.valueDataLvRadio
        : this.valueDataLv;
      let findpackage = this.data.find(
        (o) => o.id === this.dataSelect.idParent
      );
      let findproducts = findpackage.products.find(
        (o) => o.id === this.dataSelect.id
      );
      if (findproducts) {
        findproducts.features.forEach((features) => {
          features.is_selected = false;
        });
        if (findproducts.valuecheckbox.length > 0) {
          findproducts.valuecheckbox.forEach((element) => {
            let findfeatures = findproducts.features.find(
              (o) => o.id === element
            );
            findfeatures.is_selected = true;
          });
        }
        if (idDataLevel) {
          findproducts.isdefault = idDataLevel;
          findproducts.data_levels.forEach((data_levels) => {
            data_levels.is_selected = false;
          });
          findproducts.dataLv.forEach((dataLv) => {
            dataLv.is_selected = false;
          });
          let finddata_levels = findproducts.data_levels.find(
            (o) => o.id === idDataLevel
          );
          let findDataLevel = findproducts.dataLv.find(
            (o) => o.value === idDataLevel
          );
          if (finddata_levels) finddata_levels.is_selected = true;
          if (findDataLevel) findDataLevel.is_selected = true;
        } else {
          findproducts.isdefault = "";
        }
      }
      this.resetShowdata();
    },
    setDataApp() {
      let findpackage = this.data.find((o) => o.id === this.dataSelect.id);
      findpackage.applications.forEach((applications) => {
        applications.is_selected = false;
      });
      if (findpackage.valuecheckboxApp.length > 0) {
        findpackage.valuecheckboxApp.forEach((element) => {
          let findcross = findpackage.applications.find(
            (o) => o.id === element
          );
          findcross.is_selected = true;
        });
      }
      this.resetShowdata();
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.postEditData();
        }
      }
    },
    setDataCross() {
      let findpackage = this.data.find((o) => o.id === this.dataSelect.id);
      findpackage.cross_applications.forEach((cross) => {
        cross.is_selected = false;
      });
      if (findpackage.valuecheckboxCross.length > 0) {
        findpackage.valuecheckboxCross.forEach((element) => {
          let findcross = findpackage.cross_applications.find(
            (o) => o.id === element
          );
          findcross.is_selected = true;
        });
      }
      this.resetShowdata();
    },
    resetShowdata() {
      this.resetFieldOther();
      this.handleLoading(false);
      this.isShowSkeleton = false;
    },
    handleErrorApi(error) {
      this.idModal = "error";
      let errorcontent = "Is Something Error";
      this.err_id = "";
      this.err_status = "";
      if (error.response) {
        this.err_status = error.response.status;
        if (this.err_status == 401) {
          errorcontent = this.mylbl(
            "layoutmaster.modal.msg_content_unauthorized"
          );
          this.errorApi();
        } else {
          if (error.response.statusText) {
            errorcontent = error.response.statusText;
            this.errtransaction_id = error.response.data.transaction_id
              ? error.response.data.transaction_id
              : "";
            if (error.response.data.error) {
              errorcontent =
                error.response.data && error.response.data.error.length > 0
                  ? error.response.data.error[0].message
                  : error.response.statusText;
              this.err_id = error.response.data.error[0].id;
            }
          }
          setTimeout(() => {
            this.isModalResponse = true;
            this.titleModal = this.mylbl("role_page.modal.title_error");
            this.contentModal = errorcontent;
            this.handleLoading(false);
          }, 500);
        }
      }
    },
    menuClick(item, e) {
      let isShow = Object.keys(item).some((i) => i == e);
      if (isShow) {
        item.tabActive = e;
        this.tabActive = e;
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
$padding-10: 10px;
#body_detail {
  border-top: 1px solid $grey-grey-7;
}
.manage-role-setrole-test {
  overflow: hidden !important;
  padding: 0 !important;
  padding-bottom: 10px;
  .ant-page-header-heading {
    display: block;
    .ant-page-header-heading-title {
      margin-top: 4px;
      font-size: 20px !important;
    }
    .ant-page-header-heading-tags {
      margin: 10px 0 !important;
    }
  }
  .body-detail-test {
    $width250: 250px;
    $padding8: 8px;
    $padding16: 16px;
    $padding24: 24px;
    $padding32: 32px;
    overflow: auto;
    .box-header {
      display: flex;
      align-items: center;
      justify-content: space-between; /* background-color: $grey-grey-7; */
      padding: $padding8 $padding24;
      font-size: 18px;
      border-bottom: 1px solid $grey-grey-7;
      cursor: pointer;
    }
    .box-header:hover {
      background-color: $grey-grey-8;
    }
    .row-even {
      background-color: $grey-grey-9;
    }
    .row-active {
      background-color: $grey-grey-7;
    }
    .row-active:hover {
      background-color: $grey-grey-7;
    }
    .item-icon-active {
      img {
        transform: rotate(180deg);
      }
    }
    .box-detail {
      .box-item {
        .item-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          background-color: $grey-grey-10;
          .item-title {
            width: 50%;
            padding: $padding8 $padding32;
          }
          .item-icon {
            width: 50%;
            padding: $padding8 $padding32;
            text-align: right;
            cursor: pointer;
          }
        }
        .item-header-active {
          border-bottom: 1px solid $grey-grey-7;
        }
        .custom-cross {
          padding: $padding8 $padding32;
          border-bottom: 1px solid $grey-grey-7;
        }
        .item-detail {
          background-color: #f6f6f7;
          .status-icon {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-top: -3px;
          }
          .active {
            background: #2eb506;
          }
          .inactive {
            background: #ff2d2d;
          }
          .icons-manage {
            width: 20px;
          }
          .title-label-item {
            font-size: 12px;
            line-height: 1.5;
            color: $grey-grey-6;
          }
          .item-product {
            display: flex;
            border-bottom: 1px solid $grey-grey-7;
            .product-title {
              display: flex;
              padding: $padding8 0 $padding8 $padding32;
              width: $width250;
              border-right: 1px solid $grey-grey-7;
              .title-label {
                width: calc(100% - 20px);
              }
            }
            .product-detail {
              width: calc(100% - $width250);
              padding: $padding8;
              color: $grey-grey-4;
              .features-items {
                display: inline-table;
                @include flexcol(12%);
                @include breakpoint-max($desktop-xxxl) {
                  @include flexcol(20%);
                }
                @include breakpoint-max($desktop-xxl) {
                  @include flexcol(25%);
                }
                @include breakpoint-max($desktop-xs) {
                  @include flexcol(33.3%);
                }
                @include breakpoint-max($tablet-pro) {
                  @include flexcol(50%);
                }
                @include breakpoint-max($tablet-xs) {
                  @include flexcol(100%);
                }
              }
              .features-item {
                display: flex;
                align-items: center;
              }
              .ant-checkbox-wrapper {
                color: $grey-grey-4 !important;
              }
            }
          }
          .list-item {
            width: 100%;
            .ant-checkbox-group-item {
              margin-right: 0;
              display: inline-table;
              @include flexcol(12%);
              @include breakpoint-max($desktop-xxxl) {
                @include flexcol(20%);
              }
              @include breakpoint-max($desktop-xxl) {
                @include flexcol(25%);
              }
              @include breakpoint-max($desktop-xs) {
                @include flexcol(33.3%);
              }
              @include breakpoint-max($tablet-pro) {
                @include flexcol(50%);
              }
              @include breakpoint-max($tablet-xs) {
                @include flexcol(100%);
              }
            }
          }
          .item-cross {
            .detail {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .checkall-role {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .btn-custom-edit-package,
  .btn-custom-save {
    border-radius: 2px !important;
  }
  .page-wrapper {
    padding: 12px 12px 0;
  }
  .description {
    margin-bottom: 15px;
    color: $grey-grey-5;
    line-height: 1.5;
    padding: 0 12px;
    white-space: pre-line;
  }
  .btn-edit-item {
    text-align: right;
    margin-top: 25px;
  }
  .feature-list {
    width: 100%;
    .features-item {
      display: inline-table;
      @include flexcol(12%);
      @include breakpoint-max($desktop-xxxl) {
        @include flexcol(20%);
      }
      @include breakpoint-max($desktop-xxl) {
        @include flexcol(25%);
      }
      @include breakpoint-max($desktop-xs) {
        @include flexcol(33.3%);
      }
      @include breakpoint-max($tablet-pro) {
        @include flexcol(50%);
      }
      @include breakpoint-max($tablet-xs) {
        @include flexcol(100%);
      }
    }
  }
}
</style>
