<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
          <span v-if="modalprocess.isEdit" class="float-right">
            <span class="title-status">
              {{ lbls.application_page.tbl_colum_status }}
            </span>
            <span>
              <a-tooltip>
                <template slot="title">
                  <span v-if="objDataSave.is_active">
                    {{ lbls.application_page.title_status_active }}
                  </span>
                  <span v-else>
                    {{ lbls.application_page.title_status_inactive }}
                  </span>
                </template>
                <a-switch
                  :disabled="!isPermissionEdit"
                  :checked="objDataSave.is_active"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </span>
          </span>
        </div>
      </template>
      <div>
        <a-form :form="form" layout="vertical">
          <div
            v-if="
              (isPermissionView && fileListData.length > 0) || isPermissionEdit
            "
            class="config-gallery mb-3"
            @dragover.prevent
            @drop.prevent
          >
            <input
              ref="file"
              type="file"
              class="d-none"
              accept="image/jpeg, image/png"
              multiple
              @change="handleChange"
            />
            <label> {{ lbls.application_page.img_upload.name }} </label>
            <div class="card-image-list">
              <draggable v-model="fileListData" class="draggable-list w-100">
                <div
                  v-for="item in fileListData"
                  :key="item.picture_id"
                  class="card-item"
                >
                  <div class="card-image">
                    <img
                      class="image"
                      :src="
                        item.picture.includes(';base64') ||
                        item.picture.includes('http')
                          ? item.picture
                          : 'data:' +
                            item.file_type +
                            ';base64, ' +
                            item.picture
                      "
                      @error="handleError"
                    />
                    <div
                      v-if="fileListData.length > 0 && isPermissionEdit"
                      class="actions"
                    >
                      <a type="primary" @click="handleOpenPreview()">
                        <a-icon type="eye" />
                      </a>
                      <a-popconfirm
                        :title="lbls.application_page.modal.content_delete_img"
                        :ok-text="lbls.application_page.modal.btn.delete"
                        :cancel-text="lbls.btn.cancel"
                        @confirm="handledelete(item)"
                      >
                        <a><a-icon type="delete" /></a>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
                <div
                  v-show="fileListData.length < 1"
                  slot="footer"
                  class="upload-image-btn"
                  @click="clickUpload()"
                  @drop="dragFile"
                >
                  <div>
                    <span class="icon"
                      ><a-icon type="plus" class="fs-30"
                    /></span>
                    <span class="text">Upload</span>
                  </div>
                </div>
              </draggable>
            </div>
            <div class="text-secondary-custom mt-1 text-right">
              {{ lbls.application_page.img_upload.placeholder }}
            </div>
          </div>
          <a-form-item :label="lbls.application_page.input_appid.name">
            <a-input
              v-decorator="[
                'applicationprefix',
                {
                  initialValue: objDataSave.buzzebees_application_Id,
                },
              ]"
              :placeholder="lbls.application_page.input_appid.placeholder"
              disabled
              :max-length="100"
            />
          </a-form-item>
          <p class="total-char">
            {{ objDataSave.buzzebees_application_Id.length.toLocaleString() }}
            /
            {{ maxLengAppID.toLocaleString() }}
          </p>
          <a-form-item :label="lbls.application_page.tbl_colum_name">
            <a-input
              v-decorator="[
                'name',
                {
                  initialValue: objDataSave.name,
                },
              ]"
              disabled
              :placeholder="lbls.application_page.input_name.placeholder"
              :max-length="maxLengName"
              @keyup="countChar"
            />
          </a-form-item>
          <p class="total-char" href="">
            {{ objDataSave.name.length.toLocaleString() }} /
            {{ maxLengName.toLocaleString() }}
          </p>
          <a-form-item
            :label="lbls.application_page.tbl_colum_description"
            :validate-status="checkError('description') ? 'error' : ''"
            :help="checkError('description') || ''"
          >
            <a-textarea
              v-decorator="[
                'description',
                {
                  initialValue: objDataSave.description,
                  rules: [
                    {
                      required: true,
                      message: lbls.application_page.input_description.required,
                    },
                    {
                      min: 4,
                      message: lbls.application_page.input_description.min,
                    },
                  ],
                },
              ]"
              :placeholder="lbls.application_page.input_description.placeholder"
              :max-length="maxLengDescription"
              :rows="4"
              :disabled="!isPermissionEdit"
              @keyup="countChar"
            />
          </a-form-item>
          <p class="total-char" href="">
            {{ objDataSave.description.length.toLocaleString() }} /
            {{ maxLengDescription.toLocaleString() }}
          </p>
        </a-form>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
    <!-- Modal Preview -->
    <a-modal v-model="modal2Visible" class="preview-image" centered footer="">
      <div class="p-3">
        <div v-for="(item, key) in fileListData" :key="key">
          <img
            class="image"
            :src="
              item.picture.includes(';base64') || item.picture.includes('http')
                ? item.picture + '?v=' + datetimestamp
                : 'data:' +
                  item.file_type +
                  ';base64, ' +
                  item.picture +
                  '?v=' +
                  datetimestamp
            "
          />
        </div>
      </div>
    </a-modal>
    <!-- Modal Preview -->
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import fileReader from "@/helper/fileReader";
import draggable from "vuedraggable";
export default {
  name: "ManageProduct",
  components: { draggable },
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "application",
      form: this.$form.createForm(this),
      logo: require("@/assets/images/bzbs-logo1.png"),
      maxLengAppID: 100,
      maxLengName: 100,
      maxLengDescription: 100,
      disabledok: true,
      selectPackage: [],
      objDataSave: {},
      fileReader,
      fileListData: [],
      modal2Visible: false,
      datetimestamp: moment().format("x"),
      isPermissionView: false,
      isPermissionEdit: true,
    };
  },
  watch: {
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      console.log(this.lbls, "lbls");
      this.objDataSave = _.cloneDeep(this.dataobjselect);
    
      this.objDataSave = Object.keys(this.objDataSave)
        .filter((key) => !key.includes("allPackage"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: this.objDataSave[key],
          });
        }, {});
      if (this.objDataSave.logo_url) {
        this.fileListData.push({
          picture: this.objDataSave.logo_url,
        });
      }
      this.dataEditForCheck = JSON.stringify(_.cloneDeep(this.objDataSave));

      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        this.isPermissionView = permission_page.includes("application-view");
        this.isPermissionEdit = permission_page.includes("application-edit");
      }
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      if (this.isEnablePhoto) {
        if (this.fileListData.length > 0) {
          this.objDataSave.application_logo = this.fileListData[0].picture_file;
        } else {
          this.objDataSave.application_logo = "-";
        }
      }
      this.$emit("actionModal", this.objDataSave);
    },
    changeSwitch(checked) {
      this.disabledok = true;
      this.objDataSave.is_active = checked;
      this.checkBTN();
    },
    countChar(e) {
      this.disabledok = true;
      if (e.target.id == "description") {
        this.totalCharDescription = e.target.value.length;
        this.objDataSave.description = e.target.value;
      }
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.checkBTN();
        }
      });
    },
    checkBTN() {
      if (this.objDataSave.package && this.modalprocess.isEdit) {
        let newData = JSON.stringify(this.objDataSave);
        if (newData != this.dataEditForCheck) {
          this.disabledok = false;
        }
      }
      if (this.isEnablePhoto) {
        this.disabledok = false;
      }
    },
    checkError(fild) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(fild) && getFieldError(fild);
    },
    handleChangeSelect(value) {
      this.selectPackag = value;
      this.objDataSave.package = value;
      this.disabledok = true;
      this.checkBTN();
    },
    handleChange(event) {
      this.isEnablePhoto = true;
      _.forEach(event.target.files, (item) => {
        if (this.checkValidate(item)) {
          fileReader.getBase64(item).then((imageUrl) => {
            this.fileListData.push({
              picture: imageUrl,
              picture_file: item,
            });
          });
        }
      });
      this.checkBTN();
    },
    checkValidate(file) {
      const isImg = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImg) {
        this.$error({
          title: this.lbls.application_page.modal_error.title_error,
          content: this.lbls.application_page.modal_error.wrong_type,
          centered: true,
          okText: this.lbls.application_page.modal_error.btn.ok,
          okType: "default",
        });
      }
      const isSize = file.size < 500 * 1024;
      if (!isSize) {
        this.$error({
          title: this.lbls.application_page.modal_error.title_error,
          content: this.lbls.application_page.modal_error.more_than,
          centered: true,
          okText: this.lbls.application_page.modal_error.btn.ok,
          okType: "default",
        });
      }
      return isImg && isSize;
    },
    dragFile(e) {
      _.forEach(e.dataTransfer.files, (item) => {
        if (this.checkValidate(item)) {
          fileReader.getBase64(item).then((imageUrl) => {
            this.fileListData.push({
              picture: imageUrl,
              picture_file: item,
            });
            if (this.fileListData.length > 0) {
              this.isEnablePhoto = true;
              this.checkBTN();
            }
          });
        }
      });
    },
    clickUpload() {
      let fileInputElement = this.$refs.file;
      if (fileInputElement.value != "") {
        fileInputElement.value = "";
      }
      fileInputElement.click();
    },
    handleOpenPreview() {
      this.modal2Visible = true;
    },
    handledelete() {
      this.isEnablePhoto = true;
      this.fileListData = [];
      this.checkBTN();
    },
    handleError(event) {
      event.target.src = "";
      this.fileListData = [];
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
