<template>
  <div class="item">
    <div class="item-header">
      <div>
        <div class="header-name">{{ item.name }}</div>
        <div class="header-title">Free</div>
      </div>
    </div>
    <div class="item-body">
      <div class="body-description">&emsp;{{ item.description }}</div>
      <div class="body-title">Features</div>
      <div class="body-detail">
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2">Collection of customer information</span>
        </div>
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2">Set up users by branch</span>
        </div>
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2">Rate with phone number</span>
        </div>
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2">Divided into membership levels</span>
        </div>
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2">Number of customer members: 30,000 people</span>
        </div>
        <div>
          <span>
            <a-icon type="check" />
          </span>
          <span class="ml-2"
            >Creating a reward campaign / up to 2 campaigns</span
          >
        </div>
      </div>
    </div>
    <div class="item-footer">
      <div class="footer-btn-custom" @click="handleCreate(item)">
        Select this package
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ItemPackage",
  props: {
    dataitempackage: {
      type: Object,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.item = this.dataitempackage;
    },
    handleCreate(item) {
      this.$emit("handlecreate", item);
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
/* For demo */
</style>
