import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export const state = {
  auth: [],
  isAppLoad: true,
  collapsed: true,
  isEdit: false,
  managecompany: false,
  manageapp: false,
  objforsave: [],
  objfeaturelists: [],
  localecode: "en",
  themecolor: "light",
  secondstogo: 3,
  showEmergency: false,
  showModalAnnouncement: false,
  isCallAnnouncement: false,
  menu: [],
  menuProduct: [],
  productHelpCenter: {},
  menuEx: [],
};
/* eslint-disable */
export const store = new Vuex.Store({
  state,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  mutations: {
    setMenu(state, value) {
      state.menu = value;
    },
    setMenuProduct(state, value) {
      state.menuProduct = value;
    },
    setHelpCenter(state, value) {
      state.productHelpCenter = value;
    },
    setMenuEx(state, value) {
      state.menuEx = value;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    Loading(state, value) {
      state.isAppLoad = value;
    },
    setCollapsed(state, value) {
      state.collapsed = value
    },
    isEdit(state, value) {
      state.isEdit = value;
    },
    managecompany(state, value) {
      state.managecompany = value;
    },
    manageapp(state, value) {
      state.manageapp = value;
    },
    objforsave(state, value) {
      state.objforsave = value;
    },
    objfeaturelists(state, value) {
      state.objfeaturelists = value;
    },
    setLocalecode(state, value) {
      state.localecode = value;
    },
    showEmergency(state, value) {
      state.showEmergency = value;
    },
    showModalAnnouncement(state, value) {
      state.showModalAnnouncement = value;
    },
    callAnnouncement(state, val) {
      state.isCallAnnouncement = val;
    },
  },
  plugins: [createPersistedState()],
  actions: {
    initial({ commit }) {
      let auth = null;
      if (Account.getAuth()) {
        try {
          auth = Account.getAuth();
        } catch (err) {
          console.log("actions initial", err);
        }
      }
      commit("setAuth", auth);
    },
  },
});
