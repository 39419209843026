<template>
  <AppLayout>
    <div
      :class="`${$route.name}`"
      class="campaigns-detail-main campaigns-detail-page page"
    >
      <Breadcrumbs
        v-if="lbls && lbls.crossaccount_page"
        :databreadcrumbs="breadcrumbs"
        :title="lbls.crossaccount_page.title_page"
        :is-action-flex="true"
        :description="lbls.crossapplication_page.title_table_description"
      >
        <!-- :description="lbls.crossaccount_page.title_page"
        <template v-slot:breadcrumbs>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.package_page.input_search"
            class="input-search"
            size="large"
            @blur="onSearch(searchData)"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
        </template> -->
        <template v-slot:actions>
          <a-input-search
            v-model="searchData"
            :placeholder="lbls.package_page.input_search"
            class="input-search"
            size="large"
            @blur="onSearch(searchData)"
            @search="onSearch(searchData)"
          >
            <template slot="suffix">
              <a-icon
                v-if="isSearchData"
                type="close-circle"
                theme="filled"
                class="icon-close-search-input"
                @click="closeSearchData()"
              />
            </template>
          </a-input-search>
          <a-button
            type="primary"
            ghost
            :disabled="!isPermissionCrossApp"
            @click="goToPage('Cross-Application')"
          >
            <a-icon type="swap" />
            {{ lbls.menu.crossapplication }}
          </a-button>
          <a-button
            type="primary"
            :disabled="!isPermissionCrossApp"
            @click="goToPage('InvitationAccount')"
          >
            <a-icon type="unordered-list" />
            {{ lbls.menu.invitation_account }}
          </a-button>
        </template>
      </Breadcrumbs>
      <!-- <div class="row">
        <PageHeader
          @createaccount="handleCreate()"
          @onsearch="onSearch($event)"
        />
      </div> -->
      <a-card v-if="isShowSkeleton">
        <a-skeleton />
      </a-card>
      <div v-else>
        <TableList
          :datatable="data"
          :table-height="tableHeight"
          :conditiontable="conditionTable"
          @onsearchtable="onSearchTable($event)"
          @callgetlist="callGetList"
        />
        <ShowTotal
          :datashowtitle="data"
          :conditiontable="conditionTable"
          :pagesize="page_size"
          :totallist="totalList"
          @callgetlist="callGetList"
          @setpagesize="setPageSize($event)"
        />
      </div>
      <!-- Modal -->
      <div v-if="isModalResponse">
        <ModalAlert
          :modalalert="modalAlert"
          :alertdata="alertData"
          @handlemodalok="handlemodalOk"
        >
        </ModalAlert>
      </div>
      <div v-if="isModalCreate">
        <ModalCreate
          :isshowmodal="isModalCreate"
          @handlemodalcreate="handleModalCreate($event)"
        />
      </div>
    </div>
  </AppLayout>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";
import Cache from "@/helper/Cache.js";
// import PageHeader from "@/module/Cross/ManageCrossAccount/components/Layout/PageHeader.vue";
import ShowTotal from "@/components/Layout/ShowTotal";
import TableList from "@/module/Cross/ManageCrossAccount/components/Table/TableList";
import ModalAlert from "@/components/Modal/Alertmodal.vue";
import ModalCreate from "@/module/Cross/ManageCrossAccount/components/Layout/ModalCreateAccount.vue";
import { config } from "@/config/bzbsconfig.js";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    ShowTotal,
    // PageHeader,
    TableList,
    ModalAlert,
    ModalCreate,
  },
  data() {
    return {
      searchData: "",
      isSearchData: false,
      isPermissionCrossApp: true,
      isPermissionCreatV2: !config.local,
      permission_key: "cross",
      action_key: "view",
      breadcrumbs: [],
      isShowSkeleton: true,
      data: [],
      isModalResponse: false,
      isModalCreate: false,
      totalList: 0,
      idModal: "",
      nameSearch: "",
      page: 0,
      page_size: 25,
      conditionTable: {},
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      tableHeight: 0,
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.calTableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.calTableHeight);
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    createOwnerAccount() {
      window.location = window.location.origin + "/createaccount";
    },
    createAccount() {
      this.$emit("createaccount");
    },
    init() {
      this.exAccount = Cache.get("exAccount");
      this.setCallGetList().then(() => {
        this.calTableHeight();
      });
      if (this.$store.state.menu?.length) {
        this.handlePermissionPage(this.permission_key, this.action_key);
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
    },
    onSearch(searchData) {
      let text = searchData;
      if (text != this.nameSearch && !this.isShowSkeleton) {
        this.isSearchData = this.searchData != "";
        this.nameSearch = text != undefined ? text : "";
        this.setCallGetList();
      } else {
        return;
      }
    },
    closeSearchData(searchData) {
      this.searchData = "";
      this.onSearch(searchData);
      this.$forceUpdate();
    },
    onSearchTable(data) {
      this.totalList = data.totalList >= 0 ? data.totalList : this.totalList;
      this.conditionTable.isFilters = data.isFilters;
    },
    handleModalCreate() {
      setTimeout(() => {
        this.isModalCreate = false;
      }, 500);
    },
    handlemodalOk() {
      this.handleLoading(true);
      this.isModalResponse = false;
    },
    handleCreate() {
      this.isModalCreate = true;
    },
    setCallGetList() {
      return new Promise((resolve) => {
        this.isShowSkeleton = true;
        this.page = 0;
        this.page_size = this.page_size;
        this.data = [];
        this.callGetList().then(() => {
          resolve();
        });
      });
    },
    setPageSize(value) {
      this.isShowSkeleton = true;
      this.page = 0;
      this.page_size = value;
      this.data = [];
      this.callGetList();
    },
    callGetList() {
      this.page = this.page + 1;
      let params = {
        account_id: this.exAccount.account.id,
        page: this.page,
        page_size: this.page_size,
      };
      if (this.nameSearch) {
        params.filter = this.nameSearch;
      }
      return new Promise((resolve, reject) => {
        manageCrossAccountAPI
          .getSearch(params)
          .then((res) => {
            if (res.data) {
              if (res.data.result) {
                let item = res.data.result.items;
                let paging = res.data.result.paging;
                if (item.length > 0) {
                  _.forEach(res.data.result.items, (item) => {
                    item.id = item.account.id;
                    if (item.owner_user) {
                      item.owner_user.name = item.owner_user.FirstName
                        ? item.owner_user.FirstName
                        : "";
                      if (item.owner_user.LastName) {
                        item.owner_user.name =
                          item.owner_user.name + " " + item.owner_user.LastName;
                      }
                    }
                  });
                  this.data.push(...res.data.result.items);
                }
                this.totalList = this.data.length;
                this.conditionTable = {
                  hasMoreData: paging.has_more_data,
                  isFilters: false,
                };
              }
            }
            this.$nextTick(() => {
              this.handleLoading(false);
              this.isShowSkeleton = false;
              resolve(res.data);
            });
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            reject(error);
          });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/style/_mixin.scss";
</style>
