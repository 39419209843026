<template>
  <div class="campaign-list-table box-icon-a-disabled">
    <a-table
      id="campaign-list-table"
      row-key="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: innerHeight - 331, x: 860 }"
      :pagination="false"
      :locale="{
        filterConfirm: lbls.crossaccountdetail_page.btn.ok,
        filterReset: lbls.crossaccountdetail_page.btn.reset,
      }"
      @change="handleTableChange"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`${lbls.btn.search}${column.title}`"
          :value="selectedKeys[0]"
          style="
            width: 188px;
            margin-bottom: 8px;
            display: block;
            font-size: 12px;
          "
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          style="width: 90px; margin-right: 8px"
          @click="
            () => handleTableSearch(selectedKeys, confirm, column.dataIndex)
          "
        >
          {{ lbls.btn.search }}
        </a-button>
        <a-button
          style="width: 90px"
          @click="() => handleTableReset(clearFilters)"
        >
          {{ lbls.btn.reset }}
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="accepted" slot-scope="text">
        <span class="status-active" :class="{ inactive: !text }">
          {{ text === true ? "Accepted" : "Inaccept" }}
        </span>
      </template>
      <template slot="template-status" slot-scope="text">
        <a-tag v-if="text" :color="'green'" class="tag-status">
          <a-badge
            :status="'success'"
            :text="lbls.crossaccountdetail_page.title_status_active"
          />
        </a-tag>
        <a-tag v-else :color="'red'" class="tag-status">
          <a-badge
            :status="'error'"
            :text="lbls.crossaccountdetail_page.title_status_inactive"
          />
        </a-tag>
      </template>
      <template slot="application" slot-scope="text">
        <span>{{ text.name }} </span>
      </template>
      <template slot="subscription" slot-scope="text">
        <div>{{ text ? text.name : "-" }}</div>
      </template>
      <template slot="package" slot-scope="text, record">
        <div>
          {{
            record.package
              ? record.package.name
                ? record.package.name
                : "-"
              : "-"
          }}
        </div>
      </template>
      <template slot="template-edit" slot-scope="text">
        <div class="action-list">
          <a-tooltip :disabled="!isPermissionRelocate" placement="left">
            <template slot="title">
              {{ lbls.crossaccount_page.btn.relocate }}
            </template>
            <a @click="handleEdit(text)">
              <a-icon type="setting" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  components: {},
  props: {
    datatable: {
      type: Array,
    },
    conditiontable: {
      type: Object,
    },
  },
  data() {
    return {
      permission_key: "cross",
      innerHeight: 0,
      lastscrollTop: 0,
      totalList: 0,
      tableContentHeight: 0,
      tableContentWidth: 0,
      searchInput: null,
      data: [],
      dataStart: [],
      appsFilters: [],
      isFilters: false,
      columns: [
        {
          id: "Status",
          title: "Status",
          width: "135px",
          keys: "tbl_colum_status",
          dataIndex: "application.is_active",
          scopedSlots: { customRender: "template-status" },
          filters: [
            {
              text: "title_status_active",
              value: "true",
            },
            {
              text: "title_status_inactive",
              value: "false",
            },
          ],
          onFilter: (value, record) => {
            value = value == "true";
            return record.application.is_active === value;
          },
        },
        {
          id: "BzbsId",
          title: "Buzzebees Application ID",
          keys: "tbl_colum_application_id",
          dataIndex: "application.buzzebees_application_id",
          width: "220px",
        },
        {
          id: "Application",
          title: "Application",
          keys: "tbl_colum_application",
          dataIndex: "application",
          width: "300px",
          sorter: (a, b) =>
            a.application.name.localeCompare(b.application.name),
          scopedSlots: { customRender: "application" },
        },
        {
          id: "Package",
          title: "Package",
          keys: "tbl_colum_invite_package",
          dataIndex: "package.name",
          scopedSlots: { customRender: "package" },
          filters: [],
          onFilter: (value, record) => {
            if (value == "none") {
              return !record.package;
            }
            if (record.package) {
              return record.package.id == value;
            }
          },
        },
        {
          title: "",
          width: "65px",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "template-edit" },
        },
      ],
      isPermissionRelocate: true,
    };
  },
  watch: {
    datatable() {
      this.setDataFilters();
    },
    lbls() {
      this.columns = _.cloneDeep(this.columnsStart);
      this.setColumnTable();
    },
    "$store.state.menu"() {
      this.handlePermission();
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tableContent = document.querySelector(".ant-table-body");
    this.tableContentHeight =
      this.tableContent.offsetHeight + this.setHeightDefualt;
    // this.checkLayout();
    this.onScroll();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.datatable;
      this.dataStart = this.datatable;
      this.columnsStart = _.cloneDeep(this.columns);
      this.setColumnTable();
      this.setDataFilters();
      this.handlePermission();
    },
    handlePermission() {
      if (this.$store.state.menu?.length) {
        let permission_page = this.handlePermissionPage(this.permission_key);
        if (permission_page?.length) {
          this.isPermissionRelocate = this.checkArrowPermission(
            "cross-existing-relocate",
            permission_page
          );
        }
      }
    },
    setColumnTable() {
      _.forEach(this.columns, (column) => {
        if (column.keys) {
          column.title = this.lbls.crossaccountdetail_page[column.keys];
        }
        if (column.filters) {
          _.forEach(column.filters, (filters) => {
            filters.text = this.lbls.crossaccountdetail_page[filters.text];
          });
        }
      });
    },
    setDataFilters() {
      this.appsFilters.push({
        text: "-",
        value: "none",
      });
      _.forEach(this.datatable, (element) => {
        if (element.package) {
          let item = element.package;
          this.appsFilters.push({
            text: item.name,
            value: item.id,
          });
        }
      });
      this.appsFilters = [
        ...new Map(this.appsFilters.map((item) => [item.text, item])).values(),
      ];
      let findcolumns = this.columns.find((o) => o.id == "Package");
      if (findcolumns) {
        findcolumns.filters = this.appsFilters;
      }
      this.$forceUpdate();
    },
    onScroll() {
      this.tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop;
        let isScrollDown = this.lastscrollTop !== event.target.scrollTop;
        let scrollPersent = (currentScroll / maxScroll) * 100;
        if (
          scrollPersent >= 95 &&
          this.conditiontable.hasMoreData &&
          isScrollDown &&
          !this.isFilters
        ) {
          this.conditiontable.hasMoreData = false;
          this.handleLoading(true);
          this.callGetList();
        } else {
          return;
        }
        this.lastscrollTop = event.target.scrollTop;
      });
    },
    handleTableSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleTableReset(clearFilters) {
      clearFilters();
      this.searchText = "";
      this.valueStatus = "";
      this.data = this.dataStart;
    },
    handleCreate(record) {
      this.$emit("handlecreate", record);
    },
    handleEdit(record) {
      this.$emit("handleedit", record);
    },
    handleDelete(record) {
      this.$emit("handledelete", record);
    },
    callGetList() {
      this.$emit("callgetlist");
    },
    onResize() {
      this.innerHeight = window.innerHeight;
      // this.checkLayout();
      this.$forceUpdate();
    },
    checkLayout() {
      let tableWidth = document.querySelector(".ant-table-tbody");
      this.tableContentWidth = tableWidth.offsetWidth;
      this.columns[this.columns.length - 1] = this.unFixedColumn(this.columns);
      if (this.boxDetailWidth() <= this.tableContentWidth) {
        this.columns[this.columns.length - 1] = this.fixedColumn(this.columns);
      }
    },
    handleTableChange(pagination, filters, sorte, currentData) {
      console.log(pagination, filters, sorte);
      let isFilters = false;
      let isFiltersStatus = filters["application.is_active"]
        ? filters["application.is_active"]
        : [];
      let isFilterspackage = filters["package.name"]
        ? filters["package.name"]
        : [];
      isFiltersStatus = isFiltersStatus.length > 0;
      isFilterspackage = isFilterspackage.length > 0;
      isFilters = isFiltersStatus || isFilterspackage;
      this.isFilters = isFilters;
      this.totalList = currentData.currentDataSource.length;
      let onsearchData = {
        totalList: this.totalList,
        isFilters: this.isFilters,
      };
      this.$emit("onsearchtable", onsearchData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.features-custom {
  ul {
    padding-inline-start: 15px;
    margin-block-end: 2px;
  }
  .show-hide-custom {
    margin-top: 8px;
    cursor: pointer;
    color: #00a3ff;
  }
}
</style>
