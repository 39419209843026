<template>
  <div class="title-breadcrumbs">
    <a-breadcrumb :routes="databreadcrumbs">
      <template slot="itemRender" slot-scope="{ route, params, routes }">
        <span
          v-if="
            routes.indexOf(route) === routes.length - 1 && routes.length > 1
          "
        >
          {{ route.breadcrumbName }}
        </span>
        <template v-else>
          <template v-if="routes.length > 4">
            <template v-if="routes.indexOf(route) === 0">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <template v-if="routes.indexOf(route) === routes.length - 2">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <span v-if="routes.indexOf(route) === routes.length - 3">
              ...
            </span>
          </template>
          <template v-else>
            <router-link
              v-if="route.name && routes.length > 1"
              :to="{
                name: route.name,
                query: {
                  id: $route.query.id,
                },
              }"
            >
              {{ route.breadcrumbName }}
            </router-link>
          </template>
        </template>
      </template>
    </a-breadcrumb>
    <a-page-header :sub-title="description">
      <template
        v-if="productCount !== undefined || totallist !== undefined || count"
        slot="title"
      >
        {{ title }}
        <span v-if="productCount" class="product-info">
          Product: {{ productCount }}
        </span>
        <template v-if="totallist !== undefined">
          <a-badge
            :count="totallist"
            class="count-list"
            :overflow-count="totallist"
          />
        </template>
        <template v-if="count">
          <a-badge
            :count="formatNumber(count)"
            class="count-list"
            show-zero
            :overflow-count="count"
          />
        </template>
        <slot name="breadcrumbs"></slot>
      </template>
      <template v-else slot="title">
        <span v-if="titleClass" :class="titleClass">
          {{ title }}
        </span>
        <template v-else>
          {{ title }}
          <slot name="breadcrumbs"></slot>
        </template>
      </template>
      <!-- Action List Slot -->
      <!-- <div class="actions">
        <slot name="actions"></slot>
      </div> -->
    </a-page-header>
  </div>
</template>
<script>
export default {
  name: "TitleBreadcrumbs",
  props: {
    isActionFlex: Boolean,
    title: String,
    parent: Object,
    totallist: Number,
    current: String,
    databreadcrumbs: Array,
    productCount: Number,
    productLabel: String,
    count: Number,
    titleClass: String,
    description: String,
  },
};
</script>

<style scoped>
.product-info {
  border: 1px solid;
  border-radius: 25px;
  padding: 0px 8px;
  font-size: small;
  color: #a1a5b7;
}
</style>
