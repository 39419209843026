<template>
  <a-modal
    v-model="isShow"
    width="600px"
    class="modal-announcement"
    :title="data.title"
    :closable="false"
    :centered="true"
    :mask-closable="false"
    @cancel="closeModal()"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="data.content"></div>
    <template slot="footer">
      <div class="d-flex justify-content-between w-100">
        <a-checkbox @change="onChange">
          {{ labelterm.title_accept }}
        </a-checkbox>
        <div class="d-flex justify-content-end">
          <a-button
            key="submit"
            type="primary"
            :disabled="!isDisable"
            @click="closeConfirm()"
          >
            {{ labelterm.btn.ok }}
          </a-button>
          <a-button @click="closeModal()">
            {{ labelterm.btn.cancel }}
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script>
import ResponsiveMixin from "@/mixin/ResponsiveMixin";

export default {
  name: "TermAndCondition",
  components: {},
  mixins: [ResponsiveMixin],
  props: {
    isShow: Boolean,
    data: Object,
  },
  data() {
    return {
      isDisable: false,
      labelterm: {
        title_accept: "Accept, Don't show again.",
        btn: {
          ok: "Confirm Acceptation",
          cancel: "Not Acceptation",
        },
      },
    };
  },
  created() {
    this.labelterm = this.lbls.modaltermandcondition
      ? this.lbls.modaltermandcondition
      : this.labelterm;
  },
  methods: {
    onChange(e) {
      this.isDisable = e.target.checked;
      this.$emit("checkBox", e.target.checked);
    },
    closeConfirm() {
      this.$emit("closeModal");
    },
    closeModal() {
      this.$router.push({ name: "logout" });
    },
  },
};
</script>
<style lang="scss">
.modal-announcement {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
