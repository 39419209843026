<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <Breadcrumbs
          :databreadcrumbs="breadcrumbs"
          :title="lbls.menu.transferowneraccount"
        />

        <div class="manage-page-detail">
          <div
            id="enter-email"
            class="custom-process-box"
            :class="{ 'custom-process-box-active': current == 0 }"
          >
            <div class="flex-center-between">
              <label>
                {{ lbls.user_page.input_email.name }}
              </label>
            </div>
            <div class="custom-set-input-action">
              <div class="set-input-left-action">
                <a-input
                  v-model="email_name"
                  :placeholder="lbls.user_page.input_email.placeholder"
                  :disabled="isDisabledEmail"
                  @pressEnter="onCheckEmail"
                />
              </div>
              <div class="set-input-righ-action">
                <div>
                  <a-button type="primary" @click="onCheckEmail">
                    {{ lbls.user_page.modal_invite_mail.btn.search_email }}
                  </a-button>
                </div>
                <div>
                  <a-button type="primary" ghost @click="clearEmail">
                    {{ lbls.user_page.mail_upload.btn.clear }}
                  </a-button>
                </div>
              </div>
            </div>
            <div v-if="msgEmail" style="color: rgb(245, 34, 45)">
              {{ msgEmail }}
            </div>
          </div>
          <a-card v-if="isShowSkeleton" class="mt-3">
            <a-skeleton />
          </a-card>
          <div v-else class="mt-3">
            <div
              v-if="dataEmails.length == 0"
              class="w-100 text-center custom-process-box"
            >
              <a-empty :image="simpleImage" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in dataEmails"
                :key="item.id"
                class="email-group"
              >
                <div class="email-top">
                  <div v-if="index == 'Other'">
                    <div class="border-bt pb-3 row">
                      <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12 col-12">
                        Server email type
                      </div>
                      <div
                        class="col-lg-10 col-xl-10 col-md-12 col-sm-12 col-12"
                      >
                        <a-radio-group
                          v-model="emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'office_365'">
                            <img
                              :src="urlImage.office_365"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Office 365
                          </a-radio>
                        </a-radio-group>
                        <a-radio-group
                          v-model="emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'hotmail_or_outlook'">
                            <img
                              :src="urlImage.microsoft"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Hotmail/Outlook
                          </a-radio>
                        </a-radio-group>
                        <a-radio-group
                          v-model="emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'google'">
                            <img
                              :src="urlImage.google"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Google
                          </a-radio>
                        </a-radio-group>
                        <a-radio-group
                          v-model="emailServer"
                          name="radioGroupEmail"
                          button-style="solid"
                          class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12"
                          @change="onChangeEmailServer"
                        >
                          <a-radio :value="'local_account'">
                            <img
                              :src="urlImage.local"
                              style="max-width: 20px"
                              class="mr-1"
                            />
                            Local account
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </div>
                    <div>Email</div>
                  </div>
                  <div v-else>
                    <div>{{ index }}</div>
                  </div>
                </div>
                <div class="emails-list group-mail-test">
                  <div
                    v-for="email in item"
                    :key="email.id"
                    class="d-flex items-mail-test"
                  >
                    <div class="email-checkbox">
                      <a-checkbox
                        :id="email.email"
                        :checked="email.is_selected"
                        :disabled="email.is_disabled"
                        @change="onChangesCheckbox($event, email)"
                      >
                      </a-checkbox>
                    </div>
                    <div
                      class="email-desc"
                      :class="{ 'not-event': email.is_disabled }"
                      @click="onChangesCheckbox($event, email)"
                    >
                      <div class="title-email">{{ email.email }}</div>
                      <div class="title-channel">
                        <div>{{ email.channel }}</div>
                        <div v-if="email.identity_provider">
                          Identity Provider: {{ email.identity_provider }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
    <div class="custom-footer-setpermission">
      <a-button
        :disabled="objDataSaveMails?.length <= 0 && objDataSaveId?.length <= 0"
        type="primary"
        @click="handleSave()"
      >
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>

    <!-- Modal -->
    <a-modal
      v-model="showModalInviteEmail"
      width="500px"
      class="modal-invite-email modal-list"
      :closable="false"
      :centered="true"
      :mask="false"
      :mask-closable="false"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <div class="d-flex justify-content-center logo_email">
        <img :src="urlImage.local" alt="logo_email" />
      </div>
      <div class="d-flex justify-content-center">
        <h3>{{ lbls.invitation_page.modal.content_create_success }}</h3>
      </div>
      <template slot="footer">
        <a-button
          type="primary"
          style="min-width: 105px"
          @click="closeModalInviteEmail"
        >
          {{ lbls.transfer_page.btn.accept }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import _ from "lodash";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { Empty } from "ant-design-vue";
import CommonMixin from "@/mixin/CommonMixin";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
import manageUsersAPI from "@/core/Page/ManageUserAPI";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageCrossAccountAPI from "@/core/Page/ManageCrossAccountAPI";

export default {
  name: "TransferOwnerAccount",
  components: {
    AppLayout,
  },
  mixins: [CommonMixin, ErrorApiMixin],
  data() {
    return {
      breadcrumbs: [],
      permission_key: "cross",
      action_key: "transfer-owner-adjust",
      accountName: null,
      crossAccountId: null,
      current: 0,
      dataEmail: {},
      email_name: null,
      isShowSkeleton: false,
      msgEmail: null,
      isDisabledEmail: false,
      dataEmailStart: [],
      dataEmailMap: [],
      dataEmails: [],
      objDataSaveMails: [],
      objDataSaveId: [],
      emailServer: null,
      urlImage: {
        office_365: require("@/assets/images/server_type/office-365.png"),
        microsoft: require("@/assets/images/server_type/microsoft.png"),
        google: require("@/assets/images/server_type/google.png"),
        local: require("@/assets/images/server_type/local.png"),
      },
    };
  },
  watch: {
    lbls() {
      this.setBreadcrumbs();
    },
    "$store.state.menu"() {
      this.handlePermissionPage(this.permission_key, this.action_key);
    },
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.setBreadcrumbs();
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id && this.$route.query.name) {
        this.accountName = this.$route.query.name;
        this.crossAccountId = this.$route.query.id;
        this.exAccount = Cache.get("exAccount");
      } else {
        this.$router.push({ name: "ManageCrossAccount" });
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        breadcrumbName: this.lbls.menu.crossaccount,
        name: "ManageCrossAccount",
      });
      this.breadcrumbs.push({
        breadcrumbName: this.$route.query.name,
        name: "ManageCrossAccount",
      });
    },
    onCheckEmail() {
      if (this.email_name?.length > 0) {
        this.isShowSkeleton = true;
        this.isDisabledEmail = true;
        this.msgEmail = null;
        var validateEmail = Util.validateEmail(this.email_name);
        if (!validateEmail) {
          this.msgEmail =
            this.lbls.user_page.input_email.invalid + " : " + this.email_name;
          this.isShowSkeleton = false;
          this.isDisabledEmail = false;
        } else {
          if (this.email_name?.length > 0) {
            this.handleCheckEmail(this.email_name);
          } else {
            this.isShowSkeleton = false;
            this.isDisabledEmail = false;
          }
        }
      }
    },
    clearEmail() {
      this.email_name = null;
      this.isShowSkeleton = false;
      this.msgEmail = null;
      this.isDisabledEmail = false;
      this.objDataSaveMails = [];
      this.objDataSaveId = [];
      this.dataEmailStart = [];
      this.dataEmailMap = [];
      this.dataEmails = [];
      this.emailServer = null;
    },
    handleCheckEmail(email) {
      var valueSearch = {
        account_id: this.exAccount.account.id,
        emails: [email],
      };
      let objForSearch = JSON.stringify(valueSearch);
      manageUsersAPI
        .postSearchEMails(objForSearch)
        .then((res) => {
          if (res.data && res.data.result && res.data.result.items) {
            _.forEach(res.data.result.items, (item) => {
              item.is_disabled = false;
              item.emails = item.email;
              item.setid = 1;
              item.ids = item.id;
              if (item.id == null) {
                item.emails = "Other";
                item.ids = item.email;
                item.setid = 0;
              }
              let findobj = this.dataEmailStart.find((o) => {
                return o.ids === item.ids;
              });
              if (!findobj) {
                this.dataEmailStart.push(item);
              }
            });
            this.dataEmailStart.sort((a, b) => a.setid - b.setid);
            this.dataEmails = _.mapValues(
              _.groupBy(this.dataEmailStart, "emails")
            );
            this.dataEmailMap = Map.groupBy(this.dataEmailStart, (item) => {
              return item.email;
            });
            this.setDataForsave();
            if (this.dataEmailStart.length > 0) {
              this.current = 1;
            }
            this.isShowSkeleton = false;
          }
        })
        .catch((error) => {
          this.isShowSkeleton = false;
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    setDataForsave() {
      let saveid = [];
      let saveemail = [];
      _.forEach(this.dataEmailStart, (email) => {
        if (email.is_selected) {
          if (email.setid == 0) {
            saveemail.push(email.email);
          } else if (email.setid == 1) {
            saveid.push(email.id);
          }
        }
      });
      this.objDataSaveMails = saveemail;
      this.objDataSaveId = saveid;
    },
    onChangeEmailServer(e) {
      e.preventDefault();
      this.emailServer = e.target.value;
      this.checkEmailType();
      this.setDataForsave();
    },
    checkEmailType() {
      if (this.emailServer) {
        _.forEach(this.dataEmailStart, (item) => {
          if (item.setid == 0) {
            if (this.emailServer == "google") {
              if (!item.email.includes("@gmail.com")) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else if (this.emailServer == "hotmail_or_outlook") {
              if (
                !item.email.includes("@hotmail.com") &&
                !item.email.includes("@outlook.com")
              ) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else if (this.emailServer == "office_365") {
              if (
                item.email.includes("@hotmail.com") ||
                item.email.includes("@outlook.com") ||
                item.email.includes("@gmail.com")
              ) {
                item.is_selected = false;
                item.is_disabled = true;
              } else {
                item.is_disabled = false;
              }
            } else {
              item.is_disabled = false;
            }
          }
        });
      }
    },
    onChangesCheckbox(e, item) {
      let itemcheck = document.getElementById(item.email).checked;
      let checked =
        e.target.checked != undefined ? e.target.checked : !itemcheck;
      if (checked) {
        this.clearDataCheckbox();
      }
      item.is_selected = checked;
      this.checkEmailType();
      this.setDataForsave();
      this.$forceUpdate();
    },
    clearDataCheckbox() {
      if (this.dataEmailStart.length > 1) {
        _.forEach(this.dataEmailStart, (email) => {
          if (email.is_selected) {
            email.is_selected = false;
          }
        });
      }
    },
    handleSave() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        cross_account_id: this.crossAccountId,
      };

      if (this.emailServer != null) {
        valueSave.email_server = this.emailServer;
      }

      if (this.objDataSaveMails.length > 0) {
        valueSave.email = this.objDataSaveMails[0];
      } else if (this.objDataSaveId.length > 0) {
        valueSave.user_id = this.objDataSaveId[0];
      }
      let objForSave = JSON.stringify(valueSave);
      manageCrossAccountAPI
        .postTransferOwnerAccount(objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              setTimeout(() => {
                this.showModalSuccess(res?.data?.result?.type ?? null);
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    showModalSuccess(type) {
      console.log("showModalSuccess : ", type);
      this.handleLoading(false);
      if (type == "invite_email") {
        this.contentModal =
          this.lbls.invitation_page.modal.content_create_success;

        let secondsToGo = this.$store.state.secondstogo;
        const modal = this.$success({
          centered: true,
          autoFocusButton: null,
          title: this.contentModal,
          okType: "default",
          okText: `Ok (${secondsToGo})`,
          onOk: () => {
            this.$router.push({ name: "ManageCrossAccount" });
          },
        });
        const interval = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            okText: `Ok (${secondsToGo})`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          modal.destroy();
          this.$router.push({ name: "ManageCrossAccount" });
        }, secondsToGo * 1000);
      } else {
        this.contentModal =
          this.lbls.transfer_page.modal.content_transfer_success;

        let secondsToGo = this.$store.state.secondstogo;
        const modal = this.$success({
          centered: true,
          autoFocusButton: null,
          title: this.contentModal,
          okType: "default",
          okText: `Ok (${secondsToGo})`,
          onOk: () => {
            this.$router.push({ name: "ManageCrossAccount" });
          },
        });
        const interval = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            okText: `Ok (${secondsToGo})`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
          modal.destroy();
          this.$router.push({ name: "ManageCrossAccount" });
        }, secondsToGo * 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.manage-page-detail {
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 16px;
  height: calc(100vh - 332px);
}
.ant-collapse {
  background-color: #fff;
}
.custom-text-collapse-header {
  font-size: 1em;
  color: var(--text-light-text-body);
  font-weight: 500;
}
.custom-col {
  padding-left: 0 !important;
}
.custom-process-box {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  background: #ffffff;
  padding: 16px;
}
.custom-process-box-active {
  border: 1px solid var(--primary-gold-bzb-primary-bzb);
}
.custom-collapse-box-active {
  .ant-collapse {
    border: 1px solid var(--primary-gold-bzb-primary-bzb);
    border-bottom: 0;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid var(--primary-gold-bzb-primary-bzb);
  }
}
.custom-set-input-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .set-input-left-action {
    width: calc(100% - 235px);
  }
  .set-input-righ-action {
    text-align: right;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.custom-set-input-title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .set-input-left-title {
    color: #f79443;
    font-weight: normal;
  }
  .set-input-righ-title {
    width: 125px;
    text-align: right;
    font-weight: 600;
    opacity: 0.6;
  }
}
.email-list-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
  margin-bottom: 16px;
}
.email-group {
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  color: $color-body;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 12px;
  .email-top {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    margin-bottom: 8px;

    .border-bt {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    .ant-radio-button-wrapper {
      width: 165px;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .title-email {
    font-weight: 500;
  }
  .title-channel {
    font-size: 12px;
    opacity: 0.7;
    line-height: 1.2;
  }
  .email-desc {
    padding-left: 8px;
    cursor: pointer;
    &.not-event {
      cursor: no-drop;
    }
  }
  .email-select-all {
    margin-top: 8px;
  }
  .group-mail-test {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  .items-mail-test {
    width: calc((100% - 32px) / 4);
    @include breakpoint-max($desktop-xxxl) {
      width: calc((100% - 24px) / 3);
    }
    @include breakpoint-max($desktop-xxl) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($desktop-xs) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($tablet-pro) {
      width: 100%;
    }
    @include breakpoint-max($tablet-xs) {
      width: 100%;
    }
  }
}
.email-group:last-child {
  margin-bottom: 0;
}
.list-group {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .group-role-test {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  .items-role-test {
    width: calc((100% - 40px) / 5);
    @include breakpoint-max($desktop-xxxl) {
      width: calc((100% - 32px) / 4);
    }
    @include breakpoint-max($desktop-xxl) {
      width: calc((100% - 24px) / 3);
    }
    @include breakpoint-max($desktop-xs) {
      width: calc((100% - 16px) / 2);
    }
    @include breakpoint-max($tablet-pro) {
      width: 100%;
    }
    @include breakpoint-max($tablet-xs) {
      width: 100%;
    }
  }
}
.modal-invite-email {
  .logo_email {
    height: 100px;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
