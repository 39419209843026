<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
        </div>
      </template>
      <div>
        <a-form-item
          :label="lbls.crossapplication_page.select_subscription_lable"
        >
          <a-select
            v-model="selectCross"
            show-search
            style="width: 100%"
            :placeholder="lbls.crossapplication_page.select_subscription_select"
            @change="handleChange"
          >
            <a-select-option
              v-for="item in dataobjselect"
              :key="item.id"
              :value="item.name"
            >
              <span>
                {{ item.name }}
              </span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Array,
    },
    dataselectcross: {
      type: Array,
    },
    datachecked: {
      type: String,
    },
  },
  data() {
    return {
      disabledok: true,
      selectCross: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.selectCross = _.cloneDeep(this.dataselectcross);
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.applicationID);
    },
    handleChange(value) {
      this.disabledok = true;
      this.applicationID = "";
      let findobj = this.dataobjselect.find((o) => o.name === value);
      if (findobj) {
        this.applicationID = findobj.id;
      }
      if (this.modalprocess.isCreate && this.applicationID != "") {
        this.disabledok = false;
      }
      if (this.modalprocess.isEdit && this.dataselectcross != value) {
        this.disabledok = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
