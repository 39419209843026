var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data.length > 0)?_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"row m-0 content-item",class:{
        'bg-unread': item.is_unread == true,
        'display-content-list':
          _vm.clicktabs == '2'
            ? !item.is_unread
            : _vm.clicktabs == '3'
            ? item.is_unread
            : null,
      },on:{"click":function($event){return _vm.previewPost(item)}}},[_c('div',{staticClass:"col-10 p-0"},[_c('div',{staticClass:"date-time"},[(item.post_type == 'Emergency')?_c('a-tag',{attrs:{"color":"red"}},[_vm._v(" "+_vm._s(item.post_type)+" ")]):_c('a-tag',[_vm._v(" "+_vm._s(item.post_type)+" ")])],1),_c('div',{staticClass:"title-style"},[(item.is_unread)?_c('a-badge',{attrs:{"count":_vm.lbls.announcement.new}}):_vm._e(),_c('h5',{staticClass:"text short-text title-text m-0"},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"text short-text content-text",class:{ 'content-unread': item.is_unread == true },domProps:{"innerHTML":_vm._s(item.content)}}),_c('div',{staticClass:"last-update"},[_c('span',[_vm._v("Last Update: "+_vm._s(_vm.formatDateAM(item.updated_date, "DD/MMM/YYYY - HH:mm"))+" By "+_vm._s(item.updated_by)+" ")])])]),_c('div',{staticClass:"col-2 p-0 text-right view-button"},[_c('a',[_vm._v(" "+_vm._s(_vm.lbls.btn.view)+" ")])])])}):[_c('h5',{staticClass:"text-center my-5"},[_vm._v(" "+_vm._s(_vm.lbls.announcement.notfound)+" ")])],(_vm.showModalAnnouncement)?_c('ModalAnnouncement',{attrs:{"is-show":_vm.showModalAnnouncement,"data":_vm.dataPreview},on:{"closeModalAnnouncement":function($event){return _vm.closeModalAnnouncement()},"checkBox":function($event){return _vm.checkBox($event)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }