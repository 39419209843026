import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getPermissionProducts
 * @method postPermissionProducts
 * @method getApplications
 * @method getAvailableApplications
 * @method postDeleteApplications
 * @method postAddApplications
 * @method getPermissionExcouldApplications
 * @method postPermissionExcouldApplications
 * @method postPermissionCopyRole
 * @method postAddDefaultRole
 */
export default {
  config: function () {
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion2 + "/permission";
  },
  getPermissionProducts: async function (objparams) {
    var strUrl = this.path() + "/get_products";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  postPermissionProducts: async function (data) {
    var strUrl = this.path() + "/products";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  getApplications: async function (objparams) {
    var strUrl = this.path() + "/get_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getAvailableApplications: async function (objparams) {
    var strUrl = this.path() + "/get_available_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  postDeleteApplications: async function (data) {
    var strUrl = this.path() + "/delete_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postAddApplications: async function (data) {
    var strUrl = this.path() + "/add_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  getPermissionExcould: async function (objparams) {
    var strUrl = this.path() + "/get_excould";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getPermissionExcouldApplications: async function (objparams) {
    var strUrl = this.path() + "/get_excould_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  postPermissionExcouldApplications: async function (data) {
    var strUrl = this.path() + "/excould_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postPermissionExcouldAllApplications: async function (data) {
    var strUrl = this.path() + "/excould_all_application";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postPermissionCopyRole: async function (data) {
    var strUrl = this.path() + "/copy_role";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postAddDefaultRole: async function (data) {
    var strUrl = this.path() + "/add_default_role";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
};
