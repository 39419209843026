import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import { config } from "@/config/bzbsconfig.js";
import Util from "@/helper/Utility.js";

/**
 * @method getUserList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return (
      this.config().apiBaseUrl + this.config().apiVersion + "/cross_application"
    );
  },
  getSubScriptions: async function (params) {
    var url = this.path() + "/get_subscriptions";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getDetail: async function (params) {
    var url = this.path() + "/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getCrossList: async function (params) {
    var url = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  postSave: async function (data) {
    var url = this.path() + "/save";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(url, this.token(), data);
  },
  postSaveRelocate: async function (params) {
    var strUrl = this.path() + "/relocate";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  delete: async function (params) {
    var url = this.path() + "/delete";
    await Util.checkToken();
    return BzbsApi.connectBzbsDeleteBody(url, this.token(), params);
  },
};
