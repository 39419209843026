<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
          <span v-if="modalprocess.isEdit" class="float-right">
            <!-- <a-switch :checked="objDataSave.is_active" @change="changeSwitch" /> -->
            <span class="title-status">
              {{ lbls.role_page.tbl_colum_status }}
            </span>
            <span>
              <a-tooltip>
                <template slot="title">
                  <span v-if="objDataSave.is_active">
                    {{ lbls.role_page.title_status_active }}
                  </span>
                  <span v-else>
                    {{ lbls.role_page.title_status_inactive }}
                  </span>
                </template>
                <a-switch
                  :checked="objDataSave.is_active"
                  @change="changeSwitch"
                />
              </a-tooltip>
            </span>
          </span>
        </div>
      </template>
      <div>
        <a-form :form="form" layout="vertical">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item :label="lbls.role_page.input_name.name">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      initialValue: objDataSave.name,
                      rules: [
                        {
                          required: true,
                          message: lbls.role_page.input_name.required,
                        },
                        {
                          pattern: new RegExp(
                            /^[A-Za-zก-๙0-9:\[\]_ -]{1,100}$/
                          ),
                          message: lbls.role_page.input_name.pattern,
                        },
                        {
                          min: 4,
                          message: lbls.role_page.input_name.min,
                        },
                      ],
                    },
                  ]"
                  :max-length="maxLengthName"
                  :placeholder="lbls.role_page.input_name.placeholder"
                  :disabled="modalprocess.isEdit && dataobjselect.is_default_role"
                  @keyup="countChar"
                />
              </a-form-item>
              <p class="total-char" href="">
                {{
                  objDataSave.name
                    ? objDataSave.name.length
                    : totalCharName.toLocaleString()
                }}
                /
                {{ maxLengthName.toLocaleString() }}
              </p>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item
                :label="lbls.role_page.input_description.name"
                :validate-status="checkError('description') ? 'error' : ''"
                :help="checkError('description') || ''"
              >
                <a-textarea
                  v-decorator="[
                    'description',
                    {
                      initialValue: objDataSave.description,
                      rules: [
                        {
                          required: true,
                          message: lbls.role_page.input_description.required,
                        },
                        {
                          min: 4,
                          message: lbls.role_page.input_description.min,
                        },
                      ],
                    },
                  ]"
                  :placeholder="lbls.role_page.input_description.placeholder"
                  :max-length="maxLengthDescription"
                  :rows="4"
                  @keyup="countChar"
                />
              </a-form-item>
              <p class="total-char" href="">
                {{
                  objDataSave.description
                    ? objDataSave.description.length
                    : totalCharDescription.toLocaleString()
                }}
                /
                {{ maxLengthDescription.toLocaleString() }}
              </p>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template slot="footer">
        <a-button @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.role_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.role_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
    dataobjselect: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      totalCharName: 0,
      maxLengthName: 100,
      totalCharDescription: 0,
      maxLengthDescription: 4000,
      disabledok: true,
      objDataSave: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.objDataSave = this.dataobjselect;
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      console.log(this.objDataSave, "this.objDataSave");
      this.$emit("actionModal", this.objDataSave);
    },
    changeSwitch(checked) {
      this.disabledok = true;
      this.objDataSave.is_active = checked;
      this.checkBTN();
    },
    checkError(fild) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(fild) && getFieldError(fild);
    },
    countChar(e) {
      this.disabledok = true;
      if (e.target.id == "name") {
        this.objDataSave.name = e.target.value;
        this.totalCharName = e.target.value.length;
      }
      if (e.target.id == "description") {
        this.objDataSave.description = e.target.value;
        this.totalCharDescription = e.target.value.length;
      }
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.checkBTN();
        }
      });
    },
    checkBTN() {
      if (this.modalprocess.isCreate) {
        this.disabledok = false;
      }
      if (this.modalprocess.isEdit) {
        let newData = {
          id: this.objDataSave.id,
          name: this.objDataSave.name,
          description: this.objDataSave.description,
          is_active: this.objDataSave.is_active,
        };
        newData = JSON.stringify(newData);
        if (newData != this.dataobjselect.dataEditForCheck) {
          this.disabledok = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
