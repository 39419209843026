var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{staticClass:"modal-with-content",attrs:{"centered":"","closable":false,"mask-closable":false},model:{value:(_vm.modalprocess.visible),callback:function ($$v) {_vm.$set(_vm.modalprocess, "visible", $$v)},expression:"modalprocess.visible"}},[_c('template',{slot:"title"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.modalprocess.title))])])]),_c('div',[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":_vm.lbls.applicationaccount_page.input_name.name}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'appid',
                  {
                    rules: [
                      {
                        required: true,
                        message:
                          _vm.lbls.applicationaccount_page.input_name.required,
                      },
                    ],
                  },
                ]),expression:"[\n                  'appid',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message:\n                          lbls.applicationaccount_page.input_name.required,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"max-length":_vm.maxLengthAppId,"placeholder":_vm.lbls.applicationaccount_page.input_name.placeholder},on:{"keyup":_vm.countChar}})],1),_c('p',{staticClass:"total-char",attrs:{"href":""}},[_vm._v(" "+_vm._s(_vm.totalAppId.toLocaleString())+" / "+_vm._s(_vm.maxLengthAppId.toLocaleString())+" ")])],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"size":"large"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.modalprocess.cancelText ? _vm.modalprocess.cancelText : _vm.lbls.user_page.btn.cancel)+" ")]),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabledok},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.modalprocess.okText ? _vm.modalprocess.okText : _vm.lbls.user_page.btn.save)+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }