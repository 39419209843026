import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getRoleList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion + "/role";
  },
  pathV2: function () {
    return this.config().apiBaseUrl + this.config().apiVersion2 + "/role";
  },
  getCheckDuplicateName: async function (objparams) {
    var strUrl = this.path() + "/check_duplicate_role_name";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getSearch: async function (objparams, v2 = false) {
    var strUrl = this.pathV2() + "/search";
    if (v2) {
      strUrl = this.path() + "/search";
    }
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getDetail: async function (objparams) {
    var strUrl = this.path() + "/permission/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getDetailRole: async function (objparams) {
    var strUrl = this.path() + "/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getAvailabletUser: async function (objparams) {
    var strUrl = this.path() + "/get_available_users";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getAvailableApp: async function (objparams) {
    var strUrl = this.path() + "/get_available_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getApplications: async function (objparams) {
    var strUrl = this.path() + "/get_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getListUser: async function (objparams) {
    var strUrl = this.path() + "/get_users";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getProductList: async function (objparams) {
    var strUrl = this.path() + "/get_products";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  getRoleList: async function (objparams) {
    var params = {};
    if (objparams) {
      params = {
        company_id: objparams.company_id,
        role_name: objparams.role_name,
      };
    }
    var strUrl = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getPermissionApp: async function (objparams) {
    var strUrl = this.path() + "/permission/application";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), objparams);
  },
  postAddUserInRole: async function (objparams) {
    var strUrl = this.path() + "/add_users";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), objparams);
  },
  postCreateRole: async function (data) {
    var strUrl = this.path() + "/create";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postEditRole: async function (data) {
    var strUrl = this.path() + "/update";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  deleteRole: async function (params) {
    var strUrl = this.path() + "/delete";
    await Util.checkToken();
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params);
  },
  postCopyRole: async function (data) {
    var strUrl = this.path() + "/copy";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postDeleteUserInRole: async function (data) {
    var strUrl = this.path() + "/delete_users";
    await Util.checkToken();
    return BzbsApi.connectBzbsDeleteBody(strUrl, this.token(), data);
  },
  postPermissionProduct: async function (data) {
    var strUrl = this.path() + "/permission/product/save";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postPermissionApplication: async function (data) {
    var strUrl = this.path() + "/permission/application/save";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postPermissionCross: async function (data) {
    var strUrl = this.path() + "/permission/cross_application/save";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postAddApplications: async function (data) {
    var strUrl = this.path() + "/add_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postSetPermissionApp: async function (data) {
    var strUrl = this.path() + "/permission/application";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postCopyPermission: async function (data) {
    var strUrl = this.path() + "/permission/application/copy";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postCopiesPermission: async function (data) {
    var strUrl = this.path() + "/permission/applications/copy";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postDeleteApplications: async function (data) {
    var strUrl = this.path() + "/delete_applications";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postResetRole: async function (objparams) {
    var strUrl = this.pathV2() + "/reset";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), objparams);
  },
};
