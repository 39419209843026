import { config } from '@/config/bzbsconfig.js' // auto build use this
// import { config } from '@/config/bzbsconfig.dev.js';
// import { config as configStg} from '@/config/bzbsconfig.stg.js';
// import { config } from '@/config/bzbsconfig.prd.js';

export default {
  install(Vue) {
    if (config.debug) {
      Vue.bzbsConfig = config
    } else {
      Vue.bzbsConfig = config
    }
  },
}
