<template>
  <div>
    <AppLayout>
      <div
        :class="`${$route.name}`"
        class="campaigns-detail-main campaigns-detail-page page"
      >
        <Breadcrumbs
          v-if="!isShowSkeleton"
          :databreadcrumbs="breadcrumbs"
          :title="lbls.menu.assignrolemanagement"
        />
        <div v-if="isShowSkeleton" class="background-white p-5">
          <a-skeleton />
        </div>
        <div
          v-if="!isShowSkeleton"
          class="manage-page-detail set-permission-app"
        >
          <div class="set-permission-top">
            <div class="flex-center">
              <div class="mr-3">
                <router-link
                  :to="{
                    name: 'ManagePermission',
                    query: {
                      id: $route.query.id,
                    },
                  }"
                >
                  <span class="icon-custom-add icon-arrow-nex">
                    <img :src="IConArrowNext" />
                  </span>
                  <span> BACK </span>
                </router-link>
              </div>
              <a-divider type="vertical" />
              <div class="ml-3 flex-center">
                <div class="logo-custom"><img :src="logo" /></div>
                <div class="ml-1">
                  <div class="label-name-app">
                    {{ dataApplicationNew.name }}
                  </div>
                  <div v-if="dataApplicationNew.package" class="label-package">
                    <span class="label-name-package"
                      >Package: {{ dataApplicationNew.package.name }}
                    </span>
                    <span class="label-name-product ml-1">
                      Product: {{ dataProductsNew.length }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="set-permission-body">
            <div class="box-left">
              <div
                v-for="item in dataProductsNew"
                :key="item.id"
                class="item-button-href flex-center"
                :class="{ 'href-active': item.name == productActive }"
              >
                <a
                  class="w-100"
                  :href="'#' + item.name"
                  @click="clickMeTo(item.name)"
                >
                  {{ item.display_name }}
                  <span v-if="checkListofFeature(item.features)"
                    ><a-icon
                      type="star"
                      theme="twoTone"
                      two-tone-color="#f79443"
                  /></span>
                </a>
              </div>
            </div>
            <div class="box-right">
              <!-- <div v-if="dataProduct.feature_selected_all" class="mb-3">
                <span>
                  <a-checkbox
                    :checked="setCheckAllProduct()"
                    :indeterminate="setIndeterminateAllProduct()"
                    @change="onChangAllCheckBoxAll($event)"
                  >
                    <span class="lable-check-all">Select All</span>
                    <span class="lable-length">
                      {{ dataProductsNew.feature_selected_all.length }}/{{
                        dataProductsNew.feature_all.length
                      }}
                    </span>
                  </a-checkbox>
                </span>
              </div> -->
              <div class="item-product" @scroll="onScroll($event)">
                <div
                  v-for="item in dataProductsNew"
                  :id="item.name"
                  :key="item.id"
                  class="test001"
                  :class="{ 'test001-active': item.name == productActive }"
                >
                  <div class="item-title-product mb-3">
                    <span>{{ item.display_name }}</span>
                  </div>
                  <div class="mt-3 set-border-bottom"></div>
                  <div class="mt-3">
                    <a-collapse v-model="item.activeKey">
                      <a-collapse-panel
                        v-if="item.data_levels"
                        key="1"
                        :header="lbls.role_page.title_data_level"
                      >
                        <span
                          ><div>
                            <a-radio-group
                              v-model="item.idDataLv"
                              @change="onChangeRadio($event, item)"
                            >
                              <a-radio value="none"> None </a-radio>
                              <a-radio
                                v-for="lv in item.data_levels"
                                :key="lv.id"
                                :value="lv.id"
                              >
                                {{ lv.display_name }}
                              </a-radio>
                            </a-radio-group>
                          </div></span
                        >
                      </a-collapse-panel>
                      <a-collapse-panel
                        v-if="item.newmenu"
                        key="2"
                        header="Menu"
                      >
                        <span>
                          <a-tree
                            v-if="item.newmenu"
                            v-model="checkedKeysMenu"
                            checkable
                            :tree-data="item.newmenu"
                            @expand="onExpandMenu"
                          >
                            <template slot="title" slot-scope="text">
                              <div class="d-flex">
                                <div>{{ text.key }}</div>
                              </div>
                            </template>
                          </a-tree>
                        </span>
                      </a-collapse-panel>
                      <a-collapse-panel
                        v-if="item.newpage"
                        key="3"
                        header="Page"
                      >
                        <span
                          ><a-tree
                            v-if="item.newpage"
                            v-model="checkedKeysPage"
                            checkable
                            :tree-data="item.newpage"
                            @expand="onExpandPage"
                          >
                            <template slot="title" slot-scope="text">
                              <a-checkbox
                                v-if="text.islevelpage"
                                :checked="text.has_page"
                                @click="onChange($event, text, item.newpage)"
                              >
                                <span class="custom-title-checkbox">{{
                                  text.title
                                }}</span>
                              </a-checkbox>
                              <span v-else>
                                {{ text.title }}
                              </span>
                            </template>
                          </a-tree></span
                        >
                      </a-collapse-panel>
                      <a-collapse-panel
                        key="4"
                        :header="lbls.role_page.title_features"
                      >
                        <span
                          ><div class="label-item">
                            <div class="checkbox-all">
                              <a-checkbox
                                :checked="setCheckAll(item.features)"
                                :indeterminate="setIndeterminate(item.features)"
                                @change="
                                  onChangAllCheckBox($event, item.features)
                                "
                              >
                                <span>
                                  <span>Select All </span>
                                  <span class="lable-length">{{
                                    setShowLengthItem(item.features)
                                  }}</span>
                                </span>
                              </a-checkbox>
                            </div>
                            <div class="feature-list">
                              <a-checkbox
                                v-for="feature in item.features"
                                :key="feature.id"
                                :checked="feature.is_selected"
                                class="feature-item"
                                @change="onChangesCheckbox($event, feature)"
                              >
                                <span>{{ feature.display_name }}</span>
                              </a-checkbox>
                            </div>
                          </div></span
                        >
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                  <!-- <div
                    v-if="item.data_levels"
                    class="mt-2 mb-3 item-title-feature"
                  >
                    <div class="mb-2 title-item">
                      {{ lbls.role_page.title_data_level }}
                    </div>
                    <div class="label-item">
                      <div>
                        <a-radio-group
                          v-model="item.idDataLv"
                          @change="onChangeRadio($event, item)"
                        >
                          <a-radio value="none"> None </a-radio>
                          <a-radio
                            v-for="lv in item.data_levels"
                            :key="lv.id"
                            :value="lv.id"
                          >
                            {{ lv.display_name }}
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="mt-2 item-title-feature">
                    <div class="mb-2 title-item">
                      {{ lbls.role_page.title_features }}
                    </div>
                    <div class="label-item">
                      <div class="checkbox-all">
                        <a-checkbox
                          :checked="setCheckAll(item.features)"
                          :indeterminate="setIndeterminate(item.features)"
                          @change="onChangAllCheckBox($event, item.features)"
                        >
                          <span>
                            <span>Select All </span>
                            <span class="lable-length">{{
                              setShowLengthItem(item.features)
                            }}</span>
                          </span>
                        </a-checkbox>
                      </div>
                      <div class="feature-list">
                        <a-checkbox
                          v-for="feature in item.features"
                          :key="feature.id"
                          :checked="feature.is_selected"
                          class="feature-item"
                          @change="onChangesCheckbox($event, feature)"
                        >
                          <span>{{ feature.display_name }}</span>
                        </a-checkbox>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="mt-2 item-title-feature">
                    <div class="mb-2 title-item">Menu</div>
                    <div class="label-item">
                      <div class="checkbox-all">
                        <a-checkbox
                          :checked="setCheckAll(item.features)"
                          :indeterminate="setIndeterminate(item.features)"
                          @change="onChangAllCheckBox($event, item.features)"
                        >
                          <span>
                            <span>Select All </span>
                            <span class="lable-length">{{
                              setShowLengthItem(item.features)
                            }}</span>
                          </span>
                        </a-checkbox>
                      </div>
                      <div>
                        <a-tree
                          v-if="item.newmenu"
                          v-model="checkedKeysMenu"
                          checkable
                          default-expand-all
                          :tree-data="item.newmenu"
                          @expand="onExpandMenu"
                          @select="onSelectMenu"
                        >
                          <template slot="title" slot-scope="text">
                            <div class="d-flex">
                              <div>{{ text.title }}</div>
                            </div>
                          </template>
                        </a-tree>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="mt-2 item-title-feature">
                    <div class="mb-2 title-item">page</div>
                    <div class="label-item">
                      <div class="checkbox-all">
                        <a-checkbox
                          :checked="setCheckAll(item.features)"
                          :indeterminate="setIndeterminate(item.features)"
                          @change="onChangAllCheckBox($event, item.features)"
                        >
                          <span>
                            <span>Select All </span>
                            <span class="lable-length">{{
                              setShowLengthItem(item.features)
                            }}</span>
                          </span>
                        </a-checkbox>
                      </div>
                      <div>
                        <a-tree
                          v-if="item.newpage"
                          v-model="checkedKeysPage"
                          checkable
                          default-expand-all
                          :tree-data="item.newpage"
                          @expand="onExpandPage"
                          @select="onSelectPage"
                        >
                          <template slot="title" slot-scope="text">
                            <span :class="{ toontest: text.features }">
                              {{ text.title }}
                            </span>
                          </template>
                        </a-tree>
                      </div>
                    </div>
                  </div> -->
                  <div class="mt-3 set-border-bottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div v-if="isModalProcess">
          <ModalProcess
            :modalprocess="modalprocess"
            :dataobjselect="dataObjSelect"
            @actionModal="actionModal($event)"
          >
          </ModalProcess>
        </div>
        <div v-if="isModalResponse">
          <ModalAlert
            :modalalert="modalAlert"
            :alertdata="alertData"
            @handlemodalok="handlemodalOk"
          >
          </ModalAlert>
        </div>
      </div>
    </AppLayout>
    <div v-if="!isShowSkeleton" class="custom-footer-setpermission">
      <!-- <a-button :disabled="disablebtnok" type="primary" @click="handleSave()"> -->
      <a-button type="primary" @click="handleSave()">
        {{ lbls.role_page.btn.save }}
      </a-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import AppLayout from "@/components/Layout/LayoutMaster.vue";
import manageRoleAPI from "@/core/Page/ManageRoleAPI";
import Cache from "@/helper/Cache.js";
// import PageHeader from "@/module/Role/ManageSetRole/components/Layout/PageHeader.vue";
// import BodyProduct from "@/module/Role/ManageSetRole/components/Layout/BodyProduct.vue";
// import BodyApp from "@/module/Role/ManageSetRole/components/Layout/BodyApp.vue";
import ModalProcess from "@/module/Role/ManageSetRole/components/Modal/ModalProcess.vue";
import ModalAlert from "@/components/Modal/Alertmodal.vue";

export default {
  name: "ManageRole",
  components: {
    AppLayout,
    // PageHeader,
    // BodyApp,
    // BodyProduct,
    ModalProcess,
    ModalAlert,
  },
  data() {
    return {
      breadcrumbs: [],
      logo: require("@/assets/images/bzbs-logo1.png"),
      isShowSkeleton: true,
      data: [],
      dataRole: {},
      dataPackages: [],
      dataProduct: [],
      btndeletedisabled: false,
      isEdit: false,
      objSelect: [],
      dataObjSelect: [],
      valueDataLv: [],
      isModalProcess: false,
      isModalResponse: false,
      titleModalProcess: "",
      showBtnDelete: false,
      tabActive: "",
      tabItemActive: "1",
      totalList: 0,
      idModal: "",
      titleModal: "",
      isDelete: false,
      errtransaction_id: "",
      modalbtnok: "",
      contentModal: "",
      searchInput: null,
      valuecheckbox: [],
      listOfDatalv: [],
      listOfFeature: [],
      dataProductForSave: [],
      record: "",
      valueDataLvRadio: "",
      resetbgc: {
        backgroundColor: "",
      },
      disablebtnok: true,
      indeterminate: true,
      checkAll: false,
      dataPageBreadcrumb: {},
      myoffsetHeight: 0,
      alertData: {},
      modalAlert: {
        visible: false,
        id: "",
        title: "",
        message: "",
        okText: "",
        cancelText: "",
      },
      dataPageHeader: {},
      targetOffset: undefined,
      productActive: "",
      offsetTop: 0,
      dataApplicationNew: [],
      dataProductsNew: [],
      dataRoleNew: [],
      checkedKeysMenu: [],
      expandedKeysMenu: [],
      autoExpandParentMenu: true,
      checkedKeysPage: [],
      expandedKeysPage: [],
      autoExpandParentPage: true,
      activeKey: [],
      dataKeyPage: [],
      dataFeaturesSelect: [],
    };
  },
  watch: {
    offsetTop() {
      this.callbackFunc();
    },
    checkedKeysMenu(val) {
      console.log("onCheck-watch", val);
    },
  },
  mounted() {
    this.targetOffset = window.innerHeight / 2;
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.init();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.exAccount = Cache.get("exAccount");
        Promise.all([this.callGetDetail()]).then((resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess) {
            this.nameSearch = "";
            this.callGetList();
            this.callPermissonApp();
          } else {
            this.handleLoading(false);
          }
        });
      } else {
        this.$router.push({ name: "ManageRoles" });
      }
    },
    showModalProcess() {
      this.isModalProcess = true;
      this.handleLoading(false);
      this.modalprocess = {
        visible: true,
        title: this.titleModalProcess,
        key: "user_page.",
        isCreate: this.isCreate,
        isEdit: this.isEdit,
      };
    },
    closeModalProcess() {
      this.isModalProcess = false;
    },
    resetCondition() {
      this.isEdit = false;
      this.isDelete = false;
    },
    handleEdit() {
      this.resetCondition();
      this.handleLoading(true);
      this.isEdit = true;
      this.titleModalProcess = this.lbls.role_page.drw_title_edit;
      this.callGetDetailEdit();
    },
    handleDelete() {
      this.resetCondition();
      this.idModal = "confirm";
      this.isDelete = true;
      this.modalbtnok = this.lbls.role_page.modal.btn.delete;
      this.titleModal = this.lbls.role_page.modal.title_confirm;
      this.contentModal =
        this.lbls.role_page.modal.content_delete + this.objSelect.name + " ?";
      this.modalAlert = {
        id: this.idModal,
        visible: true,
        title: this.titleModal,
        message: this.contentModal,
        okText: this.modalbtnok,
        cancelText: this.modalbtncancel,
        isDelete: this.isDelete,
      };
      this.isModalResponse = true;
    },
    callPermissonApp() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
        application_id: this.$route.query.appid,
      };
      manageRoleAPI
        .getPermissionApp(params)
        .then((res) => {
          if (res.data && res.data.result) {
            let result = res.data.result;
            console.log(result, "result");
            _.forEach(result.products, (product) => {
              if (product.menus && product.menus.length > 0) {
                product.newmenu = this.onSetMenu(product.menus);
                product.newpage = this.onSetPage(product.menus);
              }
              this.dataFeaturesSelect = this.setDataFeatures(product.features);
            });
            this.dataFeaturesSelect = [...new Set(this.dataFeaturesSelect)];
            this.dataApplicationNew = result.application;
            this.dataProductsNew = result.products;
            this.dataRoleNew = result.role;
            this.isSetDataForCheckSave = true;
            this.setDataProductsNew();
            this.handleLoading(false);
            this.isShowSkeleton = false;
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetList() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetail(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataRole = res.data.result.role ? res.data.result.role : {};
            if (res.data.result.items) {
              let items = res.data.result.items;
              let dataPackages = [];
              _.forEach(items, (element) => {
                if (
                  element.products ||
                  element.applications ||
                  element.cross_applications
                ) {
                  dataPackages.push(element);
                  if (element.products) {
                    element.products.feature_all = [];
                    element.products.feature_selected_all = [];
                    _.forEach(element.products, (product) => {
                      product.isShow = true;
                      product.isEdit = false;
                      product.dataforcheckStrat = this.setValueCheck(product);
                      product.dataforcheck = _.cloneDeep(
                        product.dataforcheckStrat
                      );
                      let feature_selected = [];
                      _.forEach(product.features, (feature) => {
                        element.products.feature_all.push(feature);
                        if (feature.is_selected) {
                          feature_selected.push(feature);
                          element.products.feature_selected_all.push(feature);
                        }
                      });
                      product.feature_selected = feature_selected;
                    });
                  }
                  if (element.applications) {
                    element.applications.isShow = true;
                    element.applications.isEdit = false;
                    element.applications.dataforcheckStrat = this.setValueCheck(
                      element.applications
                    );
                    element.applications.dataforcheck = _.cloneDeep(
                      element.applications.dataforcheckStrat
                    );
                  }
                  if (element.cross_applications) {
                    element.cross_applications.isShow = true;
                    element.cross_applications.isEdit = false;
                  }
                }
                element.collapse = false;
              });
              this.dataPackages = dataPackages[0];
              this.dataProduct = this.dataPackages.products;
              // this.productActive = this.dataProduct[0].name;
              console.log(this.dataProduct, "this.dataProduct");
              // if (dataPackages && this.tabActive == "") {
              //   this.tabActive = this.dataPackages[0].package.id;
              // }
            }
          }
          this.handleLoading(false);
          this.isShowSkeleton = false;
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callGetDetail() {
      return new Promise((resolve) => {
        let isCallDetailSuccess = false;
        let params = {
          account_id: this.exAccount.account.id,
          role_id: this.$route.query.id,
        };
        manageRoleAPI
          .getDetailRole(params)
          .then((res) => {
            if (res.data && res.data.result) {
              isCallDetailSuccess = true;
              this.dataPageHeader = res.data.result;
              this.objSelect = res.data.result;
              resolve(isCallDetailSuccess);
            } else {
              resolve(isCallDetailSuccess);
            }
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(isCallDetailSuccess);
          });
      });
    },
    callGetDetailEdit() {
      let params = {
        account_id: this.exAccount.account.id,
        role_id: this.$route.query.id,
      };
      manageRoleAPI
        .getDetailRole(params)
        .then((res) => {
          if (res.data && res.data.result) {
            this.dataObjSelect = res.data.result;
            const newData = {
              id: this.objSelect.id,
              name: this.objSelect.name,
              description: this.objSelect.description,
              is_active: this.objSelect.is_active,
            };
            this.dataEditForCheck = JSON.stringify(newData);
            this.dataObjSelect.dataEditForCheck = this.dataEditForCheck;
            this.showModalProcess();
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostEditData() {
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objDataSave.id,
        role_name: this.objDataSave.name,
        role_description: this.objDataSave.description,
        is_active: this.objDataSave.is_active,
      };
      this.objForSave = JSON.stringify(valueSave);
      manageRoleAPI
        .postEditRole(this.objForSave)
        .then((res) => {
          if (res.status == "200") {
            if (res.data.result) {
              this.idModal = "success";
              this.objSelect = res.data.result;
              const newData = {
                id: this.objSelect.id,
                description: this.objSelect.description,
                is_active: this.objSelect.is_active,
                name: this.objSelect.name,
              };
              this.dataEditForCheck = JSON.stringify(newData);
              this.closeModalProcess();
              setTimeout(() => {
                this.titleModal = this.lbls.role_page.modal.title_success;
                this.contentModal =
                  this.lbls.role_page.modal.content_update_success;
                this.openNotificationWithIcon("success");
                this.dataPageHeader = res.data.result;
              }, 500);
            }
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    callPostDeleteData() {
      this.handleLoading(true);
      var valueSave = {
        account_id: this.exAccount.account.id,
        role_id: this.objSelect.id,
      };
      manageRoleAPI
        .deleteRole(valueSave)
        .then((res) => {
          if (res.status == "200") {
            this.idModal = "success";
            this.closeModalProcess();
            setTimeout(() => {
              this.titleModal = this.lbls.role_page.modal.title_success;
              this.contentModal =
                this.lbls.role_page.modal.content_delete_success;
              this.openNotificationWithIcon("success");
            }, 500);
          }
        })
        .catch((error) => {
          this.idModal = "error";
          this.handleLoading(true);
          this.errorApi(error);
        });
    },
    handlemodalOk() {
      if (this.idModal == "error") {
        this.isModalResponse = false;
        return;
      }
      this.handleLoading(true);
      if (this.isDelete) {
        this.callPostDeleteData();
      } else {
        this.callPostEditData();
      }
      this.isModalResponse = false;
    },
    handleSave() {
      // this.handleLoading(true);
      console.log(this.checkedKeysMenu, "checkedKeysMenu");
      console.log(this.dataKeyPage, "dataKeyPage");
      console.log(this.checkedKeysPage, "checkedKeysPage");
      _.forEach(this.checkedKeysPage, (item) => {
        this.dataFeaturesSelect.push(item);
      });
      this.dataFeaturesSelect = [...new Set(this.dataFeaturesSelect)];
      console.log(this.dataFeaturesSelect, "dataFeaturesSelect");
      console.log(this.dataProductForSave, "dataProductForSave"); // let record = {
      //   account_id: this.exAccount.account.id,
      //   role_id: this.dataPageHeader.id,
      //   application_id: this.dataApplicationNew.id,
      //   products: this.dataProductForSave,
      // };
      // record = JSON.stringify(record);
      // manageRoleAPI
      //   .postSetPermissionApp(record)
      //   .then((res) => {
      //     if (res.status == "200" && res.data.result) {
      //       this.idModal = "success";
      //       this.ispostPermission = true;
      //       this.titleModal = this.lbls.role_page.modal.title_success;
      //       this.contentModal =
      //         this.lbls.role_page.modal.content_update_success;
      //       this.isSetDataForCheckSave = true;
      //       this.settestcheckallproduct();
      //       this.openNotificationWithIcon("success");
      //     }
      //   })
      //   .catch((error) => {
      //     this.idModal = "error";
      //     this.handleLoading(true);
      //     this.errorApi(error);
      //   });      // Promise.all([this.callPostPermissionProduct(record)]).then(
      //   (resgetdetail) => {
      //     let isCallListSuccess = resgetdetail[0];
      //     if (isCallListSuccess == "success") {
      //       this.titleModal = this.mylbl("role_page.modal.title_success");
      //       this.contentModal = this.mylbl(
      //         "role_page.modal.content_update_success"
      //       );
      //       this.openNotificationWithIcon("success");
      //     }
      //     this.handleLoading(false);
      //   }
      // );
    },
    handleSaveProduct(product) {
      this.handleLoading(true);
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: this.subScription,
      };
      record.product_id = product.id;
      record.feature_ids = product.itemSelect;
      if (product.data_levels && product.valueRadio) {
        record.data_level_id = product.valueRadio;
      }
      record = JSON.stringify(record);
      this.callPostPermissionProduct(record);
    },
    handleSaveApp(items, sub_id) {
      this.handleLoading(true);
      let itemselect = [];
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: sub_id,
      };
      _.forEach(items, (item) => {
        if (item.is_selected) {
          itemselect.push(item.id);
        }
      });
      record.application_ids = itemselect;
      record = JSON.stringify(record);
      Promise.all([this.callPostPermissionApplication(record)]).then(
        (resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess == "success") {
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.openNotificationWithIcon("success");
            items.dataforcheckStrat = this.setValueCheck(items);
            items.dataforcheck = _.cloneDeep(items.dataforcheckStrat);
          }
          this.handleLoading(false);
          this.$forceUpdate();
        }
      );
    },
    handleSaveCrossApp(items, sub_id) {
      this.handleLoading(true);
      let itemselect = [];
      let record = {
        account_id: this.exAccount.account.id,
        role_id: this.dataPageHeader.id,
        subscription_id: sub_id,
      };
      _.forEach(items, (item) => {
        if (item.is_selected) {
          itemselect.push(item.id);
        }
      });
      record.cross_application_ids = itemselect;
      record = JSON.stringify(record);
      Promise.all([this.callPostPermissionCross(record)]).then(
        (resgetdetail) => {
          let isCallListSuccess = resgetdetail[0];
          if (isCallListSuccess == "success") {
            this.titleModal = this.lbls.role_page.modal.title_success;
            this.contentModal =
              this.lbls.role_page.modal.content_update_success;
            this.openNotificationWithIcon("success");
            items.dataforcheckStrat = this.setValueCheck(items);
            items.dataforcheck = _.cloneDeep(items.dataforcheckStrat);
          }
          this.handleLoading(false);
          this.$forceUpdate();
        }
      );
    },
    callPostPermissionProduct(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionProduct(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    callPostPermissionApplication(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionApplication(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    callPostPermissionCross(record) {
      return new Promise((resolve) => {
        manageRoleAPI
          .postPermissionCross(record)
          .then((res) => {
            if (res.status == "200" && res.data.result) {
              this.idModal = "success";
              this.ispostPermission = true;
            }
            resolve(this.idModal);
          })
          .catch((error) => {
            this.idModal = "error";
            this.handleLoading(true);
            this.errorApi(error);
            resolve(this.idModal);
          });
      });
    },
    resetFieldOther() {
      this.valueDataLv = "";
      this.valueDataLvRadio = "";
      this.isShowListDatavl = false;
      this.listOfFeature = [];
      this.valuecheckbox = [];
      this.listOfDatalv = [];
      this.valuecheckboxApp = [];
      this.listOfFeatureApp = [];
      this.valuecheckboxCross = [];
      this.listOfFeatureCross = [];
      this.data.forEach((element) => {
        element.isEditShowCheckboxApp = false;
        element.isEditShowCheckboxCross = false;
        if (element.products) {
          if (element.products.length > 0) {
            element.products.forEach((products) => {
              products.isEditShowCheckbox = false;
              products.isShowListDatavl = false;
            });
          }
        }
      });
      let temp = this.data;
      this.data = [];
      this.data = temp;
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.titleModal,
        description: this.contentModal,
      });
      this.isShowSkeleton = false;
      if (this.isDelete) {
        setTimeout(() => {
          this.$router.push("manageroles");
        }, 500);
      } else {
        this.handleLoading(false);
      }
    },
    actionModal(value) {
      if (value == "close") {
        this.isModalProcess = false;
        return;
      } else {
        this.handleLoading(true);
        if (this.isEdit) {
          this.objDataSave = value;
          this.callPostEditData();
        }
      }
    },
    changetab(val) {
      this.tabItemActive = val;
      console.log(this.tabItemActive);
    },
    callback(val) {
      console.log(val);
    },
    callbacks(val) {
      this.tabActive = val;
    },
    handleBack(item) {
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    handleBackrecord(event, item, valuename) {
      event.stopPropagation();
      let findpackage = this.dataPackages.find(
        (o) => o.package.id === this.tabActive
      );
      let findfeatures = findpackage.products.find((o) => o.id === item.id);
      if (findfeatures) {
        findfeatures.features = this.dataStrar.features;
      }
      if (valuename == "app") {
        findpackage.applications = this.dataStrar;
        findpackage.applications.isShow = true;
      }
      if (valuename == "cross_app") {
        findpackage.cross_applications = this.dataStrar;
        findpackage.cross_applications.isShow = true;
      }
      item.isEdit = !item.isEdit;
      this.$forceUpdate();
    },
    onChangesCheckbox(e, feature) {
      let checked = e.target.checked;
      feature.is_selected = checked;
      this.settestcheckallproduct();
      // this.settestcheckall(item);      // let checked = e.target.checked;
      // item.is_selected = checked;
      // if (checked) {
      //   this.featureSelect.push(item.id);
      // } else {
      //   this.featureSelect = this.featureSelect.filter((data) => {
      //     return data != item.id;
      //   });
      // }
      this.$forceUpdate();
    },
    handleClickManage(value, item) {
      if (!value.isShow) value.isShow = true;
      this.dataStrar = _.cloneDeep(value);
      this.subScription = item.subscription.id;
      this.featureSelect = [];
      _.forEach(this.dataPackages, (element) => {
        if (element.products) {
          _.forEach(element.products, (product) => {
            product.isEdit = false;
          });
        }
        if (element.applications) {
          element.applications.isEdit = false;
        }
        if (element.cross_applications) {
          element.cross_applications.isEdit = false;
        }
      });
      value.isEdit = !value.isEdit;
      this.$forceUpdate();
    },
    onChangeRadio(e, datas) {
      _.forEach(datas.data_levels, (datalevel) => {
        datalevel.is_selected = false;
        if (e.target.value == datalevel.id) {
          datalevel.is_selected = true;
        }
      });
      datas.idDataLv = e.target.value;
      datas.dataforcheck = this.setValueCheck(datas);
      this.setDataForSave();
      this.$forceUpdate();
    },
    checkFeature(features) {
      let itemselect = [];
      _.forEach(features, (feature) => {
        if (feature.is_selected) {
          itemselect.push(feature);
        }
      });
      return itemselect.length;
    },
    checkFeatureSelect(features) {
      let itemselect = [];
      _.forEach(features, (feature) => {
        if (feature.is_selected) {
          itemselect.push(feature);
        }
      });
      return itemselect.length == features.length;
    },
    changeSwitch(checked, value, item) {
      value.is_selected = checked;
      value.dataforcheck = this.setValueCheck(item);
      this.$forceUpdate();
    },
    setValueCheck(value) {
      let dataforcheck = [];
      if (value.data_levels) {
        let findobj = value.data_levels.find((o) => o.is_selected === true);
        if (findobj) {
          value.idDataLv = findobj.id;
        } else {
          value.idDataLv = "none";
        }
        dataforcheck.push(value.idDataLv);
      }
      let items = value.features ? value.features : value;
      _.forEach(items, (item) => {
        if (item.is_selected) {
          dataforcheck.push(item.id);
        }
      });
      value.dataforcheck = JSON.stringify(dataforcheck);
      return JSON.stringify(dataforcheck);
    },
    changeSwitchSelectAll(checked, value) {
      let items = value.features ? value.features : value;
      let dataforcheck = [];
      if (value.data_levels) {
        let findobj = value.data_levels.find((o) => o.is_selected === true);
        if (findobj) {
          value.idDataLv = findobj.id;
        } else {
          value.idDataLv = "none";
        }
        dataforcheck.push(value.idDataLv);
      }
      _.forEach(items, (item) => {
        item.is_selected = checked;
        if (item.is_selected) {
          dataforcheck.push(item.id);
        }
      });
      value.dataforcheck = JSON.stringify(dataforcheck);
      this.$forceUpdate();
    },
    toggles(value) {
      if (value.isEdit) return;
      value.isShow = !value.isShow;
      this.$forceUpdate();
    },
    setCollapseAll(value) {
      value.collapse = !value.collapse;
      _.forEach(value.products, (item) => {
        item.isShow = !value.collapse;
      });
      this.$forceUpdate();
    },
    clickMeTo(refName) {
      this.productActive = refName;
    },
    checkListofFeature(features) {
      let featureSelect = features.filter((data) => {
        return data.is_selected;
      });
      return featureSelect.length > 0;
    },
    onScroll(refName) {
      this.offsetTop = refName.target.scrollTop;
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      let tootest = rect.top >= 200;
      return tootest;
    },
    callbackFunc() {
      let items = document.querySelectorAll(".test001");
      this.isToonTest = false;
      for (var i = 0; i < items.length; i++) {
        if (!this.isToonTest && this.isElementInViewport(items[i])) {
          this.productActive = items[i].id;
          this.isToonTest = true;
        }
      }
    },
    setCheckAllProduct() {
      let feature_all = this.dataProductsNew.feature_all
        ? this.dataProductsNew.feature_all.length
        : 0;
      let feature_selected_all = this.dataProductsNew.feature_selected_all
        ? this.dataProductsNew.feature_selected_all.length
        : 0;
      return feature_all == feature_selected_all;
    },
    setIndeterminateAllProduct() {
      let feature_all = this.dataProductsNew.feature_all
        ? this.dataProductsNew.feature_all.length
        : 0;
      let feature_selected_all = this.dataProductsNew.feature_selected_all
        ? this.dataProductsNew.feature_selected_all.length
        : 0;
      return feature_all != feature_selected_all && feature_selected_all > 0;
    },
    onChangAllCheckBoxAll(e) {
      console.log(e.target.checked);
      _.forEach(this.dataProductsNew, (element) => {
        this.onChangAllCheckBox(e, element.features);
      });
    },
    setCheckAll(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return featuresSelect.length == features.length;
    },
    setIndeterminate(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      return (
        featuresSelect.length > 0 && featuresSelect.length != features.length
      );
    },
    setShowLengthItem(features) {
      let featuresSelect = features.filter((item) => {
        return item.is_selected;
      });
      let lengthItem =
        "(" + featuresSelect.length + "/" + features.length + ")";
      return lengthItem;
    },
    onChangAllCheckBox(e, features) {
      _.forEach(features, (item) => {
        this.onChangesCheckbox(e, item);
      });
      // this.settestcheckall(data);
      // this.dataProduct.feature_selected_all = [
      //   ...new Set(this.dataProduct.feature_selected_all),
      // ];
    },
    settestcheckall(data) {
      data.feature_selected = [];
      _.forEach(data.features, (feature) => {
        if (feature.is_selected) {
          data.feature_selected.push(feature);
        }
      });
      this.settestcheckallproduct();
    },
    settestcheckallproduct() {
      let feature_selected_all = [];
      let product_list = [];
      let itemproduct = {};
      this.dataProductsNew.feature_selected_all = [];
      _.forEach(this.dataProductsNew, (element) => {
        if (element.features) {
          let feature_list = [];
          _.forEach(element.features, (feature) => {
            if (feature.is_selected) {
              feature_selected_all.push(feature);
              feature_list.push(feature.id);
            }
          });
          if (feature_list.length > 0) {
            itemproduct = {
              id: element.id,
              feature_ids: feature_list,
            };
            product_list.push(itemproduct);
          }
          this.dataProductsNew.feature_selected_all = feature_selected_all;
        }
        element.activeKey = [];
        if (element.data_levels) {
          element.activeKey.push("1");
        }
        if (element.newmenu) {
          element.activeKey.push("2");
        }
        if (element.newpage) {
          element.activeKey.push("3");
        }
        if (element.features) {
          element.activeKey.push("4");
        }
      });
      this.dataProductForSave = product_list;
      this.setDataForSave();
    },
    setDataForSave() {
      _.forEach(this.dataProductsNew, (element) => {
        if (element.idDataLv) {
          let findobj = this.dataProductForSave.find(
            (o) => o.id === element.id
          );
          if (findobj) {
            findobj.data_level_id = element.idDataLv;
            if (element.idDataLv == "none") {
              delete findobj["data_level_id"];
            }
          } else {
            if (element.idDataLv == "none") return;
            this.dataProductForSave.push({
              id: element.id,
              data_level_id: element.idDataLv,
            });
          }
        }
      });
      if (this.isSetDataForCheckSave) {
        this.isSetDataForCheckSave = false;
        this.dataForCheckSave = JSON.stringify(this.dataProductForSave);
      }
      let dataProductForSave = JSON.stringify(this.dataProductForSave);
      this.disablebtnok = this.dataForCheckSave == dataProductForSave;
    },
    setDataProductsNew() {
      let feature_all = [];
      let feature_selected_all = [];
      _.forEach(this.dataProductsNew, (element, index) => {
        if (element.features) {
          _.forEach(element.features, (feature) => {
            feature.productid = element.id;
            feature_all.push(feature);
            if (feature.is_selected) {
              feature_selected_all.push(feature);
            }
          });
          this.dataProductsNew.feature_all = feature_all;
          this.dataProductsNew.feature_selected_all = feature_selected_all;
        }
        if (element.data_levels) {
          let findobj = element.data_levels.find((o) => o.is_selected === true);
          if (findobj) {
            element.idDataLv = findobj.id;
          } else {
            element.idDataLv = "none";
          }
        }
        if (index == 0) {
          this.productActive = element.name;
        }
      });
      this.settestcheckallproduct();
    },
    onExpandMenu(expandedKeysMenu) {
      console.log("onExpand", expandedKeysMenu);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      // this.expandedKeysMenu = expandedKeysMenu;
      // this.autoExpandParentMenu = false;
    },
    onSelectMenu(selectedKeys, info) {
      console.log("onSelect", info);
      console.log("selectedKeys", selectedKeys);
      // this.selectedKeys = selectedKeys;
    },
    onSetMenu(items) {
      let newmenu = [];
      _.forEach(items, (item) => {
        let children = [];
        if (item.menus && item.menus.length > 0) {
          let data = item.menus ? item.menus : item.features;
          children = this.onSetMenu(data);
        }
        if (item.is_menu_selected) {
          this.checkedKeysMenu.push(item.id);
          this.expandedKeysMenu = this.checkedKeysMenu;
        }
        newmenu.push({
          title: item.display_name,
          key: item.id,
          has_page: item.has_page,
          is_selected: item.is_menu_selected
            ? item.is_menu_selected
            : item.is_selected,
          sequence: item.sequence,
          children: children,
        });
      });
      return newmenu;
    },
    onExpandPage(expandedKeysPage) {
      console.log("onExpand", expandedKeysPage);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      // this.expandedKeysPage = expandedKeysPage;
      // this.autoExpandParentPage = false;
    },
    onSelectPage(selectedKeys, info) {
      console.log("selectedKeys", selectedKeys);
      console.log("info", info);
      // if (info.selected) {
      //   console.log(
      //     "onSelect",
      //     info.selectedNodes[0].data.props.disableCheckbox
      //   );
      // }
      // this.selectedKeys = selectedKeys;
    },
    onSetPage(items) {
      let newmenu = [];
      let features = null;
      _.forEach(items, (item) => {
        let children = [];
        // if (item.page_name != null) {
        //   item = Object.fromEntries(
        //     Object.entries(item).filter(([key]) => !key.includes("page_name"))
        //   );
        //   item = { ...item, menus: [{ ...item }] };
        //   item = Object.fromEntries(
        //     Object.entries(item).filter(([key]) => !key.includes("features"))
        //   );
        // }
        if (
          (item.menus && item.menus.length > 0) ||
          (item.features && item.features.length > 0)
        ) {
          if (item.features) {
            let dataFeaturesSelect = this.setDataFeatures(item.features);
            this.dataFeaturesSelect.push(dataFeaturesSelect);
          }
          let data = item.menus ? item.menus : item.features;
          children = this.onSetPage(data);
        }
        if (item.is_selected) {
          this.checkedKeysPage.push(item.id);
        }
        if (item.has_page) {
          this.dataKeyPage.push(item.id);
        }
        newmenu.push({
          title: item.display_name,
          key: item.id,
          features: features,
          has_page: item.has_page,
          islevelpage: item.page_name != null,
          disableCheckbox: item.is_selected == null,
          is_selected: item.is_menu_selected
            ? item.is_menu_selected
            : item.is_selected,
          sequence: item.sequence,
          children: children,
        });
      });
      return newmenu;
    },
    onChange(e, text, data) {
      console.log(`checked = ${e.target.checked}`);
      this.findObjData(data, text.key);
      this.dataKeyPage = [...new Set(this.dataKeyPage)];
      console.log(this.dataKeyPage);
    },
    findObjData(items, itemId) {
      let findobj = items.find((o) => o.key === itemId);
      if (findobj) {
        findobj.has_page = !findobj.has_page;
        if (findobj.has_page) {
          this.dataKeyPage.push(findobj.key);
        } else {
          this.dataKeyPage = this.dataKeyPage.filter((x) => x != findobj.key);
        }
        return;
      } else {
        _.forEach(items, (item) => {
          if (item.children && item.children.length > 0) {
            this.findObjData(item.children, itemId);
          }
        });
      }
    },
    setDataFeatures(data) {
      let featuresSelect = [];
      _.forEach(data, (item) => {
        if (item.is_selected) {
          featuresSelect.push(item.id);
        }
      });
      return featuresSelect;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.manage-page-detail {
  background: #fff;
  overflow: hidden;
  margin-top: 0;
  height: calc(100vh - 250px);
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper {
    min-width: 300px;
  }
}
.set-permission-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  margin: 0 16px;
  border-bottom: 1px solid $grey-grey-7;
  .ant-divider,
  .ant-divider-vertical {
    top: 0 !important;
    height: 30px;
  }
  .icon-arrow-nex {
    width: 15px;
    cursor: pointer;
    img {
      rotate: 180deg;
      width: 12px;
      margin-top: -2px;
    }
  }
  .logo-custom {
    img {
      width: 40px;
    }
  }
  .label-name-app {
    font-weight: 600;
    font-size: 14px;
  }
  .label-package {
    color: $grey-grey-5;
    font-size: 12px;
    font-weight: normal;
  }
  .label-name-product {
    border: 1px solid;
    border-radius: 25px;
    padding: 0px 8px;
  }
  a {
    color: $color-body;
  }
}
.set-permission-body {
  padding: 16px;
  display: flex;
  a {
    color: $color-body;
  }
  .box-left {
    width: 250px;
    margin-right: 16px;
    border-right: 1px solid $grey-grey-7;
    .item-button-href {
      height: 30px;
      &.href-active {
        border-right: 2px solid $color-primary;
        a {
          color: $color-primary;
        }
      }
    }
  }
  .box-right {
    width: calc(100% - 250px);
    .item-product {
      height: calc(100vh - 380px);
      padding-right: 8px;
      overflow: auto;
      display: block;
    }
    .test001 {
      background: #f9f9f9;
      padding: 16px;
      margin-bottom: 16px;
      &.test001-active {
        border: 1px solid $color-primary;
      }
    }
    .lable-check-all {
      color: #000;
    }
    .feature-list {
      padding-left: 16px;
    }
    .in-view {
      border: 1px solid $color-primary;
    }
    .set-border-bottom {
      border-bottom: 1px solid $grey-grey-7;
    }
    .item-title-product {
      color: #000;
    }
    .checkbox-all {
      border-bottom: 1px solid $grey-grey-10;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
    .item-title-feature {
      .title-item {
        font-size: 12px;
        opacity: 0.7;
      }
      .label-item {
        background: #fff;
        padding: 12px;
      }
    }
  }
}
.lable-length {
  opacity: 0.7;
  font-size: 12px;
}
.custom-footer-setpermission {
  position: absolute;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 63px;
  background: #fff;
  bottom: 0;
  width: 100%;
}
</style>
