var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-breadcrumbs"},[_c('a-breadcrumb',{attrs:{"routes":_vm.databreadcrumbs},scopedSlots:_vm._u([{key:"itemRender",fn:function({ route, params, routes }){return [(
          routes.indexOf(route) === routes.length - 1 && routes.length > 1
        )?_c('span',[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")]):[(routes.length > 4)?[(routes.indexOf(route) === 0)?[(route.name)?_c('router-link',{attrs:{"to":{ name: route.name }}},[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")]):_c('span',[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")])]:_vm._e(),(routes.indexOf(route) === routes.length - 2)?[(route.name)?_c('router-link',{attrs:{"to":{ name: route.name }}},[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")]):_c('span',[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")])]:_vm._e(),(routes.indexOf(route) === routes.length - 3)?_c('span',[_vm._v(" ... ")]):_vm._e()]:[(route.name && routes.length > 1)?_c('router-link',{attrs:{"to":{
              name: route.name,
              query: {
                id: _vm.$route.query.id,
              },
            }}},[_vm._v(" "+_vm._s(route.breadcrumbName)+" ")]):_vm._e()]]]}}])}),_c('a-page-header',{attrs:{"sub-title":_vm.description}},[(_vm.productCount !== undefined || _vm.totallist !== undefined || _vm.count)?_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.productCount)?_c('span',{staticClass:"product-info"},[_vm._v(" Product: "+_vm._s(_vm.productCount)+" ")]):_vm._e(),(_vm.totallist !== undefined)?[_c('a-badge',{staticClass:"count-list",attrs:{"count":_vm.totallist,"overflow-count":_vm.totallist}})]:_vm._e(),(_vm.count)?[_c('a-badge',{staticClass:"count-list",attrs:{"count":_vm.formatNumber(_vm.count),"show-zero":"","overflow-count":_vm.count}})]:_vm._e(),_vm._t("breadcrumbs")],2):_c('template',{slot:"title"},[(_vm.titleClass)?_c('span',{class:_vm.titleClass},[_vm._v(" "+_vm._s(_vm.title)+" ")]):[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("breadcrumbs")]],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }