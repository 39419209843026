import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getPackageList
 * @method getGetPackageDetail
 * @method getSearchPackageList
 * @method postBuyPackage
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion + "/package";
  },
  pathV2: function () {
    return this.config().apiBaseUrl + this.config().apiVersion2 + "/package";
  },
  getPackageList: async function (params) {
    var strUrl = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getGetPackageDetail: async function (params) {
    var strUrl = this.path() + "/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getSearchPackageList: async function (params) {
    var strUrl = this.pathV2() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  postBuyPackage: async function (params) {
    var strUrl = this.pathV2() + "/buy_package";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), params);
  },
  getCurrentPackage: async function (params) {
    var strUrl = this.pathV2() + "/get_current_package";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
};
