import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
import Util from "@/helper/Utility.js";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getUserList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = Cache.get("bzbsConfig");
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  path: function () {
    return this.config().apiBaseUrl + this.config().apiVersion + "/user";
  },
  pathV2: function () {
    return this.config().apiBaseUrl + this.config().apiVersion2 + "/user";
  },
  getUser: async function (params) {
    var strUrl = this.path() + "/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getUserList: async function (params) {
    var strUrl = this.path() + "/search";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  postInviteUser: async function (data) {
    var strUrl = this.path() + "/invite";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postAddInviteUser: async function (data) {
    var strUrl = this.pathV2() + "/add_invite";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postEditUser: async function (data) {
    var strUrl = this.path() + "/update";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postSaveRoles: async function (data) {
    var strUrl = this.path() + "/save_roles";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  postSearchEMails: async function (data) {
    var strUrl = this.path() + "/search_by_emails";
    await Util.checkToken();
    return BzbsApi.connectBzbsPostBody(strUrl, this.token(), data);
  },
  deleteUser: async function (params) {
    var strUrl = this.path() + "/delete";
    await Util.checkToken();
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params);
  },
  getUsePermission: async function (params) {
    var url =
      this.config().apiBaseUrl +
      this.config().apiVersion +
      "/user_permission/get";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getApplications(params) {
    var url = this.config().adAPI + "/v3.0/user/applications";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getMenu(params) {
    var url = this.config().adAPI + "/v3.0/user/menus";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getPackage(params) {
    var url = this.config().adAPI + "/v3.0/package/get_package";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getBzbsApplication(params) {
    var url = this.config().adAPI + "/v3.0/application/get_bzbs_applications";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getInvitation: async function (params) {
    var strUrl = this.path() + "/search_invitation";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  getInvitationCreateAccount: async function (params) {
    var strUrl = this.pathV2() + "/search_invitation_create_account";
    await Util.checkToken();
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
  },
  deleteInvitation: async function (params) {
    var strUrl = this.path() + "/delete_invitation";
    await Util.checkToken();
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), params);
  },
};
