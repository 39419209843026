<template>
  <span>
    <div v-if="isActionFlex" class="breadcrumbs-has-action-flex">
      <div class="sec-breadcrumbs">
        <TitleBreadcrumbs
          :title="title"
          :parent="parent"
          :totallist="totallist"
          :current="current"
          :databreadcrumbs="databreadcrumbs"
          :product-label="productLabel"
          :product-count="productCount"
          :count="count"
          :title-class="titleClass"
          :description="description"
        >
          <template v-slot:breadcrumbs>
            <slot name="breadcrumbs">{{ productCount }}</slot>
          </template>
        </TitleBreadcrumbs>
      </div>
      <div class="sec-action">
        <slot name="actions"></slot>
      </div>
    </div>
    <template v-else>
      <TitleBreadcrumbs
        :title="title"
        :parent="parent"
        :totallist="totallist"
        :current="current"
        :databreadcrumbs="databreadcrumbs"
        :product-label="productLabel"
        :product-count="productCount"
        :count="count"
        :title-class="titleClass"
        :description="description"
      />
    </template>
  </span>
</template>
<script>
import TitleBreadcrumbs from "@/components/Breadcrumbs/TitleBreadcrumbs.vue";
export default {
  name: "Breadcrumbs",
  components: {
    TitleBreadcrumbs,
  },
  props: {
    isActionFlex: Boolean,
    title: String,
    parent: Object,
    totallist: Number,
    current: String,
    databreadcrumbs: Array,
    productCount: Number,
    count: Number,
    productLabel: String,
    titleClass: String,
    description: String,
  },
};
</script>
