import * as Msal from "@azure/msal-browser";
import { config } from "@/config/bzbsconfig.js";
import Cache from "@/helper/Cache";
const myMSAL = new Msal.PublicClientApplication(config.msalConfig);

export default {
  methods: {
    errorApi(error) {
      if (error.response && error.response.status == 401) {
        this.errorUnauthorized();
      } else {
        if (
          error.response.data &&
          error.response.data &&
          error.response.data.error[0].id == 2001
        ) {
          this.$router.push({
            name: "unauthorized",
            query: {
              message: error.response.data.error[0].message,
            },
          });
        } else {
          let newData = {
            status: "99999-99999",
            description: "Is Something Error",
          };
          if (error.response) {
            newData = {
              status: error.response.status,
              description: error.response.statusText,
            };
            if (error.response.data) {
              if (error.response.data.error) {
                newData.error = error.response.data.error[0];
              }
              if (error.response.data.transaction_id) {
                newData.transaction_id = error.response.data.transaction_id;
              }
            }
          }
          setTimeout(() => {
            newData.labelid = newData.transaction_id
              ? "Transaction ID : "
              : "ID : ";
            newData.transaction_id = newData.transaction_id
              ? newData.transaction_id
              : newData.error
              ? newData.error.id
              : "9999";
            let errorCode = newData.error ? newData.error.code : "9999";
            let errorMessage = newData.error
              ? newData.error.message
              : "Is Something Error";
            const h = this.$createElement;
            this.$error({
              centered: true,
              autoFocusButton: null,
              title: this.lbls.user_page.modal.title_error + ": " + errorCode,
              content: h("span", [
                h(
                  "p",
                  " ( " + newData.labelid + newData.transaction_id + " ) "
                ),
                h("span", {
                  domProps: {
                    innerHTML: errorMessage,
                  },
                }),
              ]),
              okType: "default",
              okText: "Close",
            });
            this.alertData = newData;
            // this.modalAlert = {
            //   id: "error",
            //   visible: true,
            //   title: this.lbls.user_page.modal.title_error,
            // };
            this.isModalResponse = true;
            this.handleLoading(false);
          }, 100);
        }
      }
    },
    errorUnauthorized() {
      this.handleLoading(true);
      let refeshToken = config.refeshToken;
      refeshToken.account = Cache.get("msAccount");
      myMSAL
        .acquireTokenSilent(refeshToken)
        .then((res) => {
          Cache.set("msToken", res.accessToken);
          this.$router.go();
        })
        .catch(() => {
          if (Cache.get("msToken")) {
            this.$router.push({ name: "Redirectms" });
          } else {
            Cache.remove("msToken");
            this.$router.push({ name: "Home" });
          }
        });
    },
  },
};
