<template>
  <div>
    <a-modal
      v-model="modalprocess.visible"
      centered
      class="modal-with-content"
      :closable="false"
      :mask-closable="false"
    >
      <template slot="title">
        <div>
          <span>{{ modalprocess.title }}</span>
        </div>
      </template>
      <div>
        <a-form :form="form" layout="vertical">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item
                :label="lbls.applicationaccount_page.input_name.name"
              >
                <a-input
                  v-decorator="[
                    'appid',
                    {
                      rules: [
                        {
                          required: true,
                          message:
                            lbls.applicationaccount_page.input_name.required,
                        },
                      ],
                    },
                  ]"
                  :max-length="maxLengthAppId"
                  :placeholder="
                    lbls.applicationaccount_page.input_name.placeholder
                  "
                  @keyup="countChar"
                />
              </a-form-item>
              <p class="total-char" href="">
                {{ totalAppId.toLocaleString() }} /
                {{ maxLengthAppId.toLocaleString() }}
              </p>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template slot="footer">
        <a-button size="large" @click="handleClose">
          {{
            modalprocess.cancelText
              ? modalprocess.cancelText
              : lbls.user_page.btn.cancel
          }}
        </a-button>
        <a-button type="primary" :disabled="disabledok" @click="handleSave">
          {{
            modalprocess.okText ? modalprocess.okText : lbls.user_page.btn.save
          }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ManageProduct",
  props: {
    modalprocess: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      maxLengthName: 100,
      maxLengthFullName: 100,
      maxLengthEmail: 100,
      disabledok: true,
      objDataSave: "",
      selectMembers: "",
      roleID: "",
      validateStatus: "",
      msgEmail: "",
      tagValue: "",
      selectCross: "",
      totalAppId: 0,
      maxLengthAppId: 100,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.form.resetFields();
    },
    handleClose() {
      this.modalprocess.visible = false;
      this.$emit("actionModal", "close");
    },
    handleSave() {
      this.$emit("actionModal", this.objDataSave);
    },
    checkBTN() {
      if (this.objDataSave.length > 0) {
        this.disabledok = false;
      }
    },
    countChar(e) {
      this.disabledok = true;
      this.objDataSave = e.target.value;
      this.totalAppId = e.target.value.length;
      this.checkBTN();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/_mixin.scss";
</style>
